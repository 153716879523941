import React from 'react';
import { Table, Button, Icon } from 'semantic-ui-react';
import { CSVLink } from 'react-csv';
import { getActivity } from '../../../reducers/investigation-tools/selectors';
import { useActivityPageContext } from '../../../views/investigation-tools/activity-page/ActivityPageContext';
import PaginationController from '../common/PaginationController';

const ActivityPageTableContainer = () => {
  const { getState, paginationDispatch, handleSearch } =
    useActivityPageContext();
  const state = getState();
  const { entries } = getActivity(state);
  return (
    <div style={{ overflowX: 'auto' }}>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Timestamp</Table.HeaderCell>
            <Table.HeaderCell>Interface Profile ID</Table.HeaderCell>
            <Table.HeaderCell>Event Type</Table.HeaderCell>
            <Table.HeaderCell>Event Profile ID</Table.HeaderCell>
            <Table.HeaderCell>Login ID</Table.HeaderCell>
            <Table.HeaderCell>Event Login ID</Table.HeaderCell>
            <Table.HeaderCell>Login</Table.HeaderCell>
            <Table.HeaderCell>Event Login</Table.HeaderCell>
            <Table.HeaderCell>Added Email Address</Table.HeaderCell>
            <Table.HeaderCell>Added Phone Number</Table.HeaderCell>
            <Table.HeaderCell>Results</Table.HeaderCell>
            <Table.HeaderCell>Device ID</Table.HeaderCell>
            <Table.HeaderCell>Cookie ID</Table.HeaderCell>
            <Table.HeaderCell>Session ID</Table.HeaderCell>
            <Table.HeaderCell>IP City</Table.HeaderCell>
            <Table.HeaderCell>IP Country</Table.HeaderCell>
            <Table.HeaderCell>IP</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {entries && entries.length > 0 ? (
            entries.map((entry, id) => {
              return (
                <Table.Row key={id}>
                  <Table.Cell>{entry.timestamp}</Table.Cell>
                  <Table.Cell>{entry.interfaceProfileId}</Table.Cell>
                  <Table.Cell>{entry.eventType}</Table.Cell>
                  <Table.Cell>{entry.eventProfileId}</Table.Cell>
                  <Table.Cell>{entry.loginId}</Table.Cell>
                  <Table.Cell>{entry.eventLoginId}</Table.Cell>
                  <Table.Cell>{entry.loginMethod}</Table.Cell>
                  <Table.Cell>{entry.eventLoginMethod}</Table.Cell>
                  <Table.Cell>{entry.addedEmailAddress}</Table.Cell>
                  <Table.Cell>{entry.addedPhoneNumber}</Table.Cell>
                  <Table.Cell>{entry.results}</Table.Cell>
                  <Table.Cell>{entry.deviceId}</Table.Cell>
                  <Table.Cell>{entry.cookieId}</Table.Cell>
                  <Table.Cell>{entry.sessionId}</Table.Cell>
                  <Table.Cell>{entry.ipCity}</Table.Cell>
                  <Table.Cell>{entry.ipState}</Table.Cell>
                  <Table.Cell>{entry.ip}</Table.Cell>
                </Table.Row>
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell colSpan={'13'} style={{ textAlign: 'center' }}>
                - No Data -
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <PaginationController
        dispatch={paginationDispatch}
        state={state}
        tableName={'ACTIVITY_TABLE'}
        onPageNumberClick={handleSearch}
      />
      {entries && entries.length > 0 && (
        <Button style={{ marginTop: '20px' }}>
          <CSVLink data={entries} filename={'activity_list.csv'}>
            <Icon name="download" /> Download CSV
          </CSVLink>
        </Button>
      )}
    </div>
  );
};

export default ActivityPageTableContainer;
