import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize';
import { RemediationAction } from '../../../types/caseManagement';
import { toast } from 'react-toastify';
import {
  NOTES_MAX_LENGTH,
  NOTES_DISPLAY_LINE
} from '../../../constants/caseManagement';

type Props = {
  open: boolean;
  action: RemediationAction;
  onSubmit?: (action: RemediationAction, note: string) => void;
  onCancel?: () => void;
};

const getActionName = (action: RemediationAction) => {
  if (action === RemediationAction.DEACTIVATE) return 'Account Deactivation';
  if (action === RemediationAction.RESET_PASSWORD) return 'Reset Password';
  if (action === RemediationAction.FORCE_LOGOUT) return 'Force Logout';

  return '';
};

const RemediationModal = ({ open, action, onSubmit, onCancel }: Props) => {
  const [note, setNote] = useState('');
  const actionName = getActionName(action);

  const handleSubmit = () => {
    if (!note.trim()) {
      toast.error('Remediation note is required.');

      return;
    }

    onSubmit?.(action, note);
  };

  useEffect(() => {
    if (open === true) {
      setNote('');
    }
  }, [open]);

  return (
    <Modal open={open} size="tiny">
      <Modal.Header>Confirmation</Modal.Header>
      <Modal.Content>
        <Form>
          <div className="title">
            <b>{actionName} (Profile ID)</b>
          </div>

          <div className="note">
            <label>
              {actionName} Notes*
              <TextareaAutosize
                className="no-resize"
                rows={1}
                value={note}
                maxLength={NOTES_MAX_LENGTH}
                maxRows={NOTES_DISPLAY_LINE}
                onChange={evt => setNote(evt.currentTarget.value)}
              />
            </label>
          </div>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Cancel" onClick={onCancel} />
        <Button primary content="Submit" onClick={handleSubmit} />
      </Modal.Actions>
    </Modal>
  );
};

export default RemediationModal;
