import { Operator, Operators } from '../../types/decisionTree';

const STRING_UNARY_OPERATORS: Operator[] = [
  { name: 'IS_NULL', sign: 'is_null' },
  { name: 'IS_NOT_NULL', sign: 'is_not_null' }
];
const NUMERIC_UNARY_OPERATORS: Operator[] = [];

const BOOLEAN_UNARY_OPERATORS: Operator[] = [
  { name: 'IS_TRUE', sign: 'is_true' },
  { name: 'IS_FALSE', sign: 'is_false' },
  { name: 'IS_NULL', sign: 'is_null' }
];

const STRING_MULTIVALUE_OPERATORS: Operator[] = [
  { name: 'IN', sign: 'in' },
  { name: 'NOT_IN', sign: 'not_in' }
];
const NUMERIC_MULTIVALUE_OPERATORS: Operator[] = [
  { name: 'IN', sign: 'in' },
  { name: 'NOT_IN', sign: 'not_in' }
];

export const isUnaryOperator = (operatorNameOrSign: string) => {
  return (
    STRING_UNARY_OPERATORS.filter(
      unaryOp =>
        unaryOp.name === operatorNameOrSign ||
        unaryOp.sign === operatorNameOrSign
    ).length > 0 ||
    NUMERIC_UNARY_OPERATORS.filter(
      unaryOp =>
        unaryOp.name === operatorNameOrSign ||
        unaryOp.sign === operatorNameOrSign
    ).length > 0 ||
    BOOLEAN_UNARY_OPERATORS.filter(
      unaryOp =>
        unaryOp.name === operatorNameOrSign ||
        unaryOp.sign === operatorNameOrSign
    ).length > 0
  );
};

export const isMultiValueOperator = (operatorNameOrSign: string) => {
  return (
    STRING_MULTIVALUE_OPERATORS.filter(
      multiValueOp =>
        multiValueOp.name === operatorNameOrSign ||
        multiValueOp.sign === operatorNameOrSign
    ).length > 0 ||
    NUMERIC_MULTIVALUE_OPERATORS.filter(
      multiValueOp =>
        multiValueOp.name === operatorNameOrSign ||
        multiValueOp.sign === operatorNameOrSign
    ).length > 0
  );
};

export const getOperator = (operators: Operators, nameOrSign: string) => {
  const keys = Object.keys(operators);

  for (let i = 0; i < keys.length; i++) {
    const k = keys[i];
    const v = operators[k as keyof Operators];

    const r = v.find(op => op.name === nameOrSign || op.sign === nameOrSign);

    if (r) return r;
  }

  return undefined;
};
