import React, { useEffect, useReducer } from 'react';
import { Loader } from 'semantic-ui-react';

import {
  initialState,
  UserProfileContextSearchReducer
} from '../../../reducers/investigation-tools/userProfileContextSearchReducer';
import {
  goToFirstPage,
  initPagination,
  setTotalNumberOfPages
} from '../../../actions/investigation-tools/paginationAction';
import paginationReducer from '../../../reducers/investigation-tools/paginationReducer';
import { fetchUserProfileContextSearch } from '../../../actions/investigation-tools/userProfileContextSearchAction';
import {
  setKeyword,
  setSearchType
} from '../../../actions/investigation-tools/commonAction';
import {
  PaginationType,
  UserProfileSearchType
} from '../../../constants/investigationToolsConstant';
import { userProfileContextSearchLabels } from '../../../constants/investigationToolsLabels';
import { getUserProfileContextSearch } from '../../../reducers/investigation-tools/selectors';

import UserProfileContextSearchContext, {
  useUserProfileContextSearchContext
} from './UserProfileContextSearchContext';
import UserProfileContextSearchTable from '../../../components/investigation-tools/user-profile-context-search/UserProfileContextSearchTable';
import Layout from '../../../components/Layout';
import SummaryCard from '../../../components/investigation-tools/common/SummaryCard';

const UserProfileContextSearch = () => {
  const [state, dispatch] = useReducer(
    UserProfileContextSearchReducer,
    initialState
  );
  const [paginationState, paginationDispatch] = useReducer(
    paginationReducer,
    {}
  );

  const { isLoading } = state;

  useEffect(() => {
    paginationDispatch(
      initPagination([PaginationType.USER_PROFILE_CONTEXT_SEARCH_TABLE])
    );
  }, []);

  const getCombinedState = () => ({
    user_profile_context: state,
    pagination: paginationState
  });

  const handleSearch = (toFirstPage = false) => {
    if (toFirstPage) {
      paginationDispatch(
        goToFirstPage(PaginationType.USER_PROFILE_CONTEXT_SEARCH_TABLE)
      );
    }
    setTimeout(() => {
      fetchUserProfileContextSearch(dispatch, getCombinedState).then(result => {
        let totalPages = 1;
        const { totalNumberOfPages } =
          paginationState[PaginationType.USER_PROFILE_CONTEXT_SEARCH_TABLE];

        if (result && result.data) {
          const { pageCount } = result.data;
          totalPages = pageCount;
        }
        if (totalNumberOfPages !== totalPages) {
          paginationDispatch(
            setTotalNumberOfPages(
              PaginationType.USER_PROFILE_CONTEXT_SEARCH_TABLE,
              totalPages
            )
          );
        }
      });
    }, 500);
  };

  return (
    <UserProfileContextSearchContext.Provider
      value={{
        getState: getCombinedState,
        dispatch,
        paginationDispatch,
        handleSearch
      }}
    >
      <Layout>
        <h1>User Profile Context Search</h1>
        <SummaryCard
          useContext={useUserProfileContextSearchContext}
          useSelector={getUserProfileContextSearch}
          searchTypeConstants={UserProfileSearchType}
          setKeyword={setKeyword}
          setSearchType={setSearchType}
          labels={userProfileContextSearchLabels}
        />
        <Loader active={isLoading} />
        <div style={{ marginBottom: '20px' }} />
        {!isLoading && <UserProfileContextSearchTable />}
      </Layout>
    </UserProfileContextSearchContext.Provider>
  );
};

export default UserProfileContextSearch;
