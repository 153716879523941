import React, { useContext, useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Loading from './components/Loading';
import { useAuth0 } from './react-auth0-spa';
import config from './configs';

import Settings from './views/settings/Settings';
import ListSearch from './views/list-management/list-search/ListSearch';
import ListAddition from './views/list-management/list-addition/ListAddition';
import RuleManagement from './views/rule-management/RuleManagement';
import InvestigationToolsBookingDetail from './views/investigation-tools/booking-detail/BookingDetail';
import InvestigationToolsProfileSummary from './views/investigation-tools/profile-summary/ProfileSummary';
import InvestigationToolsProfileSummaryProfileList from './views/investigation-tools/profile-summary/UserProfileList';
import InvestigationToolsActivitySearch from './views/investigation-tools/activity-page/ActivityPage';
import InvestigationToolsConnectionSearch from './views/investigation-tools/connection-search/ConnectionSearch';
import UserProfileContextSearch from './views/investigation-tools/user-profile-context-search/UserProfileContextSearch';
import UserProfileData from './views/investigation-tools/user-profile-data/UserProfileData';
import KycData from './views/investigation-tools/kyc-data/KycData';
import DriverProfileContextSearch from './views/investigation-tools/driver-profile-context-search/DriverProfileContextSearch';
import FraudCheckHistory from './views/investigation-tools/fraud-check-history/FraudCheckHistory';
import DriverData from './views/investigation-tools/driver-data/DriverData';
import CommActivity from './views/investigation-tools/comm-activity/CommActivity';
import MerchantData from './views/investigation-tools/merchant-data/MerchantData';
import PaymentData from './views/investigation-tools/payment-data/PaymentData';

// styles
import './App.css';

// fontawesome
import initFontAwesome from './utils/initFontAwesome';
import {
  CASE_READ,
  CASE_QUEUE_READ,
  INVESTIGATION_TOOLS_READ
} from './constants/userPermissions';
import axios from 'axios';
import PermissionRequiredRoute from './components/route/PermissionRequiredRoute';
import ProductApiRequiredRoute from './components/route/ProductApiRequiredRoute';
import RestrictedRoute from './components/route/RestrictedRoute';
import CaseDetails from './views/case-management/CaseDetails';
import DecisionTree from './views/decision-tree/DecisionTree';
import { AppContext } from './AppContext';
import { initAxiosInterceptor } from './utils/axiosUtils';
import CaseSearch from './views/case-management/CaseSearch';
import CaseSimilarSearch from './views/case-management/CaseSimilarSearch';
import CaseQueue from './views/case-management/CaseQueue';
import Default from './components/Default';
import PopulatedSideNav from './components/PopulatedSideNav';

initFontAwesome();

const App = () => {
  const { isAuthenticated, getTokenSilently } = useAuth0();

  const [isProductApiLoading, setIsProductApiLoading] = useState(true);
  const [appState, dispatch] = useContext(AppContext);

  useEffect(() => {
    initAxiosInterceptor(getTokenSilently, showLoading => {
      dispatch({ type: showLoading ? 'SHOW_LOADING' : 'HIDE_LOADING' });
    });

    axios
      .get(config.productApi)
      .then(res => {
        if (res && res.data) {
          dispatch({
            type: 'INIT_PRODUCT_APIS',
            payload: { productApis: res.data }
          });
        }
      })
      .catch(err => {
        toast.error(
          'Error fetching product Api. Error: ' + (err && err.message)
        );
      })
      .finally(() => {
        setIsProductApiLoading(false);
      });
  }, [dispatch, getTokenSilently]);

  useEffect(() => {
    const initUserPermissions = async () => {
      try {
        const token = await getTokenSilently();
        dispatch({ type: 'INIT_USER_PERMISSION', payload: { token } });
      } catch (err) {
        console.error('error getting token value: ', err);
      }
    };

    initUserPermissions();
  }, [dispatch, getTokenSilently]);

  if (isProductApiLoading) return <Loading />;

  return (
    <Router>
      {isAuthenticated && appState.userPermissions && (
        <div>
          <PopulatedSideNav />
          <Route path="/" exact component={ListAddition} />
          <Switch>
            <Redirect from={config.appCallback} to="/" />
            <RestrictedRoute path="/settings/" component={Settings} />
            <PermissionRequiredRoute
              path="/case-details/:id"
              component={CaseDetails}
              permissions={[CASE_READ]}
            />
            <PermissionRequiredRoute
              path="/case-similar-search/:caseId/:searchField"
              component={CaseSimilarSearch}
              permissions={[CASE_READ]}
            />
            <PermissionRequiredRoute
              path="/case-queue/"
              component={CaseQueue}
              permissions={[CASE_QUEUE_READ]}
            />
            <PermissionRequiredRoute
              path="/case-search/"
              component={CaseSearch}
              permissions={[CASE_READ]}
            />
            <RestrictedRoute path="/list-search/" component={ListSearch} />
            <RestrictedRoute path="/list-addition/" component={ListAddition} />
            <ProductApiRequiredRoute
              path="/decision-tree/edit/:productId?/:treeId?/"
              component={DecisionTree}
              productApiParam="productId"
            />
            <ProductApiRequiredRoute
              path="/rule-management/:productId/"
              component={RuleManagement}
              productApiParam="productId"
            />
            <PermissionRequiredRoute
              path="/investigation-tools/booking-detail"
              component={InvestigationToolsBookingDetail}
              permissions={[INVESTIGATION_TOOLS_READ.BOOKING_DETAIL]}
            />
            <PermissionRequiredRoute
              path="/investigation-tools/profile-summary"
              component={InvestigationToolsProfileSummary}
              permissions={[INVESTIGATION_TOOLS_READ.PROFILE_SUMMARY]}
              exact
            />
            <PermissionRequiredRoute
              path="/investigation-tools/profile-summary/profile-list/:profileId"
              component={InvestigationToolsProfileSummaryProfileList}
              permissions={[INVESTIGATION_TOOLS_READ.PROFILE_SUMMARY]}
            />
            <PermissionRequiredRoute
              path="/investigation-tools/activity-page"
              component={InvestigationToolsActivitySearch}
              permissions={[INVESTIGATION_TOOLS_READ.ACTIVITY]}
            />
            <PermissionRequiredRoute
              path="/investigation-tools/connection-search"
              component={InvestigationToolsConnectionSearch}
              permissions={[
                INVESTIGATION_TOOLS_READ.CONNECTION_SEARCH_APPLICATION,
                INVESTIGATION_TOOLS_READ.CONNECTION_SEARCH_BOOKING
              ]}
            />
            <PermissionRequiredRoute
              path="/investigation-tools/user-profile-context-search"
              component={UserProfileContextSearch}
              permissions={[
                INVESTIGATION_TOOLS_READ.USER_PROFILE_CONTEXT_SEARCH
              ]}
            />
            <PermissionRequiredRoute
              path="/investigation-tools/user-profile-data/:id"
              component={UserProfileData}
              permissions={[INVESTIGATION_TOOLS_READ.USER_PROFILE_DATA]}
            />
            <PermissionRequiredRoute
              path="/investigation-tools/kyc-data/:id"
              component={KycData}
              permissions={[INVESTIGATION_TOOLS_READ.KYC_DATA]}
            />
            <PermissionRequiredRoute
              path="/investigation-tools/driver-profile-context-search"
              component={DriverProfileContextSearch}
              permissions={[
                INVESTIGATION_TOOLS_READ.DRIVER_PROFILE_CONTEXT_SEARCH
              ]}
            />
            <PermissionRequiredRoute
              path="/investigation-tools/fraud-check-history"
              component={FraudCheckHistory}
              permissions={[INVESTIGATION_TOOLS_READ.FRAUD_CHECK_HISTORY]}
            />
            <PermissionRequiredRoute
              path="/investigation-tools/driver-data/:id"
              component={DriverData}
              permissions={[INVESTIGATION_TOOLS_READ.DRIVER_DATA]}
            />
            <PermissionRequiredRoute
              path="/investigation-tools/comm-activity"
              component={CommActivity}
              permissions={[INVESTIGATION_TOOLS_READ.COMM_ACTIVITY]}
            />
            <PermissionRequiredRoute
              path="/investigation-tools/merchant-data"
              component={MerchantData}
              permissions={[INVESTIGATION_TOOLS_READ.MERCHANT_DATA]}
            />
            <PermissionRequiredRoute
              path="/investigation-tools/payment-data"
              component={PaymentData}
              permissions={[INVESTIGATION_TOOLS_READ.PAYMENT_DATA]}
            />
            <Route component={Default} />
          </Switch>
          <ToastContainer
            autoClose={2500}
            hideProgressBar={true}
            newestOnTop={true}
          />
        </div>
      )}
    </Router>
  );
};

export default App;
