import React from 'react';
import { CommonAPIResponse } from '../../../types/caseManagement';
import { Card } from 'semantic-ui-react';
import CaseAPIResponseFormatter from './CaseAPIResponseFormatter';
import { detectorContentStyle } from './CaseDetectorResponse.style';

type Props = {
  data?: CommonAPIResponse;
};

const CaseProductRequestDetails = ({ data }: Props) => {
  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>Product Request Details</Card.Header>
      </Card.Content>
      <Card.Content className={detectorContentStyle}>
        {data ? <CaseAPIResponseFormatter data={data} /> : 'No Data'}
      </Card.Content>
    </Card>
  );
};

export default CaseProductRequestDetails;
