import React from 'react';
import { Form } from 'semantic-ui-react';
import ReactDatePicker from 'react-datepicker';
import { format } from 'date-fns';

const DATE_FORMAT = 'MM/dd/yyyy';

const DateRangePicker = ({
  label,
  startDate,
  endDate,
  setStartDate,
  setEndDate
}) => {
  const dateRange =
    startDate && endDate
      ? format(startDate, DATE_FORMAT) + ' - ' + format(endDate, DATE_FORMAT)
      : startDate
      ? format(startDate, DATE_FORMAT)
      : '';

  const onDateChange = dates => {
    if (dates) {
      const [startDate, endDate] = dates;
      setStartDate(startDate);
      setEndDate(endDate);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  return (
    <Form.Field>
      <label>{label ? label : 'Date range'}</label>
      <ReactDatePicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={onDateChange}
        isClearable={true}
        selected={startDate}
        value={dateRange}
        placeholderText="Select start date and end date"
        shouldCloseOnSelect={startDate}
      />
    </Form.Field>
  );
};

export default DateRangePicker;
