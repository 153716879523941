import React, { useState, useEffect } from 'react';
import { Form, Button } from 'semantic-ui-react';
import {
  DateFilterType,
  MultipleCriteriaFilterData,
  FilterDataType
} from '../../../types/caseManagement';
import { toast } from 'react-toastify';
import {
  CATEGORY_FILTER_LABEL,
  FINAL_RECOMMENDATION_FILTER_LABEL,
  MARKING_STATUS_FILTER_LABEL
} from '../../../constants/caseManagement';
import { getStartAndEndDateFromType } from '../../../utils/case-management/dateFilter';
import DateFilterInput from './DateFilterInput';
import configs from '../../../configs';
import axios from 'axios';

type Props = {
  onSubmit?: (filterData: MultipleCriteriaFilterData) => void;
  onReset?: () => void;
};

/**
 * Search form for the Multiple Criteria tab in Case Search.
 */
const MultipleFieldSearchForm = ({ onSubmit, onReset }: Props) => {
  const [category, setCategory] = useState('ALL');
  const [finalRecommendation, setFinalRecommendation] = useState('ALL');
  const [markingStatus, setMarkingStatus] = useState('ALL');
  const [dateType, setDateType] = useState(DateFilterType.LAST_HOUR);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [categoryResponse, setCategoryResponse] = useState<
    { id: number; name: string }[]
  >([]);

  const [categoryOptions, setCategoryOptions] = useState([
    {
      key: 'ALL',
      value: 'ALL',
      text: 'All'
    }
  ]);
  const [finalRecommendationOptions, setFinalRecommendationOptions] = useState([
    {
      key: 'ALL',
      value: 'ALL',
      text: 'All'
    }
  ]);
  const [markingStatusOptions, setMarkingStatusOptions] = useState([
    {
      key: 'ALL',
      value: 'ALL',
      text: 'All'
    }
  ]);

  const setDateTypeAndValue = (type: DateFilterType) => {
    setDateType(type as DateFilterType);

    const { startDate, endDate } = getStartAndEndDateFromType(type);

    setStartDate(startDate);
    setEndDate(endDate);
  };

  const reset = () => {
    setCategory('ALL');
    setFinalRecommendation('ALL');
    setMarkingStatus('ALL');
    setDateTypeAndValue(DateFilterType.LAST_HOUR);

    onReset?.();
  };

  const submit = () => {
    onSubmit?.({
      type: FilterDataType.MULTIPLE_CRITERIA,
      category,
      finalRecommendation,
      markingStatus,
      dateType,
      startDate,
      endDate
    });
  };

  const onDateChange = (
    dateType: DateFilterType,
    startDate: Date,
    endDate: Date
  ) => {
    setDateType(dateType);
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const fetchCategoryOptions = async () => {
    try {
      const response = await axios.get(configs.caseCategories);

      setCategoryResponse(response.data);

      setCategoryOptions([
        {
          key: 'ALL',
          value: 'ALL',
          text: 'All'
        },
        ...response.data.map((res: { name: string; displayName: string }) => ({
          key: res.name,
          value: res.name,
          text: res.displayName
        }))
      ]);
    } catch (err) {
      if (err?.response?.data?.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error('Error loading Categories. Error: ' + (err && err.message));
      }
    }
  };

  const fetchMarkingStatusOptions = async () => {
    try {
      const response = await axios.get(configs.caseMarkingStatuses);

      setMarkingStatusOptions([
        {
          key: 'ALL',
          value: 'ALL',
          text: 'All'
        },
        ...response.data.map((res: { name: string; displayName: string }) => ({
          key: res.name,
          value: res.name,
          text: `Marked as ${res.displayName}`
        }))
      ]);
    } catch (err) {
      if (err?.response?.data?.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error(
          'Error loading Marking Status. Error: ' + (err && err.message)
        );
      }
    }
  };

  const setDistinctFinalRecommendationOptions = (
    data: { finalRecommendation: string }[]
  ) => {
    const seen: Record<string, boolean> = {};
    const options = [
      {
        key: 'ALL',
        value: 'ALL',
        text: 'All'
      }
    ];

    data.forEach(fr => {
      if (seen[fr.finalRecommendation]) return;

      options.push({
        key: fr.finalRecommendation,
        value: fr.finalRecommendation,
        text: fr.finalRecommendation
      });

      seen[fr.finalRecommendation] = true;
    });

    setFinalRecommendationOptions(options);
  };

  const fetchFinalRecommendationOptions = async () => {
    try {
      const categoryId = categoryResponse.find(c => c.name === category)?.id;

      const response = await axios.get(
        `${configs.caseFinalRecommendations}?productId=${categoryId}`
      );

      setDistinctFinalRecommendationOptions(response.data);
    } catch (err) {
      toast.error(
        `Error fetching final recommendation data. Error: ${err && err.message}`
      );
    }
  };

  useEffect(() => {
    setDateTypeAndValue(DateFilterType.LAST_HOUR);

    fetchCategoryOptions();
    fetchMarkingStatusOptions();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (category !== 'ALL') {
      fetchFinalRecommendationOptions();
    } else {
      setFinalRecommendationOptions([
        {
          key: 'ALL',
          value: 'ALL',
          text: 'All'
        }
      ]);
    }

    setFinalRecommendation('ALL');
  }, [category]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form>
      <Form.Select
        width={8}
        label={CATEGORY_FILTER_LABEL}
        options={categoryOptions}
        value={category}
        onChange={(evt, data) => setCategory(data.value as string)}
      />
      <Form.Select
        width={8}
        search
        label={FINAL_RECOMMENDATION_FILTER_LABEL}
        options={finalRecommendationOptions}
        value={finalRecommendation}
        onChange={(evt, data) => setFinalRecommendation(data.value as string)}
      />
      <Form.Select
        width={8}
        label={MARKING_STATUS_FILTER_LABEL}
        options={markingStatusOptions}
        value={markingStatus}
        onChange={(evt, data) => setMarkingStatus(data.value as string)}
      />
      <DateFilterInput
        dateType={dateType}
        startDate={startDate}
        endDate={endDate}
        onChange={onDateChange}
      />

      <Button primary icon="search" content="Search" onClick={submit} />
      <Button icon="undo" content="Reset" onClick={reset} />
    </Form>
  );
};

export default MultipleFieldSearchForm;
