import React, { useState, useEffect } from 'react';
import { Form, Modal, Button } from 'semantic-ui-react';

type Props = {
  open: boolean;
  defaultContent?: {
    name: string;
    description: string;
    detectorCallsAsync: boolean;
  };
  onClose?: () => void;
  onExport?: (
    name: string,
    description: string,
    dectectorCallsAsync: boolean
  ) => void;
};

type InputError =
  | false
  | {
      content: string;
    };

const ExportDTModal = ({ open, defaultContent, onClose, onExport }: Props) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [detectorCallsAsync, setDetectorCallsAsync] = useState(true);

  const [nameError, setNameError] = useState<InputError>(false);
  const [descriptionError, setDescriptionError] = useState<InputError>(false);

  const handleNameChange = (value: string) => {
    setNameError(false);
    setName(value);
  };

  const handleDescriptionChange = (value: string) => {
    setDescriptionError(false);
    setDescription(value);
  };

  const error = (message: string) => {
    return {
      content: message
    };
  };

  const handleExport = () => {
    if (!name || !name.trim()) {
      setNameError(error('Name cannot be empty'));

      return;
    }

    if (!description || !description.trim()) {
      setDescriptionError(error('Description cannot be empty'));

      return;
    }

    onExport?.(name, description, detectorCallsAsync);
  };

  useEffect(() => {
    setNameError(false);
    setDescriptionError(false);
  }, [open]);

  useEffect(() => {
    setName(defaultContent?.name || '');
    setDescription(defaultContent?.description || '');
    setDetectorCallsAsync(
      defaultContent ? defaultContent.detectorCallsAsync : true
    );
  }, [defaultContent]);

  return (
    <div>
      <Modal open={open} size="small">
        <Modal.Header>Export decision graph</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group widths={3}>
              <Form.Field label={'Name for new graph'} required />
              <Form.Input
                value={name}
                error={nameError}
                onChange={(evt, { value }) => handleNameChange(value)}
                placeholder={'Name'}
                width={8}
              />
            </Form.Group>
            <Form.Group widths={3}>
              <Form.Field label={'Description for new graph'} required />
              <Form.Input
                value={description}
                error={descriptionError}
                onChange={(evt, { value }) => handleDescriptionChange(value)}
                placeholder={'Description'}
                width={8}
              />
            </Form.Group>
            <Form.Group widths={3}>
              <Form.Field label={'Run detectors asynchronously'} required />
              <Form.Checkbox
                checked={detectorCallsAsync}
                onChange={() => setDetectorCallsAsync(!detectorCallsAsync)}
                width={8}
              />
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button icon="delete" content="Close" onClick={onClose} />
          <Button
            primary
            icon="download"
            content="Export"
            onClick={handleExport}
          />
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default ExportDTModal;
