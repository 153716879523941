import React from 'react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { Table } from 'semantic-ui-react';
import './ProfileSummaryComponents.style.css';

const UserProfileListTableContainer = ({ profileList }) => {
  return (
    <div style={{ marginBottom: '20px' }}>
      <div style={{ overflowX: 'auto' }}>
        <Table className="profile-summary-profile-list-table" celled>
          <Table.Header>
            <Table.Row className="profile-summary-profile-list-table-column-name-row">
              <Table.HeaderCell>Profile ID</Table.HeaderCell>
              <Table.HeaderCell>Customer ID</Table.HeaderCell>
              <Table.HeaderCell>Customer Name</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Phone Number</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {isArray(profileList) && !isEmpty(profileList) ? (
              profileList.map(entry => {
                const profileId = get(entry, 'profileId', null) || '-';
                const customerId = get(entry, 'customerId', null) || '-';
                const kycNames = get(entry, 'kycNames', null);
                const kycEmails = get(entry, 'kycEmails', null);
                const kycPhoneNumbers = get(entry, 'kycPhoneNumbers', null);

                return (
                  <Table.Row
                    className="profile-summary-profile-list-entry-table-row"
                    key={`${profileId}-${customerId}`}
                  >
                    <Table.Cell width={2} selectable>
                      <a
                        href={`/investigation-tools/user-profile-data/${profileId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {profileId}
                      </a>
                    </Table.Cell>
                    <Table.Cell width={2} selectable>
                      <a
                        href={`/investigation-tools/kyc-data/${customerId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {customerId}
                      </a>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      {isArray(kycNames) && !isEmpty(kycNames)
                        ? kycNames.map((name, i) => {
                            return (
                              <div key={`${profileId}-${customerId}-${name}`}>
                                {name}
                                {i < kycNames.length - 1 ? ',' : ''}
                              </div>
                            );
                          })
                        : '-'}
                    </Table.Cell>
                    <Table.Cell width={4}>
                      {isArray(kycEmails) && !isEmpty(kycEmails)
                        ? kycEmails.map((email, i) => {
                            return (
                              <div key={`${profileId}-${customerId}-${email}`}>
                                {email}
                                {i < kycEmails.length - 1 ? ',' : ''}
                              </div>
                            );
                          })
                        : '-'}
                    </Table.Cell>
                    <Table.Cell width={4}>
                      {isArray(kycPhoneNumbers) && !isEmpty(kycPhoneNumbers)
                        ? kycPhoneNumbers.map((phoneNumber, i) => {
                            return (
                              <div
                                key={`${profileId}-${customerId}-${phoneNumber}`}
                              >
                                {phoneNumber}
                                {i < kycPhoneNumbers.length - 1 ? ',' : ''}
                              </div>
                            );
                          })
                        : '-'}
                    </Table.Cell>
                  </Table.Row>
                );
              })
            ) : (
              <Table.Row className="profile-summary-profile-list-no-data-table-row">
                <Table.Cell colSpan={'15'} style={{ textAlign: 'center' }}>
                  - No Data -
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

export default UserProfileListTableContainer;
