import React from 'react';
import { Table } from 'semantic-ui-react';
import PaginationContainer from '../common/PaginationContainer';
import { getFraudCheckHistory } from '../../../reducers/investigation-tools/selectors';
import { useFraudCheckHistoryContext } from '../../../views/investigation-tools/fraud-check-history/FraudCheckHistoryContext';
import { format, parseISO } from 'date-fns';

type Entry = {
  timestamp: string;
  uuid: string;
  product: string;
  requestType: string;
  detectorName: string;
  result: string;
  decisionContext: string;
};

const DATE_FORMAT = 'LLL dd, yyyy HH:mm:ss a';

const FraudCheckHistoryTableContainer = () => {
  const { getState } = useFraudCheckHistoryContext();

  const state = getState();
  const { entries } = getFraudCheckHistory(state);

  return (
    <div>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Timestamp</Table.HeaderCell>
            <Table.HeaderCell>UU ID</Table.HeaderCell>
            <Table.HeaderCell>Product</Table.HeaderCell>
            <Table.HeaderCell>Request Type</Table.HeaderCell>
            <Table.HeaderCell>Detector Name</Table.HeaderCell>
            <Table.HeaderCell>Result</Table.HeaderCell>
            <Table.HeaderCell>Decision Context</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {entries && entries.length > 0 ? (
            entries.map((entry: Entry) => {
              return (
                <Table.Row key={entry.uuid}>
                  <Table.Cell>
                    {format(parseISO(entry.timestamp), DATE_FORMAT)}
                  </Table.Cell>
                  <Table.Cell>{entry.uuid}</Table.Cell>
                  <Table.Cell>{entry.product}</Table.Cell>
                  <Table.Cell>{entry.requestType}</Table.Cell>
                  <Table.Cell>{entry.detectorName}</Table.Cell>
                  <Table.Cell>{entry.result}</Table.Cell>
                  <Table.Cell>{entry.decisionContext}</Table.Cell>
                </Table.Row>
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell colSpan={'10'}>
                <p style={{ textAlign: 'center' }}>- No Data -</p>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <PaginationContainer tableName={'FRAUD_CHECK_HISTORY_TABLE'} />
    </div>
  );
};

export default FraudCheckHistoryTableContainer;
