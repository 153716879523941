import { DateFilterType } from '../../types/caseManagement';
import {
  subHours,
  startOfDay,
  endOfDay,
  subDays,
  startOfWeek,
  startOfMonth,
  subWeeks,
  endOfWeek,
  subMonths,
  endOfMonth,
  addDays
} from 'date-fns';

type StartAndEndDate = {
  startDate: Date;
  endDate: Date;
};

export const getStartAndEndDateFromType = (
  type: DateFilterType
): StartAndEndDate => {
  const today = new Date();

  switch (type) {
    case DateFilterType.LAST_HOUR:
      return {
        startDate: subHours(today, 1),
        endDate: today
      };
    case DateFilterType.LAST_MONTH:
      const lastMonth = subMonths(today, 1);

      return {
        startDate: startOfMonth(lastMonth),
        endDate: endOfMonth(lastMonth)
      };
    case DateFilterType.LAST_WEEK:
      const lastWeek = subWeeks(today, 1);

      return {
        startDate: startOfWeek(lastWeek, { weekStartsOn: 1 }),
        endDate: endOfWeek(lastWeek, { weekStartsOn: 1 })
      };
    case DateFilterType.LAST_12_MONTHS:
      const last12Months = addDays(subMonths(today, 12), 1);

      return {
        startDate: startOfDay(last12Months),
        endDate: endOfDay(today)
      };
    case DateFilterType.TODAY:
      return {
        startDate: startOfDay(today),
        endDate: endOfDay(today)
      };
    case DateFilterType.YESTERDAY:
      const yesterday = subDays(today, 1);

      return {
        startDate: startOfDay(yesterday),
        endDate: endOfDay(yesterday)
      };
    case DateFilterType.WEEK_TO_DATE:
      return {
        startDate: startOfWeek(today, { weekStartsOn: 1 }),
        endDate: endOfDay(today)
      };
    case DateFilterType.MONTH_TO_DATE:
      return {
        startDate: startOfMonth(today),
        endDate: endOfDay(today)
      };
    default:
      return {
        startDate: today,
        endDate: today
      };
  }
};
