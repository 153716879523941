import get from 'lodash/get';

import * as BookingDetailActionType from '../../types/investigation-tools/bookingDetailActionType';
import {
  BookingSearchType,
  MatchType,
  BookingDetailFilterAttributes
} from '../../constants/investigationToolsConstant';

type Entry = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

type BookingDetailState = {
  isLoading: boolean;
  isLoaded: boolean;
  keyword: string;
  searchType: string;
  entries: Entry[];
  matchType: string;
  filterAttributes: {
    [key: string]: string;
  };
  startDate: Date;
  endDate: Date;
  isDownloading: boolean;
};

export const initialBookingDetailState: BookingDetailState = {
  isLoading: false,
  isLoaded: false,
  keyword: '',
  searchType: get(BookingSearchType, 0, null),
  entries: [],
  matchType: get(Object.values(MatchType), 0, null),
  filterAttributes: {
    [BookingDetailFilterAttributes.PAYMENT_ASSIGNMENT_STATUS]: null,
    [BookingDetailFilterAttributes.PAYMENT_METHOD]: null,
    [BookingDetailFilterAttributes.PRODUCT_TYPE]: null
  },
  startDate: null,
  endDate: null,
  isDownloading: false
};

export default function bookingDetailReducer(
  state = initialBookingDetailState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: Record<string, any> = {}
) {
  const action = { ...payload };

  switch (action.type) {
    case BookingDetailActionType.SET_IS_LOADING_STATE:
      return {
        ...state,
        isLoading: action.value
      };
    case BookingDetailActionType.SET_IS_LOADED_STATE:
      return {
        ...state,
        isLoaded: action.value
      };
    case BookingDetailActionType.SET_KEYWORD:
      return {
        ...state,
        keyword: action.value
      };
    case BookingDetailActionType.SET_SEARCH_TYPE:
      return {
        ...state,
        searchType: action.value
      };
    case BookingDetailActionType.SET_ENTRIES:
      return {
        ...state,
        entries: action.entries
      };
    case BookingDetailActionType.SET_MATCH_TYPE:
      return {
        ...state,
        matchType: action.value
      };
    case BookingDetailActionType.SET_FILTER_ATTRIBUTE:
      return {
        ...state,
        filterAttributes: {
          ...state.filterAttributes,
          [action.attribute]: action.value
        }
      };
    case BookingDetailActionType.SET_START_DATE:
      return {
        ...state,
        startDate: action.value
      };
    case BookingDetailActionType.SET_END_DATE:
      return {
        ...state,
        endDate: action.value
      };
    case BookingDetailActionType.SET_IS_DOWNLOADING:
      return {
        ...state,
        isDownloading: action.value
      };
    default:
      return state;
  }
}
