import { css } from 'emotion';

export const remediationFormStyle = css`
  & .title span {
    font-weight: bold;
  }

  & .title .checkbox {
    padding: 3px 0 0 8px !important;
  }

  & .field-row {
    padding: 0.5em 0 0 0 !important;
  }

  & .field-row-data {
    color: '#4A5568';
    word-wrap: break-word;
  }

  & .field-row label,
  & .field-row-label {
    font-weight: bold;
  }

  & .tags {
    margin: 1em 1em 0 1em;
  }

  & .tags .title {
    font-weight: bold;
  }

  & .tag-row {
    margin: 0.5em 0 0 0;
  }

  & .note {
    margin: 1em 1em 0 1em;
  }

  & .attribute-combinations {
    margin-top: 0.5em;
  }

  & .bl-fields {
    margin: 1em 0 2em 0;
  }

  & .wl-fields {
    margin: 2em 1em 2em 1em;
  }

  & .wl-field-row {
    padding: 0.3em 0 0 0 !important;
  }

  & .ad-fields {
    margin: 1em 1em 0 1em;
  }

  & .ad-types {
    margin: 1em 2em 0 2em;
  }
`;
