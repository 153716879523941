import React, { useEffect, useReducer } from 'react';
import { Loader } from 'semantic-ui-react';

import activityReducer, {
  initialState
} from '../../../reducers/investigation-tools/activityReducer';
import {
  initPagination,
  setTotalNumberOfPages,
  goToFirstPage
} from '../../../actions/investigation-tools/paginationAction';
import paginationReducer from '../../../reducers/investigation-tools/paginationReducer';
import {
  fetchActivity,
  addQuery,
  deleteQuery,
  setConditional,
  setKeyword,
  setSearchType
} from '../../../actions/investigation-tools/activityPageAction';
import {
  PaginationType,
  ActivitySearchType
} from '../../../constants/investigationToolsConstant';
import Layout from '../../../components/Layout';
import ActivityPageTableContainer from '../../../components/investigation-tools/activity-page/ActivityPageTableContainer';
import ActivityPageContext, {
  useActivityPageContext
} from './ActivityPageContext';
import SummaryCardWithAddCondition from '../../../components/investigation-tools/common/SummaryCardWithAddCondition';
import { getActivity } from '../../../reducers/investigation-tools/selectors';

const ActivityPage = () => {
  const [state, dispatch] = useReducer(activityReducer, initialState);
  const [paginationState, paginationDispatch] = useReducer(
    paginationReducer,
    {}
  );

  const { isLoading } = state;

  useEffect(() => {
    paginationDispatch(initPagination([PaginationType.ACTIVITY_TABLE]));
  }, []);

  const getCombinedState = () => ({
    activity: { ...state },
    pagination: { ...paginationState }
  });

  const handleSearch = async (toFirstPage = false) => {
    if (toFirstPage) {
      paginationDispatch(goToFirstPage(PaginationType.ACTIVITY_TABLE));
    }
    fetchActivity(dispatch, getCombinedState).then(result => {
      let totalPages = 1;
      const { totalNumberOfPages } =
        paginationState[PaginationType.ACTIVITY_TABLE];

      if (result && result.data) {
        const { pageCount } = result.data;
        totalPages = pageCount;
      }
      if (totalNumberOfPages !== totalPages) {
        paginationDispatch(
          setTotalNumberOfPages(PaginationType.ACTIVITY_TABLE, totalPages)
        );
      }
    });
  };

  return (
    <ActivityPageContext.Provider
      value={{
        getState: getCombinedState,
        dispatch,
        paginationDispatch,
        handleSearch
      }}
    >
      <Layout>
        <h1>Activity</h1>
        <SummaryCardWithAddCondition
          useContext={useActivityPageContext}
          useSelector={getActivity}
          searchTypeConstants={ActivitySearchType}
          setKeyword={setKeyword}
          setSearchType={setSearchType}
          addQuery={addQuery}
          deleteQuery={deleteQuery}
          setConditional={setConditional}
        />
        <Loader active={isLoading} />
        <div style={{ marginBottom: '20px' }} />
        {!isLoading && <ActivityPageTableContainer />}
      </Layout>
    </ActivityPageContext.Provider>
  );
};

export default ActivityPage;
