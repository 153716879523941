import { Dispatch } from 'react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import * as ConnectionSearchActionType from '../../types/investigation-tools/connectionSearchActionType';
import { PaginationType } from '../../constants/investigationToolsConstant';
import {
  getConnectionSearch,
  getPagination
} from '../../reducers/investigation-tools/selectors';
import {
  searchApplicationConnection,
  searchBookingConnection
} from './serviceAction';
import { showErrorToast } from '../../utils/common';

type Relationship = {
  [key: string]: string | number | null | Relationship;
};

type Spec = {
  clientInterface: string;
  data: {
    currentPageNo: number;
    searchAttributes: {
      searchType: string;
      matchType: string;
      searchValue: string;
      filterAttributes: {
        [key: string]: string;
      };
    };
  };
};

type Result = {
  data: {
    pageCount: number;
    currentPageNo: number;
    applicationRelationshipList?: Relationship[];
    bookingRelationshipList?: Relationship[];
  };
};

type FetchResult = {
  paginationType: string;
  result: Partial<Result>;
};

export const setIsLoadingApplication = (value: boolean) => ({
  type: ConnectionSearchActionType.SET_IS_LOADING_APPLICATION_STATE,
  value
});

export const setIsLoadingBooking = (value: boolean) => ({
  type: ConnectionSearchActionType.SET_IS_LOADING_BOOKING_STATE,
  value
});

export const setIsLoadedApplication = (value: boolean) => ({
  type: ConnectionSearchActionType.SET_IS_LOADED_APPLICATION_STATE,
  value
});

export const setIsLoadedBooking = (value: boolean) => ({
  type: ConnectionSearchActionType.SET_IS_LOADED_BOOKING_STATE,
  value
});

export const setKeyword = (value: string) => ({
  type: ConnectionSearchActionType.SET_KEYWORD,
  value
});

export const setApplicationSearchType = (value: string) => ({
  type: ConnectionSearchActionType.SET_APPLICATION_SEARCH_TYPE,
  value
});

export const setBookingSearchType = (value: string) => ({
  type: ConnectionSearchActionType.SET_BOOKING_SEARCH_TYPE,
  value
});

export const setMatchType = (value: string) => ({
  type: ConnectionSearchActionType.SET_MATCH_TYPE,
  value
});

export const setApplicationEntries = (entries: Relationship[]) => ({
  type: ConnectionSearchActionType.SET_APPLICATION_ENTRIES,
  entries
});

export const setBookingEntries = (entries: Relationship[]) => ({
  type: ConnectionSearchActionType.SET_BOOKING_ENTRIES,
  entries
});

export const setApplicationFilterAttribute = (
  attribute: string,
  value: string
) => ({
  type: ConnectionSearchActionType.SET_APPLICATION_FILTER_ATTRIBUTE,
  attribute,
  value
});

export const setBookingFilterAttribute = (
  attribute: string,
  value: string
) => ({
  type: ConnectionSearchActionType.SET_BOOKING_FILTER_ATTRIBUTE,
  attribute,
  value
});

const validConnectionTypes = {
  APPLICATION: 'application',
  BOOKING: 'booking'
};

const getSpec = (getState: Function, connectionType: string): Partial<Spec> => {
  if (!Object.values(validConnectionTypes).includes(connectionType)) return {};

  const paginationType =
    connectionType === validConnectionTypes.APPLICATION
      ? PaginationType.APPLICATION_RELATIONSHIP_TABLE
      : PaginationType.BOOKING_RELATIONSHIP_TABLE;

  const state = getState();
  const table = getConnectionSearch(state);
  const pagination = getPagination(state, paginationType);

  const spec: Spec = {
    clientInterface: '',
    data: {
      currentPageNo: get(pagination, 'currentPage', 1),
      searchAttributes: {
        searchType: get(
          table,
          `${connectionType.toLowerCase()}SearchType`,
          null
        ),
        matchType: get(table, 'matchType', null),
        searchValue: get(table, 'keyword', null),
        filterAttributes: get(
          table,
          `${connectionType.toLowerCase()}FilterAttributes`,
          null
        )
      }
    }
  };

  return spec;
};

const handleFetchError = (err: Error) => {
  showErrorToast(err);

  return {};
};

export const fetchApplicationRelationships = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
  getState: Function
): Promise<Partial<Result>> => {
  dispatch(setIsLoadingApplication(true));
  dispatch(setIsLoadedApplication(false));

  const spec = getSpec(getState, validConnectionTypes.APPLICATION);

  return searchApplicationConnection(spec)
    .then((result: Partial<Result>) => {
      const applicationRelationshipList: Relationship[] = get(
        result,
        'data.applicationRelationshipList',
        null
      );

      if (isArray(applicationRelationshipList)) {
        dispatch(setApplicationEntries(applicationRelationshipList));
      }

      return result;
    })
    .catch(handleFetchError)
    .finally(() => {
      dispatch(setIsLoadingApplication(false));
      dispatch(setIsLoadedApplication(true));
    });
};

export const fetchBookingRelationships = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
  getState: Function
): Promise<Partial<Result>> => {
  dispatch(setIsLoadingBooking(true));
  dispatch(setIsLoadedBooking(false));

  const spec = getSpec(getState, validConnectionTypes.BOOKING);

  return searchBookingConnection(spec)
    .then((result: Partial<Result>) => {
      const bookingRelationshipList: Relationship[] = get(
        result,
        'data.bookingRelationshipList',
        null
      );

      if (isArray(bookingRelationshipList)) {
        dispatch(setBookingEntries(bookingRelationshipList));
      }

      return result;
    })
    .catch(handleFetchError)
    .finally(() => {
      dispatch(setIsLoadingBooking(false));
      dispatch(setIsLoadedBooking(true));
    });
};
