import { Dispatch } from 'react';
import * as commonActionType from '../../types/investigation-tools/commonActionType';
import { searchUserProfileData } from './serviceAction';
import { showErrorToast } from '../../utils/common';
import { formatNumber } from '../../utils/numberFormatter';

type Entry = {
  [key: string]: string | number | null | Entry;
};

type Spec = {
  clientInterface: string;
  profileId: number;
};

type Result = {
  data: {
    userProfileData: {
      profileId: string;
      kycId: string;
      fullName: string;
      userProfileStatus: string;
      gender: string;
      birthDate: string;
      cityOfResidence: string;
      registeredDate: string;
    };
    customerHistory: {
      totalBooking: string;
      totalIssuedBooking: string;
      lastBookingValue: string;
      totalTransaction: string;
    };
    storedCreditCardList: Entry[];
    userAssetsList: Entry[];
    emailAddressList: Entry[];
    phoneNumberList: Entry[];
  };
};

export const setResult = (result: Result) => ({
  type: commonActionType.SET_RESULT,
  result
});

export const fetchUserProfileData = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
  profileId: string
) => {
  const spec: Spec = {
    clientInterface: '',
    profileId: Number(profileId)
  };

  return searchUserProfileData(spec)
    .then((result: any) => {
      if (result && result.data) {
        const res = result.data;

        // TODO: TEMP thousand format, no currency info passed from API
        if (res.customerHistory) {
          const { lastBookingValue = 0, totalTransaction = 0 } =
            res.customerHistory;
          res.customerHistory.lastBookingValue = formatNumber(
            Number(lastBookingValue || 0)
          );
          res.customerHistory.totalTransaction = formatNumber(
            Number(totalTransaction || 0)
          );
        }
        if (
          res.userAssets &&
          Array.isArray(res.userAssets) &&
          res.userAssets.length > 0
        ) {
          res.userAssets = res.userAssets.map(each => {
            each.value = formatNumber(Number(each.value || 0));
            return each;
          });
        }

        // TODO: TEMP patch null value, avoid default fallback to value
        const prefillNullValue = arrayObj => {
          return arrayObj.map(each => {
            Object.keys(each).forEach(key => {
              each[key] = each[key] || '-';
            });
            return each;
          });
        };
        if (
          res.emailAddresses &&
          Array.isArray(res.emailAddresses) &&
          res.emailAddresses.length > 0
        ) {
          res.emailAddresses = prefillNullValue(res.emailAddresses);
        }
        if (
          res.phoneNumbers &&
          Array.isArray(res.phoneNumbers) &&
          res.phoneNumbers.length > 0
        ) {
          res.phoneNumbers = prefillNullValue(res.phoneNumbers);
        }

        dispatch(setResult(res));
      }
    })
    .catch((err: Error) => {
      showErrorToast(err);
    });
};
