import React, { useEffect, useState } from 'react';
import isFunction from 'lodash/isFunction';
import isFinite from 'lodash/isFinite';

import PaginationControl from '../../../components/common/PaginationControl/PaginationControl';
import { PaginationType } from '../../../constants/investigationToolsConstant';
import { getPagination } from '../../../reducers/investigation-tools/selectors';
import { goToAPage } from '../../../actions/investigation-tools/paginationAction';
import './ConnectionSearchPaginationContainer.style.css';
import { useConnectionSearchContext } from '../../../views/investigation-tools/connection-search/ConnectionSearchContext';

const validPaginationTypes = [
  PaginationType.APPLICATION_RELATIONSHIP_TABLE,
  PaginationType.BOOKING_RELATIONSHIP_TABLE
];

const ConnectionSearchPaginationContainer = ({
  paginationType,
  handleSearch
}) => {
  const { getState, paginationDispatch } = useConnectionSearchContext();
  const [currentPageNumber, setCurrentPageNumber] = useState(0);

  const state = getState();
  const { currentPage, totalNumberOfPages } = getPagination(
    state,
    paginationType
  );

  useEffect(() => {
    if (currentPageNumber === currentPage && isFunction(handleSearch)) {
      handleSearch();
    }
  }, [currentPageNumber, currentPage, handleSearch]);

  if (!validPaginationTypes.includes(paginationType)) return null;

  const paginationControlProps = {
    currentPageNumber: isFinite(currentPage) ? currentPage : 1,
    totalNumberOfPages: isFinite(totalNumberOfPages) ? totalNumberOfPages : 1,
    onPageNumberClick: pageNumber => {
      setCurrentPageNumber(pageNumber);
      paginationDispatch(goToAPage(paginationType, pageNumber));
    }
  };

  return (
    <div className="connection-search-pagination-container">
      <PaginationControl {...paginationControlProps} />
    </div>
  );
};

export default ConnectionSearchPaginationContainer;
