import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';
import React, { Component } from 'react';

import './PaginationControl.style.css';

export default class PaginationControl extends Component {
  static propTypes = {
    currentPageNumber: PropTypes.number.isRequired,
    maximumPageNumbersPerSegment: PropTypes.number,
    totalNumberOfPages: PropTypes.number.isRequired,
    onPageNumberClick: PropTypes.func.isRequired
  };

  static defaultProps = {
    maximumPageNumbersPerSegment: 5
  };

  constructor(...args) {
    super(...args);

    this.state = {
      pageNumberOffset: 0,
      selectedPageNumber: this.props.currentPageNumber || 1
    };
  }

  handleForwardNextClick() {
    return () => {
      const pageNumberOffset =
        this.state.pageNumberOffset + this.props.maximumPageNumbersPerSegment;
      const stateUpdatedCallback = () => {
        const pageNumberClickHandler = this.handlePageNumberClick(
          pageNumberOffset + 1
        );
        pageNumberClickHandler();
      };
      this.setState(
        {
          pageNumberOffset
        },
        stateUpdatedCallback
      );
    };
  }

  handleForwardPreviousClick(mode = 'first') {
    return () => {
      const pageNumberOffset =
        this.state.pageNumberOffset - this.props.maximumPageNumbersPerSegment;
      const stateUpdatedCallback = () => {
        const selectedPageNumber =
          mode === 'first'
            ? pageNumberOffset + 1
            : pageNumberOffset + this.props.maximumPageNumbersPerSegment;
        const pageNumberClickHandler =
          this.handlePageNumberClick(selectedPageNumber);
        pageNumberClickHandler();
      };
      this.setState(
        {
          pageNumberOffset
        },
        stateUpdatedCallback
      );
    };
  }

  handleNextClick() {
    return () => {
      const maximumPageNumber = Math.min(
        this.state.pageNumberOffset + this.props.maximumPageNumbersPerSegment,
        this.props.totalNumberOfPages
      );
      const selectedPageNumber = this.state.selectedPageNumber + 1;
      if (selectedPageNumber > maximumPageNumber) {
        const forwardNextClickHandler = this.handleForwardNextClick();
        forwardNextClickHandler();
      } else {
        const pageNumberClickHandler =
          this.handlePageNumberClick(selectedPageNumber);
        pageNumberClickHandler();
      }
    };
  }

  handlePageNumberClick(selectedPageNumber) {
    return () => {
      if (selectedPageNumber !== this.state.selectedPageNumber) {
        const stateUpdatedCallback = () => {
          const { onPageNumberClick } = this.props;

          typeof onPageNumberClick === 'function' &&
            onPageNumberClick(selectedPageNumber);
        };

        this.setState({ selectedPageNumber }, stateUpdatedCallback);
      }
    };
  }

  handlePreviousClick() {
    return () => {
      const selectedPageNumber = this.state.selectedPageNumber - 1;
      if (selectedPageNumber === this.state.pageNumberOffset) {
        const forwardPreviousClickHandle =
          this.handleForwardPreviousClick('last');
        forwardPreviousClickHandle();
      } else {
        const pageNumberClickHandler =
          this.handlePageNumberClick(selectedPageNumber);
        pageNumberClickHandler();
      }
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.currentPageNumber &&
      nextProps.currentPageNumber !== this.state.selectedPageNumber
    ) {
      const pageNumberOffset =
        Math.floor(
          nextProps.currentPageNumber / this.props.maximumPageNumbersPerSegment
        ) * this.props.maximumPageNumbersPerSegment;
      this.setState({ pageNumberOffset }, () => {
        const pageNumberClickHandler = this.handlePageNumberClick(
          nextProps.currentPageNumber
        );
        pageNumberClickHandler();
      });
    }
  }

  render() {
    const maximumPageNumber = Math.min(
      this.state.pageNumberOffset + this.props.maximumPageNumbersPerSegment,
      this.props.totalNumberOfPages
    );

    const pages = [];
    for (
      let page = this.state.pageNumberOffset + 1;
      page <= maximumPageNumber;
      page++
    ) {
      pages.push(page);
    }

    return (
      <div className="pagination-control-container">
        <Button.Group size="mini">
          <Button
            disabled={this.state.pageNumberOffset === 0}
            basic
            onClick={this.handleForwardPreviousClick()}
          >
            <Icon name="angle double left" />
          </Button>
          <Button
            disabled={this.state.selectedPageNumber === 1}
            onClick={this.handlePreviousClick()}
            basic
          >
            <Icon name="angle left" />
          </Button>
          {pages.map(page => (
            <Button
              className="pagination-control-page-number-button"
              key={page}
              basic={page !== parseInt(this.state.selectedPageNumber, 10)}
              color="blue"
              onClick={this.handlePageNumberClick(page)}
            >
              {page}
            </Button>
          ))}
          <Button
            disabled={
              this.state.selectedPageNumber === this.props.totalNumberOfPages ||
              this.props.totalNumberOfPages <= 0
            }
            onClick={this.handleNextClick()}
            basic
          >
            <Icon name="angle right" />
          </Button>
          <Button
            disabled={
              this.state.pageNumberOffset +
                this.props.maximumPageNumbersPerSegment >=
              this.props.totalNumberOfPages
            }
            onClick={this.handleForwardNextClick()}
            basic
          >
            <Icon name="angle double right" />
          </Button>
        </Button.Group>
      </div>
    );
  }
}
