import {
  PaginationAction,
  PaginationActionType
} from '../../types/investigation-tools/paginationActionType';

export const addPagination = (id: string): PaginationAction => ({
  type: PaginationActionType.ADD_PAGINATION,
  id
});

export const goToAPage = (
  id: string,
  pageNumber: number
): PaginationAction => ({
  type: PaginationActionType.GO_TO_A_PAGE,
  id,
  pageNumber
});

export const goToFirstPage = (id: string): PaginationAction => goToAPage(id, 1);

export const goToLastPage = (id: string): PaginationAction => ({
  type: PaginationActionType.GO_TO_LAST_PAGE,
  id
});

export const goToNextPage = (id: string): PaginationAction => ({
  type: PaginationActionType.GO_TO_NEXT_PAGE,
  id
});

export const goToPreviousPage = (id: string): PaginationAction => ({
  type: PaginationActionType.GO_TO_PREVIOUS_PAGE,
  id
});

export const initPagination = (ids: string[]): PaginationAction => ({
  type: PaginationActionType.INIT_PAGINATION,
  ids
});

export const setTotalNumberOfPages = (
  id: string,
  total: number
): PaginationAction => ({
  type: PaginationActionType.SET_TOTAL_NUMBER_OF_PAGES,
  id,
  total
});
