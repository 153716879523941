export enum PaginationActionType {
  ADD_PAGINATION = 'pagination/ADD_PAGINATION',
  GO_TO_A_PAGE = 'pagination/GO_TO_A_PAGE',
  GO_TO_LAST_PAGE = 'pagination/GO_TO_LAST_PAGE',
  GO_TO_FIRST_PAGE = 'pagination/GO_TO_FIRST_PAGE',
  GO_TO_NEXT_PAGE = 'pagination/GO_TO_NEXT_PAGE',
  GO_TO_PREVIOUS_PAGE = 'pagination/GO_TO_PREVIOUS_PAGE',
  INIT_PAGINATION = 'pagination/INIT_PAGINATION',
  SET_TOTAL_NUMBER_OF_PAGES = 'pagination/SET_TOTAL_NUMBER_OF_PAGES'
}

export interface PaginationAddPaginationAction {
  type: PaginationActionType.ADD_PAGINATION;
  id: string;
}

export interface PaginationGoToAPageAction {
  type: PaginationActionType.GO_TO_A_PAGE;
  pageNumber: number;
  id: string;
}

export interface PaginationGoToFirstPageAction {
  type: PaginationActionType.GO_TO_FIRST_PAGE;
  id: string;
}

export interface PaginationGoToLastPageAction {
  type: PaginationActionType.GO_TO_LAST_PAGE;
  id: string;
}

export interface PaginationGoToNextPageAction {
  type: PaginationActionType.GO_TO_NEXT_PAGE;
  id: string;
}

export interface PaginationGoToPreviousPageActiong {
  type: PaginationActionType.GO_TO_PREVIOUS_PAGE;
  id: string;
}

export interface PaginationInitPaginationAction {
  type: PaginationActionType.INIT_PAGINATION;
  ids: string[];
}

export interface PaginationSetTotalNumberOfPages {
  type: PaginationActionType.SET_TOTAL_NUMBER_OF_PAGES;
  id: string;
  total: number;
}

export type PaginationAction =
  | PaginationAddPaginationAction
  | PaginationGoToAPageAction
  | PaginationGoToFirstPageAction
  | PaginationGoToLastPageAction
  | PaginationGoToNextPageAction
  | PaginationGoToPreviousPageActiong
  | PaginationInitPaginationAction
  | PaginationSetTotalNumberOfPages;
