import React, { useState, useEffect, useContext, ReactNode } from 'react';
import { Checkbox, Grid, Popup, Button, Confirm } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import Layout from '../../components/Layout';
import Header from '../../components/header/Header';
import style from './Settings.style';
import axios from 'axios';
import configs from '../../configs';
import { showErrorToast } from '../../utils/common';
import { AppContext } from '../../AppContext';
import {
  CONFIG_AUTO_BLACKLIST,
  CASE_QUEUE_WRITE
} from '../../constants/userPermissions';

enum ModalState {
  NONE,
  CLEAR_QUEUE
}

const Settings = () => {
  const [globalConfig, setGlobalConfig] = useState({
    isAutoBlacklisting: false
  });
  const [currentModal, setCurrentModal] = useState(ModalState.NONE);
  const [{ userPermissions }] = useContext(AppContext);

  useEffect(() => {
    if (!userPermissions[CONFIG_AUTO_BLACKLIST]) return;

    axios
      .get(configs.globalConfiguration)
      .then(res => {
        if (res && res.data) {
          setGlobalConfig(res.data);
        }
      })
      .catch(err => {
        toast.error(
          'Error in loading the global configuration. Error: ' +
            (err && err.message)
        );
      });
  }, [userPermissions]);

  const onChangeHandler = () => {
    globalConfig.isAutoBlacklisting = !globalConfig.isAutoBlacklisting;

    axios
      .put(configs.globalConfiguration, globalConfig)
      .then(res => {
        toast.success('Global configuration has been updated successfully!');
        setGlobalConfig(res.data);
      })
      .catch(err => {
        // Rollback
        globalConfig.isAutoBlacklisting = !globalConfig.isAutoBlacklisting;
        toast.error(
          'Global configuration failed to update. Error: ' +
            (err && err.message)
        );
      });
  };

  const handleClearQueue = async () => {
    setCurrentModal(ModalState.NONE);

    try {
      await axios.put(configs.caseQueueClear);

      toast.success('Case Queue cleared successfully');
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      showErrorToast(err as any);
    }
  };

  const getSettingsNodes = () => {
    const r: ReactNode[] = [];

    if (userPermissions[CONFIG_AUTO_BLACKLIST]) {
      r.push(
        <Grid.Row key="auto-blacklist">
          <Grid.Column width={3}>
            <label style={{ fontWeight: 'bold' }}>
              Auto Blacklisting&nbsp;&nbsp;
            </label>
            <Popup
              trigger={<Button circular icon="info" size="mini" />}
              content="Attributes in one transaction are blacklisted if one is fraudulent"
              inverted
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <Checkbox
              toggle
              checked={globalConfig.isAutoBlacklisting}
              disabled={!userPermissions[CONFIG_AUTO_BLACKLIST]}
              onChange={onChangeHandler}
            />
          </Grid.Column>
        </Grid.Row>
      );
    }

    if (userPermissions[CASE_QUEUE_WRITE]) {
      r.push(
        <Grid.Row key="clear-queue">
          <Grid.Column width={3}>
            <label style={{ fontWeight: 'bold' }}>Clear Case Queue</label>
          </Grid.Column>
          <Grid.Column width={3}>
            <Button
              disabled={!userPermissions[CASE_QUEUE_WRITE]}
              onClick={() => setCurrentModal(ModalState.CLEAR_QUEUE)}
            >
              Clear Queue
            </Button>
          </Grid.Column>
        </Grid.Row>
      );
    }

    if (r.length === 0) {
      r.push(
        <Grid.Row key="no-permissions">
          <Grid.Column>{`You don't have any permission on this page.`}</Grid.Column>
        </Grid.Row>
      );
    }

    return r;
  };

  return (
    <div className={style}>
      <Layout>
        <Header icon="settings" groupTitle="Global Settings" />
        <br />
        <Grid>{getSettingsNodes()}</Grid>
      </Layout>

      <Confirm
        open={currentModal === ModalState.CLEAR_QUEUE}
        size="mini"
        header="Confirmation"
        content={
          <div style={{ padding: '1em' }}>
            <p>
              The operation will mark all cases on the queue to be &quot;Not
              Reviewed&quot; and will empty the queue.
            </p>
            <p>
              <b>This operation cannot be undone.</b>
            </p>
            <p>Clear Queue?</p>
          </div>
        }
        onCancel={() => setCurrentModal(ModalState.NONE)}
        onConfirm={handleClearQueue}
      />
    </div>
  );
};

export default Settings;
