import { format, parseISO } from 'date-fns';

export const DateTimeFormat = 'dd MMM yyyy, HH:mm:ss';

export const convertToLocalTime = utcTime => {
  return format(parseISO(utcTime), 'dd/MM/yyyy HH:mm:ss');
};

export const formatEpochToDateTime = epoch => {
  return epoch ? format(Number(epoch) * 1000, DateTimeFormat) : '';
};
