import React, { Dispatch } from 'react';
import get from 'lodash/get';

import { PaginationType } from '../../../constants/investigationToolsConstant';
import { PaginationState } from '../../../reducers/investigation-tools/paginationReducer';
import {
  goToAPage,
  goToFirstPage,
  goToLastPage,
  goToNextPage,
  goToPreviousPage
} from '../../../actions/investigation-tools/paginationAction';

import './PaginationContainer.style.css';
import { Button, Icon } from 'semantic-ui-react';

const PAGE_OFFSET = 11;
const HALF_OFFSET = Math.floor(PAGE_OFFSET / 2);
const calculateOffset = page =>
  page <= HALF_OFFSET ? PAGE_OFFSET - page : HALF_OFFSET;

type PaginationControllerProps = {
  state: Record<string, PaginationState>;
  dispatch: Dispatch<any>;
  tableName: string;
  onPageNumberClick: () => void;
};

const PaginationController = ({
  state,
  dispatch,
  tableName,
  onPageNumberClick
}: PaginationControllerProps) => {
  // @ts-ignore
  const pagination = { ...state.pagination[PaginationType[tableName]] };

  if (!pagination) {
    return null;
  }
  const currentPageNumber = get(pagination, 'currentPage', 1);
  const totalNumberOfPages = get(pagination, 'totalNumberOfPages', 1);

  const handlePageNumberClick = (pageNumber: number) => {
    dispatch(goToAPage(PaginationType[tableName], pageNumber));
    onPageNumberClick();
  };

  const handleForwardPreviousClick = () => {
    dispatch(goToFirstPage(PaginationType[tableName]));
    onPageNumberClick();
  };

  const handlePreviousClick = () => {
    dispatch(goToPreviousPage(PaginationType[tableName]));
    onPageNumberClick();
  };

  const handleNextClick = () => {
    dispatch(goToNextPage(PaginationType[tableName]));
    onPageNumberClick();
  };

  const handleForwardNextClick = () => {
    dispatch(goToLastPage(PaginationType[tableName]));
    onPageNumberClick();
  };

  return (
    <div className="pagination-control-container">
      <Button.Group size="mini">
        <Button
          disabled={currentPageNumber === 1}
          basic
          onClick={handleForwardPreviousClick}
        >
          <Icon name="angle double left" />
        </Button>
        <Button
          disabled={currentPageNumber === 1}
          onClick={handlePreviousClick}
          basic
        >
          <Icon name="angle left" />
        </Button>
        {(() => {
          const PagesComponent = [];
          let pageOffset = calculateOffset(currentPageNumber);
          pageOffset =
            pageOffset !== HALF_OFFSET
              ? pageOffset
              : calculateOffset(totalNumberOfPages - currentPageNumber + 1);

          const startPage = Math.max(currentPageNumber - pageOffset, 1);
          const endPage = Math.min(
            currentPageNumber + pageOffset,
            totalNumberOfPages
          );
          for (let page = startPage; page <= endPage; page++) {
            PagesComponent.push(
              <Button
                className="pagination-control-page-number-button"
                key={page}
                basic={page !== currentPageNumber}
                color="blue"
                onClick={() => handlePageNumberClick(page)}
              >
                {page}
              </Button>
            );
          }
          return PagesComponent;
        })()}
        <Button
          disabled={currentPageNumber === totalNumberOfPages}
          onClick={handleNextClick}
          basic
        >
          <Icon name="angle right" />
        </Button>
        <Button
          disabled={currentPageNumber === totalNumberOfPages}
          onClick={handleForwardNextClick}
          basic
        >
          <Icon name="angle double right" />
        </Button>
      </Button.Group>
    </div>
  );
};

export default PaginationController;
