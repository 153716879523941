const emailAddress = 'Email Address';

export const kycDataLabels = {
  idCardNumber: 'KTP Number',
  idCardFullName: 'Fullname as on KTP',
  idCardAddress: 'KTP Address',
  emailAddress,
  dateOfBirth: 'Date of Birth',
  cityOfBirth: 'City of Birth',
  province: 'Province',
  city: 'City',
  district: 'Kecamatan',
  subDistrict: 'Kelurahan',
  postalCode: 'Postal Code',
  educationLevel: 'Education Level',
  numberOfDependants: 'Number of Dependants',
  applicantPhoneNumber: "Applicant's Mobile Number",
  emergencyPhoneNumber: 'Emergency Mobile Number',
  fullName: 'Fullname',
  motherMaidenName: 'Your Mother Maiden Name',
  relationship: 'Relationship',
  street: "Applicant's Address",
  companyName: 'Company Name',
  industry: 'Industry',
  occupationType: 'Occupation Type',
  officePhoneNumber: 'Office Phone Number',
  officeAddress: 'Office Address'
};

export const userProfileDataLabel = {
  totalBooking: 'Total Booking',
  totalIssuedBooking: 'Total Issued Booking',
  lastBookingValue: 'Last Booking Value',
  totalTransaction: 'Total Transaction',
  maskedCardNumber: 'Masked Card Number',
  expiryDate: 'Expiry',
  assetName: 'Asset',
  value: 'Value',
  emailAddress: 'Email Address',
  addedDate: 'Added Date',
  removedDate: 'Removed Date',
  phoneNumber: 'Phone Number'
};

export const userProfileContextSearchLabels = {
  PROFILE_ID: 'Profile ID',
  KYC_ID: 'KYC ID',
  PROFILE_KYC_NAME: 'Profile Name / KYC Name',
  EMAIL_ADDRESS: emailAddress,
  PHONE_NUMBER: 'Phone Number',
  ID_CARD_NUMBER: 'Customer ID Card Number (NIK)',
  BOOKING_ID: 'Booking ID'
};

export const driverDataLabels = {
  driverId: 'Driver ID',
  driverName: 'Driver Name',
  idCardNumber: 'Driver ID card number (NIK)',
  registeredDate: 'Registered Date',
  accountStatus: 'Account Status',
  suspensionStatus: 'Suspension Status',
  mainPhoneNo: 'Main Phone Number',
  emergencyPhoneNo: 'Emergency Phone number',
  email: emailAddress,
  address: 'Address',
  domicileCity: 'Domicile City',
  birthDate: 'Birth Date',
  previousJob: 'Previous Job',
  additionalPhoneNo: 'Additional Phone Number'
};

export const merchantDataSearchLabels = {
  MERCHANT_ID: 'MERCHANT ID',
  MERCHANT_NAME: 'MERCHANT NAME',
  PHONE_NO: 'MERCHANT PHONE NUMBER',
  EMAIL: 'EMAIL ADDRESS',
  NIK: 'OWNER ID CARD NUMBER (NIK)',
  BOOKING_ID: 'BOOKING ID'
};

export const paymentDataSearchLabels = {
  PROFILE_ID: 'Profile ID',
  PAYMENT_ID: 'Payment ID',
  BOOKING_ID: 'Booking ID',
  BANK_ACCOUNT_NUMBER: 'Bank Account Number',
  BANK_ACCOUNT_NAME: 'Bank Account Name'
};
