import React, { useReducer } from 'react';
import get from 'lodash/get';

import PaginationControl from '../../../components/common/PaginationControl/PaginationControl';
import { PaginationType } from '../../../constants/investigationToolsConstant';
import paginationReducer from '../../../reducers/investigation-tools/paginationReducer';
import { getPagination } from '../../../reducers/investigation-tools/selectors';
import { goToAPage } from '../../../actions/investigation-tools/paginationAction';

const ProfileSummaryPaginationContainer = () => {
  const [state, dispatch] = useReducer(paginationReducer, {});

  const pagination = getPagination(state, PaginationType.PROFILE_SUMMARY_TABLE);

  if (!pagination) {
    return null;
  }

  const paginationControlProps = {
    currentPageNumber: get(pagination, 'currentPage', 1),
    totalNumberOfPages: get(pagination, 'totalNumberOfPages', 1),
    onPageNumberClick: pageNumber => {
      dispatch(goToAPage(PaginationType.PROFILE_SUMMARY_TABLE, pageNumber));
    }
  };

  return (
    <div
      style={{ margin: '20px 0' }}
      className="profile-summary-pagination-container"
    >
      <PaginationControl {...paginationControlProps} />
    </div>
  );
};

export default ProfileSummaryPaginationContainer;
