import React from 'react';
import startCase from 'lodash/startCase';
import isFunction from 'lodash/isFunction';
import { Card, Form, Select, Button, Input, Radio } from 'semantic-ui-react';

import {
  setApplicationSearchType,
  setBookingSearchType,
  setKeyword,
  setMatchType
} from '../../../actions/investigation-tools/connectionSearchAction';
import {
  ConnectionSearchType,
  MatchType
} from '../../../constants/investigationToolsConstant';
import { getConnectionSearch } from '../../../reducers/investigation-tools/selectors';
import { useConnectionSearchContext } from '../../../views/investigation-tools/connection-search/ConnectionSearchContext';
import './ConnectionSearchCard.style.css';

const ConnectionSearchCard = () => {
  const { getState, dispatch, handleSearch } = useConnectionSearchContext();

  const state = getState();
  const { applicationSearchType, bookingSearchType, matchType, keyword } =
    getConnectionSearch(state);

  const handleSelectChange = (event, data) => {
    dispatch(setApplicationSearchType(data.value));
    dispatch(setBookingSearchType(data.value));
  };

  const handleInputChange = (event, data) => {
    dispatch(setKeyword(data.value));
  };

  const handleRadioChange = (event, data) => {
    dispatch(setMatchType(data.value));
  };

  return (
    <Card className="connection-search-card">
      <Card.Content className="connection-search-card-content">
        <Form>
          <Form.Field>
            <label>Search Type</label>
            <Select
              options={ConnectionSearchType.map(type => ({
                key: type,
                value: type,
                text: type
              }))}
              onChange={handleSelectChange}
              value={applicationSearchType}
            />
          </Form.Field>
          <Form.Field>
            <label>Keyword</label>
            <Input
              className="connection-search-card-input-keyword"
              onChange={handleInputChange}
              value={keyword || ''}
            />
          </Form.Field>
          {Object.keys(MatchType).map(key => {
            const value = MatchType[key];

            return (
              <Form.Field key={value}>
                <Radio
                  className="connection-search-card-radio"
                  label={startCase(key.toLowerCase())}
                  name="matchTypeGroup"
                  value={value}
                  checked={matchType === value}
                  onChange={handleRadioChange}
                />
              </Form.Field>
            );
          })}
          <Form.Field>
            <Button
              className="connection-search-card-button-search"
              onClick={() => isFunction(handleSearch) && handleSearch()}
              disabled={
                !applicationSearchType ||
                !bookingSearchType ||
                !matchType ||
                typeof keyword !== 'string' ||
                !keyword.trim()
              }
            >
              Search
            </Button>
          </Form.Field>
        </Form>
      </Card.Content>
    </Card>
  );
};

export default ConnectionSearchCard;
