import merge from 'lodash/merge';

import configLocal from './local.json';
import configDevelopment from './development.json';
import configStaging from './staging.json';
import configProduction from './production.json';

let config;
switch (process.env.REACT_APP_ENV) {
  case 'development':
    config = configDevelopment;
    break;
  case 'staging':
    config = configStaging;
    break;
  case 'production':
    config = configProduction;
    break;
  default:
    config = configLocal;
}

const apiEndpoints = {
  paylaterTransactionUpload: config.fmgsHostName + '/v1/blacklist/upload',
  globalConfiguration:
    config.fmgsHostName + '/v1/blacklist/global-configuration',

  whitelistAttributeCombinations:
    config.fmgsHostName + '/v1/whitelist/attribute-combinations',
  whitelistTags: config.fmgsHostName + '/v1/whitelist/tags',
  whitelistPaymentMethods:
    config.fmgsHostName + '/v1/whitelist/payment-methods',
  whitelistReferenceTypes:
    config.fmgsHostName + '/v1/whitelist/reference-types',
  whitelistEntry: config.fmgsHostName + '/v1/whitelist/entry',
  whitelistEntries: config.fmgsHostName + '/v1/whitelist/entries',

  blacklistAttributes: config.fmgsHostName + '/v1/blacklist/attributes',
  blacklistTags: config.fmgsHostName + '/v1/blacklist/attributeTags',
  blacklistEntries: config.fmgsHostName + '/v1/blacklist/entries',
  blacklistReferenceTypes:
    config.fmgsHostName + '/v1/blacklist/reference-types',

  // Rule Management Api
  productApi: config.fmgsHostName + '/v1/decision-tree/products',
  rulesOperators: config.fmgsHostName + '/v1/decision-tree/operators',
  rulesDecisionTreeById: config.fmgsHostName + '/v1/decision-tree/',
  rulesSave: config.fmgsHostName + '/v1/decision-tree/product',
  rulesValidate: config.fmgsHostName + '/v1/decision-tree/validate/product',
  rulesActivate: config.fmgsHostName + '/v1/decision-tree/activate',
  rulesDecisionTreeSummary:
    config.fmgsHostName + '/v1/decision-tree/summary/product/',
  rulesDecisionPackageSummary:
    config.fmgsHostName + '/v1/decision-tree/decision-package/summary',
  rulesDeciderByProductId:
    config.fmgsHostName + '/v1/decision-tree/deciders/info',
  rulesDecisionPackageByProductId:
    config.fmgsHostName + '/v1/decision-tree/decision-package/info',
  rulesDecidingVariableByDeciderId:
    config.fmgsHostName + '/v1/decision-tree/deciding-variables/info/',

  // Case Management Api
  caseMarkingStatuses: config.fmgsHostName + '/v1/case/marking-statuses',
  caseSearchFields:
    config.fmgsHostName + '/v1/case/field-value-search/search-fields',
  caseReferenceTypes: config.fmgsHostName + '/v1/products/reference-types',
  caseCategories: config.fmgsHostName + '/v1/products/categories',
  caseFinalRecommendations:
    config.fmgsHostName + '/v1/products/final-recommendations',
  caseMultiCriteriaSearch:
    config.fmgsHostName + '/v1/case/multi-criteria-search',
  caseFieldAndValueSearch: config.fmgsHostName + '/v1/case/field-value-search',
  caseDetailsSummary: config.fmgsHostName + '/v1/case/details-summary',
  caseDetailsProductRequest: config.fmgsHostName + '/v1/case/:caseId/request',
  caseDetailsDetectorResponse:
    config.fmgsHostName + '/v1/case/:caseId/detectors',
  caseDetailsRemediationBlacklist:
    config.fmgsHostName + '/v1/case/:caseId/remediation/blacklist',
  caseDetailsRemediationWhitelist:
    config.fmgsHostName + '/v1/case/:caseId/whitelist-remediation',
  caseDetailsRemediationAccountDeactivation:
    config.fmgsHostName + '/v1/case/:caseId/remediation/account-deactivation',
  caseDetailsRemediationResetPassword:
    config.fmgsHostName + '/v1/case/:caseId/remediation/password-reset',
  caseDetailsMarkStatus: config.fmgsHostName + '/v1/case/:caseId/mark-status',
  caseDetailsNotes: config.fmgsHostName + '/v1/case/:caseId/notes',
  caseDetailsActionHistory:
    config.fmgsHostName + '/v1/case/:caseId/action-history',
  caseMultipleMarkSuspect:
    config.fmgsHostName + '/v1/case/multiple-mark-suspect',
  caseMultipleMarkNeutral:
    config.fmgsHostName + '/v1/case/multiple-mark-neutral',
  caseMultipleMarkTrusted:
    config.fmgsHostName + '/v1/case/multiple-mark-trusted',
  caseBulkRemediation:
    config.fmgsHostName + '/v1/case/remediation/bulk-account-remediation',
  caseQueueEntries: config.fmgsHostName + '/v1/case/case-queue-search',
  caseQueueClear: config.fmgsHostName + '/v1/case/clear-queue',
  caseQueueAdd: config.fmgsHostName + '/v1/case/case-queue-add',

  // Investigation Tools
  kycData: config.fmgsHostName + '/:kycId'
};

const suffix = {
  appCallback: '/callback',
  appLogout: '/logout'
};

const investigationTools = {
  currencies: [
    {
      code: 'NONE',
      currencySymbol: '',
      currencyPosition: 'head',
      locale: '',
      numberOfDecimalPoint: 0,
      separator: {
        decimal: ',',
        group: '.'
      }
    },
    {
      code: 'IDR',
      currencySymbol: 'Rp ',
      currencyPosition: 'head',
      locale: 'id-ID',
      numberOfDecimalPoint: 0,
      separator: {
        decimal: ',',
        group: '.'
      }
    },
    {
      code: 'USD',
      currencySymbol: 'US$ ',
      currencyPosition: 'head',
      locale: 'en-US',
      numberOfDecimalPoint: 2,
      separator: {
        decimal: '.',
        group: ','
      }
    },
    {
      code: 'SGD',
      currencySymbol: 'S$ ',
      currencyPosition: 'head',
      locale: 'en-SG',
      numberOfDecimalPoint: 2,
      separator: {
        decimal: '.',
        group: ','
      }
    },
    {
      code: 'MYR',
      currencySymbol: 'RM ',
      currencyPosition: 'head',
      locale: 'ms-MY',
      numberOfDecimalPoint: 2,
      separator: {
        decimal: '.',
        group: ','
      }
    },
    {
      code: 'THB',
      currencySymbol: 'THB ',
      currencyPosition: 'head',
      locale: 'en-TH',
      numberOfDecimalPoint: 2,
      separator: {
        decimal: '.',
        group: ','
      }
    },
    {
      code: 'PHP',
      currencySymbol: '₱  ',
      currencyPosition: 'head',
      locale: 'en-PH',
      numberOfDecimalPoint: 2,
      separator: {
        decimal: '.',
        group: ','
      }
    },
    {
      code: 'VND',
      currencySymbol: ' VND',
      currencyPosition: 'tail',
      locale: 'vi-VN',
      numberOfDecimalPoint: 0,
      separator: {
        decimal: ',',
        group: '.'
      }
    }
  ],
  bookingDetailLinks: {
    bookingId: {
      AIRPORT_TRANSFER:
        'https://ppstool.gtr.staging-traveloka.com/booking-detail?id=%s',
      BPJS_HEALTH:
        'https://ebitool-fe.ebi.staging-traveloka.com/transaction-detail?id=%s',
      BUS: 'https://bustool.gtr.staging-traveloka.com/booking-details?id=%s',
      'CABLE_TV_&_INTERNET_POSTPAID':
        'https://ebitool-fe.ebi.staging-traveloka.com/transaction-detail?id=%s',
      'CABLE_TV_&_INTERNET_PREPAID':
        'https://ebitool-fe.ebi.staging-traveloka.com/transaction-detail?id=%s',
      CAR_RENTAL:
        'https://pprtool.ppr.staging-traveloka.com/booking-center/detail?bookingId=%s',
      CREDIT_CARD:
        'https://ebitool-fe.ebi.staging-traveloka.com/transaction-detail?id=%s',
      EATS_DELIVERY:
        'https://tools-cul-shared-stg.test.tvlk.cloud/booking/delivery-detail?id=%s',
      EATS_VOUCHER:
        'https://tools-cul-shared-stg.test.tvlk.cloud/booking/detail?id=%s',
      ELECTRONIC_MONEY:
        'https://ebitool-fe.ebi.staging-traveloka.com/transaction-detail?id=%s',
      FLIGHT:
        'https://tool.fpr.staging-traveloka.com/booking/flight?bookingId=%s',
      'FLIGHT_+_HOTEL': 'https://tool.cxp.staging-traveloka.com/booking?id=%s',
      FLIGHT_ANCILLARY:
        'https://tool.fpr.staging-traveloka.com/booking/ancillary?bookingId=%s',
      'FLIGHT_CHECK-IN':
        'https://tool.fpr.staging-traveloka.com/booking/checkin?bookingId=%s',
      GAME_VOUCHER:
        'https://ebitool-fe.ebi.staging-traveloka.com/transaction-detail?id=%s',
      GIFT_VOUCHER:
        'https://gvood.test.tvlk.cloud/tools/giftvoucher/bookingcenter/detail?id=%s',
      GLOBAL_TRAIN:
        'https://trntool.gtr.staging-traveloka.com/global/booking-details?id=%s',
      HEALTH:
        'https://tool.xpe.staging-traveloka.com/athena/booking/detail?bookingId=%s',
      HOTEL:
        'https://accom-dashboard-canary.acd.development-traveloka.com/booking-detail?bookingId=%s',
      INSURANCE:
        'https://ins-tools-fe.test.tvlk.cloud/booking/agent-booking?agentBookingId=%s',
      INSURANCE_POSTPAID:
        'https://ebitool-fe.ebi.staging-traveloka.com/transaction-detail?id=%s',
      MOBILE_DATA_PACKAGES:
        'https://ebitool-fe.ebi.staging-traveloka.com/transaction-detail?id=%s',
      MOBILE_POSTPAID:
        'https://ebitool-fe.ebi.staging-traveloka.com/transaction-detail?id=%s',
      MOBILE_TOP_UP:
        'https://ebitool-fe.ebi.staging-traveloka.com/transaction-detail?id=%s',
      MOVIES:
        'https://tools-cnm-shared-stg.test.tvlk.cloud/booking/detail?id=%s',
      MULTIFINANCE:
        'https://ebitool-fe.ebi.staging-traveloka.com/transaction-detail?id=%s',
      ONE_WAY:
        'https://tool.fpr.staging-traveloka.com/booking/flight?bookingId=%s',
      ORDER_NOW:
        'https://tools-cul-shared-stg.test.tvlk.cloud/booking/order-in-app-detail?id=%s',
      PAYLATER_CREDIT_CARD:
        'https://ebitool-fe.ebi.staging-traveloka.com/transaction-detail?id=%s',
      PBB: 'https://ebitool-fe.ebi.staging-traveloka.com/transaction-detail?id=%s',
      PDAM: 'https://ebitool-fe.ebi.staging-traveloka.com/transaction-detail?id=%s',
      PLN_POSTPAID:
        'https://ebitool-fe.ebi.staging-traveloka.com/transaction-detail?id=%s',
      PLN_PREPAID:
        'https://ebitool-fe.ebi.staging-traveloka.com/transaction-detail?id=%s',
      TRAIN: 'https://trntool.gtr.staging-traveloka.com/booking-details?id=%s',
      TREATS:
        'https://tools-cul-shared-stg.test.tvlk.cloud/booking/order-in-app-detail?id=%s',
      XPERIENCE: 'https://tool.xpe.staging-traveloka.com/booking-details?id=%s'
    },
    profileId: '/investigation-tools/user-profile-data/%s',
    customerId: '/investigation-tools/kyc-data/%s'
  }
};

export default merge({}, apiEndpoints, suffix, investigationTools, config);
