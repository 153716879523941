import React, { useEffect, useReducer } from 'react';
import { Loader } from 'semantic-ui-react';
import get from 'lodash/get';

import bookingDetailReducer from '../../../reducers/investigation-tools/bookingDetailReducer';
import paginationReducer from '../../../reducers/investigation-tools/paginationReducer';
import {
  initPagination,
  goToAPage,
  setTotalNumberOfPages
} from '../../../actions/investigation-tools/paginationAction';
import {
  downloadBookingDetail,
  fetchBookingDetail
} from '../../../actions/investigation-tools/bookingDetailAction';
import { PaginationType } from '../../../constants/investigationToolsConstant';
import Layout from '../../../components/Layout';
import BookingDetailContext from './BookingDetailContext';
import BookingDetailTableContainer from '../../../components/investigation-tools/booking-detail/BookingDetailTableContainer';
import BookingDetailCard from '../../../components/investigation-tools/booking-detail/BookingDetailCard';

const BookingDetail = () => {
  const [state, dispatch] = useReducer(bookingDetailReducer);
  const [paginationState, paginationDispatch] = useReducer(paginationReducer);

  const { isLoading, isLoaded } = { ...state };

  useEffect(() => {
    dispatch();
    paginationDispatch(initPagination([PaginationType.BOOKING_DETAIL_TABLE]));
  }, []);

  const getCombinedState = () => ({
    booking_detail: { ...state },
    pagination: { ...paginationState }
  });

  const handleSearch = (resetPagination = false) => {
    fetchBookingDetail(dispatch, getCombinedState).then(result => {
      const pageCount = get(result, 'data.pageCount', null);
      const currentPageNo = get(result, 'data.currentPageNo', null);

      paginationDispatch(
        setTotalNumberOfPages(
          PaginationType.BOOKING_DETAIL_TABLE,
          isFinite(pageCount) ? pageCount : 1
        )
      );

      paginationDispatch(
        goToAPage(
          PaginationType.BOOKING_DETAIL_TABLE,
          isFinite(currentPageNo) && !resetPagination ? currentPageNo : 1
        )
      );
    });
  };

  const handleDownload = () => {
    downloadBookingDetail(dispatch, getCombinedState);
  };

  return (
    <BookingDetailContext.Provider
      value={{
        getState: getCombinedState,
        dispatch,
        paginationDispatch,
        handleSearch,
        handleDownload
      }}
    >
      <Layout>
        <div style={{ marginBottom: '20px' }}>
          <h1>Booking Detail</h1>
          <BookingDetailCard />
          <Loader active={isLoading} />
        </div>
        {isLoaded && <BookingDetailTableContainer />}
      </Layout>
    </BookingDetailContext.Provider>
  );
};

export default BookingDetail;
