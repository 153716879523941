import { css } from 'react-emotion';

export default css`
  & {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 50px;
    background: #2f323d;
    z-index: 3;
    // -webkit-transition: width 0.2s ease-in-out;
    // transition: width 0.2s ease-in-out;
  }

  & .ui.icon.button > .chevron.left {
    color: #fff;
  }

  & .sideNavIcon {
    display: block;
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    padding: 15px;
    text-align: center;
    color: #fff;
    text-decoration: none;
    -webkit-transition: background 0.2s ease-out;
    transition: background 0.2s ease-out;
  }

  & .sideNavIcon:hover,
  & .sideNavIcon:active,
  & .sideNavIcon:focus {
    background: rgba(255, 255, 255, 0.02);
  }

  & .sideNavHeader {
    height: 58px;
    background: #3b3f4d;
  }

  & .sideNavHeaderTitle {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    left: 20px;
    padding: 10px 0;
    color: #fff;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  & .sideNavHeader button {
    background: transparent;
    border-width: 0;
    width: 25px;
    padding: 20px 17px;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
  }

  & .sideNavHeader button span {
    display: block;
    border-radius: 3px;
    width: 15px;
    height: 2px;
    background: #fff;
    margin-bottom: 3px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  & .menuGroupContainer {
    overflow-y: auto;
    height: calc(100% - 150px);
    margin-top: 5px;
    margin-bottom: 20px;
    margin-right: 2px;

    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.25);
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(255, 255, 255, 0.5);
    }

    &::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 5px;
    }
  }

  & .menuGroup {
    position: relative;
    margin-right: 2px;
  }

  & .menuGroup.bottom {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }

  & .sideNavList {
    display: none;
    background: #2f323d;
    list-style: none;
    color: #fff;
    padding-left: 0;
  }

  & .sideNavList li {
    padding: 0;
  }

  & .sideNavList .activeSelected {
    background: #4a5568;
  }

  & .sideNavList li.activeSelected > a {
    background: #0000 !important;
  }

  & .listTitle {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 0 !important;
    margin: 0 20px;
    margin-bottom: 5px;
    white-space: nowrap;
    font-weight: 300;
    opacity: 0.5;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      width: 0;
      background: transparent;
    }
  }

  & .listTitle:first-child {
    margin-top: 20px;
    color: #fff !important;
  }

  & .sideNavList li > a,
  & .menuGroup button {
    color: #fff;
    display: block;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 300;
    text-decoration: none;
    letter-spacing: 1px;
    opacity: 0.8;
    white-space: nowrap;
  }

  & .sideNavList li > a:hover,
  & .menuGroup button:hover {
    background: rgba(255, 255, 255, 0.08);
  }

  & .menuGroup button {
    background: transparent;
    text-align: left;
    border: none;
    border-radius: 0;
    box-sizing: content-box;
  }

  /* Opened nav menu */
  & .sideNavList.open {
    display: block;
    position: absolute;
    top: 0;
    left: 50px;
    padding: 0 0 10px 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    background: #3b3f4d;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  }

  & .sideNavList.open.bottom {
    top: -45px;
  }

  & .sideNavList.open .listTitle {
    font-weight: 400;
  }

  &.open {
    width: 200px;
    overflow-y: hidden;
    overflow-x: hidden;
  }

  &.open .sideNavIcon {
    display: none;
  }

  &.open .sideNavHeaderTitle {
    opacity: 1;
  }

  &.open .toggleSideNav {
    position: absolute;
    right: 0px;
    top: 14px;
  }

  &.open .sideNavList {
    display: block;
    margin: 0;
  }

  &.open .sideNavList li > a {
    padding-left: 25px;
  }
`;
