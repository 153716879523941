import { toast } from 'react-toastify';

export function showErrorToast(
  err?: {
    message: string;
    response?: { data?: string | { message: string } };
  },
  level: 'error' | 'warn' = 'error',
  label = 'Error'
) {
  if (err?.response?.data) {
    if (typeof err.response.data === 'string') {
      toast[level](err.response.data);
    } else {
      toast[level](err.response.data.message);
    }
  } else {
    toast[level](`${label}: ${err?.message}`);
  }
}
