import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, DropdownProps } from 'semantic-ui-react';
import { nodeTypes } from './NodeAndEdgeTypes';
import {
  Decider,
  DecisionPackage,
  DecidingVariable,
  NodeTypeKey
} from '../../types/decisionTree';

export type NodeModalSaveParams = {
  type: NodeTypeKey;
  decider?: Decider;
  decisionPackage?: DecisionPackage;
  decidingVariable?: DecidingVariable;
};

type Props = {
  open: boolean;
  nodeTypeKey: NodeTypeKey;
  deciderName: string;
  decisionPackageName: string;
  decidingVariableName: string;
  deciders: Decider[];
  decisionPackages: DecisionPackage[];
  decidingVariables: DecidingVariable[];
  onChange?: (data: {
    nodeTypeKey: NodeTypeKey;
    deciderName: string;
    decisionPackageName: string;
    decidingVariableName: string;
  }) => void;
  onClose?: () => void;
  onSave?: (params: NodeModalSaveParams) => void;
};

type Option = {
  key: string;
  text: string;
  value: string;
};

type InputError =
  | false
  | {
      content: string;
    };

const nodeTypeOptions = [
  {
    key: nodeTypes[NodeTypeKey.NORMAL].shapeId,
    text: nodeTypes[NodeTypeKey.NORMAL].typeText,
    value: NodeTypeKey.NORMAL
  },
  {
    key: nodeTypes[NodeTypeKey.DECISION_PACKAGE].shapeId,
    text: nodeTypes[NodeTypeKey.DECISION_PACKAGE].typeText,
    value: NodeTypeKey.DECISION_PACKAGE
  }
];

const NodeEditModal = ({
  open,
  nodeTypeKey,
  deciderName,
  decisionPackageName,
  decidingVariableName,
  deciders,
  decisionPackages,
  decidingVariables,
  onChange,
  onClose,
  onSave
}: Props) => {
  const [deciderOptions, setDeciderOptions] = useState<Option[]>([]);
  const [decidingVariableOptions, setDecidingVariableOptions] = useState<
    Option[]
  >([]);
  const [decisionPackageOptions, setDecisionPackageOptions] = useState<
    Option[]
  >([]);

  const [nodeTypeError, setNodeTypeError] = useState<InputError>(false);
  const [deciderError, setDeciderError] = useState<InputError>(false);
  const [decidingVariableError, setDecidingVariableError] =
    useState<InputError>(false);
  const [decisionPackageError, setDecisionPackageError] =
    useState<InputError>(false);

  const handleNodeTypeChange = (
    evt: React.FormEvent,
    { value }: DropdownProps
  ) => {
    setNodeTypeError(false);

    onChange?.({
      nodeTypeKey: value as NodeTypeKey,
      deciderName: value === NodeTypeKey.NORMAL ? deciderName : '',
      decisionPackageName:
        value === NodeTypeKey.DECISION_PACKAGE ? decisionPackageName : '',
      decidingVariableName:
        value === NodeTypeKey.NORMAL ? decidingVariableName : ''
    });
  };

  const handleDeciderChange = (
    evt: React.FormEvent,
    { value }: DropdownProps
  ) => {
    setDeciderError(false);

    onChange?.({
      nodeTypeKey,
      deciderName: value as string,
      decisionPackageName,
      decidingVariableName: ''
    });
  };

  const handleDecidingVariableChange = (
    evt: React.FormEvent,
    { value }: DropdownProps
  ) => {
    setDecidingVariableError(false);

    onChange?.({
      nodeTypeKey,
      deciderName,
      decisionPackageName,
      decidingVariableName: value as string
    });
  };

  const handleDecisionPackageChange = (
    evt: React.FormEvent,
    { value }: DropdownProps
  ) => {
    setDecisionPackageError(false);

    onChange?.({
      nodeTypeKey,
      deciderName,
      decisionPackageName: value as string,
      decidingVariableName
    });
  };

  const error = (message: string) => {
    return {
      content: message
    };
  };

  const handleSave = () => {
    if (
      nodeTypeKey !== NodeTypeKey.NORMAL &&
      nodeTypeKey !== NodeTypeKey.DECISION_PACKAGE
    ) {
      setNodeTypeError(error('Node type cannot be empty.'));
      return;
    }

    if (nodeTypeKey === NodeTypeKey.NORMAL) {
      setDecisionPackageError(false);

      if (!deciderName) {
        setDeciderError(error('Decider cannot be empty'));
        return;
      }

      if (!decidingVariableName) {
        setDecidingVariableError(error('Deciding variable cannot be empty'));
        return;
      }
    } else if (nodeTypeKey === NodeTypeKey.DECISION_PACKAGE) {
      setDeciderError(false);
      setDecidingVariableError(false);

      if (!decisionPackageName) {
        setDecisionPackageError(error('Decision Package Name cannot be empty'));
        return;
      }
    }

    onSave?.({
      type: nodeTypeKey,
      decider:
        nodeTypeKey === NodeTypeKey.NORMAL
          ? deciders.find(d => d.name === deciderName)
          : undefined,
      decidingVariable:
        nodeTypeKey === NodeTypeKey.NORMAL
          ? decidingVariables.find(dv => dv.name === decidingVariableName)
          : undefined,
      decisionPackage:
        nodeTypeKey === NodeTypeKey.DECISION_PACKAGE
          ? decisionPackages.find(dp => dp.name === decisionPackageName)
          : undefined
    });
  };

  useEffect(() => {
    setDeciderOptions(
      deciders.map(d => ({
        key: d.name,
        value: d.name,
        text: d.name
      }))
    );
  }, [deciders]);

  useEffect(() => {
    setDecidingVariableOptions(
      decidingVariables.map(d => ({
        key: d.name,
        value: d.name,
        text: d.name
      }))
    );
  }, [decidingVariables]);

  useEffect(() => {
    setDecisionPackageOptions(
      decisionPackages.map(d => ({
        key: d.name,
        value: d.name,
        text: d.name
      }))
    );
  }, [decisionPackages]);

  useEffect(() => {
    setNodeTypeError(false);
    setDeciderError(false);
    setDecidingVariableError(false);
    setDecisionPackageError(false);
  }, [open]);

  return (
    <Modal open={open} size="small">
      <Modal.Header>Create / Edit Node</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group widths={3}>
            <Form.Field label={'Node Type'} />
            <Form.Select
              onChange={handleNodeTypeChange}
              value={nodeTypeKey}
              options={nodeTypeOptions}
              error={nodeTypeError}
              width={8}
            />
          </Form.Group>
          {nodeTypeKey === NodeTypeKey.NORMAL && (
            <Form.Group widths={3}>
              <Form.Field label={'Decider'} required />
              <Form.Select
                search
                placeholder={'Select Decider Name'}
                value={deciderName}
                onChange={handleDeciderChange}
                error={deciderError}
                options={deciderOptions}
                width={8}
              />
            </Form.Group>
          )}
          {nodeTypeKey === NodeTypeKey.NORMAL && (
            <Form.Group widths={3}>
              <Form.Field label={'Deciding Variable'} required />
              <Form.Select
                search
                placeholder={'Select Deciding Variable'}
                value={decidingVariableName}
                onChange={handleDecidingVariableChange}
                error={decidingVariableError}
                options={decidingVariableOptions}
                width={8}
              />
            </Form.Group>
          )}
          {nodeTypeKey === NodeTypeKey.DECISION_PACKAGE && (
            <Form.Group widths={3}>
              <Form.Field label={'Decision Package'} required />
              <Form.Select
                required
                placeholder={'Select Decision Package'}
                onChange={handleDecisionPackageChange}
                value={decisionPackageName}
                error={decisionPackageError}
                options={decisionPackageOptions}
                width={8}
              />
            </Form.Group>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button icon="delete" content="Close" onClick={onClose} />
        <Button primary icon="add" content="Save" onClick={handleSave} />
      </Modal.Actions>
    </Modal>
  );
};

export default NodeEditModal;
