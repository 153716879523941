import React, { useState, useContext, useEffect, useRef } from 'react';
import { IEdge, INode } from 'react-digraph';
import Layout from '../../components/Layout';
import Header from '../../components/header/Header';
import ActionButtons from '../../components/decision-tree/ActionButtons';
import {
  GCData,
  GCNode,
  Decider,
  DecidingVariable,
  DecisionPackage,
  NodeTypeKey,
  Operators,
  OperatorType,
  GCEdge,
  EdgeConditionValue,
  DTLog,
  DTLogLevel,
  SaveData,
  ExportPayload,
  SavePayload
} from '../../types/decisionTree';
import {
  createEmptyData,
  addNode,
  deleteNode,
  addEdge,
  deleteEdge,
  updateNode,
  updateEdge
} from '../../utils/decision-tree/treeEditUtils';
import { NODE_KEY } from '../../constants/decisionTree';
import {
  nodeTypes,
  nodeSubtypes,
  edgeTypes
} from '../../components/decision-tree/NodeAndEdgeTypes';
import GraphCreator from '../../components/decision-tree/GraphCreator';
import NodeEditModal, {
  NodeModalSaveParams
} from '../../components/decision-tree/NodeEditModal';
import configs from '../../configs';
import { toast } from 'react-toastify';
import { useParams, useHistory } from 'react-router-dom';
import { ProductAPI } from '../../types/product';
import EdgeEditModal, {
  EdgeModalSaveParams
} from '../../components/decision-tree/EdgeEditModal';
import {
  generateEditorData,
  generateClientFormatFromTree,
  generateEditorDataFromTree,
  appendIdsToTree,
  generateTreeData
} from '../../utils/decision-tree/treeSaveUtils';
import DecisionTreeConsole from '../../components/decision-tree/DecisionTreeConsole';
import { formatLog } from '../../utils/decision-tree/treeConsoleUtils';
import fileDownload from 'js-file-download';
import {
  generateGraphData,
  generateGraphEditorData,
  generateClientFormatFromGraph,
  validateGraph
} from '../../utils/decision-tree/graphSaveUtils';
import axios from 'axios';
import { AppContext } from '../../AppContext';
import {
  dtWrapperStyle,
  dtActionsStyle,
  dtConsoleWrapperStyle
} from './DecisionTree.style';
import ActionModals from '../../components/decision-tree/ActionModals';

let lastSelectTime = 0;

export enum ModalState {
  NONE,
  NODE_EDIT,
  EDGE_EDIT,
  BACK,
  SAVE,
  ACTIVATE,
  EXPORT,
  IMPORT,
  CLEAR
}

const generateSaveFormat = ({ nodes, edges }: GCData): SaveData => {
  const err = validateGraph(nodes, edges);

  if (err) {
    throw new Error(err);
  }

  return {
    graph: generateGraphData(nodes, edges),
    graphEditorData: generateGraphEditorData(nodes),
    decisionTree: generateTreeData(nodes, edges),
    editorData: generateEditorData(nodes, edges)
  };
};

const getSavePayload = (
  saveData: SaveData,
  name: string,
  description: string,
  detectorCallsAsync: boolean,
  apiName: string
): SavePayload => {
  return {
    decisionTree: {
      name,
      description,
      api: apiName,
      detectorCallsAsync,
      root: saveData.decisionTree,
      graph: saveData.graph
    },
    editorData: saveData.editorData,
    graphEditorData: saveData.graphEditorData
  };
};

const getTreeInfoLogMessage = (
  name: string,
  version: string,
  detectorCallsAsync: boolean,
  status: string
) => `Decision Graph Editor
${'Graph name'.padEnd(31)}: ${name}
${'Graph version'.padEnd(31)}: ${version}
${'Graph detector async evaluation'.padEnd(31)}: ${detectorCallsAsync}
${'Graph status'.padEnd(31)}: ${status}
${'Mode'.padEnd(31)}: view`;

const DecisionTree = () => {
  const { productId, treeId } = useParams();
  const [{ isDTMaximized, productApis }] = useContext(AppContext);
  const history = useHistory();

  const [productApi, setProductApi] = useState<ProductAPI>();
  const [data, setData] = useState<GCData>(createEmptyData());
  const [selected, setSelected] = useState<object | null>(null);
  const [deciders, setDeciders] = useState<Decider[]>([]);
  const [decidingVariables, setDecidingVariables] = useState<
    DecidingVariable[]
  >([]);
  const [decisionPackages, setDecisionPackages] = useState<DecisionPackage[]>(
    []
  );
  const [operators, setOperators] = useState<Operators | undefined>();
  const [defaultSaveInfo, setDefaultSaveInfo] = useState({
    name: '',
    description: '',
    detectorCallsAsync: true
  });

  const [nodeEditState, setNodeEditState] = useState({
    nodeTypeKey: NodeTypeKey.NORMAL,
    deciderName: '',
    decidingVariableName: '',
    decisionPackageName: ''
  });

  const [edgeModalState, setEdgeModalState] = useState<{
    edge?: GCEdge;
    sourceNode?: GCNode;
    targetNode?: GCNode;
  }>();
  const [edgeEditState, setEdgeEditState] = useState({
    operatorName: '',
    priorityText: '',
    isDefaultBranch: false,
    conditionValueText: ''
  });

  const [activeModal, setActiveModal] = useState(ModalState.NONE);
  const [consoleExpanded, setConsoleExpanded] = useState(false);
  const [consoleLogs, setConsoleLogs] = useState<DTLog[]>([]);

  const [treeInfo, setTreeInfo] = useState<
    | {
        name: string;
        version: number;
        status: 'ACTIVE' | 'INACTIVE';
      }
    | undefined
  >();
  const isViewMode = treeId && treeId > 0;

  const containerRef = useRef<HTMLDivElement>(null);
  const [panNodeId, setPanNodeId] = useState(0);

  const log = {
    info: (message: string) =>
      setConsoleLogs([...consoleLogs, formatLog(message, DTLogLevel.INFO)]),
    success: (message: string) =>
      setConsoleLogs([...consoleLogs, formatLog(message, DTLogLevel.SUCCESS)]),
    warn: (message: string) =>
      setConsoleLogs([...consoleLogs, formatLog(message, DTLogLevel.WARN)]),
    error: (message: string) =>
      setConsoleLogs([...consoleLogs, formatLog(message, DTLogLevel.ERROR)])
  };

  const goToRuleManagementPage = () => {
    history.push(`/rule-management/${productId}`);
  };

  const setNodeDecidingVariable = async (node?: GCNode) => {
    if (!node) return;
    if (!node.decider?.name) return;
    if (node.decidingVariable?.operatorType) return;

    const decider = node.decider?.id
      ? node.decider
      : deciders.find(d => d.name === node.decider?.name);

    node.decider = decider;

    const dvs = await fetchDecidingVariables(decider);

    const decidingVariable = dvs.find(
      d => d.name === node.decidingVariable?.name
    );

    node.decidingVariable = decidingVariable;
  };

  const beginEditNode = (node: GCNode) => {
    if (node.decider && node.decider.id <= 0) {
      setNodeDecidingVariable(node);
    } else if (nodeEditState.deciderName !== node.decider?.name) {
      fetchDecidingVariables(node.decider);
    }

    setNodeEditState({
      nodeTypeKey:
        node.type === NodeTypeKey.EMPTY ? NodeTypeKey.NORMAL : node.type,
      deciderName: node.decider?.name || '',
      decidingVariableName: node.decidingVariable?.name || '',
      decisionPackageName: node.decisionPackage?.name || ''
    });

    setActiveModal(ModalState.NODE_EDIT);
  };

  const handleSelectNode = (inode: INode | null) => {
    if (inode && inode === selected && !isViewMode) {
      if (Date.now() - lastSelectTime <= 300) {
        beginEditNode(inode as GCNode);
      }
    } else {
      setSelected(inode);
    }

    lastSelectTime = Date.now();
  };

  const handleCreateNode = (x: number, y: number) => {
    if (isViewMode) return;

    setData(addNode(data, { x, y }));
  };

  const handleDeleteNode = (selected: object, nodeId: string) => {
    if (isViewMode) return;

    setData(deleteNode(data, Number(nodeId)));
  };

  const getConditionValueText = (conditionValue?: EdgeConditionValue) => {
    if (conditionValue === null || conditionValue === undefined) return '';

    if (Array.isArray(conditionValue)) {
      return conditionValue.join(',');
    }

    return `${conditionValue}`;
  };

  const beginEditEdge = async (edge: GCEdge) => {
    setEdgeEditState({
      priorityText: edge.priority.toString(),
      operatorName: edge.operator?.name || '',
      isDefaultBranch: edge.isDefaultBranch,
      conditionValueText: edge.operator
        ? getConditionValueText(edge.conditionValue)
        : ''
    });

    setActiveModal(ModalState.EDGE_EDIT);

    const sourceNode = data.nodes.find(node => node.id === edge.source);
    const targetNode = data.nodes.find(node => node.id === edge.target);

    await setNodeDecidingVariable(sourceNode);

    setEdgeModalState({
      edge,
      sourceNode,
      targetNode
    });
  };

  const handleSelectEdge = (iedge: IEdge) => {
    if (iedge === selected && !isViewMode) {
      if (Date.now() - lastSelectTime <= 300) {
        beginEditEdge(iedge as unknown as GCEdge);
      }
    } else {
      setSelected(iedge);
    }

    lastSelectTime = Date.now();
  };

  const handleCreateEdge = async (sourceINode: INode, targetINode: INode) => {
    if (isViewMode) return;

    const sourceNode = sourceINode as GCNode;
    const targetNode = targetINode as GCNode;

    if (sourceNode.type === NodeTypeKey.EMPTY) {
      log.error(
        'Please configure decider name and deciding variable in source node'
      );
    } else if (sourceNode.type === NodeTypeKey.DECISION_PACKAGE) {
      log.error('An edge cannot start from a decision package node');
    } else {
      setEdgeEditState({
        priorityText: '',
        isDefaultBranch: false,
        operatorName: '',
        conditionValueText: ''
      });

      setActiveModal(ModalState.EDGE_EDIT);

      await setNodeDecidingVariable(sourceNode);

      setEdgeModalState({
        edge: undefined,
        sourceNode,
        targetNode
      });
    }
  };

  const handleDeleteEdge = (selectedEdge: IEdge, edges: IEdge[]) => {
    if (isViewMode) return;

    setData(deleteEdge(data, selectedEdge.id));
  };

  const fetchDeciders = async () => {
    try {
      const response = await axios.get(configs.rulesDeciderByProductId, {
        params: {
          productId
        }
      });

      setDeciders(response.data);
    } catch (err) {
      toast.error(
        'Error in loading rules deciders. Error: ' + (err && err.message)
      );
    }
  };

  const fetchDecisionPackages = async () => {
    try {
      const response = await axios.get(
        configs.rulesDecisionPackageByProductId,
        {
          params: {
            productId
          }
        }
      );

      setDecisionPackages(response.data);
    } catch (err) {
      toast.error(
        'Error in loading rules decision packages. Error: ' +
          (err && err.message)
      );
    }
  };

  const fetchDecidingVariables = async (
    decider?: Decider
  ): Promise<DecidingVariable[]> => {
    if (!decider) {
      setDecidingVariables([]);
      return [];
    }

    try {
      const url =
        configs.rulesDecidingVariableByDeciderId +
        decider.type +
        '/' +
        decider.id;

      const response = await axios.get(url);

      setDecidingVariables(response.data);

      return response.data;
    } catch (err) {
      toast.error(
        'Error loading deciding variables. Error: ' + (err && err.message)
      );

      return [];
    }
  };

  const fetchOperators = async () => {
    if (operators) return;

    try {
      const response = await axios.get(configs.rulesOperators);

      setOperators(response.data);
    } catch (err) {
      toast.error(
        'Error in loading rules operators. Error: ' + (err && err.message)
      );
    }
  };

  const fetchDT = async () => {
    if (!operators) return;

    try {
      const response = await axios.get(configs.rulesDecisionTreeById + treeId);

      setTreeInfo({
        name: response.data.decisionTree.name,
        status: response.data.decisionTree.status,
        version: response.data.decisionTree.version
      });

      setDefaultSaveInfo({
        name: response.data.decisionTree.name,
        description: response.data.decisionTree.description,
        detectorCallsAsync: response.data.decisionTree.detectorCallsAsync
      });

      if (response.data.decisionTree.graph && response.data.graphEditorData) {
        setData(
          generateClientFormatFromGraph(
            response.data.decisionTree.graph,
            response.data.graphEditorData,
            operators
          )
        );
      } else {
        setData(
          generateClientFormatFromTree(
            response.data.decisionTree.root,
            response.data.editorData,
            operators
          )
        );
      }

      log.info(
        getTreeInfoLogMessage(
          response.data.decisionTree.name,
          response.data.decisionTree.version,
          response.data.decisionTree.detectorCallsAsync,
          response.data.decisionTree.status
        )
      );
    } catch (err) {
      toast.error(
        'Error in fetching Decision Graph. Error: ' + (err && err.message)
      );
    }
  };

  const handleNodeEditChange = (data: {
    nodeTypeKey: NodeTypeKey;
    deciderName: string;
    decisionPackageName: string;
    decidingVariableName: string;
  }) => {
    if (nodeEditState.deciderName !== data.deciderName) {
      const decider = deciders.find(d => d.name === data.deciderName);

      fetchDecidingVariables(decider);
    }

    setNodeEditState({
      nodeTypeKey: data.nodeTypeKey,
      deciderName: data.deciderName,
      decidingVariableName: data.decidingVariableName,
      decisionPackageName: data.decisionPackageName
    });
  };

  const handleEdgeEditChange = (data: {
    priorityText: string;
    isDefaultBranch: boolean;
    operatorName: string;
    conditionValueText: string;
  }) => {
    setEdgeEditState({
      priorityText: data.priorityText,
      isDefaultBranch: data.isDefaultBranch,
      operatorName: data.operatorName,
      conditionValueText: data.conditionValueText
    });
  };

  const handleNodeEditSave = ({
    type,
    decider,
    decidingVariable,
    decisionPackage
  }: NodeModalSaveParams) => {
    if (!selected) return;

    const selectedNode = selected as GCNode;

    setData(
      updateNode(data, selectedNode.id, {
        ...selectedNode,
        type,
        decider,
        decidingVariable,
        decisionPackage
      })
    );

    setActiveModal(ModalState.NONE);
  };

  const handleEdgeEditSave = ({
    priority,
    isDefaultBranch,
    operator,
    conditionValue
  }: EdgeModalSaveParams) => {
    if (edgeModalState?.edge) {
      setData(
        updateEdge(data, edgeModalState.edge.id, {
          ...edgeModalState.edge,
          priority,
          isDefaultBranch,
          operator,
          conditionValue
        })
      );
    } else if (edgeModalState?.sourceNode && edgeModalState?.targetNode) {
      setData(
        addEdge(data, {
          source: edgeModalState.sourceNode.id,
          target: edgeModalState.targetNode.id,
          priority,
          isDefaultBranch,
          operator,
          conditionValue
        })
      );
    }

    setActiveModal(ModalState.NONE);
  };

  const handleBack = () => {
    if (isViewMode || data.nodes.length <= 0) goToRuleManagementPage();
    else setActiveModal(ModalState.BACK);
  };

  const validateWithSaveFormat = async (): Promise<SaveData> => {
    try {
      if (!productApi) {
        throw new Error('Invalid product');
      }

      const saveData = generateSaveFormat(data);

      const payload = getSavePayload(
        saveData,
        'test-validation-graph',
        'test-validation-description',
        false,
        productApi.name
      );

      const res = await axios.post(
        `${configs.rulesValidate}/${productId}`,
        payload
      );

      if (res.data.length) {
        const validateResult = [
          'Error validating decision graph',
          ...res.data
        ].join('\n');

        throw new Error(validateResult);
      } else {
        return saveData;
      }
    } catch (err) {
      throw err;
    }
  };

  const handleValidate = async () => {
    try {
      await validateWithSaveFormat();

      log.success('No validation errors.');
      toast.success('No validation errors.');
    } catch (err) {
      log.error(err.message);
      toast.error(
        'Error validating decision graph, please check decision graph console for details'
      );
    }
  };

  const handleSaveDT = async (
    name: string,
    description: string,
    detectorCallsAsync: boolean
  ) => {
    try {
      if (!productApi) {
        throw new Error('Invalid product');
      }

      const saveData = generateSaveFormat(data);

      const payload = getSavePayload(
        saveData,
        name,
        description,
        detectorCallsAsync,
        productApi.name
      );

      await axios.post(`${configs.rulesSave}/${productId}`, payload);

      log.success('Decision Graph saved successfully');
      toast.success('Decision Graph saved successfully');
      goToRuleManagementPage();
    } catch (err) {
      const toastMsg =
        'Error saving decision graph, please check console for details';

      if (err.response && err.response.data) {
        const consoleMsg = Array.isArray(err.response.data)
          ? ['Error validating decision graph', ...err.response.data].join('\n')
          : err.response.data.message;

        toast.error(toastMsg);
        log.error(consoleMsg);
      } else {
        toast.error(toastMsg);
        log.error(err.message);
      }
    }

    setActiveModal(ModalState.NONE);
  };

  const handleActivateDT = async () => {
    if (!treeInfo) {
      const msg = 'Saved decision graph not loaded, please reload the page.';

      log.error(msg);
      toast.error(msg);

      return;
    }

    try {
      await axios.post(`${configs.rulesActivate}/${treeId}`);

      const msg = 'Decision graph activated.';

      log.success(msg);
      toast.success(msg);

      goToRuleManagementPage();
    } catch (err) {
      const msg = `Error activating decision graph: ${err.message}`;

      log.error(msg);
      toast.error(msg);
    }
  };

  const handleExportDT = async (
    name: string,
    description: string,
    detectorCallsAsync: boolean
  ) => {
    if (!productApi) return;

    try {
      const saveData = await validateWithSaveFormat();

      const payload: ExportPayload = {
        name: name,
        description: description,
        api: productApi.name,
        detectorCallsAsync: detectorCallsAsync,
        graph: saveData.graph,
        graphEditorData: saveData.graphEditorData
      };

      const fileName = `${productApi.name}_${name.replace(/ /g, '_')}.json`;

      fileDownload(JSON.stringify(payload), fileName);

      setActiveModal(ModalState.NONE);

      log.success('Decision Graph exported successfully');
    } catch (err) {
      log.error(err.message);
      toast.error(
        'Error exporting decision graph, please check decision graph console for details'
      );
    }
  };

  const handleImportDT = (importedTreeData: ExportPayload) => {
    if (!operators) return;

    if (!productApi || productApi.name !== importedTreeData.api) {
      const msg = `Incorrect API name in JSON, expected: ${productApi?.name} found: ${importedTreeData.api}. Graph cannot be imported.`;
      log.error(msg);
      toast.error(msg);
      return;
    }

    try {
      if (importedTreeData.graph && importedTreeData.graphEditorData) {
        setData(
          generateClientFormatFromGraph(
            importedTreeData.graph,
            importedTreeData.graphEditorData,
            operators
          )
        );

        setPanNodeId(importedTreeData.graph.rootNode);
      } else if (importedTreeData.root) {
        const treeDataWithId = appendIdsToTree(importedTreeData.root);

        const midX = (containerRef.current?.clientWidth || 0) * 0.5;
        const topX = (containerRef.current?.clientHeight || 0) * 0.125;

        const editorData = generateEditorDataFromTree(
          treeDataWithId,
          midX,
          topX
        );

        setData(
          generateClientFormatFromTree(treeDataWithId, editorData, operators)
        );

        setPanNodeId(treeDataWithId.id);
      }

      setActiveModal(ModalState.NONE);

      setDefaultSaveInfo({
        name: importedTreeData.name,
        description: importedTreeData.description,
        detectorCallsAsync: importedTreeData.detectorCallsAsync
      });
    } catch (err) {
      const msg = `Failed to import Decision Graph: ${err.message}`;

      log.error(msg);
      toast.error(msg);
    }
  };

  const handleClearDT = () => {
    setData(createEmptyData());

    setDefaultSaveInfo({
      name: '',
      description: '',
      detectorCallsAsync: true
    });

    setActiveModal(ModalState.NONE);

    log.info('Decision Graph cleared');
  };

  const handleKeyUp = (e: KeyboardEvent) => {
    if (e.key !== 'F2' || !selected) return;

    if ('title' in selected) {
      beginEditNode(selected as GCNode);
    } else if ('source' in selected) {
      beginEditEdge(selected as GCEdge);
    }
  };

  useEffect(() => {
    if (!isViewMode) {
      fetchDeciders();
      fetchDecisionPackages();

      log.info('Decision Graph Editor\nMode         : edit');
    }

    fetchOperators();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const api = productApis.find(p => p.id.toString() === productId);

    if (!api) return;

    setProductApi(api);
  }, [productApis, productId]);

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  });

  useEffect(() => {
    if (!isViewMode) return;

    fetchDT();
  }, [treeId, operators]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      {!isDTMaximized && (
        <Header
          className="header-list-add"
          groupTitle={`Decision Graph Manager - ${productApi?.displayName}`}
          title={
            treeInfo && (
              <div>
                {treeInfo.name} (Version: {treeInfo.version})
              </div>
            )
          }
        />
      )}

      <div
        className={`${dtWrapperStyle}${isDTMaximized ? ' maximized' : ''}`}
        ref={containerRef}
      >
        <GraphCreator
          selected={selected}
          nodeKey={NODE_KEY}
          nodes={data.nodes}
          edges={data.edges}
          nodeTypes={nodeTypes}
          nodeSubtypes={nodeSubtypes}
          edgeTypes={edgeTypes}
          onSelectNode={handleSelectNode}
          onCreateNode={handleCreateNode}
          onDeleteNode={handleDeleteNode}
          onSelectEdge={handleSelectEdge}
          onCreateEdge={handleCreateEdge}
          onSwapEdge={() => {
            // no-op
          }}
          onDeleteEdge={handleDeleteEdge}
          canSwapEdge={() => false}
          canDeleteNode={() => activeModal === ModalState.NONE}
          canDeleteEdge={() => activeModal === ModalState.NONE}
          canCreateEdge={() => !isViewMode}
          panNodeId={panNodeId}
          onPanToNode={() => setPanNodeId(0)}
        />

        <div className={`${dtActionsStyle} vertical`}>
          <ActionButtons
            viewMode={isViewMode}
            treeStatus={treeInfo?.status}
            onBack={handleBack}
            onSave={() => setActiveModal(ModalState.SAVE)}
            onActivate={() => setActiveModal(ModalState.ACTIVATE)}
            onExport={() => setActiveModal(ModalState.EXPORT)}
            onImport={() => setActiveModal(ModalState.IMPORT)}
            onValidate={handleValidate}
            onClear={() => setActiveModal(ModalState.CLEAR)}
          />
        </div>

        <div className={dtConsoleWrapperStyle}>
          <DecisionTreeConsole
            expanded={consoleExpanded}
            logs={consoleLogs}
            onHeadClick={() => setConsoleExpanded(!consoleExpanded)}
          />
        </div>

        <NodeEditModal
          open={activeModal === ModalState.NODE_EDIT}
          nodeTypeKey={nodeEditState.nodeTypeKey}
          deciderName={nodeEditState.deciderName}
          decisionPackageName={nodeEditState.decisionPackageName}
          decidingVariableName={nodeEditState.decidingVariableName}
          deciders={deciders}
          decidingVariables={decidingVariables}
          decisionPackages={decisionPackages}
          onChange={handleNodeEditChange}
          onClose={() => setActiveModal(ModalState.NONE)}
          onSave={handleNodeEditSave}
        />

        <EdgeEditModal
          open={activeModal === ModalState.EDGE_EDIT}
          operators={operators}
          operatorType={
            edgeModalState?.sourceNode?.decidingVariable
              ?.operatorType as OperatorType
          }
          priorityText={edgeEditState.priorityText}
          isDefaultBranch={edgeEditState.isDefaultBranch}
          operatorName={edgeEditState.operatorName}
          conditionValueText={edgeEditState.conditionValueText}
          onChange={handleEdgeEditChange}
          onClose={() => setActiveModal(ModalState.NONE)}
          onSave={handleEdgeEditSave}
        />

        <ActionModals
          activeModal={activeModal}
          defaultSaveInfo={defaultSaveInfo}
          onSave={handleSaveDT}
          onActivate={handleActivateDT}
          onExport={handleExportDT}
          onImport={handleImportDT}
          onBack={goToRuleManagementPage}
          onClear={handleClearDT}
          onClose={() => setActiveModal(ModalState.NONE)}
        />
      </div>
    </Layout>
  );
};

export default DecisionTree;
