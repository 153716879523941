import React from 'react';
import { ExportPayload } from '../../types/decisionTree';
import SaveDTModal from './SaveDTModal';
import ExportDTModal from './ExportDTModal';
import ImportDTModal from './ImportDTModal';
import { Confirm, Modal, Button } from 'semantic-ui-react';
import { ModalState } from '../../views/decision-tree/DecisionTree';

type Props = {
  activeModal: ModalState;
  defaultSaveInfo?: {
    name: string;
    description: string;
    detectorCallsAsync: boolean;
  };
  onSave?: (
    name: string,
    description: string,
    dectectorCallsAsync: boolean
  ) => void;
  onActivate?: () => void;
  onExport?: (
    name: string,
    description: string,
    dectectorCallsAsync: boolean
  ) => void;
  onImport?: (importedTreeData: ExportPayload) => void;
  onBack?: () => void;
  onClear?: () => void;
  onClose?: () => void;
};

const ActionModals = ({
  activeModal,
  defaultSaveInfo,
  onSave,
  onActivate,
  onExport,
  onImport,
  onBack,
  onClear,
  onClose
}: Props) => {
  return (
    <React.Fragment>
      <SaveDTModal
        open={activeModal === ModalState.SAVE}
        defaultContent={defaultSaveInfo}
        onClose={onClose}
        onSave={onSave}
      />

      <Confirm
        open={activeModal === ModalState.ACTIVATE}
        header={'Activate decision graph'}
        content={'Are you sure you want to activate this decision graph?'}
        onCancel={onClose}
        onConfirm={onActivate}
      />

      <ExportDTModal
        open={activeModal === ModalState.EXPORT}
        defaultContent={defaultSaveInfo}
        onClose={onClose}
        onExport={onExport}
      />

      <ImportDTModal
        open={activeModal === ModalState.IMPORT}
        onClose={onClose}
        onImport={onImport}
      />

      <Modal open={activeModal === ModalState.BACK} size="small">
        <Modal.Header>Go Back</Modal.Header>
        <Modal.Content>
          Changes you made so far will not be saved. Are you sure you want
          continue?
        </Modal.Content>
        <Modal.Actions>
          <Button primary content="Cancel" onClick={onClose} />
          <Button content="OK" onClick={onBack} />
        </Modal.Actions>
      </Modal>

      <Confirm
        open={activeModal === ModalState.CLEAR}
        header={'Clear Canvas'}
        content={
          'This will clear the entire canvas. Are you sure you want to continue?'
        }
        onCancel={onClose}
        onConfirm={onClear}
      />
    </React.Fragment>
  );
};

export default ActionModals;
