import React, { useEffect, useReducer } from 'react';
import { Loader } from 'semantic-ui-react';
import get from 'lodash/get';

import {
  initialState,
  FraudCheckHistoryReducer
} from '../../../reducers/investigation-tools/fraudCheckHistoryReducer';
import {
  initPagination,
  goToAPage,
  setTotalNumberOfPages
} from '../../../actions/investigation-tools/paginationAction';
import paginationReducer from '../../../reducers/investigation-tools/paginationReducer';
import {
  setStartDate,
  setEndDate,
  fetchFraudCheckHistory
} from '../../../actions/investigation-tools/fraudCheckHistoryAction';
import {
  setKeyword,
  setSearchType
} from '../../../actions/investigation-tools/commonAction';
import {
  PaginationType,
  FraudCheckHistoryType
} from '../../../constants/investigationToolsConstant';
import { getFraudCheckHistory } from '../../../reducers/investigation-tools/selectors';

import FraudCheckHistoryContext, {
  useFraudCheckHistoryContext
} from './FraudCheckHistoryContext';
import FraudCheckHistoryTable from '../../../components/investigation-tools/fraud-check-history/FraudCheckHistoryTable';
import DateRangeFilter from '../../../components/investigation-tools/common/DateRangeFilter';
import Layout from '../../../components/Layout';
import SummaryCard from '../../../components/investigation-tools/common/SummaryCard';

const FraudCheckHistory = () => {
  const [state, dispatch] = useReducer(FraudCheckHistoryReducer, initialState);
  const [paginationState, paginationDispatch] = useReducer(
    paginationReducer,
    {}
  );

  const { isLoading, startDate, endDate } = { ...state };

  useEffect(() => {
    paginationDispatch(
      // @ts-ignore
      initPagination([PaginationType.FRAUD_CHECK_HISTORY_TABLE])
    );
  }, []);

  const getCombinedState = () => ({
    fraud_check_history: { ...state },
    pagination: { ...paginationState }
  });

  const handleSearch = () => {
    fetchFraudCheckHistory(dispatch, getCombinedState).then(result => {
      const pageCount = get(result, 'data.pageCount', null);
      const currentPageNo = get(result, 'data.currentPageNo', null);

      paginationDispatch(
        // @ts-ignore
        setTotalNumberOfPages(
          PaginationType.FRAUD_CHECK_HISTORY_TABLE,
          isFinite(pageCount) ? pageCount : 1
        )
      );

      paginationDispatch(
        // @ts-ignore
        goToAPage(
          PaginationType.FRAUD_CHECK_HISTORY_TABLE,
          isFinite(currentPageNo) ? currentPageNo : 1
        )
      );
    });
  };

  const renderDateRangeFilter = () => {
    return (
      <DateRangeFilter
        startDate={startDate}
        endDate={endDate}
        setStartDate={(startDate: Date) => {
          dispatch(setStartDate(startDate));
        }}
        setEndDate={(endDate: Date) => {
          dispatch(setEndDate(endDate));
        }}
      />
    );
  };

  return (
    <FraudCheckHistoryContext.Provider
      value={{
        getState: getCombinedState,
        dispatch,
        paginationDispatch,
        handleSearch
      }}
    >
      <Layout>
        <h1>Fraud Check History</h1>
        <SummaryCard
          useContext={useFraudCheckHistoryContext}
          useSelector={getFraudCheckHistory}
          searchTypeConstants={FraudCheckHistoryType}
          setKeyword={setKeyword}
          setSearchType={setSearchType}
        >
          {renderDateRangeFilter()}
        </SummaryCard>
        <Loader active={isLoading} />
        <div style={{ marginBottom: '20px' }} />
        {!isLoading && <FraudCheckHistoryTable />}
      </Layout>
    </FraudCheckHistoryContext.Provider>
  );
};

export default FraudCheckHistory;
