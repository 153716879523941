import {
  PaginationAction,
  PaginationActionType
} from '../../types/investigation-tools/paginationActionType';

type PaginationEntry = {
  currentPage: number;
  totalNumberOfPages: number;
};

export type PaginationState = Record<string, PaginationEntry>;

const initialState: PaginationState = {};

const firstPageNumber = 1;

const emptyPaginationState = {
  currentPage: firstPageNumber,
  totalNumberOfPages: firstPageNumber
};

export default function paginationReducer(
  state = initialState,
  action: PaginationAction
): PaginationState {
  switch (action.type) {
    case PaginationActionType.ADD_PAGINATION:
      if (state[action.id]) {
        throw new Error(
          `There's existing pagination state named '${action.id}'.`
        );
      }
      return {
        ...state,
        [action.id]: emptyPaginationState
      };
    case PaginationActionType.GO_TO_A_PAGE: {
      state[action.id].currentPage = action.pageNumber;
      return state;
    }
    case PaginationActionType.GO_TO_FIRST_PAGE: {
      state[action.id].currentPage = firstPageNumber;
      return state;
    }
    case PaginationActionType.GO_TO_LAST_PAGE: {
      const { totalNumberOfPages } = state[action.id];
      state[action.id].currentPage = totalNumberOfPages;
      return state;
    }
    case PaginationActionType.GO_TO_NEXT_PAGE: {
      const { currentPage, totalNumberOfPages } = state[action.id];
      state[action.id].currentPage = Math.min(
        currentPage + 1,
        totalNumberOfPages
      );
      return state;
    }
    case PaginationActionType.GO_TO_PREVIOUS_PAGE: {
      const { currentPage } = state[action.id];
      state[action.id].currentPage = Math.max(currentPage + 1, 1);
      return state;
    }
    case PaginationActionType.INIT_PAGINATION:
      return {
        ...state,
        ...action.ids.reduce(
          (acc, id) => ({ ...acc, [id]: emptyPaginationState }),
          {}
        )
      };
    case PaginationActionType.SET_TOTAL_NUMBER_OF_PAGES: {
      return {
        ...state,
        [action.id]: { ...state[action.id], totalNumberOfPages: action.total }
      };
    }
    default:
      return state;
  }
}
