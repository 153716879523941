import * as commonActionType from '../../types/investigation-tools/commonActionType';

export type Entry = {
  [key: string]: string | number | null | Entry;
};

export const setIsLoading = (value: boolean) => ({
  type: commonActionType.SET_IS_LOADING_STATE,
  value
});

export const setIsLoaded = (value: boolean) => ({
  type: commonActionType.SET_IS_LOADED_STATE,
  value
});

export const setKeyword = (value: string) => ({
  type: commonActionType.SET_KEYWORD,
  value
});

export const setSearchType = (value: string) => ({
  type: commonActionType.SET_SEARCH_TYPE,
  value
});

export const setEntries = (entries: Entry[]) => ({
  type: commonActionType.SET_ENTRIES,
  entries
});

export const setFilterAttribute = (attribute: string, value: string) => ({
  type: commonActionType.SET_FILTER_ATTRIBUTE,
  attribute,
  value
});
