import React, { useState, useRef, useEffect } from 'react';
import {
  GraphView,
  GraphUtils,
  Node,
  IEdge,
  IGraphViewProps
} from 'react-digraph';
import { GCNode, NodeFns, GCEdge } from '../../types/decisionTree';
import NodeTooltipContent from './NodeTooltipContent';
import EdgeTooltipContent from './EdgeTooltipContent';
import Tooltip from './Tooltip';
import {
  NODE_SIZE,
  NODE_TEXT_LINE_GAP,
  NODE_TEXT_SIZE,
  NODE_TEXT_LINE_MAX_LENGTH,
  EDGE_WIDTH,
  EDGE_TEXT_LINE_GAP,
  EDGE_TEXT_SIZE,
  EDGE_TEXT_CHAR_WIDTH,
  EDGE_TEXT_MIN_CHAR,
  EDGE_TEXT_LINE_MAX_LENGTH,
  EDGE_PADDING
} from '../../constants/decisionTree';
import { nodeTypes, nodeSubtypes } from './NodeAndEdgeTypes';
import { css } from 'emotion';

const graphCreatorStyle = css`
  & {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 120px);
    overflow: hidden;
  }

  & svg {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 120px);
    overflow: visible;
  }

  & .edge-text {
    font-family: Menlo, 'Courier New', Courier, monospace;
  }
`;

const nodeFns = Node as unknown as NodeFns;

const useTooltipState = () => {
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [content, setContent] = useState<React.ReactNode>(null);
  const [show, setShow] = useState(false);

  return {
    x,
    setX,
    y,
    setY,
    content,
    setContent,
    show,
    setShow
  };
};

type Props = {
  panNodeId: number;
  onPanToNode?: () => void;
  onSelectEdge: (iedge: IEdge) => void;
} & IGraphViewProps;

const GraphCreator = ({
  panNodeId = 0,
  onPanToNode,
  onSelectEdge,
  ...rest
}: Props) => {
  const tooltipState = useTooltipState();

  // need ref to root div element to get the position
  const gcDivRef = useRef<HTMLDivElement>(null);
  const gcRef = useRef(null);

  const setTooltipPosition = (screenX: number, screenY: number) => {
    if (!gcDivRef.current) return [0, 0];

    const rect = gcDivRef.current.getBoundingClientRect();

    const x = Math.floor(screenX - rect.left);
    const y = Math.floor(screenY - rect.top);

    tooltipState.setX(x + 8);
    tooltipState.setY(y - 4);
  };

  const handleNodeMouseOver = (node: GCNode, x: number, y: number) => {
    tooltipState.setContent(<NodeTooltipContent node={node} />);
    setTooltipPosition(x, y);
    tooltipState.setShow(true);
  };

  const handleNodeMouseOut = () => {
    tooltipState.setShow(false);
  };

  const handleEdgeMouseOver = (edge: GCEdge, x: number, y: number) => {
    tooltipState.setContent(<EdgeTooltipContent edge={edge} />);
    setTooltipPosition(x, y);
    tooltipState.setShow(true);
  };

  const handleEdgeMouseOut = () => {
    tooltipState.setShow(false);
  };

  const handleMouseMove = (evt: React.MouseEvent) => {
    if (!tooltipState.show) return;
    setTooltipPosition(evt.clientX, evt.clientY);
  };

  const renderNode = (
    nodeRef: unknown,
    data: GCNode,
    id: string,
    selected: boolean,
    hovered: boolean
  ) => {
    const nodeShapeContainerClassName = GraphUtils.classNames('shape');
    const nodeClassName = GraphUtils.classNames('node', { selected, hovered });
    const nodeSubtypeClassName = GraphUtils.classNames('subtype-shape', {
      selected
    });

    const nodeTypeXlinkHref =
      nodeFns.getNodeTypeXlinkHref(data, nodeTypes) || '';
    const nodeSubtypeXlinkHref =
      nodeFns.getNodeSubtypeXlinkHref(data, nodeSubtypes) || '';

    return (
      <g
        className={nodeShapeContainerClassName}
        width={NODE_SIZE}
        height={NODE_SIZE}
        onMouseOver={(evt: React.MouseEvent) =>
          handleNodeMouseOver(data, evt.clientX, evt.clientY)
        }
        onMouseOut={handleNodeMouseOut}
      >
        {!!data.subtype && (
          <use
            className={nodeSubtypeClassName}
            x={-NODE_SIZE / 2}
            y={-NODE_SIZE / 2}
            width={NODE_SIZE}
            height={NODE_SIZE}
            xlinkHref={nodeSubtypeXlinkHref}
          />
        )}
        <use
          className={nodeClassName}
          x={-NODE_SIZE / 2}
          y={-NODE_SIZE / 2}
          width={NODE_SIZE}
          height={NODE_SIZE}
          xlinkHref={nodeTypeXlinkHref}
        />
      </g>
    );
  };

  const renderNodeText = (
    data: GCNode,
    id: string | number,
    isSelected: boolean
  ) => {
    const { title } = data;
    const lines = title.split('\n');

    const className = GraphUtils.classNames('node-text', {
      selected: isSelected
    });

    return (
      <text
        className={className}
        pointerEvents="none"
        textAnchor="middle"
        y={-lines.length * NODE_TEXT_LINE_GAP * 0.5 - NODE_TEXT_SIZE * 0.5}
      >
        {lines.map((line, i) => (
          <tspan
            key={`node-${id}-text-${i}`}
            x={0}
            dy={NODE_TEXT_LINE_GAP}
            fontSize={NODE_TEXT_SIZE}
            alignmentBaseline="middle"
          >
            {line.length > NODE_TEXT_LINE_MAX_LENGTH
              ? line.substr(0, NODE_TEXT_LINE_MAX_LENGTH - 3) + '...'
              : line}
          </tspan>
        ))}

        <title>{title}</title>
      </text>
    );
  };

  const registerEdgeEvents = (edgeContainer: SVGGElement, edge: IEdge) => {
    edgeContainer.onmouseenter = evt => {
      handleEdgeMouseOver(edge as unknown as GCEdge, evt.clientX, evt.clientY);
    };

    edgeContainer.onmouseleave = handleEdgeMouseOut;

    edgeContainer.onclick = evt => {
      onSelectEdge(edge);

      evt.stopPropagation();
    };
  };

  const afterRenderEdge = (
    id: string,
    element: SVGElement,
    edge: IEdge,
    edgeContainer: SVGGElement,
    isEdgeSelected: boolean
  ) => {
    const textEl = edgeContainer.getElementsByTagName('text')[0];
    const useEl = edgeContainer.getElementsByTagName('use')[0];

    if (!textEl || !useEl) return;

    registerEdgeEvents(edgeContainer, edge);

    const text = edge.handleText;

    if (!text) return;

    const lines = text.split('\n');
    lines.splice(1, 0, '----');

    if (lines.length <= 1) return;

    const linetexts = lines.map(
      line =>
        `<tspan x="0" dy="${EDGE_TEXT_LINE_GAP}" font-size="${EDGE_TEXT_SIZE}" alignment-baseline="middle">${
          line.length > EDGE_TEXT_LINE_MAX_LENGTH
            ? line.substr(0, EDGE_TEXT_LINE_MAX_LENGTH - 3) + '...'
            : line
        }</tspan>`
    );

    const yOffset =
      -lines.length * EDGE_TEXT_LINE_GAP * 0.5 - EDGE_TEXT_SIZE * 0.5;

    textEl.setAttribute('y', yOffset.toString());
    textEl.setAttribute('pointer-events', 'none');
    textEl.innerHTML = linetexts.join('');

    const maxLength = lines.reduce(
      (prev, cur) => (cur.length > prev ? cur.length : prev),
      0
    );
    const edgeRectHalfWidth = EDGE_WIDTH * 0.5;
    const expectedWidth =
      Math.max(
        Math.min(
          maxLength * EDGE_TEXT_CHAR_WIDTH,
          EDGE_TEXT_LINE_MAX_LENGTH * EDGE_TEXT_CHAR_WIDTH
        ),
        EDGE_TEXT_CHAR_WIDTH * EDGE_TEXT_MIN_CHAR
      ) + EDGE_PADDING;

    useEl.setAttribute(
      'transform',
      `${textEl.getAttribute('transform')} scale(${
        expectedWidth / EDGE_WIDTH
      }, 1.0) translate(-${edgeRectHalfWidth}, -${edgeRectHalfWidth})`
    );
  };

  useEffect(() => {
    if (panNodeId <= 0) return;
    if (!gcRef.current) return;

    setTimeout(() => {
      (
        gcRef.current as unknown as {
          panToNode: (id: string, zoom?: boolean) => void;
        }
      ).panToNode(panNodeId.toString(), false);

      onPanToNode?.();
    });
  }, [onPanToNode, panNodeId]);

  return (
    <div
      ref={gcDivRef}
      className={graphCreatorStyle}
      onMouseMove={handleMouseMove}
    >
      <GraphView
        ref={gcRef}
        renderNode={renderNode}
        renderNodeText={renderNodeText}
        afterRenderEdge={afterRenderEdge}
        showGraphControls={false}
        edgeHandleSize={EDGE_WIDTH}
        maxTitleChars={NODE_TEXT_LINE_MAX_LENGTH}
        centerNodeOnMove={false}
        nodeSize={NODE_SIZE}
        rotateEdgeHandle={false}
        onSelectEdge={onSelectEdge}
        {...rest}
      />
      <Tooltip x={tooltipState.x} y={tooltipState.y} show={tooltipState.show}>
        {tooltipState.content}
      </Tooltip>
    </div>
  );
};

export default GraphCreator;
