import React, { useReducer } from 'react';
import get from 'lodash/get';

import PaginationControl from '../../../components/common/PaginationControl/PaginationControl';
import { PaginationType } from '../../../constants/investigationToolsConstant';
import paginationReducer from '../../../reducers/investigation-tools/paginationReducer';
import { getPagination } from '../../../reducers/investigation-tools/selectors';
import { goToAPage } from '../../../actions/investigation-tools/paginationAction';

import './PaginationContainer.style.css';

const ProfileSummaryPaginationContainer = ({
  tableName
}: {
  tableName: string;
}) => {
  const [state, dispatch] = useReducer(paginationReducer, {});

  // @ts-ignore
  const pagination = getPagination(state, PaginationType[tableName]);
  if (!pagination) {
    return null;
  }

  const paginationControlProps = {
    currentPageNumber: get(pagination, 'currentPage', 1),
    totalNumberOfPages: get(pagination, 'totalNumberOfPages', 1),
    onPageNumberClick: (pageNumber: number) => {
      // @ts-ignore
      dispatch(goToAPage(PaginationType[tableName], pageNumber));
    }
  };

  return (
    <div className="container">
      <PaginationControl {...paginationControlProps} />
    </div>
  );
};

export default ProfileSummaryPaginationContainer;
