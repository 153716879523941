import React, { useState, useEffect, useRef } from 'react';
import { Popup } from 'semantic-ui-react';
import { css } from 'emotion';

const truncatedStyle = css`
  & {
    line-height: 1.5em;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    height: 3em;
    overflow: hidden;
  }
`;

const nonTruncatedStyle = css`
  & {
    line-height: 1.5em;
    max-height: 3em;
    overflow: hidden;
  }
`;

const TruncatedDiv = ({ children }) => {
  const [isOverflow, setIsOverflow] = useState(false);
  const [needUpdate, setNeedUpdate] = useState(false);
  const textDiv = useRef(null);

  useEffect(() => {
    setIsOverflow(false);
    setNeedUpdate(true);
  }, [children]);

  useEffect(() => {
    if (!textDiv.current || !needUpdate) return;

    setIsOverflow(textDiv.current.scrollHeight > textDiv.current.offsetHeight);
    setNeedUpdate(false);
  }, [needUpdate]);

  return isOverflow ? (
    <Popup
      basic
      pinned
      hoverable
      content={children}
      trigger={
        <div ref={textDiv} className={truncatedStyle}>
          {children}
        </div>
      }
    />
  ) : (
    <div ref={textDiv} className={nonTruncatedStyle}>
      {children}
    </div>
  );
};

export default TruncatedDiv;
