import React from 'react';
import { Checkbox, Grid, Form } from 'semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize';
import {
  SummaryAPIResponse,
  CaseBlacklistState
} from '../../../types/caseManagement';
import {
  SUMMARY_PROFILE_ID_LABEL,
  SUMMARY_EMAIL_ADDRESS_LABEL,
  SUMMARY_BOOKING_EMAIL_ADDRESS_LABEL,
  SUMMARY_PHONE_NUMBER_LABEL,
  SUMMARY_BOOKING_PHONE_NUMBER_LABEL,
  SUMMARY_DEVICE_ID_LABEL,
  SUMMARY_IP_ADDRESS_LABEL,
  SUMMARY_BANK_ACCOUNT_DETAILS_LABEL,
  NOTES_MAX_LENGTH,
  NOTES_DISPLAY_LINE
} from '../../../constants/caseManagement';
import { remediationFormStyle } from './CaseRemediation.style';

type Props = {
  hideFields?: boolean;
  showSelectAllTags?: boolean;
  data?: SummaryAPIResponse;
  blacklistTags: string[];
  isActive?: boolean;
  isFieldSelected: Record<string, boolean>;
  isTagSelected: Record<string, boolean>;
  noteLabel?: string;
  note: string;
  disabled?: boolean;
  onChange?: (value: CaseBlacklistState) => void;
};

const CaseBlacklist = ({
  hideFields = false,
  showSelectAllTags = false,
  data,
  blacklistTags,
  isActive = false,
  isFieldSelected,
  isTagSelected,
  noteLabel = 'Blacklisting Notes*',
  note,
  disabled = false,
  onChange
}: Props) => {
  const isAllTagsSelected = () => {
    for (let i = 0; i < blacklistTags.length; i++) {
      const tag = blacklistTags[i];

      if (!isTagSelected[tag]) return false;
    }

    return true;
  };

  const handleFieldSelectionChange = (field: keyof SummaryAPIResponse) => {
    onChange?.({
      isActive,
      isFieldSelected: {
        ...isFieldSelected,
        [field]: !isFieldSelected[field]
      },
      isTagSelected,
      note
    });
  };

  const handleTagSelectionChange = (tag: string) => {
    onChange?.({
      isActive,
      isFieldSelected,
      isTagSelected: { ...isTagSelected, [tag]: !isTagSelected[tag] },
      note
    });
  };

  const handleNoteChange = (note: string) => {
    onChange?.({
      isActive,
      isFieldSelected,
      isTagSelected,
      note
    });
  };

  const handleBlacklistSelectionChange = () => {
    onChange?.({
      isActive: !isActive,
      isFieldSelected,
      isTagSelected,
      note
    });
  };

  const handleSelectAllTagsChange = () => {
    const selected = isAllTagsSelected();
    const newIsTagSelected: Record<string, boolean> = {};

    if (!selected) {
      blacklistTags.forEach(tag => {
        newIsTagSelected[tag] = true;
      });
    }

    onChange?.({
      isActive,
      isFieldSelected,
      isTagSelected: newIsTagSelected,
      note
    });
  };

  const renderFieldCheckbox = (
    label: string,
    field: keyof SummaryAPIResponse
  ) => (
    <Grid.Row className="field-row">
      <Grid.Column>
        <Checkbox
          checked={isFieldSelected[field] || false}
          label={label}
          disabled={disabled || !isActive || (!hideFields && !data?.[field])}
          onChange={() => handleFieldSelectionChange(field)}
        />
      </Grid.Column>
      {!hideFields && (
        <Grid.Column className="field-row-data">
          {data?.[field] || '-'}
        </Grid.Column>
      )}
    </Grid.Row>
  );

  return (
    <Form className={remediationFormStyle}>
      <div className="title">
        <span className="title">Blacklisting</span>
        <Checkbox
          className="title-checkbox"
          disabled={disabled}
          checked={!disabled && isActive}
          onChange={handleBlacklistSelectionChange}
        />
      </div>

      <div className="bl-fields">
        <Grid columns={hideFields ? 1 : 2}>
          {renderFieldCheckbox(SUMMARY_PROFILE_ID_LABEL, 'profileId')}
          {renderFieldCheckbox(SUMMARY_EMAIL_ADDRESS_LABEL, 'primaryEmail')}
          {renderFieldCheckbox(
            SUMMARY_BOOKING_EMAIL_ADDRESS_LABEL,
            'secondaryEmail'
          )}
          {renderFieldCheckbox(SUMMARY_PHONE_NUMBER_LABEL, 'primaryPhone')}
          {renderFieldCheckbox(
            SUMMARY_BOOKING_PHONE_NUMBER_LABEL,
            'secondaryPhone'
          )}
          {renderFieldCheckbox(SUMMARY_DEVICE_ID_LABEL, 'deviceId')}
          {renderFieldCheckbox(SUMMARY_IP_ADDRESS_LABEL, 'ipAddress')}
          {renderFieldCheckbox(
            SUMMARY_BANK_ACCOUNT_DETAILS_LABEL,
            'bankAccountDetails'
          )}
        </Grid>
      </div>

      <div className="tags">
        <div className="title">Blacklisting Tags</div>

        {showSelectAllTags && (
          <div className="tag-row">
            <Checkbox
              checked={isAllTagsSelected()}
              label={'Select All'}
              disabled={!isActive}
              onChange={handleSelectAllTagsChange}
            />
          </div>
        )}
        {blacklistTags.map(tag => (
          <div key={tag} className="tag-row">
            <Checkbox
              checked={isTagSelected[tag] || false}
              label={tag}
              disabled={disabled || !isActive}
              onChange={() => handleTagSelectionChange(tag)}
            />
          </div>
        ))}
      </div>

      <div className="note">
        <label>
          {noteLabel}
          <TextareaAutosize
            className="no-resize"
            rows={1}
            value={note}
            maxLength={NOTES_MAX_LENGTH}
            maxRows={NOTES_DISPLAY_LINE}
            disabled={disabled || !isActive}
            onChange={evt => handleNoteChange(evt.currentTarget.value)}
          />
        </label>
      </div>
    </Form>
  );
};

export default CaseBlacklist;
