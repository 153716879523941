import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import { Table, Select } from 'semantic-ui-react';

import {
  ConnectionSearchFilterAttributes,
  SearchIdType,
  RelationshipType,
  PaginationType
} from '../../../../constants/investigationToolsConstant';
import { getConnectionSearch } from '../../../../reducers/investigation-tools/selectors';
import {
  setApplicationSearchType,
  setApplicationFilterAttribute
} from '../../../../actions/investigation-tools/connectionSearchAction';
import { useConnectionSearchContext } from '../../../../views/investigation-tools/connection-search/ConnectionSearchContext';
import ConnectionSearchPaginationContainer from '../ConnectionSearchPaginationContainer';
import './ConnectionSearchTableContainer.style.css';
import { optionMapper } from './common';

const ApplicationRelationshipTableContainer = () => {
  const { getState, dispatch, handleApplicationSearch } =
    useConnectionSearchContext();
  const [searchType, setSearchType] = useState('');
  const [filters, setFilters] = useState({});

  const state = getState();
  const {
    applicationEntries,
    applicationSearchType,
    applicationFilterAttributes
  } = getConnectionSearch(state);

  useEffect(() => {
    if (
      isEqual(searchType, applicationSearchType) &&
      isFunction(handleApplicationSearch)
    ) {
      handleApplicationSearch();
    }
  }, [searchType, applicationSearchType, handleApplicationSearch]);

  useEffect(() => {
    if (
      isEqual(filters, applicationFilterAttributes) &&
      isFunction(handleApplicationSearch)
    ) {
      handleApplicationSearch();
    }
  }, [filters, applicationFilterAttributes, handleApplicationSearch]);

  const handleSearchTypeChange = (event, data) => {
    setSearchType(data.value);
    dispatch(setApplicationSearchType(data.value));
  };

  const handleFilterChange = attribute => (event, data) => {
    setFilters({ ...applicationFilterAttributes, [attribute]: data.value });
    dispatch(setApplicationFilterAttribute(attribute, data.value));
  };

  const defaultOption = {
    key: 'NULL',
    text: 'NULL',
    value: null
  };

  return (
    <div>
      <div className="connection-search-table-container">
        <Table className="connection-search-table">
          <Table.Header>
            <Table.Row className="connection-search-table-column-name-row">
              <Table.HeaderCell>Search ID</Table.HeaderCell>
              <Table.HeaderCell>Type of Search ID</Table.HeaderCell>
              <Table.HeaderCell>Relationship Type</Table.HeaderCell>
              <Table.HeaderCell>Relationship Profile ID</Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell>
                <Select
                  className="connection-search-table-parameter-select"
                  options={optionMapper(SearchIdType)}
                  onChange={handleSearchTypeChange}
                  value={applicationSearchType}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Select
                  className="connection-search-table-parameter-select"
                  options={[defaultOption, ...optionMapper(RelationshipType)]}
                  onChange={handleFilterChange(
                    ConnectionSearchFilterAttributes.RELATIONSHIP_TYPE
                  )}
                  value={get(
                    applicationFilterAttributes,
                    ConnectionSearchFilterAttributes.RELATIONSHIP_TYPE,
                    null
                  )}
                />
              </Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {isArray(applicationEntries) && !isEmpty(applicationEntries) ? (
              applicationEntries.map((entry, idx) => (
                <Table.Row
                  className="connection-search-entry-table-row"
                  key={idx}
                >
                  <Table.Cell>{get(entry, 'searchId', '')}</Table.Cell>
                  <Table.Cell>{get(entry, 'searchIdType', '')}</Table.Cell>
                  <Table.Cell>{get(entry, 'relationshipType', '')}</Table.Cell>
                  <Table.Cell>
                    {get(entry, 'relationshipProfileId', '')}
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row className="connection-search-no-data-table-row">
                <Table.Cell colSpan={'4'} style={{ textAlign: 'center' }}>
                  - No Data -
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
      <ConnectionSearchPaginationContainer
        paginationType={PaginationType.APPLICATION_RELATIONSHIP_TABLE}
        handleSearch={handleApplicationSearch}
      />
    </div>
  );
};

export default ApplicationRelationshipTableContainer;
