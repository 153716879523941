import React from 'react';
import {
  DetectorAPIResponse,
  CommonAPIResponse
} from '../../../types/caseManagement';
import { css } from 'emotion';

type Props = {
  data: CommonAPIResponse;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getResValue = (value?: any) => {
  if (value === '' || value === null || value === undefined) return '-';

  return value.toString();
};

const labelStyle = css`
  & {
    font-weight: bold;
  }
`;
const valueStyle = css`
  & {
    color: #4a5568;
  }
`;
const prefixStyle = css`
  & {
    display: inline-block;
    width: 12px;
  }
`;
const indentedStyle = css`
  & {
    padding-left: 1.5em;
  }
`;

const renderResponse = (
  res: CommonAPIResponse,
  isIndented = false,
  prefix = ''
) => {
  // non object or array
  if (res === null || res === undefined || !(typeof res === 'object')) {
    return (
      <React.Fragment>
        {prefix && <span className={prefixStyle}>{prefix}</span>}
        <span className={valueStyle}>{getResValue(res)}</span>
      </React.Fragment>
    );
  }

  //array
  if (res.constructor === Array) {
    return (
      <React.Fragment>
        {(res as DetectorAPIResponse[]).map((v, i) => (
          <div
            key={`response-list-${i}`}
            className={isIndented ? indentedStyle : ''}
          >
            {renderResponse(v, false, '-')}
          </div>
        ))}
      </React.Fragment>
    );
  }

  // object
  const resObj = res as { [key: string]: CommonAPIResponse };

  return (
    <React.Fragment>
      {Object.keys(resObj).map((k, i) => (
        <div
          key={`response-${k}-${i}`}
          className={isIndented ? indentedStyle : ''}
        >
          {prefix && (
            <span className={prefixStyle}>{i === 0 ? prefix : ''}</span>
          )}
          <span className={labelStyle}>{k}: </span>
          {renderResponse(resObj[k], true)}
        </div>
      ))}
    </React.Fragment>
  );
};

const CaseAPIResponseFormatter = ({ data }: Props) => {
  return renderResponse(data);
};

export default CaseAPIResponseFormatter;
