import config from '../configs';

export const FraudServiceUrl = {
  SEARCH_PROFILE:
    config.fmgsHostName + '/v1/investigation-tools/profile/search',
  SEARCH_USER_PROFILE_LIST:
    config.fmgsHostName + '/v1/investigation-tools/user-profile-list',
  SEARCH_BOOKING:
    config.fmgsHostName + '/v1/investigation-tools/booking/search',
  DOWNLOAD_BOOKING: config.fmgsHostName + '/v1/investigation-tools/booking/csv',
  SEARCH_BOOKING_CONNECTION:
    config.fmgsHostName + '/v1/investigation-tools/connection/booking/search',
  SEARCH_APPLICATION_CONNECTION:
    config.fmgsHostName +
    '/v1/investigation-tools/connection/application/search',
  SEARCH_ACTIVITY:
    config.fmgsHostName + '/v1/investigation-tools/activity/search',
  SEARCH_PAYMENT_DATA:
    config.fmgsHostName + '/v1/investigation-tools/payment-history/search',
  SEARCH_DRIVER_DATA:
    config.fmgsHostName + '/v1/investigation-tools/driver-data',
  SEARCH_USER_PROFILE_CONTEXT:
    config.fmgsHostName + '/v1/investigation-tools/user-profile-context/search',
  SEARCH_USER_PROFILE_DATA:
    config.fmgsHostName + '/v1/investigation-tools/user-profile-data',
  SEARCH_DRIVER_PROFILE_CONTEXT_SEARCH:
    config.fmgsHostName +
    '/v1/investigation-tools/driver-profile-context/search',
  SEARCH_MERCHANT_DATA:
    config.fmgsHostName + '/v1/investigation-tools/merchant-data/search',
  SEARCH_FRAUD_CHECK_HISTORY:
    config.fmgsHostName + '/v1/investigation-tools/fraud-history/search',
  SEARCH_COMM_ACTIVITY:
    config.fmgsHostName +
    '/v1/investigation-tools/communication-history/search',
  KYC_DATA: config.fmgsHostName + '/v1/investigation-tools/kyc-data'
};
