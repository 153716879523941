import React, { useEffect, useReducer } from 'react';
import { Loader } from 'semantic-ui-react';
import get from 'lodash/get';

import {
  initialState,
  MerchantDataReducer
} from '../../../reducers/investigation-tools/merchantDataReducer';
import {
  initPagination,
  goToAPage,
  setTotalNumberOfPages
} from '../../../actions/investigation-tools/paginationAction';
import paginationReducer from '../../../reducers/investigation-tools/paginationReducer';
import { fetchMerchantData } from '../../../actions/investigation-tools/merchantDataAction';
import {
  setKeyword,
  setSearchType
} from '../../../actions/investigation-tools/commonAction';
import {
  PaginationType,
  MerchantDataSearchType
} from '../../../constants/investigationToolsConstant';
import { getMerchantData } from '../../../reducers/investigation-tools/selectors';

import MerchantDataContext, {
  useMerchantDataContext
} from './MerchantDataContext';
import MerchantDataTable from '../../../components/investigation-tools/merchant-data/MerchantDataTable';
import Layout from '../../../components/Layout';
import SummaryCard from '../../../components/investigation-tools/common/SummaryCard';
import { merchantDataSearchLabels } from '../../../constants/investigationToolsLabels';

const MerchantData = () => {
  const [state, dispatch] = useReducer(MerchantDataReducer, initialState);
  const [paginationState, paginationDispatch] = useReducer(
    paginationReducer,
    {}
  );

  const { isLoading } = { ...state };

  useEffect(() => {
    paginationDispatch(
      // @ts-ignore
      initPagination([PaginationType.MERCHANT_DATA_TABLE])
    );
  }, []);

  const getCombinedState = () => ({
    merchant_data: { ...state },
    pagination: { ...paginationState }
  });

  const handleSearch = () => {
    fetchMerchantData(dispatch, getCombinedState).then(result => {
      const pageCount = get(result, 'data.pageCount', null);
      const currentPageNo = get(result, 'data.currentPageNo', null);

      paginationDispatch(
        // @ts-ignore
        setTotalNumberOfPages(
          PaginationType.MERCHANT_DATA_TABLE,
          isFinite(pageCount) ? pageCount : 1
        )
      );

      paginationDispatch(
        // @ts-ignore
        goToAPage(
          PaginationType.MERCHANT_DATA_TABLE,
          isFinite(currentPageNo) ? currentPageNo : 1
        )
      );
    });
  };

  return (
    <MerchantDataContext.Provider
      value={{
        getState: getCombinedState,
        dispatch,
        paginationDispatch,
        handleSearch
      }}
    >
      <Layout>
        <h1>Merchant Data</h1>
        <SummaryCard
          useContext={useMerchantDataContext}
          useSelector={getMerchantData}
          searchTypeConstants={MerchantDataSearchType}
          setKeyword={setKeyword}
          setSearchType={setSearchType}
          labels={merchantDataSearchLabels}
        />
        <Loader active={isLoading} />
        <div style={{ marginBottom: '20px' }} />
        {!isLoading && <MerchantDataTable />}
      </Layout>
    </MerchantDataContext.Provider>
  );
};

export default MerchantData;
