export const PaginationType = {
  PROFILE_SUMMARY_TABLE: 'profileSummaryTable',
  PROFILE_SUMMARY_PROFILE_LIST: 'profileSummaryProfileList',
  APPLICATION_RELATIONSHIP_TABLE: 'applicationRelationshipTable',
  BOOKING_RELATIONSHIP_TABLE: 'bookingRelationshipTable',
  BOOKING_DETAIL_TABLE: 'bookingDetailTable',
  ACTIVITY_TABLE: 'activityTable',
  BLACKLISTED_APPLICATION_USER_TABLE: 'blacklistedApplicationUserTable',
  USER_PROFILE_CONTEXT_SEARCH_TABLE: 'userProfileContextSearchTable',
  PAYMENT_DATA_TABLE: 'paymentDataTable',
  DRIVER_PROFILE_CONTEXT_SEARCH_TABLE: 'driverProfileContextSearchTable',
  DRIVER_DATA_TABLE: 'driverDataTable',
  MERCHANT_DATA_TABLE: 'merchantDataTable',
  FRAUD_CHECK_HISTORY_TABLE: 'fraudCheckHistoryTable',
  COMM_ACTIVITY_TABLE: 'commActivityTable'
};

export const MatchType = {
  EXACT_MATCH: 'EXACT',
  FUZZY_MATCH: 'FUZZY'
};

export const ProfileSearchFilterAttributes = {
  PROXY_TYPE: 'proxyType'
};

export const ProfileSearchType = ['CUSTOMER_ID', 'PROFILE_ID', 'CUSTOMER_NAME'];

export const ProfileProxyType = [
  'APPLICATION_EMAIL',
  'APPLICATION_KTP_NAME',
  'APPLICATION_NAME',
  'APPLICATION_PHONE_NUMBER',
  'BOOKING_CONTACT_EMAIL',
  'BOOKING_CONTACT_PHONE',
  'CUSTOMER_ID_CARD_NUMBER',
  'DEVICE_ID',
  'EMERGENCY_CONTACT_NAME',
  'EMERGENCY_PHONE_NUMBER',
  'MOTHER_MAIDEN_NAME',
  'OFFICE_NAME',
  'OFFICE_PHONE_NUMBER'
];

export const ConnectionSearchType = ['EMAIL', 'NAME', 'PHONE_NUMBER'];

export const BookingSearchType = [
  'BOOKING_ID',
  'PROFILE_ID',
  'USER_ID',
  'BOOKING_CONTACT_EMAIL',
  'BOOKING_CONTACT_PHONE',
  'DEVICE_ID',
  'COOKIE_ID'
];

export const ActivitySearchType = [
  'DEVICE_ID',
  'PROFILE_ID',
  'EVENT_PROFILE_ID',
  'LOGIN_ID',
  'EVENT_LOGIN_ID',
  'COOKIE_ID',
  'ADDED_EMAIL_ADDRESS',
  'ADDED_PHONE_NUMBER',
  'EVENT_TYPE',
  'LOGIN_METHOD',
  'EVENT_LOGIN_METHOD',
  'RESULTS',
  'SESSION_ID',
  'IP_CITY',
  'IP'
];

export const ActivitySearchConditional = ['AND', 'OR'];

export const BookingDetailFilterAttributes = {
  PAYMENT_ASSIGNMENT_STATUS: 'paymentAssignmentStatus',
  PAYMENT_METHOD: 'paymentMethod',
  PRODUCT_TYPE: 'productType'
};

export const PaymentAssignmentStatus = ['NOT_PAID', 'PAID'];

export const PaymentMethod = {
  SEVEN_ELEVEN: '7-Eleven',
  ADJUSTMENT: 'Adjustment',
  AFFILIATE: 'Affiliate',
  AFFINONLINE: 'affinOnline',
  AIRPAY: 'Airpay',
  ALFAMART: 'Alfamart',
  AMONLINE: 'AmOnline',
  ATM: 'ATM',
  AXS_STATION: 'AXS Station',
  BANGKOK_BANK: 'Bangkok Bank',
  BANK_ISLAM: 'Bank Islam',
  BANK_OF_AYUDHYA: 'Bank of Ayudhya',
  BCA_KLIKPAY: 'BCA KlikPay',
  BIG_C: 'Big C',
  CASH: 'Cash',
  CASH_ON_DELIVERY: 'Cash on Delivery',
  CIMB_CLICKS: 'CIMB Clicks',
  COINS: 'Coins',
  CORPORATE_TRANSFER: 'Corporate Transfer',
  COUNTER_PAYMENT: 'Counter Payment',
  CREDIT_CARD: 'Credit Card',
  DRAGONPAY: 'Dragonpay',
  E_PAY: 'e-pay',
  EDC: 'EDC',
  ENETS: 'eNETS',
  FAMILY_MART: 'Family Mart',
  HONG_LEONG: 'Hong Leong',
  INDOMARET: 'Indomaret',
  INSTANT_DEBIT: 'Instant Debit',
  INTERNET_BANKING: 'Internet Banking',
  K_PLUS: 'K PLUS',
  KRUNG_THAI_BANK: 'Krung Thai Bank',
  LINKAJA: 'LinkAja',
  MANDIRI_CLICKPAY: 'Mandiri Clickpay',
  MANDIRI_DEBIT: 'Mandiri Debit',
  MANUAL_CORPORATE: 'Manual Corporate',
  MAYBANK2U: 'Maybank2u',
  MPAY_STATION: 'mPAY Station',
  ONEPAY: 'OnePAY',
  PAY_AT_HOTEL: 'Pay at Hotel',
  PAY_AT_POST: 'Pay at Post',
  PAYLATER: 'PayLater',
  PAYMENT_METHOD: 'payment_method',
  PAYNAMICS: 'Paynamics',
  PAYOO: 'Payoo',
  PAYPAL: 'PayPal',
  POINTS: 'Points',
  POST_OFFICE: 'Post Office',
  PUBLIC_BANK_PBE: 'Public Bank PBe',
  REBOOK_FLIGHT: 'Rebook Flight',
  REBOOK_HOTEL: 'Rebook Hotel',
  REIMBURSEMENT_BEFORE_ISSUANCE: 'Reimbursement Before Issuance',
  RESCHEDULE_CASHBACK: 'Reschedule Cashback',
  REVPAY_FPX: 'Revpay FPX',
  RHB_NOW: 'RHB Now',
  SAM_KIOSK: 'SAM Kiosk',
  SIAM_COMMERCIAL_BANK: 'Siam Commercial Bank',
  TESCO_LOTUS: 'Tesco Lotus',
  TESTING: 'Testing',
  TMB_BANK: 'TMB Bank',
  TOT_JUST_PAY: 'TOT Just Pay',
  TRANSFER: 'Transfer',
  TRUE_MONEY_EXPRESS: 'True Money Express',
  UANGKU_BALANCE: 'UANGKU Balance',
  UOB: 'UOB',
  VIRTUAL_ACCOUNT: 'Virtual Account',
  WEBPAY: 'Webpay'
};

export const ProductType = {
  AIRPORT_TRANSFER: 'Airport Transfer',
  BPJS_HEALTH: 'BPJS Health',
  BUS: 'Bus',
  CABLE_TV_INTERNET_POSTPAID: 'Cable TV & Internet Postpaid',
  CABLE_TV_INTERNET_PREPAID: 'Cable TV & Internet Prepaid',
  CAR_RENTAL: 'Car Rental',
  CREDIT_CARD: 'Credit Card',
  DISCOUNT_PACKAGE: 'Discount Package',
  EATS_DELIVERY: 'Eats Delivery',
  EATS_VOUCHER: 'Eats Voucher',
  ELECTRONIC_MONEY: 'Electronic Money',
  FLIGHT: 'Flight',
  FLIGHT_PLUS_HOTEL: 'Flight + Hotel',
  FLIGHT_ANCILLARY: 'Flight Ancillary',
  FLIGHT_CHECK_IN: 'Flight Check-In',
  GAME_VOUCHER: 'Game Voucher',
  GIFT_VOUCHER: 'Gift Voucher',
  GLOBAL_TRAIN: 'Global Train',
  GOLD: 'Gold',
  HEALTH: 'Health',
  HOTEL: 'Hotel',
  INSURANCE: 'Insurance',
  INSURANCE_POSTPAID: 'Insurance Postpaid',
  MOBILE_DATA_PACKAGES: 'Mobile Data Packages',
  MOBILE_POSTPAID: 'Mobile Postpaid',
  MOBILE_TOP_UP: 'Mobile Top Up',
  MOVIES: 'Movies',
  MULTIFINANCE: 'Multifinance',
  ONE_WAY: 'One Way',
  ORDER_AHEAD: 'Order Ahead',
  ORDER_NOW: 'Order Now',
  PAYLATER_CREDIT_CARD: 'PayLater Credit Card',
  PBB: 'PBB',
  PDAM: 'PDAM',
  PLN_POSTPAID: 'PLN Postpaid',
  PLN_PREPAID: 'PLN Prepaid',
  ROAMING: 'Roaming',
  SIM_CARD: 'SIM Card',
  TELKOM: 'Telkom',
  TOP_UP_DATA_PACKAGES: 'Top Up & Data Packages',
  TRAIN: 'Train',
  TREATS: 'Treats',
  VISA: 'Visa',
  WIFI_RENTAL: 'WiFi Rental',
  XPERIENCE: 'Xperience'
};

export const ConnectionSearchFilterAttributes = {
  RELATIONSHIP_TYPE: 'relationshipType',
  BOOKING_CONTACT_EMAIL: 'bookingContactEmail'
};

export const RelationshipType = [
  'APPLICATION_NAME',
  'APPLICATION_PHONE_NUMBER',
  'APPLICATION_EMAIL',
  'APPLICATION_EMERGENCY_CONTACT_PHONE',
  'APPLICATION_OFFICE_PHONE_NUMBER'
];

export const SearchIdType = ['EMAIL', 'NAME', 'PHONE_NUMBER'];

export const UserProfileContextFilterAttributes = {
  PROFILE_FULL_NAME: 'profileFullName',
  EMAIL_ADDRESS: 'emailAddress'
};

export const UserProfileSearchType = [
  'PROFILE_ID',
  'KYC_ID',
  'PROFILE_KYC_NAME',
  'EMAIL_ADDRESS',
  'PHONE_NUMBER',
  'ID_CARD_NUMBER',
  'BOOKING_ID'
];

export const PaymentDataSearchType = [
  'PROFILE_ID',
  'PAYMENT_ID',
  'BOOKING_ID',
  'BANK_ACCOUNT_NUMBER',
  'BANK_ACCOUNT_NAME'
];

export const DriverProfileContextFilterAttributes = {
  DRIVER_NAME: 'driverName',
  DOMICILE_CITY: 'domicileCity'
};

export const DriverProfileSearchType = [
  'DRIVER_ID',
  'DRIVER_NAME',
  'PHONE_NUMBER',
  'EMAIL_ADDRESS',
  'DRIVER_ID_CARD_NUMBER',
  'BOOKING_ID'
];

export const MerchantDataSearchType = [
  'MERCHANT_ID',
  'MERCHANT_NAME',
  'PHONE_NO',
  'EMAIL',
  'NIK',
  'BOOKING_ID'
];

export const FraudCheckHistoryType = ['PROFILE_ID', 'KYC_ID'];

export const CommActivityMessageType = [
  'ALL',
  'EMAIL',
  'SMS',
  'NOTIFICATION',
  'MY_INBOX'
];

export const CommonSearchConditional = ['AND', 'OR'];
