import React from 'react';
import ReactDOM from 'react-dom';
import ActionEventListener from './ActionEventListener';
import { Button, Popup } from 'semantic-ui-react';
import { AppContext } from '../../AppContext';
// CSS
import style from './SideNav.style';
import SideNavAccordion from './SideNavAccordion';

/**
 * Adapted from Traveloka Soya Component
 * https://github.com/traveloka/soya-component
 */
class SideNav extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this._handleClickOutside = this._handleClickOutside.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleListMenu = this.toggleListMenu.bind(this);

    this.state = {
      openedMenuItem: null
    };
  }

  componentDidMount() {
    const domNode = ReactDOM.findDOMNode(this); // eslint-disable-line react/no-find-dom-node
    this.clickOutsideListener = ActionEventListener.createPredefinedEvent(
      ActionEventListener.event.CLICK_OUTSIDE,
      {
        el: domNode,
        callback: this._handleClickOutside
      }
    );
    this.clickOutsideListener.listen();
  }

  componentWillUnmount() {
    this.clickOutsideListener.destroy();
  }

  _handleClickOutside() {
    if (this.state.openedMenuItem != null) {
      this.setState({ openedMenuItem: null });
    }
  }

  toggleMenu() {
    const [appState, dispatch] = this.context;

    dispatch({
      type: appState.isSideNavOpen ? 'CLOSE_SIDENAV' : 'OPEN_SIDENAV'
    });

    this.setState({ openedMenuItem: null });
  }

  toggleListMenu(listMenuId) {
    this.setState({
      openedMenuItem: listMenuId
    });
  }

  isSameRootPath(path, currentPath) {
    if (path === '' || path === '/') return false;

    const p1 = path.split('/');
    const p2 = currentPath.split('/');

    if (p1.length > p2.length) return false;

    for (let i = 1; i < p2.length; i++) {
      if (!p1[i]) return true;
      if (p1[i] !== p2[i]) return false;
    }

    return true;
  }

  isActiveLink({ path, childPaths }, currentPath) {
    const trimmedPath = this.trimPath(path);

    if (trimmedPath === currentPath) return true;

    if (this.isSameRootPath(trimmedPath, currentPath)) return true;

    if (childPaths && childPaths.length > 0) {
      for (let i = 0; i < childPaths.length; i++) {
        const trimmedChildPath = this.trimPath(childPaths[i]);

        if (this.isSameRootPath(trimmedChildPath, currentPath)) return true;
      }
    }

    return false;
  }

  trimPath(path) {
    if (path[path.length - 1] === '/') {
      return path.substring(0, path.length - 1);
    }

    return path;
  }

  render() {
    const [{ isSideNavOpen }] = this.context;

    const trimmedPath = this.trimPath(this.props.currentPath);

    return (
      <div
        style={this.props.style}
        className={style + ' ' + (isSideNavOpen ? 'open' : '')}
      >
        <div className={'sideNavHeader'}>
          <div className={'sideNavHeaderTitle'}>{this.props.title}</div>
          {isSideNavOpen && (
            <Popup
              basic
              hideOnScroll
              inverted
              content="Close side bar"
              trigger={
                <Button
                  size="mini"
                  icon="chevron left"
                  className={'toggleSideNav'}
                  onClick={this.toggleMenu}
                />
              }
            />
          )}
          {!isSideNavOpen && (
            <button className={'toggleSideNav'} onClick={this.toggleMenu}>
              <span />
              <span />
              <span />
            </button>
          )}
        </div>

        {isSideNavOpen ? (
          <SideNavAccordion menuData={this.props.menuData} />
        ) : (
          <div>
            {this.props.menuData.map((mn, id) => {
              let icon = <span>{mn.iconText}</span>;
              const position = mn.showAtBottom ? 'bottom' : '';
              if (mn.icon) icon = <span className={mn.icon} />;
              return (
                <div className={'menuGroup ' + position} key={id}>
                  <a // eslint-disable-line
                    onClick={() => this.toggleListMenu(id)}
                    title={mn.title}
                    className={'sideNavIcon'}
                  >
                    {icon}
                  </a>
                  <ul
                    className={
                      'sideNavList ' +
                      (this.state.openedMenuItem === id ? 'open ' : '') +
                      position
                    }
                  >
                    {mn.links.length > 0 && (
                      <li className={'listTitle'}>{mn.title}</li>
                    )}
                    {mn.links.map((ml, idx) => {
                      if (ml.component && React.isValidElement(ml.component)) {
                        const isCurrent = this.isActiveLink(ml, trimmedPath);

                        return (
                          <li key={idx} className={isCurrent ? 'active' : ''}>
                            {ml.component}
                          </li>
                        );
                      }
                      return (
                        <li key={idx}>
                          <a href={ml.href} title={ml.title}>
                            {ml.title}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export default SideNav;
