export const SET_IS_LOADED_STATE = 'SET_IS_LOADED_STATE';
export const SET_IS_LOADING_STATE = 'SET_IS_LOADING_STATE';
export const SET_KEYWORD = 'SET_KEYWORD';
export const SET_SEARCH_TYPE = 'SET_SEARCH_TYPE';
export const SET_FILTER_ATTRIBUTE = 'SET_FILTER_ATTRIBUTE';
export const SET_ENTRIES = 'SET_ENTRIES';
export const ADD_QUERY = 'ADD_QUERY';
export const REMOVE_QUERY = 'REMOVE_QUERY';
export const SET_CONDITIONAL = 'SET_CONDITIONAL';
export const SET_RESULT = 'SET_RESULT';
export const SET_START_DATE = 'SET_START_DATE';
export const SET_END_DATE = 'SET_END_DATE';
