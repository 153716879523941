import React, { useState, useEffect } from 'react';
import {
  Accordion,
  Icon,
  Form,
  Table,
  Grid,
  Confirm,
  GridColumn
} from 'semantic-ui-react';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import _ from 'lodash';
import config from '../../../configs';
import { toast } from 'react-toastify';
import { convertToLocalTime } from '../../../utils/dateFormatter';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox';
import TruncatedCell from '../../../components/table/TruncatedCell';
import axios from 'axios';
import { listSearchStyle } from './ListSearch.style';

const BlackListSearch = () => {
  const headers = [
    'Last Modified Time',
    'Last Modified By',
    'Entry Type',
    'Value',
    'Tag',
    'Notes',
    'Case ID',
    'Reference Type',
    'Reference ID'
  ];

  const [data, setData] = useState([]);
  const [active, setActive] = useState(true);
  const [searchFields, setSearchFields] = useState([]);
  const [tags, setTags] = useState([]);

  const [fieldValue, setFieldValue] = useState('');
  const [selectedField, setSelectedField] = useState('');
  const [searchedEntryType, setSearchedEntryType] = useState({
    typeKey: '',
    typeName: ''
  });

  const [page, setPage] = useState(0);
  const [size] = useState(20);
  const [totalNum, setTotalNum] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [selections, setSelections] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);

  const handleSelect = rowIdx => {
    if (_.isEmpty(selections) || rowIdx >= selections.length) {
      return;
    }
    const newSelections = selections.slice();
    newSelections[rowIdx] = !newSelections[rowIdx];
    setSelections(newSelections);
  };

  const handleAllSelected = () => {
    let newSelections;
    if (checkAllSelected()) {
      newSelections = Array(data.length).fill(false);
    } else {
      newSelections = Array(data.length).fill(true);
    }
    setSelections(newSelections);
  };

  const checkAllSelected = () => {
    return (
      selections.length > 0 &&
      selections.filter(selection => selection === true).length ===
        selections.length
    );
  };

  const onClickDelete = () => {
    if (_.isEmpty(selections.filter(selection => selection === true))) {
      toast.error('No blacklist entry has been selected');
    } else {
      setDeleteModal(true);
    }
  };

  const deleteSelectedEntries = () => {
    const selectedIdxes = [];
    selections.forEach((value, index) => {
      if (value) {
        selectedIdxes.push(index);
      }
    });
    const blacklistKeys = selectedIdxes.map(idx => {
      return {
        attributeValue: data[idx].attributeValue,
        tagId: data[idx].tagId
      };
    });
    axios
      .delete(config.blacklistEntries, {
        data: {
          attributeType: searchedEntryType.typeKey,
          blacklistKeys
        }
      })
      .then(() => {
        toast.success('Blacklist entries have been deleted successfully.');
        setDeleteModal(false);
        getBlacklistEntries(page);
      })
      .catch(err => {
        toast.error(
          'Error deleting blacklist entries. Error: ' + (err && err.message)
        );
      });
  };

  const toggleSearch = () => {
    setActive(!active);
  };

  const onFieldChange = field => {
    setFieldValue('');
    setSelectedField(field);
  };

  const getEntryType = value => {
    try {
      return searchFields.filter(field => field.value === value)[0].text;
    } catch (err) {
      toast.error(
        'Error fetching search field name. Error: ' + (err && err.message)
      );
    }
  };

  const getTag = tagId => {
    if (_.isEmpty(tags)) {
      return;
    }
    try {
      return tags.filter(tag => tag.id === tagId)[0].name;
    } catch (err) {
      toast.error('Error fetching Tag. Error: ' + (err && err.message));
    }
  };

  const getBlacklistEntries = page => {
    if (_.isEmpty(selectedField)) {
      toast.error('Please select search field');
      return;
    }
    axios
      .get(config.blacklistEntries, {
        params: {
          attributeKey: selectedField,
          attributeValue: encodeURIComponent(fieldValue.trim()),
          page: page,
          size: size,
          sort: 'lastUpdatedAt,DESC'
        }
      })
      .then(res => {
        if (res && res.data) {
          setData(res.data.content);
          setSearchedEntryType({
            typeKey: selectedField,
            typeName: getEntryType(selectedField)
          });
          setTotalNum(res.data.total);
          setTotalPages(Math.ceil(res.data.total / size));
          setSelections(Array(res.data.content.length).fill(false));
        }
      })
      .catch(err => {
        toast.error(
          'Error fetching blacklist data. Error: ' + (err && err.message)
        );
      });
  };

  const nextPage = () => {
    if (totalPages === 0) {
      return;
    }
    const newPage = Math.min(totalPages - 1, page + 1);
    setPage(newPage);
    getBlacklistEntries(newPage);
  };

  const prevPage = () => {
    if (totalPages === 0) {
      return;
    }
    const newPage = Math.max(0, page - 1);
    setPage(newPage);
    getBlacklistEntries(newPage);
  };

  const onClickSearch = () => {
    setPage(0);
    getBlacklistEntries(0);
  };

  useEffect(() => {
    const promiseArray = [config.blacklistAttributes, config.blacklistTags].map(
      url => axios.get(url)
    );

    Promise.all(promiseArray)
      .then(res => {
        if (res[0] && res[0].data) {
          setSearchFields(
            res[0].data.map(attribute => {
              return {
                name: attribute.attributeName,
                key: attribute.attributeKey,
                text: attribute.attributeName,
                value: attribute.attributeKey
              };
            })
          );
        }
        if (res[1] && res[1].data) {
          setTags(res[1].data);
        }
      })
      .catch(err => {
        toast.error(
          'Error loading blacklist data. Error: ' + (err && err.message)
        );
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={listSearchStyle}>
      <Segment>
        <Accordion>
          <Accordion.Title active={active} index={0} onClick={toggleSearch}>
            <Icon name="dropdown" />
            Search Parameters
          </Accordion.Title>
          <Accordion.Content active={active}>
            <Form>
              <Form.Select
                width={8}
                fluid
                label="Search Field"
                placeholder="Select Field"
                options={searchFields}
                onChange={(evt, data) => onFieldChange(data.value)}
                value={selectedField}
              />
              <Form.Input
                width={8}
                fluid
                label="Field Value"
                placeholder="Field Value"
                onChange={(evt, { value }) => setFieldValue(value)}
                value={fieldValue}
              />
              <Button
                primary
                icon="search"
                content="Search"
                onClick={onClickSearch}
              />
            </Form>
          </Accordion.Content>
        </Accordion>
      </Segment>
      <Button
        icon="delete"
        content="Remove Selected from Blacklist"
        onClick={onClickDelete}
      />
      <div className="table-scroll">
        <Table celled className="search-table">
          <Table.Header>
            {headers && (
              <Table.Row textAlign="center">
                <Table.HeaderCell className="width-select-box">
                  <Checkbox
                    checked={checkAllSelected()}
                    onClick={handleAllSelected}
                  />
                </Table.HeaderCell>
                {headers.map((header, idx) => {
                  return (
                    <Table.HeaderCell className="width-field" key={idx}>
                      {header}
                    </Table.HeaderCell>
                  );
                })}
              </Table.Row>
            )}
          </Table.Header>
          <Table.Body>
            {data.length ? (
              data.map((detail, idx) => {
                return (
                  <Table.Row key={idx} textAlign="center">
                    <Table.Cell>
                      <Checkbox
                        checked={selections[idx]}
                        onClick={() => handleSelect(idx)}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      {convertToLocalTime(detail.lastUpdatedAt)}
                    </Table.Cell>
                    <TruncatedCell>{detail.lastUpdatedBy}</TruncatedCell>
                    <TruncatedCell>{searchedEntryType.typeName}</TruncatedCell>
                    <TruncatedCell>{detail.attributeValue}</TruncatedCell>
                    <TruncatedCell>{getTag(detail.tagId)}</TruncatedCell>
                    <TruncatedCell>{detail.notes}</TruncatedCell>
                    <TruncatedCell>{detail.fraudId}</TruncatedCell>
                    <TruncatedCell>{detail.referenceType}</TruncatedCell>
                    <TruncatedCell>{detail.referenceId}</TruncatedCell>
                  </Table.Row>
                );
              })
            ) : (
              <Table.Row textAlign="center">
                <Table.Cell colSpan={headers.length + 1}>No Data</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>

      <Grid colums={15} className={data.length ? 'margin-top-0' : 'hidden'}>
        <GridColumn floated={'left'} width={5} verticalAlign={'middle'}>
          <label className="total-label">
            <h4>
              Showing {page * size + 1}-{page * size + data.length} of{' '}
              {totalNum} rows
            </h4>
          </label>
        </GridColumn>
        <GridColumn floated={'right'} width={10} verticalAlign={'middle'}>
          <Button
            content="Prev"
            icon="left arrow"
            labelPosition="left"
            disabled={page <= 0}
            onClick={() => prevPage()}
          />
          <Button
            content="Next"
            icon="right arrow"
            labelPosition="right"
            disabled={page >= totalPages - 1}
            onClick={() => nextPage()}
          />
        </GridColumn>
      </Grid>

      <Confirm
        header={'Delete Blacklist Entries'}
        content={
          'This will delete the selected blacklist entries. Are you sure you want to continue?'
        }
        onCancel={() => setDeleteModal(false)}
        onConfirm={deleteSelectedEntries}
        open={deleteModal}
      />
    </div>
  );
};

export default BlackListSearch;
