import React from 'react';
import Layout from '../../../components/Layout';
import Header from '../../../components/header/Header';
import { Tab } from 'semantic-ui-react';
import BlackListAddition from '../../../components/blacklist/BlackListAddition';

import style from './ListAddition.style';
import WhiteListAddition from './WhiteListAddition';

const ListAddition = () => {
  return (
    <Layout>
      <Header
        className="header-list-add"
        groupTitle="List Management"
        title="List Addition"
      />
      <Tab className="tab-list-add" panes={panes} />
    </Layout>
  );
};

const panes = [
  {
    menuItem: 'Blacklist',
    render: () => (
      <Tab.Pane className={style + ' tab-list-add-upload'}>
        <BlackListAddition />
      </Tab.Pane>
    )
  },
  {
    menuItem: 'Whitelist',
    render: () => (
      <Tab.Pane className={style + ' tab-list-add whitelist'}>
        <WhiteListAddition />
      </Tab.Pane>
    )
  }
];

export default ListAddition;
