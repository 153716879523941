import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Checkbox } from 'semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize';
import { toast } from 'react-toastify';
import {
  NOTES_MAX_LENGTH,
  NOTES_DISPLAY_LINE
} from '../../../constants/caseManagement';

type Props = {
  open: boolean;
  tags?: string[];
  onSubmit?: (selectedTags: string[], note: string) => void;
  onCancel?: () => void;
};

const MarkNeutralModal = ({ open, tags = [], onSubmit, onCancel }: Props) => {
  const [isTagSelected, setIsTagSelected] = useState<Record<string, boolean>>(
    {}
  );
  const [note, setNote] = useState('');

  const isAllTagsSelected = () => {
    for (let i = 0; i < tags.length; i++) {
      const tag = tags[i];

      if (!isTagSelected[tag]) return false;
    }

    return true;
  };

  const handleSelectAllTagsChange = () => {
    const selected = isAllTagsSelected();

    const newIsTagSelected: Record<string, boolean> = {};

    if (!selected) {
      tags.forEach(tag => {
        newIsTagSelected[tag] = true;
      });
    }

    setIsTagSelected(newIsTagSelected);
  };

  const handleTagSelectionChange = (tag: string) => {
    setIsTagSelected({
      ...isTagSelected,
      [tag]: !isTagSelected[tag]
    });
  };

  const handleSubmit = () => {
    if (!note.trim()) {
      toast.error('Remediation note is required.');

      return;
    }

    onSubmit?.(
      Object.keys(isTagSelected).filter(k => isTagSelected[k]),
      note
    );
  };

  useEffect(() => {
    if (open === true) {
      setIsTagSelected({});
      setNote('');
    }
  }, [open]);

  return (
    <Modal open={open} size="tiny">
      <Modal.Header>Confirmation</Modal.Header>
      <Modal.Content>
        <Form>
          <div className="title">
            <p>
              <b>Note!!</b>
            </p>
            <p>
              <b>
                All attributes from selected case(s) will be removed from
                Whitelist and Blacklist
              </b>
            </p>
          </div>

          <div className="tags">
            <div className="title">Blacklisting / Whitelisting Tags</div>

            <div className="tag-row">
              <Checkbox
                checked={isAllTagsSelected()}
                label={'Select All'}
                onChange={handleSelectAllTagsChange}
              />
            </div>

            {tags.map(tag => (
              <div key={tag} className="tag-row">
                <Checkbox
                  checked={isTagSelected[tag] || false}
                  label={tag}
                  onChange={() => handleTagSelectionChange(tag)}
                />
              </div>
            ))}
          </div>

          <div className="note">
            <label>
              Marking Notes*
              <TextareaAutosize
                className="no-resize"
                rows={1}
                value={note}
                maxLength={NOTES_MAX_LENGTH}
                maxRows={NOTES_DISPLAY_LINE}
                onChange={evt => setNote(evt.currentTarget.value)}
              />
            </label>
          </div>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Cancel" onClick={onCancel} />
        <Button primary content="Submit" onClick={handleSubmit} />
      </Modal.Actions>
    </Modal>
  );
};

export default MarkNeutralModal;
