import { css } from 'emotion';

export const containerStyle = css`
  & .actions {
    margin: 1em 0;
    display: flex;
    align-items: flex-end;
  }

  & .selected-count {
    flex: 0 0 8em;
    min-width: 8em;
    text-align: right;
    font-weight: bold;
  }

  & .actions .add-to-review {
    flex: 1 1 auto;
    margin: 0 1em;
    text-align: center;
  }
`;

export const tableStyle = css`
  & {
    width: 100%;
    table-layout: fixed;
  }

  & th {
    height: 4em;
  }

  & td.data-cell {
    height: 5em;
    min-width: 50px;
    word-wrap: break-word;
  }

  & td.search-cell {
    padding: 0 !important;
  }

  &.ui.table tr.active {
    background: #bfdbfe !important;
  }

  & tr:nth-child(even) {
    background: #f9fafb;
  }

  & .page-buttons {
    margin-top: 1em;
  }

  & tbody {
    display: block;
    width: 3799px; // Fixed the size of body with margin for vertical scroller
    max-height: 800px; // Set the max height for the scroll to be present
    overflow: auto;
  }

  & tbody {
    display: block;
    width: 3799px; // Fixed the size of body with margin for vertical scroller
    max-height: 650px; // Set the max height for the scroll to be present
    overflow: auto;
  }
`;

export const totalLabelStyle = css`
  & {
    color: grey;
  }
`;
