import React, { useState } from 'react';

import {
  Progress,
  Form,
  Grid,
  Button,
  Divider,
  Segment,
  Table
} from 'semantic-ui-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../../../configs';
import TruncatedCell from '../../../components/table/TruncatedCell';
import axios from 'axios';

const UploadFile = () => {
  // File Size limit in Kb
  const FILE_SIZE_LIMIT = 250;

  const [selectedFile, setSelectedFile] = useState(null);
  const [loaded, setLoaded] = useState(0);
  const [loading, setLoading] = useState(false);
  const [failedLines, setFailedLines] = useState(null);

  const onChangeHandler = event => {
    if (event && event.target && event.target.files) {
      // Can be empty file
      setSelectedFile(event.target.files[0]);
    }
  };

  const onClickHandler = () => {
    if (!selectedFile) {
      toast.error('Choose file to upload first!');
      return;
    }

    if ((selectedFile.size / 1024).toFixed(1) > FILE_SIZE_LIMIT) {
      toast.error(`File size limit is ${FILE_SIZE_LIMIT}Kb`);
      return;
    }
    setLoading(true);
    const data = new FormData();
    data.append('file', selectedFile);

    axios
      .post(config.paylaterTransactionUpload, data, {
        onUploadProgress: ProgressEvent => {
          setLoaded((ProgressEvent.loaded / ProgressEvent.total) * 100);
        }
      })
      .then(res => {
        setFailedLines(null);
        setLoading(false);

        if (
          res.data &&
          res.data.failedLines &&
          res.data.failedLines.length > 0
        ) {
          setFailedLines(res.data.failedLines);

          const errorMessage =
            res.data.totalLineCount === res.data.failedLines.length
              ? 'Uploaded file failed. Please fix the issues and reupload!'
              : 'Uploaded file partially successful. Please fix the remaining issues and reupload!';

          toast.info(errorMessage);
        } else {
          toast.success('File Uploaded Successfully!');
        }
      })
      .catch(err => {
        setLoading(false);

        if (err && err.response && err.response.data) {
          setFailedLines(JSON.stringify(err.response.data, null, 2));
        }

        toast.error(
          'Failed to: upload the csv file. error: ' + (err && err.message)
        );
      });
  };

  return (
    <div>
      <Grid>
        <Grid.Row centered>
          <label>
            <h4>List Type : Blacklist</h4>
          </label>
        </Grid.Row>
      </Grid>

      <Form>
        <Form.Field>
          <label> Choose your CSV file to upload </label>
        </Form.Field>
        <Form.Field>
          <input
            type="file"
            name="file"
            className="input-list-add-upload"
            accept=".csv"
            onChange={e => onChangeHandler(e)}
          />
        </Form.Field>
        <Divider />

        {loaded === 0 ? null : (
          <Form.Field>
            <label className="progress-list-add-upload">Upload Progress</label>
            <Progress percent={loaded} success progress />
          </Form.Field>
        )}

        <Form.Field>
          <div className="offset-md-3 col-md-6 upload-loading-spinner-container">
            {!loading ? (
              <Button
                primary
                icon="upload"
                content="Upload"
                onClick={() => onClickHandler()}
              />
            ) : (
              <Button loading primary content="Upload" disabled />
            )}
          </div>
        </Form.Field>
        <Form.Field>
          {failedLines && (
            <div>
              <label>
                <h4>Please check the errors, fix them and re-upload</h4>
              </label>

              {Array.isArray(failedLines) ? (
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={1}>Line Number</Table.HeaderCell>
                      <Table.HeaderCell width={7}>
                        Failed Attribute
                      </Table.HeaderCell>
                      <Table.HeaderCell width={8}>Reason</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {failedLines.map(line => (
                      <Table.Row
                        key={line.lineNumber}
                        style={{ height: '4em' }}
                      >
                        <TruncatedCell>{line.lineNumber}</TruncatedCell>
                        <TruncatedCell>{line.failedAttribute}</TruncatedCell>
                        <TruncatedCell>{line.reason}</TruncatedCell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              ) : (
                <Segment style={{ overflow: 'auto' }}>
                  <pre>{failedLines}</pre>
                </Segment>
              )}
            </div>
          )}
        </Form.Field>
      </Form>
    </div>
  );
};

export default UploadFile;
