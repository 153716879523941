import React from 'react';
import { GCNode } from '../../types/decisionTree';
import { dtTooltipContentStyle } from '../../views/decision-tree/DecisionTree.style';

type Props = {
  node: GCNode;
};

const NodeTooltipContent = ({ node }: Props) => {
  const lines = node.title.split('\n');

  return (
    <div>
      <div>
        <b>Node ID: {node.id}</b>
      </div>
      <div className={dtTooltipContentStyle}>
        {lines.map((line, i) => (
          <div key={`tooltip-content-info-${i}`}>{line}</div>
        ))}
      </div>
    </div>
  );
};

export default NodeTooltipContent;
