import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import './AllBookingDetailTableDownloadButton.style.css';

const AllBookingDetailTableDownloadButton = ({
  hasEntries,
  isDownloading = false,
  onClick = {}
}) => {
  if (!hasEntries) return null;

  return (
    <Button
      className="booking-detail-csv-download-button"
      onClick={onClick}
      disabled={isDownloading}
    >
      <Icon name="download"></Icon>
      Download All Page CSV
    </Button>
  );
};

export default AllBookingDetailTableDownloadButton;
