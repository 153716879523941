import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import isEmpty from 'lodash/isEmpty';
import snakeCase from 'lodash/snakeCase';
import { Table, Select, Loader } from 'semantic-ui-react';
import util from 'util';

import { setFilterAttribute } from '../../../actions/investigation-tools/bookingDetailAction';
import { printCurrencyValue } from '../../../utils/currencyView';
import {
  BookingDetailFilterAttributes,
  PaymentMethod,
  ProductType,
  PaymentAssignmentStatus,
  PaginationType
} from '../../../constants/investigationToolsConstant';
import {
  getBookingDetail,
  getPagination
} from '../../../reducers/investigation-tools/selectors';
import BookingDetailPaginationContainer from './BookingDetailPaginationContainer';
import BookingDetailTableDownloadButton from './BookingDetailTableDownloadButton';
import { useBookingDetailContext } from '../../../views/investigation-tools/booking-detail/BookingDetailContext';
import configs from '../../../configs';
import './BookingDetailTableContainer.style.css';
import AllBookingDetailTableDownloadButton from './AllBookingDetailTableDownloadButton';

const getLink = (key, id) => {
  const linkTemplate = get(configs, `bookingDetailLinks.${key}`, null);
  const link =
    typeof linkTemplate === 'string' && !isEmpty(linkTemplate.trim())
      ? util.format(linkTemplate.trim(), id)
      : '';

  const target = '_blank';
  const rel = 'noopener noreferrer';
  const enabledLinkClass = 'booking-detail-table-link';
  const disabledLinkClass = 'booking-detail-table-disabled-link';

  return (
    <a
      href={link}
      target={target}
      rel={rel}
      className={!isEmpty(link) ? enabledLinkClass : disabledLinkClass}
      onClick={e => isEmpty(link) && e.preventDefault()}
    >
      {id}
    </a>
  );
};

const BookingDetailTableContainer = () => {
  const { getState, dispatch, handleSearch, handleDownload } =
    useBookingDetailContext();
  const [filters, setFilters] = useState({});

  const state = getState();
  const { entries, filterAttributes, isDownloading } = getBookingDetail(state);
  const { totalNumberOfPages } = getPagination(
    state,
    PaginationType.BOOKING_DETAIL_TABLE
  );

  useEffect(() => {
    if (isEqual(filters, filterAttributes) && isFunction(handleSearch)) {
      handleSearch();
    }
  }, [filters, filterAttributes, handleSearch]);

  const handleFilterChange = attribute => (event, data) => {
    setFilters({ ...filterAttributes, [attribute]: data.value });
    dispatch(setFilterAttribute(attribute, data.value));
  };

  const defaultOption = {
    key: 'NULL',
    text: 'All',
    value: null
  };

  const bookingIdKey = 'bookingId';
  const profileIdKey = 'profileId';
  const customerIdKey = 'customerId';
  const defaultCellValue = '';

  return (
    <div style={{ marginBottom: '25px' }}>
      <div style={{ overflowX: 'auto' }}>
        <Table className="booking-detail-table">
          <Table.Header>
            <Table.Row className="booking-detail-table-column-name-row">
              <Table.HeaderCell>BID</Table.HeaderCell>
              <Table.HeaderCell>Loan ID</Table.HeaderCell>
              <Table.HeaderCell>Payment Method</Table.HeaderCell>
              <Table.HeaderCell>Product</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Device ID</Table.HeaderCell>
              <Table.HeaderCell>Cookie ID</Table.HeaderCell>
              <Table.HeaderCell>BC Phone</Table.HeaderCell>
              <Table.HeaderCell>BC Email</Table.HeaderCell>
              <Table.HeaderCell>Profile ID</Table.HeaderCell>
              <Table.HeaderCell>Customer ID</Table.HeaderCell>
              <Table.HeaderCell>Detail</Table.HeaderCell>
              <Table.HeaderCell>Amount</Table.HeaderCell>
              <Table.HeaderCell>
                <div className="booking-detail-table-coupon-amount">
                  Coupon Amount
                </div>
                <div>Consumption Date</div>
              </Table.HeaderCell>
              <Table.HeaderCell>Invoice Timestamp</Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell />
              <Table.HeaderCell>
                <Select
                  className="booking-detail-table-filter-attribute-select"
                  options={[
                    defaultOption,
                    ...Object.keys(PaymentMethod).map(key => ({
                      key: key,
                      text: PaymentMethod[key] || key,
                      value: key
                    }))
                  ]}
                  onChange={handleFilterChange(
                    BookingDetailFilterAttributes.PAYMENT_METHOD
                  )}
                  value={get(
                    filterAttributes,
                    BookingDetailFilterAttributes.PAYMENT_METHOD,
                    null
                  )}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Select
                  className="booking-detail-table-filter-attribute-select"
                  options={[
                    defaultOption,
                    ...Object.keys(ProductType).map(key => ({
                      key: key,
                      text: ProductType[key] || key,
                      value: key
                    }))
                  ]}
                  onChange={handleFilterChange(
                    BookingDetailFilterAttributes.PRODUCT_TYPE
                  )}
                  value={get(
                    filterAttributes,
                    BookingDetailFilterAttributes.PRODUCT_TYPE,
                    null
                  )}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Select
                  className="booking-detail-table-filter-attribute-select"
                  options={[
                    defaultOption,
                    ...PaymentAssignmentStatus.map(status => ({
                      key: status,
                      text: status,
                      value: status
                    }))
                  ]}
                  onChange={handleFilterChange(
                    BookingDetailFilterAttributes.PAYMENT_ASSIGNMENT_STATUS
                  )}
                  value={get(
                    filterAttributes,
                    BookingDetailFilterAttributes.PAYMENT_ASSIGNMENT_STATUS,
                    null
                  )}
                />
              </Table.HeaderCell>
              <Table.HeaderCell />
              <Table.HeaderCell />
              <Table.HeaderCell />
              <Table.HeaderCell />
              <Table.HeaderCell />
              <Table.HeaderCell />
              <Table.HeaderCell />
              <Table.HeaderCell />
              <Table.HeaderCell />
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {entries && entries.length > 0 ? (
              entries.map(entry => {
                const bookingId = get(entry, bookingIdKey, defaultCellValue);
                const product = get(entry, 'product', defaultCellValue);

                return (
                  <Table.Row
                    className="booking-detail-entry-table-row"
                    key={bookingId}
                  >
                    <Table.Cell>
                      {getLink(
                        `${bookingIdKey}.${snakeCase(product).toUpperCase()}`,
                        bookingId
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {get(entry, 'loanId', defaultCellValue)}
                    </Table.Cell>
                    <Table.Cell>
                      {get(entry, 'paymentMethod', defaultCellValue)}
                    </Table.Cell>
                    <Table.Cell>{product}</Table.Cell>
                    <Table.Cell>
                      {get(entry, 'status', defaultCellValue)}
                    </Table.Cell>
                    <Table.Cell>
                      {get(entry, 'deviceId', defaultCellValue)}
                    </Table.Cell>
                    <Table.Cell>
                      {get(entry, 'cookieId', defaultCellValue)}
                    </Table.Cell>
                    <Table.Cell>
                      {get(entry, 'contactPhone', defaultCellValue)}
                    </Table.Cell>
                    <Table.Cell>
                      {get(entry, 'contactEmail', defaultCellValue)}
                    </Table.Cell>
                    <Table.Cell>
                      {getLink(
                        profileIdKey,
                        get(entry, profileIdKey, defaultCellValue)
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {getLink(
                        customerIdKey,
                        get(entry, customerIdKey, defaultCellValue)
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {get(entry, 'productDetail', defaultCellValue)}
                    </Table.Cell>
                    <Table.Cell>
                      {printCurrencyValue(
                        get(entry, 'expectedAmount', defaultCellValue)
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <div className="booking-detail-table-coupon-amount">
                        {printCurrencyValue(
                          get(entry, 'totalCouponAmount', defaultCellValue)
                        )}
                      </div>
                      <div>
                        {get(entry, 'consumptionDate', defaultCellValue)}
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      {get(entry, 'invoiceTimestamp', defaultCellValue)}
                    </Table.Cell>
                  </Table.Row>
                );
              })
            ) : (
              <Table.Row className="booking-detail-no-data-table-row">
                <Table.Cell colSpan={'15'} style={{ textAlign: 'center' }}>
                  - No Data -
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
      <BookingDetailPaginationContainer />
      <BookingDetailTableDownloadButton entries={entries} />
      <Loader active={isDownloading} />
      {totalNumberOfPages > 1 && (
        <AllBookingDetailTableDownloadButton
          hasEntries={entries.length > 0}
          isDownloading={isDownloading}
          onClick={() => {
            handleDownload();
          }}
        />
      )}
    </div>
  );
};

export default BookingDetailTableContainer;
