import { css } from 'emotion';

export const detectorContentStyle = css`
  & {
    height: 360px;
    overflow: auto;
  }
`;

export const detectorResponseGroupStyle = css`
  & {
    margin-bottom: 1em;
  }
  & h3 {
    margin-bottom: 0.25em;
  }
`;
