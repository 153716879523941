import React, { useEffect, useState } from 'react';
import {
  Card,
  Grid,
  Image,
  TableBody,
  TableCell,
  TableRow
} from 'semantic-ui-react';
import { Link, useParams, BrowserRouter } from 'react-router-dom';

import InfoBox from '../../../components/investigation-tools/common/InfoBox';
import Layout from '../../../components/Layout';
import { showErrorToast } from '../../../utils/common';
import { css } from 'emotion';
import { FraudServiceUrl } from '../../../constants/serviceConstants';
import { kycDataLabels } from '../../../constants/investigationToolsLabels';
import Table from 'semantic-ui-react/dist/commonjs/collections/Table';
import axios from 'axios';
import omit from 'lodash/omit';

export const cardContentStyle = css`
  & {
    height: 300px;
    overflow: auto;
  }
`;

const activePropertiesStyle = css`
  &.table tbody tr td {
    border: none;
  }
`;

const KycData = () => {
  const { id } = useParams();
  const [kycData, setKycData] = useState({
    kycId: '',
    profileId: '',
    applicantId: ''
  });
  const [result, setResult] = useState({
    personalDetail: {},
    documentDetail: {
      idCardImageUri: '',
      selfPortraitImageUri: ''
    },
    emergencyContact: {},
    cardDeliveryAddress: {},
    employmentDetail: {},
    officeAddress: {},
    notes: []
  });

  const {
    personalDetail,
    documentDetail,
    emergencyContact,
    cardDeliveryAddress,
    employmentDetail,
    officeAddress,
    notes
  } = result;

  useEffect(() => {
    fetchKycData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchKycData = async () => {
    try {
      const response = await axios.get(FraudServiceUrl.KYC_DATA + `/${id}`);
      const { data } = response.data;
      setResult({
        ...data,
        personalDetail: omit(data.documentsAndPersonalDetail, [
          'idCardImageUri',
          'selfPortraitImageUri'
        ]),
        documentDetail: {
          idCardImageUri: data.documentsAndPersonalDetail.idCardImageUri,
          selfPortraitImageUri:
            data.documentsAndPersonalDetail.selfPortraitImageUri
        },
        employmentDetail: omit(data.employmentDetail, 'officeAddress'),
        officeAddress: data.employmentDetail.officeAddress
      });
      setKycData({
        kycId: data.kycId,
        profileId: data.profileId,
        applicantId: data.applicantId
      });
    } catch (err) {
      showErrorToast(err);
    }
  };

  const renderKycData = () => {
    const cxpToolLink =
      process.env.REACT_APP_ENV === 'production'
        ? 'https://tool.cxp.traveloka.com'
        : 'https://tool.cxp.staging-traveloka.com';

    return (
      <Table basic="very" columns={8} className={activePropertiesStyle}>
        <TableBody className={'kyc-data-card'}>
          <TableRow>
            <TableCell width={1}>
              <b>KYC ID</b>
            </TableCell>
            <TableCell width={7}>{kycData.kycId}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell width={1}>
              <b>Profile ID</b>
            </TableCell>
            <TableCell width={7}>
              <BrowserRouter>
                <Link
                  to={{
                    pathname: `${cxpToolLink}/customer/profile?id=${kycData.profileId}`
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {kycData.profileId}
                </Link>
              </BrowserRouter>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell width={3}>
              <b>Application ID</b>
            </TableCell>
            <TableCell width={7}>{kycData.applicantId}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  return (
    <Layout>
      <h1>Kyc Data</h1>
      {renderKycData()}
      <h3>Documents and Personal Details</h3>
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column width={8}>
            <InfoBox data={personalDetail} labels={kycDataLabels} />
          </Grid.Column>
          <Grid.Column width={8}>
            <Image
              src={documentDetail.idCardImageUri}
              style={{ marginBottom: '20px' }}
            />
            <Image src={documentDetail.selfPortraitImageUri} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <h3>Emergency Contact</h3>
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column width={8}>
            <InfoBox data={emergencyContact} labels={kycDataLabels} />
          </Grid.Column>
          <Grid.Column width={8}>
            <InfoBox
              title={'Card Delivery Address'}
              data={cardDeliveryAddress}
              labels={kycDataLabels}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <h3>Employment Detail</h3>
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column width={8}>
            <InfoBox data={employmentDetail} labels={kycDataLabels} />
          </Grid.Column>
          <Grid.Column width={8}>
            <InfoBox data={officeAddress} labels={kycDataLabels} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <h3>Note</h3>
      <Card fluid style={{ marginBottom: '20px' }}>
        <Card.Content className={cardContentStyle}>
          {notes.map(line => (
            // eslint-disable-next-line react/jsx-key
            <p>{line.value}</p>
          ))}
        </Card.Content>
      </Card>
    </Layout>
  );
};

export default KycData;
