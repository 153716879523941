import React, { useState } from 'react';
import { Card, Form, Button, Table } from 'semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize';
import {
  CaseNotesData,
  CaseNotesSubmitParams
} from '../../../types/caseManagement';
import { format, parseISO } from 'date-fns';
import {
  NOTES_DATE_FORMAT,
  NOTES_MAX_LENGTH,
  NOTES_DISPLAY_LINE
} from '../../../constants/caseManagement';
import {
  caseNotesFormStyle,
  caseNotesTextareaStyle,
  addNotesButtonStyle,
  caseNotesTableWrapperStyle,
  caseNotesTableStyle
} from './CaseNotes.style';

type Props = {
  data: CaseNotesData[];
  onSubmit?: (params: CaseNotesSubmitParams) => void | Promise<void>;
};

const CaseNotes = ({ data, onSubmit }: Props) => {
  const [note, setNote] = useState('');

  const handleSubmit = async () => {
    await onSubmit?.({
      notes: note
    });

    setNote('');
  };

  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>Case Notes</Card.Header>
      </Card.Content>
      <Card.Content>
        <Form className={caseNotesFormStyle}>
          <div className={caseNotesTextareaStyle}>
            <TextareaAutosize
              className="no-resize"
              rows={1}
              placeholder="Case Notes"
              value={note}
              maxLength={NOTES_MAX_LENGTH}
              maxRows={NOTES_DISPLAY_LINE}
              onChange={evt => setNote(evt.currentTarget.value)}
            />
          </div>
          <div className={addNotesButtonStyle}>
            <Button primary disabled={!note.trim()} onClick={handleSubmit}>
              Add Notes
            </Button>
          </div>
        </Form>

        <div className={caseNotesTableWrapperStyle}>
          <Table celled striped className={caseNotesTableStyle}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={4}>Date</Table.HeaderCell>
                <Table.HeaderCell width={3}>User</Table.HeaderCell>
                <Table.HeaderCell>Notes</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {data.length > 0 ? (
                data.map((detail, i) => (
                  <Table.Row key={`case-notes-${i}`}>
                    <Table.Cell>
                      {format(parseISO(detail.createdAt), NOTES_DATE_FORMAT)}
                    </Table.Cell>
                    <Table.Cell>{detail.createdBy}</Table.Cell>
                    <Table.Cell>
                      {detail.notes.split('\n').map((line, idx) => (
                        <span key={idx}>
                          {line}
                          <br />
                        </span>
                      ))}
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row textAlign="center">
                  <Table.Cell colSpan={3}>No Data</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </div>
      </Card.Content>
    </Card>
  );
};

export default CaseNotes;
