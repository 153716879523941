import React, { SyntheticEvent } from 'react';
import { Form, DropdownProps } from 'semantic-ui-react';
import {
  DATE_TYPE_FILTER_LABEL,
  START_DATE_FILTER_LABEL,
  DATEPICKER_DATE_FORMAT,
  END_DATE_FILTER_LABEL,
  DATEPICKER_TIME_FORMAT
} from '../../../constants/caseManagement';
import { DateFilterType } from '../../../types/caseManagement';
import ReactDatePicker from 'react-datepicker';
import { isAfter, setMilliseconds } from 'date-fns';
import { getStartAndEndDateFromType } from '../../../utils/case-management/dateFilter';

const dateTypeOptions = [
  {
    key: DateFilterType.LAST_HOUR,
    value: DateFilterType.LAST_HOUR,
    text: 'Last Hour'
  },
  {
    key: DateFilterType.TODAY,
    value: DateFilterType.TODAY,
    text: 'Today'
  },
  {
    key: DateFilterType.YESTERDAY,
    value: DateFilterType.YESTERDAY,
    text: 'Yesterday'
  },
  {
    key: DateFilterType.WEEK_TO_DATE,
    value: DateFilterType.WEEK_TO_DATE,
    text: 'Week to Date'
  },
  {
    key: DateFilterType.LAST_WEEK,
    value: DateFilterType.LAST_WEEK,
    text: 'Last Week'
  },
  {
    key: DateFilterType.MONTH_TO_DATE,
    value: DateFilterType.MONTH_TO_DATE,
    text: 'Month to Date'
  },
  {
    key: DateFilterType.LAST_MONTH,
    value: DateFilterType.LAST_MONTH,
    text: 'Last Month'
  },
  {
    key: DateFilterType.LAST_12_MONTHS,
    value: DateFilterType.LAST_12_MONTHS,
    text: 'Last 12 Months'
  },
  {
    key: DateFilterType.CUSTOM,
    value: DateFilterType.CUSTOM,
    text: 'Custom Range'
  }
];

type Props = {
  dateType: DateFilterType;
  startDate: Date;
  endDate: Date;
  onChange?: (dateType: DateFilterType, startDate: Date, endDate: Date) => void;
};

/**
 * This component is used to display 3 inputs, the date filter type, start date, and end date.
 * Start and end date input is a datepicker.
 */
const DateFilterInput = ({ dateType, startDate, endDate, onChange }: Props) => {
  const onDateTypeChange = (
    _evt: SyntheticEvent<HTMLElement, Event>,
    { value }: DropdownProps
  ) => {
    const type = value as DateFilterType;

    if (type === DateFilterType.CUSTOM) {
      onChange?.(type, startDate, endDate);
    } else {
      const date = getStartAndEndDateFromType(type);

      onChange?.(type, date.startDate, date.endDate);
    }
  };

  const onStartDateChange = (date: Date) => {
    onChange?.(dateType, date, isAfter(date, endDate) ? date : endDate);
  };

  const onEndDateChange = (date: Date) => {
    const endDateInclusive = setMilliseconds(date, 59999);

    onChange?.(
      dateType,
      startDate,
      isAfter(endDateInclusive, startDate) ? endDateInclusive : startDate
    );
  };

  return (
    <Form.Group>
      <Form.Select
        width={4}
        label={DATE_TYPE_FILTER_LABEL}
        options={dateTypeOptions}
        value={dateType}
        onChange={onDateTypeChange}
      />
      <Form.Field width={4}>
        <label>{START_DATE_FILTER_LABEL}</label>
        <ReactDatePicker
          placeholderText={START_DATE_FILTER_LABEL}
          disabled={dateType !== DateFilterType.CUSTOM}
          showTimeSelect
          timeFormat={DATEPICKER_TIME_FORMAT}
          timeIntervals={15}
          timeCaption="Time"
          dateFormat={DATEPICKER_DATE_FORMAT}
          selected={startDate}
          onChange={onStartDateChange}
        />
      </Form.Field>
      <Form.Field width={4}>
        <label>{END_DATE_FILTER_LABEL}</label>
        <ReactDatePicker
          placeholderText={END_DATE_FILTER_LABEL}
          disabled={dateType !== DateFilterType.CUSTOM}
          showTimeSelect
          timeFormat={DATEPICKER_TIME_FORMAT}
          timeIntervals={15}
          timeCaption="Time"
          dateFormat={DATEPICKER_DATE_FORMAT}
          selected={endDate}
          minDate={startDate}
          onChange={onEndDateChange}
        />
      </Form.Field>
    </Form.Group>
  );
};

export default DateFilterInput;
