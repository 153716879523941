import React, { useContext } from 'react';
import RestrictedRoute from './RestrictedRoute';
import { AppContext } from '../../AppContext';
import permissionChecker from '../../utils/permissionChecker';
import Default from '../Default';

type PermissionRequiredRouteProps = {
  component: () => JSX.Element;
  path: string;
  permissions: string[];
  exact?: boolean;
};

const PermissionRequiredRoute = ({
  component: Component,
  permissions,
  ...rest
}: PermissionRequiredRouteProps) => {
  const [{ userPermissions }] = useContext(AppContext);
  const routePermissionChecker = permissionChecker(userPermissions);

  return (
    <RestrictedRoute
      {...rest}
      component={routePermissionChecker(permissions) ? Component : Default}
    />
  );
};

export default PermissionRequiredRoute;
