import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import CaseBlacklist from '../case-details/CaseBlacklist';
import {
  CaseBlacklistState,
  CaseBlacklistSubmitParams
} from '../../../types/caseManagement';
import { toast } from 'react-toastify';

type Props = {
  open: boolean;
  blacklistTags?: string[];
  onSubmit?: (params: CaseBlacklistSubmitParams) => void;
  onCancel?: () => void;
};

const MarkSuspectModal = ({
  open,
  blacklistTags = [],
  onSubmit,
  onCancel
}: Props) => {
  const [blacklistState, setBlacklistState] = useState<CaseBlacklistState>({
    isActive: true,
    isFieldSelected: {},
    isTagSelected: {},
    note: ''
  });

  const handleChange = (value: CaseBlacklistState) => {
    setBlacklistState({
      ...value,
      isActive: true
    });
  };

  const validate = (params: CaseBlacklistSubmitParams) => {
    const messageList: string[] = [];

    if (params.attributeTypes.length <= 0 && params.tags.length > 0) {
      messageList.push(
        'Blacklist tags must be empty if no attribute is selected.'
      );
    }

    if (params.attributeTypes.length > 0 && params.tags.length <= 0) {
      messageList.push('Blacklist tags is required.');
    }

    if (params.notes.trim() === '') {
      messageList.push('Blacklist notes is required.');
    }

    return messageList;
  };

  const handleSubmit = () => {
    const caseDetailToBLAttributeMap: Record<string, string> = {
      profileId: 'PROFILE_ID',
      primaryEmail: 'PRIMARY_EMAIL',
      secondaryEmail: 'SECONDARY_EMAIL',
      primaryPhone: 'PRIMARY_PHONE',
      secondaryPhone: 'SECONDARY_PHONE',
      deviceId: 'DEVICE_ID',
      ipAddress: 'IP_ADDRESS',
      bankAccountDetails: 'BANK_ACCOUNT_DETAILS'
    };

    const blacklistParams: CaseBlacklistSubmitParams = {
      attributeTypes: Object.keys(blacklistState.isFieldSelected)
        .filter(k => blacklistState.isFieldSelected[k])
        .map(k => caseDetailToBLAttributeMap[k]),
      notes: blacklistState.note,
      tags: Object.keys(blacklistState.isTagSelected).filter(
        k => blacklistState.isTagSelected[k]
      )
    };

    const errMessages = validate(blacklistParams);

    if (errMessages.length > 0) {
      toast.error(
        <ul>
          {errMessages.map((message, i) => (
            <li key={`error-message-${i}`}>{message}</li>
          ))}
        </ul>
      );

      return;
    }

    onSubmit?.(blacklistParams);
  };

  useEffect(() => {
    if (open === true) {
      setBlacklistState({
        isActive: true,
        isFieldSelected: {},
        isTagSelected: {},
        note: ''
      });
    }
  }, [open]);

  return (
    <Modal open={open} size="tiny">
      <Modal.Header>Confirmation</Modal.Header>
      <Modal.Content>
        <CaseBlacklist
          hideFields
          showSelectAllTags
          isActive={blacklistState.isActive}
          blacklistTags={blacklistTags}
          isFieldSelected={blacklistState.isFieldSelected}
          isTagSelected={blacklistState.isTagSelected}
          noteLabel={'Mark Status / Blacklisting Notes*'}
          note={blacklistState.note}
          onChange={handleChange}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button content="Cancel" onClick={onCancel} />
        <Button primary content="Submit" onClick={handleSubmit} />
      </Modal.Actions>
    </Modal>
  );
};

export default MarkSuspectModal;
