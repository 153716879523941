import React from 'react';
import { Checkbox, Grid, Form, Select } from 'semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize';
import {
  SummaryAPIResponse,
  CaseWhitelistState,
  WhitelistAttributeCombination
} from '../../../types/caseManagement';
import {
  SUMMARY_PROFILE_ID_LABEL,
  WL_PAYMENT_METHOD_LABEL,
  WL_PAYMENT_DETAILS_LABEL,
  SUMMARY_EMAIL_ADDRESS_LABEL,
  SUMMARY_BOOKING_EMAIL_ADDRESS_LABEL,
  SUMMARY_PHONE_NUMBER_LABEL,
  SUMMARY_BOOKING_PHONE_NUMBER_LABEL,
  SUMMARY_DEVICE_ID_LABEL,
  SUMMARY_IP_ADDRESS_LABEL,
  SUMMARY_BANK_ACCOUNT_DETAILS_LABEL,
  NOTES_MAX_LENGTH,
  NOTES_DISPLAY_LINE
} from '../../../constants/caseManagement';
import { remediationFormStyle } from './CaseRemediation.style';

type Props = {
  hideFields?: boolean;
  showAllAttrCombinations?: boolean;
  data?: SummaryAPIResponse;
  whitelistTags: string[];
  attributeCombinations: WhitelistAttributeCombination[];
  isActive?: boolean;
  attributeCombinationId?: number;
  isTagSelected: Record<string, boolean>;
  note: string;
  disabled?: boolean;
  onChange?: (value: CaseWhitelistState) => void;
};

type WLAttributeDataMap = Record<
  string,
  { value?: string | null; label: string }[]
>;

const getAttributeCombinationOptions = (
  fullOptions: WhitelistAttributeCombination[],
  dataMap: WLAttributeDataMap,
  showAllAttrCombinations = false
) => {
  const res: { key: number; value: number; text: string }[] = [];

  if (showAllAttrCombinations) {
    return fullOptions.map(opt => {
      return {
        key: opt.id,
        value: opt.id,
        text: opt.displayName
      };
    });
  }

  fullOptions.forEach(opt => {
    const filtered = opt.list.filter(attr => {
      const v = dataMap[attr];

      if (!v) return false;

      return v.reduce(
        (prev, curr) => (prev || curr.value) as boolean,
        false as boolean
      );
    });

    if (filtered.length === opt.list.length) {
      res.push({
        key: opt.id,
        value: opt.id,
        text: opt.displayName
      });
    }
  });

  return res;
};

const CaseWhitelist = ({
  hideFields = false,
  showAllAttrCombinations,
  data,
  whitelistTags,
  attributeCombinations,
  isActive = false,
  attributeCombinationId,
  isTagSelected,
  note,
  disabled = false,
  onChange
}: Props) => {
  const wlAttributeDataMap: WLAttributeDataMap = {
    EMAIL_ADDRESS: [
      {
        value: data?.primaryEmail,
        label: SUMMARY_EMAIL_ADDRESS_LABEL
      },
      {
        value: data?.secondaryEmail,
        label: SUMMARY_BOOKING_EMAIL_ADDRESS_LABEL
      }
    ],
    PHONE_NUMBER: [
      {
        value: data?.primaryPhone,
        label: SUMMARY_PHONE_NUMBER_LABEL
      },
      {
        value: data?.secondaryPhone,
        label: SUMMARY_BOOKING_PHONE_NUMBER_LABEL
      }
    ],
    PROFILE_ID: [
      {
        value: data?.profileId,
        label: SUMMARY_PROFILE_ID_LABEL
      }
    ],
    DEVICE_ID: [
      {
        value: data?.deviceId,
        label: SUMMARY_DEVICE_ID_LABEL
      }
    ],
    IP_ADDRESS: [
      {
        value: data?.ipAddress,
        label: SUMMARY_IP_ADDRESS_LABEL
      }
    ],
    BANK_ACCOUNT_DETAILS: [
      {
        value: data?.bankAccountDetails,
        label: SUMMARY_BANK_ACCOUNT_DETAILS_LABEL
      }
    ],
    PAYMENT_METHOD: [
      {
        value: data?.maskedCardNumber ? 'Credit Card' : '',
        label: WL_PAYMENT_METHOD_LABEL
      },
      {
        value: data?.maskedCardNumber,
        label: WL_PAYMENT_DETAILS_LABEL
      }
    ]
  };

  const attributeCombinationOptions = getAttributeCombinationOptions(
    attributeCombinations,
    wlAttributeDataMap,
    showAllAttrCombinations
  );

  const selectedAttributeCombination = attributeCombinations.find(
    attr => attr.id === attributeCombinationId
  );

  const handleAttributeCombinationChange = (attributeCombinationId: number) => {
    onChange?.({
      isActive,
      attributeCombinationId,
      isTagSelected,
      note
    });
  };

  const handleTagSelectionChange = (tag: string) => {
    onChange?.({
      isActive,
      attributeCombinationId,
      isTagSelected: { ...isTagSelected, [tag]: !isTagSelected[tag] },
      note
    });
  };

  const handleNoteChange = (note: string) => {
    onChange?.({
      isActive,
      attributeCombinationId,
      isTagSelected,
      note
    });
  };

  const handleWhitelistSelectionChange = () => {
    onChange?.({
      isActive: !isActive,
      attributeCombinationId: 0,
      isTagSelected: {},
      note
    });
  };

  const renderField = (
    label: string,
    fieldData?: null | string | number,
    key?: string
  ) => (
    <Grid.Row key={key} className="wl-field-row">
      <Grid.Column className="field-row-label">{label}</Grid.Column>
      <Grid.Column className="field-row-data">{fieldData || '-'}</Grid.Column>
    </Grid.Row>
  );

  const renderAttributeCombinationFields = () => {
    if (!selectedAttributeCombination || !data) return null;

    const fieldRows: React.ReactNode[] = [];

    selectedAttributeCombination.list.forEach(attr => {
      const fields = wlAttributeDataMap[attr];

      fields.forEach((field, i) => {
        fieldRows.push(renderField(field.label, field.value, `${attr}-${i}`));
      });
    });

    return <Grid columns={2}>{fieldRows}</Grid>;
  };

  return (
    <Form className={remediationFormStyle}>
      <div className="title">
        <span className="title">Whitelisting</span>
        <Checkbox
          className="title-checkbox"
          disabled={disabled}
          checked={!disabled && isActive}
          onChange={handleWhitelistSelectionChange}
        />
      </div>

      <div className="attribute-combinations">
        <Select
          fluid
          placeholder="Select attribute"
          options={attributeCombinationOptions}
          value={attributeCombinationId}
          disabled={disabled || !isActive}
          onChange={(evt, data) =>
            handleAttributeCombinationChange(data.value as number)
          }
        />
      </div>

      <div className="wl-fields">{renderAttributeCombinationFields()}</div>

      <div className="tags">
        <div className="title">Whitelisting Tags</div>

        {whitelistTags.map(tag => (
          <div key={tag} className="tag-row">
            <Checkbox
              checked={isTagSelected[tag] || false}
              label={tag}
              disabled={disabled || !isActive}
              onChange={() => handleTagSelectionChange(tag)}
            />
          </div>
        ))}
      </div>

      <div className="note">
        <label>
          {isActive
            ? '*Mark as Trusted & whitelisting notes'
            : '*Mark as Trusted notes'}
          <TextareaAutosize
            className="no-resize"
            rows={1}
            value={note}
            maxLength={NOTES_MAX_LENGTH}
            maxRows={NOTES_DISPLAY_LINE}
            onChange={evt => handleNoteChange(evt.currentTarget.value)}
          />
        </label>
      </div>
    </Form>
  );
};

export default CaseWhitelist;
