import React from 'react';
import { Card, Table } from 'semantic-ui-react';
import { css } from 'emotion';

export const cardContentStyle = css`
  & {
    height: 200px;
    overflow: auto;
  }
`;

const ScrollableCard = ({
  title = '',
  data,
  headers,
  className = '',
  labels = {}
}) => {
  if (!data) return null;

  return (
    <Card fluid className={className}>
      <Card.Content>
        <Card.Header>{title}</Card.Header>
      </Card.Content>
      <Card.Content className={cardContentStyle}>
        <Table celled>
          <Table.Header>
            <Table.Row>
              {headers.map(head => (
                <Table.HeaderCell key={head}>
                  {labels[head] || head}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map((item, index) => {
              return (
                <Table.Row key={index}>
                  {headers.map(key => (
                    <Table.Cell key={key + index}>
                      {item[key] || item.value}
                    </Table.Cell>
                  ))}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Card.Content>
    </Card>
  );
};

export default ScrollableCard;
