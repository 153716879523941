import React from 'react';
import { Modal, Button, Icon } from 'semantic-ui-react';
import { css } from 'emotion';

type Props = {
  open: boolean;
  label?: string;
  current: number;
  target: number;
  onLinkClick?: () => void;
  onClose?: () => void;
};

const progressModalStyle = css`
  & .loading-icon {
    position: relative;
    margin-left: 1em;
  }

  & .result-link {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
  }
`;

const ProgressModal = ({
  open,
  label = '',
  current,
  target,
  onClose,
  onLinkClick
}: Props) => {
  const isFinished = current >= target;

  const renderFinishedContent = () => {
    return (
      <div>
        <p>
          {label} Finished {current}/{target}
        </p>
        <p>
          Please see the details on bottom of this page or click this{' '}
          <span onClick={onLinkClick} className="result-link">
            link
          </span>
          .
        </p>
      </div>
    );
  };

  const renderProgressContent = () => {
    return (
      <div>
        <p>
          {label} in progress {current}/{target}
        </p>
        <p>
          <span>Please wait...</span>
          <Icon loading name="spinner" className="loading-icon" />
        </p>
      </div>
    );
  };

  return (
    <Modal open={open} size="tiny" className={progressModalStyle}>
      <Modal.Header>
        {label} {isFinished ? 'Finished' : 'In Progress'}
      </Modal.Header>
      <Modal.Content>
        {isFinished ? renderFinishedContent() : renderProgressContent()}
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          content="Close"
          disabled={!isFinished}
          onClick={onClose}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ProgressModal;
