import jwt_decode from 'jwt-decode';
import { ProductAPI } from '../types/product';

export type AppState = {
  isLoading: number;
  isSideNavOpen: boolean;
  isDTMaximized: boolean;
  userPermissions: Record<string, boolean>;
  productApis: ProductAPI[];
};

export type AppAction =
  | { type: 'SHOW_LOADING' }
  | { type: 'HIDE_LOADING' }
  | { type: 'OPEN_SIDENAV' }
  | { type: 'CLOSE_SIDENAV' }
  | { type: 'MAXIMIZE_DT' }
  | { type: 'MINIMIZE_DT' }
  | { type: 'INIT_USER_PERMISSION'; payload: { token: string } }
  | { type: 'INIT_PRODUCT_APIS'; payload: { productApis: ProductAPI[] } };

export const initialAppState: AppState = {
  isLoading: 0,
  isSideNavOpen: true,
  isDTMaximized: false,
  userPermissions: {},
  productApis: []
};

export function appReducer(state: AppState, action: AppAction): AppState {
  switch (action.type) {
    case 'SHOW_LOADING':
      return { ...state, isLoading: state.isLoading + 1 };
    case 'HIDE_LOADING':
      return { ...state, isLoading: state.isLoading - 1 };
    case 'OPEN_SIDENAV':
      return { ...state, isSideNavOpen: true };
    case 'CLOSE_SIDENAV':
      return { ...state, isSideNavOpen: false };
    case 'MAXIMIZE_DT':
      return { ...state, isDTMaximized: true };
    case 'MINIMIZE_DT':
      return { ...state, isDTMaximized: false };
    case 'INIT_USER_PERMISSION':
      const decoded = jwt_decode(action.payload.token) as {
        permissions: string[];
      };

      const resPermission: Record<string, boolean> = {};

      decoded.permissions.forEach(permission => {
        resPermission[permission] = true;
      });

      return { ...state, userPermissions: resPermission };
    case 'INIT_PRODUCT_APIS':
      return { ...state, productApis: action.payload.productApis };
    default:
      return state;
  }
}
