import React from 'react';
import { Card, Dropdown, Button } from 'semantic-ui-react';
import LabelAndValue from './LabelAndValue';
import {
  SUMMARY_CASE_ID_LABEL,
  SUMMARY_REQUEST_DATE_LABEL,
  SUMMARY_REFERENCE_TYPE_LABEL,
  SUMMARY_REFERENCE_ID_LABEL,
  SUMMARY_PROFILE_ID_LABEL,
  SUMMARY_EMAIL_ADDRESS_LABEL,
  SUMMARY_BOOKING_EMAIL_ADDRESS_LABEL,
  SUMMARY_PHONE_NUMBER_LABEL,
  SUMMARY_BOOKING_PHONE_NUMBER_LABEL,
  SUMMARY_DEVICE_ID_LABEL,
  SUMMARY_IP_ADDRESS_LABEL,
  SUMMARY_IP_COUNTRY_LABEL,
  SUMMARY_BANK_ACCOUNT_DETAILS_LABEL,
  SUMMARY_RECOMMENDATION_LABEL,
  SUMMARY_MARKING_STATUS_LABEL,
  SUMMARY_REQUEST_DATE_FORMAT,
  SUMMARY_API_NAME_LABEL,
  SUMMARY_CURRENCY_LABEL,
  SUMMARY_TRANSACTION_AMOUNT_LABEL,
  SUMMARY_MASKED_CARD_NUMBER_LABEL,
  ADD_TO_REVIEW_BTN,
  SUMMARY_CARD_COUNTRY_ID_LABEL
} from '../../../constants/caseManagement';
import { SummaryAPIResponse } from '../../../types/caseManagement';
import { format, parseISO } from 'date-fns';
import { formatNumber } from '../../../utils/numberFormatter';
import { spaceTopStyle, containerStyle, cardStyle } from './CaseSummary.style';

type SimilarSearchOption = {
  key: string;
  name: string;
  text: string;
};

const similarSearchOptions: SimilarSearchOption[] = [
  {
    key: 'all',
    name: 'ALL',
    text: 'By All'
  },
  {
    key: 'email',
    name: 'EMAIL_ADDRESS',
    text: 'By Email Address'
  },
  {
    key: 'phone',
    name: 'PHONE_NUMBER',
    text: 'By Phone Number'
  },
  {
    key: 'deviceId',
    name: 'DEVICE_ID',
    text: 'By Device ID'
  },
  {
    key: 'ipAddress',
    name: 'IP_ADDRESS',
    text: 'By IP Address'
  },
  {
    key: 'profileId',
    name: 'PROFILE_ID',
    text: 'By Profile ID'
  },
  {
    key: 'bankAccountDetails',
    name: 'BANK_ACC_DETAILS',
    text: 'By Bank Account Details'
  }
];

type Props = {
  data?: SummaryAPIResponse;
  pushHistory?: (path: string) => void;
  hideAddToReview?: boolean;
  onAddToReview?: () => void;
};

const CaseSummary = ({
  data,
  pushHistory,
  onAddToReview,
  hideAddToReview = false
}: Props) => {
  const formatRequestDate = (isoStr: string) => {
    return format(parseISO(isoStr), SUMMARY_REQUEST_DATE_FORMAT);
  };

  const handleSimilarSearch = (option: SimilarSearchOption) => {
    if (!data) return;

    pushHistory?.(`/case-similar-search/${data?.caseId}/${option.name}`);
  };

  const isSimilarSearchOptionDisabled = (option: SimilarSearchOption) => {
    if (!data) return true;

    if (option.key === 'all') return false;

    if (option.key === 'email') {
      return !data.primaryEmail && !data.secondaryPhone;
    }

    if (option.key === 'phone') {
      return !data.primaryPhone && !data.secondaryPhone;
    }

    return !data[option.key as keyof SummaryAPIResponse];
  };

  const isAddToReviewButtonHidden =
    hideAddToReview ||
    !data ||
    (data.reviewStatus && data.reviewStatus === 'IN_REVIEW');

  return (
    <Card fluid className={cardStyle}>
      <Card.Content
        className={isAddToReviewButtonHidden ? '' : 'header-with-button'}
      >
        <Card.Header>
          {isAddToReviewButtonHidden ? (
            'Case Summary'
          ) : (
            <div className="header-flex">
              <div className="header-title">Case Summary</div>
              <div>
                <Button className="header-button" onClick={onAddToReview}>
                  {ADD_TO_REVIEW_BTN}
                </Button>
              </div>
            </div>
          )}
        </Card.Header>
      </Card.Content>
      <Card.Content>
        <div className={containerStyle} style={{ display: 'flex' }}>
          <div className="details">
            <div>
              <LabelAndValue
                label={SUMMARY_CASE_ID_LABEL}
                value={data?.caseId}
              />
              <LabelAndValue label={SUMMARY_API_NAME_LABEL} value={data?.api} />
              <LabelAndValue
                label={SUMMARY_REQUEST_DATE_LABEL}
                value={data ? formatRequestDate(data.requestDate) : ''}
              />
            </div>

            <div className={spaceTopStyle}>
              <LabelAndValue
                label={SUMMARY_REFERENCE_TYPE_LABEL}
                value={data?.referenceType}
              />
              <LabelAndValue
                label={SUMMARY_REFERENCE_ID_LABEL}
                value={data?.referenceId}
              />
            </div>

            <div className={spaceTopStyle}>
              <LabelAndValue
                label={SUMMARY_PROFILE_ID_LABEL}
                value={data?.profileId}
              />
              <LabelAndValue
                label={SUMMARY_EMAIL_ADDRESS_LABEL}
                value={data?.primaryEmail}
              />
              <LabelAndValue
                label={SUMMARY_BOOKING_EMAIL_ADDRESS_LABEL}
                value={data?.secondaryEmail}
              />
              <LabelAndValue
                label={SUMMARY_PHONE_NUMBER_LABEL}
                value={data?.primaryPhone}
              />
              <LabelAndValue
                label={SUMMARY_BOOKING_PHONE_NUMBER_LABEL}
                value={data?.secondaryPhone}
              />
              <LabelAndValue
                label={SUMMARY_DEVICE_ID_LABEL}
                value={data?.deviceId}
              />
              <LabelAndValue
                label={SUMMARY_IP_ADDRESS_LABEL}
                value={data?.ipAddress}
              />
              <LabelAndValue
                label={SUMMARY_IP_COUNTRY_LABEL}
                value={data?.ipCountryId}
              />
              <LabelAndValue
                label={SUMMARY_BANK_ACCOUNT_DETAILS_LABEL}
                value={data?.bankAccountDetails}
              />
              <LabelAndValue
                label={SUMMARY_CURRENCY_LABEL}
                value={data?.currency}
              />
              <LabelAndValue
                label={SUMMARY_TRANSACTION_AMOUNT_LABEL}
                value={data?.amount ? formatNumber(Number(data.amount)) : ''}
              />
              <LabelAndValue
                label={SUMMARY_MASKED_CARD_NUMBER_LABEL}
                value={data?.maskedCardNumber}
              />
              <LabelAndValue
                label={SUMMARY_CARD_COUNTRY_ID_LABEL}
                value={data?.cardCountryId}
              />
            </div>

            <div className={spaceTopStyle}>
              <LabelAndValue
                label={SUMMARY_RECOMMENDATION_LABEL}
                value={data?.finalRecommendation}
              />
            </div>

            <div className={spaceTopStyle}>
              <LabelAndValue
                label={SUMMARY_MARKING_STATUS_LABEL}
                value={data?.markingStatus}
              />
            </div>
          </div>

          <div className="similar-search">
            <Dropdown button fluid text="Similar Searches">
              <Dropdown.Menu>
                {similarSearchOptions.map(opt => (
                  <Dropdown.Item
                    key={opt.key}
                    text={opt.text}
                    disabled={isSimilarSearchOptionDisabled(opt)}
                    onClick={() => handleSimilarSearch(opt)}
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </Card.Content>
    </Card>
  );
};

export default CaseSummary;
