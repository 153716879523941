import React from 'react';
import { DetectorAPIResponse } from '../../../types/caseManagement';
import { Card } from 'semantic-ui-react';
import CaseAPIResponseFormatter from './CaseAPIResponseFormatter';
import {
  detectorContentStyle,
  detectorResponseGroupStyle
} from './CaseDetectorResponse.style';

type Props = {
  data?: DetectorAPIResponse;
};

const CaseDetectorResponse = ({ data }: Props) => {
  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>Detector Response Details</Card.Header>
      </Card.Content>
      <Card.Content className={detectorContentStyle}>
        {data && data.length > 0
          ? data.map((detectorResponse, i) => (
              <div
                key={`detector-response-${i}`}
                className={detectorResponseGroupStyle}
              >
                <h3>{detectorResponse.name}</h3>
                <CaseAPIResponseFormatter data={detectorResponse.res} />
              </div>
            ))
          : 'No Data'}
      </Card.Content>
    </Card>
  );
};

export default CaseDetectorResponse;
