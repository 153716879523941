import * as React from 'react';
import { Accordion, Transition } from 'semantic-ui-react';
import { isActiveLink, trimPath } from './SideNavUtils';

type MenuLink = {
  path: string;
  childPaths: string;
  component: React.ReactElement;
  href: string;
  title: string;
};

type SideNavAccordionProps = {
  menuData: {
    showAtBottom: boolean;
    title: string;
    links: MenuLink[];
  }[];
};

export default function SideNavAccordion({ menuData }: SideNavAccordionProps) {
  const [state, setState] = React.useState({
    activeIndex: 0
  });

  const trimmedPath = trimPath(window.location.href);

  const { activeIndex } = state;
  const handleClick = index => () => {
    setState({
      activeIndex: index
    });
  };

  return (
    <Accordion as={'div'} className={'menuGroupContainer'}>
      {menuData.map((mn, id) => {
        const position = mn.showAtBottom ? 'bottom' : '';
        const showComponent = activeIndex === id || mn.showAtBottom;
        return (
          <div className={'menuGroup ' + position} key={id}>
            <Accordion.Title
              active={showComponent}
              index={id}
              onClick={mn.showAtBottom ? undefined : handleClick(id)}
            >
              <span className={'listTitle'}>{mn.title}</span>
            </Accordion.Title>
            <Accordion.Content active={showComponent}>
              <Transition.Group animation={'slide down'} duration={250}>
                {showComponent && (
                  <ul className={'sideNavList ' + position}>
                    {mn.links.map((ml, idx) => {
                      if (ml.component && React.isValidElement(ml.component)) {
                        const isCurrent = isActiveLink(ml, trimmedPath);
                        return (
                          <li
                            key={idx}
                            className={isCurrent ? 'activeSelected' : ''}
                          >
                            {ml.component}
                          </li>
                        );
                      }
                      return (
                        <li key={idx}>
                          <a href={ml.href} title={ml.title}>
                            {ml.title}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </Transition.Group>
            </Accordion.Content>
          </div>
        );
      })}
    </Accordion>
  );
}
