import get from 'lodash/get';
import * as CommonActionType from '../../types/investigation-tools/commonActionType';
import { FraudCheckHistoryType } from '../../constants/investigationToolsConstant';
import { startOfDay, subDays } from 'date-fns';

type Entry = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

type FraudCheckHistoryState = {
  isLoading: boolean;
  keyword: string;
  startDate: Date;
  endDate: Date;
  searchType: string;
  entries: Entry[];
};

export const initialState: FraudCheckHistoryState = {
  isLoading: false,
  keyword: '',
  startDate: startOfDay(subDays(new Date(), 3)),
  endDate: null,
  searchType: get(FraudCheckHistoryType, 0, null),
  entries: []
};

export function FraudCheckHistoryReducer(
  state = initialState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any
) {
  switch (action.type) {
    case CommonActionType.SET_IS_LOADING_STATE:
      return {
        ...state,
        isLoading: action.value
      };
    case CommonActionType.SET_KEYWORD:
      return {
        ...state,
        keyword: action.value
      };
    case CommonActionType.SET_START_DATE:
      return {
        ...state,
        startDate: action.value
      };
    case CommonActionType.SET_END_DATE:
      return {
        ...state,
        endDate: action.value
      };
    case CommonActionType.SET_SEARCH_TYPE:
      return {
        ...state,
        searchType: action.value
      };
    case CommonActionType.SET_ENTRIES:
      return {
        ...state,
        entries: action.entries
      };
    default:
      return state;
  }
}
