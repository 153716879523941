import React from 'react';
import { css } from 'emotion';

type Props = {
  label: string;
  value?: string | null;
} & React.HTMLAttributes<HTMLDivElement>;

const labelStyle = css`
  & {
    font-weight: bold;
  }
`;

const valueStyle = css`
  & {
    color: #4a5568;
    word-wrap: break-word;
  }
`;

const LabelAndValue = ({ label, value, ...rest }: Props) => {
  return (
    <div {...rest}>
      <span className={labelStyle}>{label}: </span>
      <span className={valueStyle}>{value || '-'}</span>
    </div>
  );
};

export default LabelAndValue;
