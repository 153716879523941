import axios from 'axios';

export const axiosNoLoading = axios.create();

export const initAxiosInterceptor = (
  getTokenSilently: () => Promise<string>,
  loadingFn: (showLoading: boolean) => void
) => {
  axiosNoLoading.interceptors.request.use(
    async config => {
      const token = await getTokenSilently();

      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`
      };

      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  axiosNoLoading.interceptors.response.use(
    async response => {
      return response;
    },
    error => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.request.use(
    async config => {
      loadingFn(true);

      const token = await getTokenSilently();

      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`
      };

      return config;
    },
    error => {
      loadingFn(false);

      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    async response => {
      loadingFn(false);

      return response;
    },
    error => {
      loadingFn(false);

      return Promise.reject(error);
    }
  );
};
