import React from 'react';
import { Table } from 'semantic-ui-react';
import PaginationContainer from '../common/PaginationContainer';
import { getMerchantData } from '../../../reducers/investigation-tools/selectors';
import { useMerchantDataContext } from '../../../views/investigation-tools/merchant-data/MerchantDataContext';

type Entry = {
  merchantId: string;
  merchantName: string;
  phoneNo: string;
  address: string;
  city: string;
  ownerName: string;
  ownerIdCardNo: string;
  ownerPhoneNo: string;
  ownerEmail: string;
  product: string;
};

const MerchantDataTableContainer = () => {
  const { getState } = useMerchantDataContext();

  const state = getState();
  const { entries } = getMerchantData(state);

  return (
    <div>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Merchant ID</Table.HeaderCell>
            <Table.HeaderCell>Merchant Name</Table.HeaderCell>
            <Table.HeaderCell>Merchant Phone</Table.HeaderCell>
            <Table.HeaderCell>Merchant Address</Table.HeaderCell>
            <Table.HeaderCell>Merchant City</Table.HeaderCell>
            <Table.HeaderCell>Owner Name</Table.HeaderCell>
            <Table.HeaderCell>Owner ID Card</Table.HeaderCell>
            <Table.HeaderCell>Owner Phone</Table.HeaderCell>
            <Table.HeaderCell>Owner Email Address</Table.HeaderCell>
            <Table.HeaderCell>Product</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {entries && entries.length > 0 ? (
            entries.map((entry: Entry) => {
              return (
                <Table.Row key={entry.merchantId}>
                  <Table.Cell>{entry.merchantId}</Table.Cell>
                  <Table.Cell>{entry.merchantName}</Table.Cell>
                  <Table.Cell>{entry.phoneNo}</Table.Cell>
                  <Table.Cell>{entry.address}</Table.Cell>
                  <Table.Cell>{entry.city}</Table.Cell>
                  <Table.Cell>{entry.ownerName}</Table.Cell>
                  <Table.Cell>{entry.ownerIdCardNo}</Table.Cell>
                  <Table.Cell>{entry.ownerPhoneNo}</Table.Cell>
                  <Table.Cell>{entry.ownerEmail}</Table.Cell>
                  <Table.Cell>{entry.product}</Table.Cell>
                </Table.Row>
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell colSpan={'10'}>
                <p style={{ textAlign: 'center' }}>- No Data -</p>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <PaginationContainer tableName={'MERCHANT_DATA_TABLE'} />
    </div>
  );
};

export default MerchantDataTableContainer;
