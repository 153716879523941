import React, { useContext } from 'react';
import { Popup, Button } from 'semantic-ui-react';
import { AppContext } from '../../AppContext';

type Props = {
  viewMode?: boolean;
  treeStatus?: 'ACTIVE' | 'INACTIVE';
  onBack?: () => void;
  onSave?: () => void;
  onActivate?: () => void;
  onValidate?: () => void;
  onExport?: () => void;
  onImport?: () => void;
  onClear?: () => void;
};

const ActionButtons = ({
  treeStatus,
  viewMode,
  onBack,
  onSave,
  onActivate,
  onValidate,
  onExport,
  onImport,
  onClear
}: Props) => {
  const [{ isDTMaximized }, dispatch] = useContext(AppContext);

  return (
    <React.Fragment>
      <Popup
        content="Back"
        position="right center"
        trigger={
          <Button size="big" circular icon="arrow left" onClick={onBack} />
        }
      />
      <Popup
        content={isDTMaximized ? 'Minimize' : 'Maximize'}
        position="right center"
        trigger={
          <Button
            circular
            size="big"
            icon={`window ${isDTMaximized ? 'minimize' : 'maximize'}`}
            onClick={() =>
              dispatch({ type: isDTMaximized ? 'MINIMIZE_DT' : 'MAXIMIZE_DT' })
            }
          />
        }
      />
      <Popup
        content="Export"
        position="right center"
        trigger={
          <Button
            primary
            size="big"
            circular
            icon="download"
            onClick={onExport}
          />
        }
      />

      {!viewMode && (
        <React.Fragment>
          <Popup
            content="Import"
            position="right center"
            trigger={
              <Button
                primary
                size="big"
                circular
                icon="upload"
                onClick={onImport}
              />
            }
          />
          <Popup
            content="Save"
            position="right center"
            trigger={
              <Button
                primary
                size="big"
                circular
                icon="save"
                onClick={onSave}
              />
            }
          />
          <Popup
            content="Validate"
            position="right center"
            trigger={
              <Button
                primary
                size="big"
                circular
                icon="check"
                onClick={onValidate}
              />
            }
          />
          <Popup
            content="Clear"
            position="right center"
            trigger={
              <Button size="big" circular icon="delete" onClick={onClear} />
            }
          />
        </React.Fragment>
      )}

      {viewMode && treeStatus === 'INACTIVE' && (
        <Popup
          content="Activate"
          position="right center"
          trigger={
            <Button
              primary
              size="big"
              circular
              icon="toggle on"
              onClick={onActivate}
            />
          }
        />
      )}
    </React.Fragment>
  );
};

export default ActionButtons;
