import get from 'lodash/get';
import * as CommonActivtiyActionType from '../../types/investigation-tools/commActivityActionType';
import { CommActivityMessageType } from '../../constants/investigationToolsConstant';
import { startOfDay, subDays } from 'date-fns';

type Entry = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

type CommActivityState = {
  isLoading: boolean;
  profileId: string;
  topic: string;
  messageType: string;
  searchLimit: string;
  startDate: Date;
  endDate: Date;
  entries: Entry[];
};

export const initialState: CommActivityState = {
  isLoading: false,
  profileId: '',
  topic: '',
  messageType: get(CommActivityMessageType, 0, null),
  searchLimit: '',
  startDate: startOfDay(subDays(new Date(), 30)),
  endDate: new Date(),
  entries: []
};

export function CommActivityReducer(
  state = initialState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any
) {
  switch (action.type) {
    case CommonActivtiyActionType.SET_IS_LOADING_STATE:
      return {
        ...state,
        isLoading: action.value
      };
    case CommonActivtiyActionType.SET_MESSAGE_TYPE:
      return {
        ...state,
        messageType: action.value
      };
    case CommonActivtiyActionType.SET_SEARCH_LIMIT:
      return {
        ...state,
        searchLimit: action.value
      };
    case CommonActivtiyActionType.SET_START_DATE:
      return {
        ...state,
        startDate: action.value
      };
    case CommonActivtiyActionType.SET_END_DATE:
      return {
        ...state,
        endDate: action.value
      };
    case CommonActivtiyActionType.SET_PROFILE_ID:
      return {
        ...state,
        profileId: action.value
      };
    case CommonActivtiyActionType.SET_TOPIC:
      return {
        ...state,
        topic: action.value
      };
    case CommonActivtiyActionType.SET_ENTRIES:
      return {
        ...state,
        entries: action.entries
      };
    default:
      return state;
  }
}
