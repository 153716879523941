import get from 'lodash/get';
import isArray from 'lodash/isArray';

export const defaultResult = true;

const permissionChecker =
  (userPermissions: Record<string, boolean>) =>
  (neededPermission: string[]): boolean => {
    if (!isArray(neededPermission)) return defaultResult;

    let permitted = true;

    for (const permission of neededPermission) {
      if (get(userPermissions, permission, false) !== true) {
        permitted = false;
        break;
      }
    }

    return permitted;
  };

export default permissionChecker;
