import { hierarchy, tree, HierarchyPointNode } from 'd3-hierarchy';
import {
  GCNode,
  GCEdge,
  NodeTypeKey,
  SaveTreeNode,
  SaveTreeEdge,
  SaveEditorData,
  GCData,
  NodeData,
  Operators,
  SaveEditorEdge,
  SaveEditorNode
} from '../../types/decisionTree';
import { isUnaryOperator, getOperator } from './operatorUtils';
import { addNode, addEdge } from './treeEditUtils';

export const generateTreeData = (
  nodes: GCNode[],
  edges: GCEdge[]
): SaveTreeNode => {
  const nodesIdsTraversed: number[] = [];

  const constructTreeNode = (node: GCNode): SaveTreeNode => {
    nodesIdsTraversed.push(node.id);

    const treeNode: SaveTreeNode = {
      id: node.id,
      nodeType: node.type
    };

    if (treeNode.nodeType === NodeTypeKey.DECISION_PACKAGE) {
      treeNode.data = { name: node.decisionPackage?.name };
    } else {
      treeNode.data = {
        deciderType: node.decider?.type,
        deciderName: node.decider?.name,
        decidingVariable: node.decidingVariable?.name,
        edges: edges
          .filter(edge => edge.source === treeNode.id)
          .map(constructTreeEdge)
      };
    }

    return treeNode;
  };

  const constructTreeEdge = (edge: GCEdge): SaveTreeEdge => {
    const treeEdge: SaveTreeEdge = {
      id: edge.id,
      priority: edge.priority,
      isDefaultBranch: edge.isDefaultBranch
    };

    if (edge.operator && !treeEdge.isDefaultBranch) {
      if (!isUnaryOperator(edge.operator.name)) {
        treeEdge.conditionValue = edge.conditionValue;
      }

      treeEdge.operator = edge.operator.name;
    }

    const targetNode = nodes.find(node => edge.target === node.id);

    if (targetNode) {
      treeEdge.targetNode = constructTreeNode(targetNode);
    }

    return treeEdge;
  };

  const rootNodes = nodes.filter(node => {
    return edges.findIndex(edge => edge.target === node.id) === -1;
  });

  return constructTreeNode(rootNodes[0]);
};

export const generateEditorData = (
  nodes: GCNode[],
  edges: GCEdge[]
): SaveEditorData => {
  const editorEdges = edges.map(edge => {
    return {
      id: edge.id,
      source: edge.source,
      target: edge.target
    };
  });

  const editorNodes = nodes.map(node => {
    return {
      id: node.id,
      x: node.x,
      y: node.y,
      content: node.title
    };
  });

  return {
    nodes: editorNodes,
    edges: editorEdges
  };
};

export const generateClientFormatFromTree = (
  treeData: SaveTreeNode,
  { nodes, edges }: SaveEditorData,
  operators: Operators
): GCData => {
  let res: GCData = {
    nodes: [],
    edges: [],
    lastId: 0
  };

  const parseNode = (node: SaveTreeNode) => {
    const id = node.id;
    const existingNode = nodes.find(it => it.id === id);

    const params: { [T in keyof NodeData]?: NodeData[T] } =
      node.nodeType === NodeTypeKey.DECISION_PACKAGE
        ? {
            decisionPackage: {
              id: 0,
              name: node.data?.name || ''
            }
          }
        : {
            decider: {
              id: 0,
              displayName: '',
              type: node.data?.deciderType || '',
              name: node.data?.deciderName || ''
            },
            decidingVariable: {
              id: 0,
              description: '',
              operatorType: '',
              name: node.data?.decidingVariable || ''
            }
          };

    res = addNode(
      res,
      {
        x: existingNode?.x,
        y: existingNode?.y,
        type: node.nodeType as NodeTypeKey,
        ...params
      },
      id
    );

    if (node.data?.edges && node.data.edges.length > 0) {
      node.data.edges.forEach(it => parseEdge(it));
    }
  };

  const parseEdge = (edge: SaveTreeEdge) => {
    const id = edge.id;
    const existingEdge = edges.find(it => it.id === id);
    const operator = getOperator(operators, edge.operator || '');

    res = addEdge(
      res,
      {
        source: existingEdge?.source || 0,
        target: existingEdge?.target || 0,
        priority: Number(edge.priority),
        isDefaultBranch: edge.isDefaultBranch,
        operator: operator,
        conditionValue: edge.conditionValue
      },
      id
    );

    if (edge.targetNode) {
      parseNode(edge.targetNode);
    }
  };

  parseNode(treeData);

  return res;
};

type D3NodeData = {
  id: number;
  edges: SaveEditorEdge[];
  children: D3NodeData[];
};

export const appendIdsToTree = (rootNode: SaveTreeNode): SaveTreeNode => {
  const r = { ...rootNode };

  let lastId = 0;

  const appendIdToNode = (node: SaveTreeNode) => {
    node.id = ++lastId;

    if (!node.data?.edges) return;

    node.data.edges.forEach(edge => {
      edge.id = ++lastId;

      if (edge.targetNode) appendIdToNode(edge.targetNode);
    });
  };

  appendIdToNode(r);

  return r;
};

const parseD3NodeData = (node: SaveTreeNode): D3NodeData => {
  const result: D3NodeData = {
    id: node.id,
    edges: [],
    children: []
  };

  if (!node.data?.edges) return result;

  node.data.edges.forEach(edge => {
    result.edges.push({
      id: edge.id,
      source: node.id,
      target: (edge.targetNode as SaveTreeNode).id
    });

    result.children.push(parseD3NodeData(edge.targetNode as SaveTreeNode));
  });

  return result;
};

export const generateEditorDataFromTree = (
  rootNode: SaveTreeNode,
  offsetX = 0,
  offsetY = 0
) => {
  const convertImportedToD3TreeFormat = (
    rootNode: SaveTreeNode
  ): HierarchyPointNode<D3NodeData> => {
    try {
      const result = parseD3NodeData(rootNode);
      const hierarchyRootNode = hierarchy(result);
      const d3Tree = tree<D3NodeData>().nodeSize([275, 400])(hierarchyRootNode);

      d3Tree.each((node: { x: number; y: number }) => {
        node.x += offsetX;
        node.y += offsetY;
      });

      return d3Tree;
    } catch (err) {
      throw new Error('Invalid JSON tree format');
    }
  };

  /**
   * Convert D3 Tree data to editor data format used for rendering
   */
  const convertD3TreeToEditorDataFormat = (
    d3Tree: HierarchyPointNode<D3NodeData>
  ): SaveEditorData => {
    try {
      const nodes: SaveEditorNode[] = [];
      const edges: SaveEditorEdge[] = [];

      const parseNode = (node: HierarchyPointNode<D3NodeData>) => {
        nodes.push({
          id: node.data.id,
          x: node.x,
          y: node.y,
          content: ''
        });

        if (!node.children) return;

        for (let i = 0; i < node.children.length; i++) {
          const edge = node.data.edges[i];
          const child = node.children[i];

          edges.push({
            id: edge.id,
            source: edge.source,
            target: edge.target
          });

          parseNode(child);
        }
      };

      parseNode(d3Tree);

      return {
        nodes,
        edges
      };
    } catch (err) {
      throw new Error('Error while converting to editor data format');
    }
  };

  const d3Tree = convertImportedToD3TreeFormat(rootNode);

  return convertD3TreeToEditorDataFormat(d3Tree);
};
