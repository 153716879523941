import React from 'react';
import { Table } from 'semantic-ui-react';
import TruncatedDiv from './TruncatedDiv';

const TruncatedCell = ({ children, ...rest }) => {
  return (
    <Table.Cell {...rest}>
      <TruncatedDiv>{children}</TruncatedDiv>
    </Table.Cell>
  );
};

export default TruncatedCell;
