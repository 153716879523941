import validator from 'validator';

export const isEmailValid = (value: string): boolean => {
  return validator.isEmail(value);
};

export const isPhoneNumberValid = (value: string): boolean => {
  //Cusom Regex used in the backend
  const re = /^\+\d+$/;
  return re.test(value);
};

export const isIpAddressValid = (value: string): boolean => {
  return validator.isIP(value);
};

export const isEmailDomainValid = (value: string): boolean => {
  return validator.isFQDN(value);
};

export const isEmpty = (value: string): boolean => {
  return value === '' || value === null || value === undefined;
};

export const isMaskedCreditCardValid = (value: string): boolean => {
  const re = /^[0-9]{6}X{2,9}[0-9]{3,4}$/;
  return re.test(value);
};

export const isValidWithoutSpace = (
  value: string,
  validatorFunc: (value: string) => boolean
): boolean => {
  return validatorFunc(value.split(' ').join(''));
};
