import React, { useEffect, useReducer } from 'react';
import { Loader } from 'semantic-ui-react';
import get from 'lodash/get';

import {
  initialState,
  CommActivityReducer
} from '../../../reducers/investigation-tools/commActivityReducer';
import {
  initPagination,
  goToAPage,
  setTotalNumberOfPages
} from '../../../actions/investigation-tools/paginationAction';
import paginationReducer from '../../../reducers/investigation-tools/paginationReducer';
import {
  fetchCommActivity,
  setIsLoading
} from '../../../actions/investigation-tools/commActivityAction';
import { PaginationType } from '../../../constants/investigationToolsConstant';

import CommActivityContext from './CommActivityContext';
import CommActivityTable from '../../../components/investigation-tools/comm-activity/CommActivityTable';
import Layout from '../../../components/Layout';
import CommActivitySearchCard from '../../../components/investigation-tools/comm-activity/CommActivitySearchCard';

const CommActivity = () => {
  const [state, dispatch] = useReducer(CommActivityReducer, initialState);
  const [paginationState, paginationDispatch] = useReducer(
    paginationReducer,
    {}
  );

  const { isLoading } = { ...state };

  useEffect(() => {
    paginationDispatch(
      // @ts-ignore
      initPagination([PaginationType.COMM_ACTIVITY_TABLE])
    );
  }, []);

  const getCombinedState = () => ({
    comm_activity: state,
    pagination: paginationState
  });

  const handleSearch = () => {
    dispatch(setIsLoading(true));
    fetchCommActivity(dispatch, getCombinedState).then(result => {
      const pageCount = get(result, 'data.pageCount', null);
      const currentPageNo = get(result, 'data.currentPageNo', null);

      paginationDispatch(
        // @ts-ignore
        setTotalNumberOfPages(
          PaginationType.COMM_ACTIVITY_TABLE,
          pageCount || 1
        )
      );

      paginationDispatch(
        // @ts-ignore
        goToAPage(PaginationType.COMM_ACTIVITY_TABLE, currentPageNo || 1)
      );
      dispatch(setIsLoading(false));
    });
  };

  return (
    <CommActivityContext.Provider
      value={{
        getState: getCombinedState,
        dispatch,
        paginationDispatch,
        handleSearch
      }}
    >
      <Layout>
        <h1>Comm Activity</h1>
        <CommActivitySearchCard />
        <Loader active={isLoading} />
        <div style={{ marginBottom: '20px' }} />
        {!isLoading && <CommActivityTable />}
      </Layout>
    </CommActivityContext.Provider>
  );
};

export default CommActivity;
