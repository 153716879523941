export const CONFIG_AUTO_BLACKLIST = 'R:ABL';
export const CASE_READ = 'R:CASE';
export const CASE_MARK_STATUS = 'W:CSMRKSTATUS';
export const CASE_BLACKLIST = 'W:CASEBLREM';
export const CASE_WHITELIST = 'W:CSWLREM';
export const CASE_USER_BLOCK = 'W:CSUSRBLK';
export const CASE_RESET_PASSWORD = 'W:CSUSRRSTPASS';
export const CASE_FORCE_LOGOUT = 'W:CSUSRLOGOUT';
export const CASE_QUEUE_READ = 'R:CSQUEUE';
export const CASE_QUEUE_WRITE = 'W:CSQUEUE';
export const INVESTIGATION_TOOLS_READ = {
  CONNECTION_SEARCH_APPLICATION: 'R:ITCSA',
  CONNECTION_SEARCH_BOOKING: 'R:ITCSB',
  BOOKING_DETAIL: 'R:ITBD',
  PROFILE_SUMMARY: 'R:ITPS',
  ACTIVITY: 'R:ITA',
  USER_PROFILE_CONTEXT_SEARCH: 'R:ITUPCS',
  USER_PROFILE_DATA: 'R:ITUPD',
  KYC_DATA: 'R:ITKYC',
  PAYMENT_DATA: 'R:ITPD',
  DEVICE_DATA: 'R:ITDD',
  COMM_ACTIVITY: 'R:ITCA',
  FRAUD_CHECK_HISTORY: 'R:ITFCH',
  DRIVER_PROFILE_CONTEXT_SEARCH: 'R:ITDPCS',
  DRIVER_DATA: 'R:ITDRV',
  MERCHANT_DATA: 'R:ITMD'
};
