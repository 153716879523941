import React from 'react';
import isFunction from 'lodash/isFunction';
import { Card, Form, Select, Button, Input } from 'semantic-ui-react';

import {
  setProfileId,
  setTopic,
  setMessageType,
  setSearchLimit,
  setStartDate,
  setEndDate
} from '../../../actions/investigation-tools/commActivityAction';
import { CommActivityMessageType } from '../../../constants/investigationToolsConstant';
import { getCommActivity } from '../../../reducers/investigation-tools/selectors';
import { useCommActivityContext } from '../../../views/investigation-tools/comm-activity/CommActivityContext';
import './CommActivitySearchCard.style.css';
import DateRangeFilter from '../common/DateRangeFilter';

const ConnectionSearchCard = () => {
  const { getState, dispatch, handleSearch } = useCommActivityContext();

  const state = getState();
  const { profileId, topic, messageType, startDate, endDate, searchLimit } =
    getCommActivity(state);

  const handleSelectChange = (event, data) => {
    dispatch(setMessageType(data.value));
  };

  const handleProfileIdChange = (event, data) =>
    dispatch(setProfileId(data.value));

  const handleSearchLimitChange = (event, data) =>
    dispatch(setSearchLimit(data.value));

  const handleTopicChange = (event, data) => dispatch(setTopic(data.value));

  return (
    <Card className="comm-search-card">
      <Card.Content className="comm-search-card-content">
        <Form>
          <Form.Field>
            <label>Profile Id</label>
            <Input
              onChange={handleProfileIdChange}
              value={profileId || ''}
              className="comm-search-card-input-profile-id"
            />
          </Form.Field>
          <Form.Field>
            <label>Topic</label>
            <Input onChange={handleTopicChange} value={topic || ''} />
          </Form.Field>
          <Form.Field>
            <label>Message Type</label>
            <Select
              options={CommActivityMessageType.map(type => ({
                key: type,
                value: type,
                text: type
              }))}
              onChange={handleSelectChange}
              value={messageType}
            />
          </Form.Field>
          <DateRangeFilter
            startDate={startDate}
            endDate={endDate}
            setStartDate={(startDate: Date) => {
              dispatch(setStartDate(startDate));
            }}
            setEndDate={(endDate: Date) => {
              dispatch(setEndDate(endDate));
            }}
          />
          <Form.Field>
            <label>Search Limit</label>
            <Input
              onChange={handleSearchLimitChange}
              value={searchLimit || ''}
            />
          </Form.Field>
          <Form.Field>
            <Button
              className="comm-search-card-button-search"
              onClick={() => isFunction(handleSearch) && handleSearch()}
            >
              Search
            </Button>
          </Form.Field>
        </Form>
      </Card.Content>
    </Card>
  );
};

export default ConnectionSearchCard;
