import React, { useState, useEffect } from 'react';
import {
  Accordion,
  Icon,
  Form,
  Table,
  Grid,
  Confirm,
  GridColumn
} from 'semantic-ui-react';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import _ from 'lodash';
import config from '../../../configs';
import { toast } from 'react-toastify';
import { convertToLocalTime } from '../../../utils/dateFormatter';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox';
import TruncatedCell from '../../../components/table/TruncatedCell';
import axios from 'axios';
import { listSearchStyle } from './ListSearch.style';

const WhiteListSearch = () => {
  const headers = [
    'Last Modified Time',
    'Last Modified By',
    'Entry Type',
    'Profile ID',
    'Payment Method',
    'Payment Details',
    'Email Address',
    'Email Domain',
    'Phone Number',
    'Device ID',
    'IP Address',
    'Bank Account Details',
    'Tag',
    'Notes',
    'Case ID',
    'Reference Type',
    'Reference ID'
  ];

  const [data, setData] = useState([]);
  const [active, setActive] = useState(true);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [referenceTypes, setReferenceTypes] = useState([]);
  const [tags, setTags] = useState([]);
  const [attributeCombinations, setAttributeCombinations] = useState([]);

  const [paymentMethodId, setPaymentMethodId] = useState(0);
  const [fieldDetail, setFieldDetail] = useState('');
  const [selectedField, setSelectedField] = useState('');
  const [paymentMethodSelected, setPaymentMethodSelected] = useState(false);

  const [page, setPage] = useState(0);
  const [size] = useState(20);
  const [totalNum, setTotalNum] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [selections, setSelections] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);

  const WHITELIST_SEARCH_FIELDS = [
    {
      key: 'emailAddress',
      text: 'Email Address',
      value: 'emailAddress'
    },
    {
      key: 'emailDomain',
      text: 'Email Domain',
      value: 'emailDomain'
    },
    {
      key: 'phoneNumber',
      text: 'Phone Number',
      value: 'phoneNumber'
    },
    {
      key: 'deviceId',
      text: 'Device ID',
      value: 'deviceId'
    },
    {
      key: 'ipAddress',
      text: 'IP Address',
      value: 'ipAddress'
    },
    {
      key: 'bankAccountDetails',
      text: 'Bank Account Details',
      value: 'bankAccountDetails'
    },
    {
      key: 'profileId',
      text: 'Profile ID',
      value: 'profileId'
    },
    {
      key: 'paymentMethodId',
      text: 'Payment Method',
      value: 'paymentMethodId'
    },
    {
      key: 'referenceId',
      text: 'Reference ID',
      value: 'referenceId'
    }
  ];

  const handleSelect = rowIdx => {
    if (_.isEmpty(selections) || rowIdx >= selections.length) {
      return;
    }
    const newSelections = selections.slice();
    newSelections[rowIdx] = !newSelections[rowIdx];
    setSelections(newSelections);
  };

  const handleAllSelected = () => {
    let newSelections;
    if (checkAllSelected()) {
      newSelections = Array(data.length).fill(false);
    } else {
      newSelections = Array(data.length).fill(true);
    }
    setSelections(newSelections);
  };

  const checkAllSelected = () => {
    return (
      selections.length > 0 &&
      selections.filter(selection => selection === true).length ===
        selections.length
    );
  };

  const onClickDelete = () => {
    if (_.isEmpty(selections.filter(selection => selection === true))) {
      toast.error('No whitelist entry has been selected');
    } else {
      setDeleteModal(true);
    }
  };

  const deleteSelectedEntries = () => {
    const selectedIdxes = [];
    selections.forEach((value, index) => {
      if (value) {
        selectedIdxes.push(index);
      }
    });
    const fraudIds = selectedIdxes.map(idx => data[idx].fraudId);
    axios
      .delete(config.whitelistEntries, {
        data: {
          fraudIds
        }
      })
      .then(() => {
        toast.success('Whitelist entries have been deleted successfully.');
        setDeleteModal(false);
        getWhitelistEntries(page);
      })
      .catch(err => {
        toast.error(
          'Error deleting whitelist entries. Error: ' + (err && err.message)
        );
      });
  };

  const toDropDownList = responseList => {
    return responseList.map(item => {
      return {
        key: item.id,
        text: item.displayName,
        value: item.id
      };
    });
  };

  const toggleSearch = () => {
    setActive(!active);
  };

  const onFieldChange = field => {
    setPaymentMethodId(0);
    setFieldDetail('');
    setPaymentMethodSelected(field === 'paymentMethodId');
    setSelectedField(field);
  };

  const onPaymentMethodChange = paymentMethodId => {
    setPaymentMethodId(paymentMethodId);
  };

  const getEntryType = attributeCombinationId => {
    if (_.isEmpty(attributeCombinations)) {
      return;
    }
    try {
      return attributeCombinations.filter(
        attribute => attribute.id === attributeCombinationId
      )[0].displayName;
    } catch (err) {
      toast.error('Error fetching EntryType. Error: ' + (err && err.message));
    }
  };

  const getPaymentMethod = payment => {
    if (_.isEmpty(paymentOptions)) {
      return;
    }
    try {
      return _.isNull(payment)
        ? ''
        : paymentOptions.filter(
            method => method.key === payment.paymentMethodId
          )[0].text;
    } catch (err) {
      toast.error(
        'Error fetching PaymentMethod. Error: ' + (err && err.message)
      );
    }
  };

  const getTag = tagId => {
    if (_.isEmpty(tags)) {
      return;
    }
    try {
      return tags.filter(tag => tag.id === tagId)[0].displayName;
    } catch (err) {
      toast.error('Error fetching Tag. Error: ' + (err && err.message));
    }
  };

  const getReferenceType = referenceType => {
    if (_.isEmpty(referenceTypes) || _.isNull(referenceType)) {
      return;
    }
    try {
      return referenceTypes.filter(
        type => type.id === referenceType.referenceTypeId
      )[0].displayName;
    } catch (err) {
      toast.error(
        'Error fetching ReferenceType. Error: ' + (err && err.message)
      );
    }
  };

  const getWhitelistEntries = page => {
    axios
      .get(config.whitelistEntries, {
        params: {
          ...(!_.isEmpty(selectedField)
            ? {
                [selectedField]: paymentMethodSelected
                  ? paymentMethodId
                  : encodeURIComponent(fieldDetail.trim())
              }
            : {}),
          ...(paymentMethodSelected
            ? { paymentDetails: encodeURIComponent(fieldDetail.trim()) }
            : {}),
          page: page,
          size: size,
          sort: 'lastUpdatedAt,DESC'
        }
      })
      .then(res => {
        if (res && res.data) {
          setData(res.data.content);
          setTotalNum(res.data.total);
          setTotalPages(Math.ceil(res.data.total / size));
          setSelections(Array(res.data.content.length).fill(false));
        }
      })
      .catch(err => {
        toast.error(
          'Error fetching whitelist data. Error: ' + (err && err.message)
        );
      });
  };

  const nextPage = () => {
    if (totalPages === 0) {
      return;
    }
    const newPage = Math.min(totalPages - 1, page + 1);
    setPage(newPage);
    getWhitelistEntries(newPage);
  };

  const prevPage = () => {
    if (totalPages === 0) {
      return;
    }
    const newPage = Math.max(0, page - 1);
    setPage(newPage);
    getWhitelistEntries(newPage);
  };

  const onClickSearch = () => {
    setPage(0);
    getWhitelistEntries(0);
  };

  useEffect(() => {
    const promiseArray = [
      config.whitelistPaymentMethods,
      config.whitelistReferenceTypes,
      config.whitelistTags,
      config.whitelistAttributeCombinations
    ].map(url => axios.get(url));

    Promise.all(promiseArray)
      .then(res => {
        if (res[0] && res[0].data) {
          setPaymentOptions(toDropDownList(res[0].data));
        }
        if (res[1] && res[1].data) {
          setReferenceTypes(res[1].data);
        }
        if (res[2] && res[2].data) {
          setTags(res[2].data);
        }
        if (res[3] && res[3].data) {
          setAttributeCombinations(res[3].data);
        }
      })
      .catch(err => {
        toast.error(
          'Error loading whitelist data. Error: ' + (err && err.message)
        );
      });
  }, []); // eslint-disable-line

  return (
    <div className={listSearchStyle}>
      <Segment>
        <Accordion>
          <Accordion.Title active={active} index={0} onClick={toggleSearch}>
            <Icon name="dropdown" />
            Search Parameters
          </Accordion.Title>
          <Accordion.Content active={active}>
            <Form>
              <Form.Group widths="equal">
                <Form.Select
                  fluid
                  label="Search Field"
                  placeholder="Select Field"
                  options={WHITELIST_SEARCH_FIELDS}
                  onChange={(evt, data) => onFieldChange(data.value)}
                  value={selectedField}
                />
                <Form.Select
                  disabled={!paymentMethodSelected}
                  placeholder="Payment Method"
                  fluid
                  label="Payment Method"
                  onChange={(evt, data) => onPaymentMethodChange(data.value)}
                  options={paymentOptions}
                  value={paymentMethodId}
                />
              </Form.Group>
              <Form.Input
                fluid
                label="Field Details"
                placeholder="Field Details"
                onChange={(evt, { value }) => setFieldDetail(value)}
                value={fieldDetail}
              />
              <Button
                primary
                icon="search"
                content="Search"
                onClick={onClickSearch}
              />
            </Form>
          </Accordion.Content>
        </Accordion>
      </Segment>
      <Button
        icon="delete"
        content="Remove Selected from Whitelist"
        onClick={onClickDelete}
      />
      <div className="table-scroll">
        <Table celled className="search-table">
          <Table.Header>
            {headers && (
              <Table.Row textAlign="center">
                <Table.HeaderCell className="width-select-box">
                  <Checkbox
                    checked={checkAllSelected()}
                    onClick={handleAllSelected}
                  />
                </Table.HeaderCell>
                {headers.map((header, idx) => {
                  return (
                    <Table.HeaderCell className="width-field" key={idx}>
                      {header}
                    </Table.HeaderCell>
                  );
                })}
              </Table.Row>
            )}
          </Table.Header>
          <Table.Body>
            {data.length ? (
              data.map((detail, idx) => {
                return (
                  <Table.Row key={idx} textAlign="center">
                    <Table.Cell>
                      <Checkbox
                        checked={selections[idx]}
                        onClick={() => handleSelect(idx)}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      {convertToLocalTime(detail.lastUpdatedAt)}
                    </Table.Cell>
                    <TruncatedCell>{detail.lastUpdatedBy}</TruncatedCell>
                    <TruncatedCell>
                      {getEntryType(detail.attributeCombinationId)}
                    </TruncatedCell>
                    <TruncatedCell>{detail.profileId}</TruncatedCell>
                    <TruncatedCell>
                      {getPaymentMethod(detail.payment)}
                    </TruncatedCell>
                    <TruncatedCell>
                      {_.isNull(detail.payment) ? '' : detail.payment.details}
                    </TruncatedCell>
                    <TruncatedCell>{detail.emailAddress}</TruncatedCell>
                    <TruncatedCell>{detail.emailDomain}</TruncatedCell>
                    <TruncatedCell>{detail.phoneNumber}</TruncatedCell>
                    <TruncatedCell>{detail.deviceId}</TruncatedCell>
                    <TruncatedCell>{detail.ipAddress}</TruncatedCell>
                    <TruncatedCell>{detail.bankAccountDetails}</TruncatedCell>
                    <TruncatedCell>
                      {getTag(detail.metadata.tagId)}
                    </TruncatedCell>
                    <TruncatedCell>{detail.metadata.notes}</TruncatedCell>
                    <TruncatedCell>{detail.fraudId}</TruncatedCell>
                    <TruncatedCell>
                      {getReferenceType(detail.reference)}
                    </TruncatedCell>
                    <TruncatedCell>
                      {_.isNull(detail.reference)
                        ? ''
                        : detail.reference.referenceIdNotes}
                    </TruncatedCell>
                  </Table.Row>
                );
              })
            ) : (
              <Table.Row textAlign="center">
                <Table.Cell colSpan={headers.length + 1}>No Data</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>

      <Grid colums={15} className={data.length ? 'margin-top-0' : 'hidden'}>
        <GridColumn floated={'left'} width={5} verticalAlign={'middle'}>
          <label className="total-label">
            <h4>
              Showing {page * size + 1}-{page * size + data.length} of{' '}
              {totalNum} rows
            </h4>
          </label>
        </GridColumn>
        <GridColumn floated={'right'} width={10} verticalAlign={'middle'}>
          <Button
            content="Prev"
            icon="left arrow"
            labelPosition="left"
            disabled={page <= 0}
            onClick={() => prevPage()}
          />
          <Button
            content="Next"
            icon="right arrow"
            labelPosition="right"
            disabled={page >= totalPages - 1}
            onClick={() => nextPage()}
          />
        </GridColumn>
      </Grid>

      <Confirm
        header={'Delete Whitelist Entries'}
        content={
          'This will delete the selected whitelist entries. Are you sure you want to continue?'
        }
        onCancel={() => setDeleteModal(false)}
        onConfirm={deleteSelectedEntries}
        open={deleteModal}
      />
    </div>
  );
};

export default WhiteListSearch;
