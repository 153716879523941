import React from 'react';
import { Checkbox, Grid, Form, Radio } from 'semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize';
import {
  CaseProfileRemediationState,
  CaseProfileRemediationType
} from '../../../types/caseManagement';
import {
  SUMMARY_PROFILE_ID_LABEL,
  NOTES_MAX_LENGTH,
  NOTES_DISPLAY_LINE
} from '../../../constants/caseManagement';
import { remediationFormStyle } from './CaseRemediation.style';

type Props = {
  profileId?: string | null;
  isActive?: boolean;
  type?: CaseProfileRemediationType;
  note?: string;
  accountDeactivationDisabled?: boolean;
  resetPasswordDisabled?: boolean;
  onChange?: (value: CaseProfileRemediationState) => void;
};

const CaseProfileRemediation = ({
  profileId,
  isActive = false,
  type,
  note = '',
  accountDeactivationDisabled = false,
  resetPasswordDisabled = false,
  onChange
}: Props) => {
  const allDisabled = accountDeactivationDisabled && resetPasswordDisabled;

  const handleNoteChange = (note: string) => {
    onChange?.({
      isActive,
      note,
      type
    });
  };

  const handleProfileRemediationSelectionChange = () => {
    onChange?.({
      isActive: !isActive,
      note,
      type
    });
  };

  const handleTypeChange = (type: CaseProfileRemediationType) => {
    onChange?.({
      isActive,
      note,
      type
    });
  };

  return (
    <Form className={remediationFormStyle}>
      <div className="title">
        <span className="title">Remediation By Profile</span>
        <Checkbox
          className="title-checkbox"
          checked={!allDisabled && isActive}
          disabled={allDisabled || !profileId}
          onChange={handleProfileRemediationSelectionChange}
        />
      </div>

      <div className="ad-types">
        <Form.Field>
          <Radio
            label="Account Deactivation"
            name="type"
            value={CaseProfileRemediationType.ACCOUNT_DEACTIVATION}
            checked={type === CaseProfileRemediationType.ACCOUNT_DEACTIVATION}
            disabled={accountDeactivationDisabled || !isActive}
            onChange={(evt, data) =>
              handleTypeChange(data.value as CaseProfileRemediationType)
            }
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label="Reset Password"
            name="type"
            value={CaseProfileRemediationType.RESET_PASSWORD}
            checked={type === CaseProfileRemediationType.RESET_PASSWORD}
            disabled={resetPasswordDisabled || !isActive}
            onChange={(evt, data) =>
              handleTypeChange(data.value as CaseProfileRemediationType)
            }
          />
        </Form.Field>
      </div>

      <div className="ad-fields">
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column className="field-row-label">
              {SUMMARY_PROFILE_ID_LABEL}
            </Grid.Column>
            <Grid.Column className="field-row-data">
              {profileId || '-'}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>

      <div className="note">
        <label>
          {type === CaseProfileRemediationType.ACCOUNT_DEACTIVATION
            ? 'Account Deactivation Notes*'
            : 'Reset Password Notes*'}
          <TextareaAutosize
            className="no-resize"
            rows={1}
            value={note}
            maxLength={NOTES_MAX_LENGTH}
            maxRows={NOTES_DISPLAY_LINE}
            disabled={allDisabled || !isActive}
            onChange={evt => handleNoteChange(evt.currentTarget.value)}
          />
        </label>
      </div>
    </Form>
  );
};

export default CaseProfileRemediation;
