import React from 'react';
import isFinite from 'lodash/isFinite';

import PaginationControl from '../../common/PaginationControl/PaginationControl';
import { PaginationType } from '../../../constants/investigationToolsConstant';
import { goToAPage } from '../../../actions/investigation-tools/paginationAction';

const UserProfileListPaginationContainer = ({
  dispatch,
  currentPage,
  totalNumberOfPages
}) => {
  const paginationControlProps = {
    currentPageNumber: isFinite(currentPage) ? currentPage : 1,
    totalNumberOfPages: isFinite(totalNumberOfPages) ? totalNumberOfPages : 1,
    onPageNumberClick: pageNumber =>
      dispatch(
        goToAPage(PaginationType.PROFILE_SUMMARY_PROFILE_LIST, pageNumber)
      )
  };

  return (
    <div
      style={{ margin: '20px 0' }}
      className="profile-summary-profile-list-pagination-container"
    >
      <PaginationControl {...paginationControlProps} />
    </div>
  );
};

export default UserProfileListPaginationContainer;
