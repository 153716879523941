import { css } from 'emotion';

export const listSearchStyle = css`
  & .table-scroll {
    overflow: auto hidden;
    margin-top: 1em;
  }

  & .total-label {
    padding-top: 5px;
    padding-left: 10px;
    color: grey;
  }

  & .search-table {
    border-collapse: collapse;
    table-layout: fixed;
  }

  & .search-table th {
    background: #b8d1f3 !important;
  }

  & .search-table th.width-select-box {
    width: 50px;
  }

  & .search-table th.width-field {
    width: 200px;
  }

  & .search-table td {
    padding: 7px;
    border: #4e95f4 1px solid;
    word-wrap: break-word;
  }

  & .search-table tr {
    background: #b8d1f3;
  }

  & .search-table tr:nth-child(odd) {
    background: #dae5f4;
  }

  & .search-table tr:nth-child(even) {
    background: white;
  }

  & .ui.grid.hidden {
    display: none;
  }

  & .ui.grid.margin-top-0 {
    margin-top: 0;
  }

  & .search-table tr td {
    height: 5em;
  }
`;
