import React, { useState, useEffect } from 'react';
import { useAuth0 } from '../../react-auth0-spa';
import { toast } from 'react-toastify';

import { Form, Input, Select, TextArea, Grid, Button } from 'semantic-ui-react';
import {
  getReferenceTypes,
  getBlackListTags,
  getBlacklistAttributes,
  submitBlackList
} from '../../apis/BlackListClient';
import {
  isEmpty,
  isEmailValid,
  isEmailDomainValid,
  isIpAddressValid,
  isPhoneNumberValid
} from '../../utils/commonFormValidations';

const BlackListAdditionForm = () => {
  const [tags, setTags] = useState([]);
  const [auth0Token, setAuth0Token] = useState([]);

  const [attributeListType, setAttributeListType] = useState([]);
  const [referenceListTypes, setReferenceListTypes] = useState([]);

  const attributeValidator = {
    EMAIL: {
      validate: value => isEmailValid(value),
      message: 'Please use correct email Address'
    },
    EMAIL_DOMAIN: {
      validate: value => isEmailDomainValid(value),
      message: 'Please use correct email domain'
    },
    PHONE: {
      validate: value => isPhoneNumberValid(value),
      message: 'Please use correct Phone Number'
    },
    IP_ADDRESS: {
      validate: value => isIpAddressValid(value),
      message: 'Please use correct IP Address'
    }
  };

  // set form state
  const defaultFormState = {
    selectedAttribute: '',
    selectedValue: '',
    selectedTag: '',
    markingNote: '',
    referenceType: '',
    referenceId: ''
  };
  const [formState, setFormState] = useState(defaultFormState);

  const { getTokenSilently } = useAuth0();

  const handleReferenceTypeOnChange = (evt, data) => {
    const newValue = data.value;
    if (isEmpty(newValue)) {
      setFormState({ ...formState, referenceId: '', referenceType: '' });
    } else {
      setFormState({ ...formState, referenceType: data.value });
    }
  };

  const isFormValid = () => {
    const selectedValue = formState.selectedValue.trim();

    //check for empty values
    if (
      isEmpty(formState.selectedAttribute) ||
      isEmpty(selectedValue) ||
      isEmpty(formState.selectedTag) ||
      (!isReferenceTypeNotSelected && isEmpty(formState.referenceId))
    ) {
      return {
        valid: false,
        message: 'Please fix errors / required fields in the form'
      };
    }
    //Attribute specific validation
    const fieldValueValidator = attributeValidator[formState.selectedAttribute];
    const noFieldValidationError =
      !fieldValueValidator || fieldValueValidator.validate(selectedValue);

    if (!noFieldValidationError) {
      return { valid: false, message: fieldValueValidator.message };
    }
    //in case of no error
    return { valid: true, message: undefined };
  };

  const handleBlackListSubmit = () => {
    const formValidator = isFormValid();
    if (formValidator.valid) {
      // submit data
      const postBody = {
        attributeType: formState.selectedAttribute,
        attributeValue: formState.selectedValue.trim(),
        tag: formState.selectedTag,
        markingNotes: formState.markingNote.trim(),
        referenceId: formState.referenceId,
        referenceType: formState.referenceType
      };
      submitBlackList(auth0Token, postBody)
        .then(() => {
          toast.success('Blaclist entry has been added successfully!');
          setFormState(defaultFormState);
        })
        .catch(err => {
          if (err.response) {
            toast.error('Error: ' + err.response.data);
          } else {
            toast.error('Error saving blacklist data: ' + (err && err.message));
          }
        });
    } else {
      toast.error(formValidator.message);
      return;
    }
  };

  const handleFormClear = () => {
    setFormState(defaultFormState);
  };
  const isReferenceTypeNotSelected = isEmpty(formState.referenceType);

  useEffect(() => {
    getTokenSilently().then(token => {
      setAuth0Token(token);

      Promise.all([
        getReferenceTypes(token),
        getBlackListTags(token),
        getBlacklistAttributes(token)
      ])
        .then(res => {
          if (res[0] && res[0].status === 200) {
            setReferenceListTypes(
              res[0].data.map(ref => {
                return {
                  key: ref.name,
                  value: ref.name,
                  text: ref.name
                };
              })
            );
          }
          if (res[1] && res[1].status === 200) {
            setTags(
              res[1].data.map(tag => {
                return {
                  key: tag.id,
                  value: tag.id,
                  text: tag.name
                };
              })
            );
          }
          if (res[2] && res[2].status === 200) {
            setAttributeListType(
              res[2].data.map(attr => {
                return {
                  key: attr.attributeKey,
                  value: attr.attributeKey,
                  text: attr.attributeName
                };
              })
            );
          }
        })
        .catch(err => {
          toast.error(
            'Error loading blacklist data. Error: ' + (err && err.message)
          );
        });
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnBlur = evt => {
    setFormState({
      ...formState,
      selectedValue:
        !formState.selectedAttribute ||
        formState.selectedAttribute === 'BANK_ACCOUNT_DETAILS'
          ? evt.target.value.trim()
          : evt.target.value.split(' ').join('')
    });
  };

  return (
    <div>
      <Form>
        <Form.Field inline required>
          <label>Field</label>
          <Select
            placeholder="Select Field"
            options={attributeListType}
            value={formState.selectedAttribute}
            onChange={(evt, data) =>
              setFormState({ ...formState, selectedAttribute: data.value })
            }
          />
        </Form.Field>
        <Form.Field inline required>
          <label>Value</label>
          <Input
            placeholder="Enter a value"
            value={formState.selectedValue}
            onChange={evt =>
              setFormState({ ...formState, selectedValue: evt.target.value })
            }
            onBlur={handleOnBlur}
          />
        </Form.Field>
        <Form.Field inline required>
          <label>Tag</label>
          <Select
            placeholder="Select Tag Option"
            options={tags}
            value={formState.selectedTag}
            onChange={(evt, data) =>
              setFormState({ ...formState, selectedTag: data.value })
            }
          />
        </Form.Field>
        <Form.Field inline>
          <label>Marking Notes</label>
          <TextArea
            rows={2}
            placeholder="Marking Notes"
            value={formState.markingNote}
            onChange={(evt, data) =>
              setFormState({ ...formState, markingNote: data.value })
            }
          />
        </Form.Field>
        <Form.Field inline>
          <label>Reference Types</label>
          <Select
            placeholder="Select Reference Type"
            options={referenceListTypes}
            onChange={handleReferenceTypeOnChange}
            clearable
            value={formState.referenceType}
          />
        </Form.Field>
        <Form.Field inline required={!isReferenceTypeNotSelected}>
          <label>Reference Id</label>
          <Input
            placeholder="Enter Reference Id"
            disabled={isReferenceTypeNotSelected}
            value={formState.referenceId}
            onChange={(evt, data) =>
              setFormState({ ...formState, referenceId: data.value.trim() })
            }
          />
        </Form.Field>

        <Grid>
          <Grid.Column floated="right" width={4}>
            <Button
              primary
              icon="plus"
              content="Add Blacklist"
              type="submit"
              onClick={handleBlackListSubmit}
            />
            <Button icon="close" content="Clear" onClick={handleFormClear} />
          </Grid.Column>
        </Grid>
      </Form>
    </div>
  );
};

export default BlackListAdditionForm;
