import React from 'react';
import { Table } from 'semantic-ui-react';
import { getPaymentData } from '../../../reducers/investigation-tools/selectors';
import { usePaymentDataContext } from '../../../views/investigation-tools/payment-data/PaymentDataContext';
import PaginationController from '../common/PaginationController';

type Entry = {
  paymentTimestamp: string;
  paymentDatetime: string;
  paymentId: string;
  bookingId: string;
  profileId: string;
  paymentType: string;
  bankName: string;
  bankAccountNo: string;
  bankAccountName: string;
  cardNumber: string;
  cardExpireDate: string;
};

const PaymentDataTableContainer = () => {
  const { getState, paginationDispatch, handleSearch } =
    usePaymentDataContext();
  const state = getState();
  const { entries } = getPaymentData(state);

  return (
    <div>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Timestamp</Table.HeaderCell>
            <Table.HeaderCell>Payment ID</Table.HeaderCell>
            <Table.HeaderCell>Booking ID</Table.HeaderCell>
            <Table.HeaderCell>Profile ID</Table.HeaderCell>
            <Table.HeaderCell>Payment</Table.HeaderCell>
            <Table.HeaderCell>Bank Name</Table.HeaderCell>
            <Table.HeaderCell>Bank Account Number</Table.HeaderCell>
            <Table.HeaderCell>Bank Account Name</Table.HeaderCell>
            <Table.HeaderCell>Card Number</Table.HeaderCell>
            <Table.HeaderCell>Card Expiry Date</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {entries && entries.length > 0 ? (
            entries.map((entry: Entry, index: number) => {
              return (
                <Table.Row key={index}>
                  <Table.Cell>{entry.paymentDatetime}</Table.Cell>
                  <Table.Cell>{entry.paymentId}</Table.Cell>
                  <Table.Cell>{entry.bookingId}</Table.Cell>
                  <Table.Cell>{entry.profileId}</Table.Cell>
                  <Table.Cell>{entry.paymentType}</Table.Cell>
                  <Table.Cell>{entry.bankName}</Table.Cell>
                  <Table.Cell>{entry.bankAccountNo}</Table.Cell>
                  <Table.Cell>{entry.bankAccountName}</Table.Cell>
                  <Table.Cell>{entry.cardNumber}</Table.Cell>
                  <Table.Cell>{entry.cardExpireDate}</Table.Cell>
                </Table.Row>
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell colSpan={'10'}>
                <p style={{ textAlign: 'center' }}>- No Data -</p>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <PaginationController
        dispatch={paginationDispatch}
        state={state}
        tableName={`PAYMENT_DATA_TABLE`}
        onPageNumberClick={handleSearch}
      />
    </div>
  );
};

export default PaymentDataTableContainer;
