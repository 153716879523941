import React, { useState, useEffect } from 'react';
import {
  DateFilterType,
  FieldAndValueFilterData,
  FilterDataType
} from '../../../types/caseManagement';
import { subHours } from 'date-fns';
import { getStartAndEndDateFromType } from '../../../utils/case-management/dateFilter';
import { Form, Button } from 'semantic-ui-react';
import DateFilterInput from './DateFilterInput';
import {
  FIELD_FILTER_LABEL,
  REFERENCE_TYPE_FILTER_LABEL,
  VALUE_FILTER_LABEL,
  REFERENCE_ID_FILTER_LABEL,
  REFERENCE_TYPE_FIELD
} from '../../../constants/caseManagement';
import configs from '../../../configs';
import { toast } from 'react-toastify';
import axios from 'axios';

type Props = {
  onSubmit?: (filterData: FieldAndValueFilterData) => void;
  onReset?: () => void;
};

/**
 * Search form for the Field And Value tab in Case Search.
 */
const FieldAndValueSearchForm = ({ onSubmit, onReset }: Props) => {
  const [field, setField] = useState('');
  const [referenceType, setReferenceType] = useState('ALL');
  const [value, setValue] = useState('');
  const [dateType, setDateType] = useState(DateFilterType.LAST_HOUR);
  const [startDate, setStartDate] = useState(subHours(new Date(), 1));
  const [endDate, setEndDate] = useState(new Date());

  const [fieldOptions, setFieldOptions] = useState<Record<string, string>[]>(
    []
  );
  const [referenceTypeOptions, setReferenceTypeOptions] = useState([
    {
      key: 'ALL',
      value: 'ALL',
      text: 'ALL'
    }
  ]);

  const setDateTypeAndValue = (type: DateFilterType) => {
    setDateType(type as DateFilterType);

    const { startDate, endDate } = getStartAndEndDateFromType(type);

    setStartDate(startDate);
    setEndDate(endDate);
  };

  const reset = () => {
    setField(fieldOptions.length > 0 ? fieldOptions[0].value : '');
    setReferenceType('ALL');
    setValue('');
    setDateTypeAndValue(DateFilterType.LAST_HOUR);

    onReset?.();
  };

  const submit = () => {
    onSubmit?.({
      type: FilterDataType.FIELD_AND_VALUE,
      field,
      referenceType: field === REFERENCE_TYPE_FIELD ? referenceType : undefined,
      value,
      dateType,
      startDate,
      endDate
    });
  };

  const onDateChange = (
    dateType: DateFilterType,
    startDate: Date,
    endDate: Date
  ) => {
    setDateType(dateType);
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const fetchFieldOptions = async () => {
    try {
      const response = await axios.get(configs.caseSearchFields);

      setFieldOptions(
        response.data.map((res: { name: string; displayName: string }) => ({
          key: res.name,
          value: res.name,
          text: res.displayName
        }))
      );

      setField(response.data[0].name);
    } catch (err) {
      if (err?.response?.data?.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error('Error loading Fields. Error: ' + (err && err.message));
      }
    }
  };

  const fetchReferenceTypeOptions = async () => {
    try {
      const response = await axios.get(configs.caseReferenceTypes);

      setReferenceTypeOptions([
        {
          key: 'ALL',
          value: 'ALL',
          text: 'ALL'
        },
        ...response.data.map((res: { name: string; displayName: string }) => ({
          key: res.name,
          value: res.name,
          text: res.name
        }))
      ]);
    } catch (err) {
      if (err?.response?.data?.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error(
          'Error loading Reference Types. Error: ' + (err && err.message)
        );
      }
    }
  };

  useEffect(() => {
    setDateTypeAndValue(DateFilterType.LAST_HOUR);

    fetchFieldOptions();
    fetchReferenceTypeOptions();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form>
      <Form.Select
        width={8}
        label={FIELD_FILTER_LABEL}
        options={fieldOptions}
        value={field}
        onChange={(evt, data) => setField(data.value as string)}
      />
      <Form.Select
        width={8}
        label={REFERENCE_TYPE_FILTER_LABEL}
        options={referenceTypeOptions}
        value={referenceType}
        onChange={(evt, data) => setReferenceType(data.value as string)}
        disabled={field !== REFERENCE_TYPE_FIELD}
      />
      <Form.Input
        width={8}
        label={
          field === REFERENCE_TYPE_FIELD
            ? REFERENCE_ID_FILTER_LABEL
            : VALUE_FILTER_LABEL
        }
        placeholder={
          field === REFERENCE_TYPE_FIELD
            ? REFERENCE_ID_FILTER_LABEL
            : VALUE_FILTER_LABEL
        }
        value={value}
        onChange={(evt, data) => setValue(data.value)}
      />
      <DateFilterInput
        dateType={dateType}
        startDate={startDate}
        endDate={endDate}
        onChange={onDateChange}
      />

      <Button
        primary
        icon="search"
        content="Search"
        onClick={submit}
        disabled={value === ''}
      />
      <Button icon="undo" content="Reset" onClick={reset} />
    </Form>
  );
};

export default FieldAndValueSearchForm;
