import React, { useState, useEffect, useRef, createRef } from 'react';
import {
  Table,
  Checkbox,
  Grid,
  GridColumn,
  Button,
  Form
} from 'semantic-ui-react';
import TruncatedCell from '../../table/TruncatedCell';
import {
  CaseResultTableDetail,
  RemediationAction,
  MarkingStatus
} from '../../../types/caseManagement';
import {
  MARK_SUSPECT_BTN,
  MARK_TRUSTED_BTN,
  MARK_NEUTRAL_BTN,
  REMEDIATION_DEACTIVATE_BTN,
  REMEDIATION_RESET_PASSWORD_BTN,
  REMEDIATION_FORCE_LOGOUT_BTN,
  RESULT_DATE_FORMAT,
  ADD_TO_REVIEW_BTN,
  TABLESEARCH_EXACT_MATCH_PLACEHOLDER,
  TABLESEARCH_START_WITH_PLACEHOLDER,
  TABLESEARCH_CONTAINS_PLACEHOLDER,
  TABLESEARCH_DEFAULT_PLACEHOLDER
} from '../../../constants/caseManagement';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { formatNumber } from '../../../utils/numberFormatter';
import FixedScrollDiv from '../../common/FixedScrollDiv';
import {
  containerStyle,
  tableStyle,
  totalLabelStyle
} from './ResultTable.style';

type Props = {
  data: CaseResultTableDetail[];
  filter?: Record<string, string>;
  totalDataCount: number;
  page: number;
  pageSize: number;
  hideMarkStatusSuspect?: boolean;
  hideMarkStatusNeutral?: boolean;
  hideMarkStatusTrusted?: boolean;
  hideAddToReview?: boolean;
  hideAccountDeactivation?: boolean;
  hideResetPassword?: boolean;
  hideForceLogout?: boolean;
  onPageChange?: (newPage: number) => void;
  onMarkStatus?: (
    markingStatus: MarkingStatus,
    selectedData: CaseResultTableDetail[]
  ) => void;
  onRemediationAction?: (
    action: RemediationAction,
    selectedData: CaseResultTableDetail[]
  ) => void;
  onAddToReview?: (selectedData: CaseResultTableDetail[]) => void;
  onSearch?: (filter: Record<string, string>) => void;
};

type ResultTableColumn = {
  label: string;
  field: string;
  searchKey?: string;
  render?: (row: CaseResultTableDetail) => React.ReactNode;
  width: number;
  noTruncate?: boolean;
  align?: 'left' | 'right' | 'center';
  searchNumberOnly?: boolean;
};

const headers: ResultTableColumn[] = [
  {
    label: 'Case ID',
    field: 'id',
    searchKey: 'tableSearch.id',
    render: (row: CaseResultTableDetail) => {
      return (
        <Link
          to={`/case-details/${row.id}`}
          target="_blank"
          style={{ overflowWrap: 'anywhere' }}
        >
          {row.id}
        </Link>
      );
    },
    width: 240,
    noTruncate: true
  },
  {
    label: 'API',
    field: 'api',
    searchKey: 'tableSearch.api',
    width: 240,
    noTruncate: true
  },
  {
    label: 'Ref Type',
    field: 'referenceType',
    searchKey: 'tableSearch.referenceType',
    width: 280,
    noTruncate: true
  },
  {
    label: 'Ref ID',
    field: 'referenceId',
    searchKey: 'tableSearch.referenceId',
    width: 200,
    noTruncate: true
  },
  {
    label: 'Date',
    field: 'requestDate',
    render: (row: CaseResultTableDetail) =>
      format(parseISO(row.requestDate), RESULT_DATE_FORMAT),
    width: 200,
    noTruncate: true
  },
  {
    label: 'Email Address',
    field: 'emails',
    searchKey: 'tableSearch.email',
    render: (row: CaseResultTableDetail) =>
      row.emails.map((email, i) => (
        <div key={`email-${row.id}-${i}`}>{email}</div>
      )),
    width: 280,
    noTruncate: true
  },
  {
    label: 'Device ID',
    field: 'deviceId',
    searchKey: 'tableSearch.deviceId',
    width: 200,
    noTruncate: true
  },
  {
    label: 'IP Address',
    field: 'ipAddress',
    searchKey: 'tableSearch.ipAddress',
    width: 200
  },
  {
    label: 'IP Country',
    field: 'ipCountryId',
    searchKey: 'tableSearch.ipCountryId',
    width: 150,
    noTruncate: true
  },
  {
    label: 'Profile ID',
    field: 'profileId',
    searchKey: 'tableSearch.profileId',
    width: 200
  },
  {
    label: 'Phone Number',
    field: 'phones',
    searchKey: 'tableSearch.phone',
    render: (row: CaseResultTableDetail) =>
      row.phones.map((phone, i) => (
        <div key={`phone-${row.id}-${i}`}>{phone}</div>
      )),
    width: 200,
    noTruncate: true
  },
  {
    label: 'Bank Account Details',
    field: 'bankAccountDetails',
    searchKey: 'tableSearch.bankAccountDetails',
    width: 300,
    noTruncate: true
  },
  {
    label: 'Currency',
    field: 'currency',
    searchKey: 'tableSearch.currency',
    width: 150,
    noTruncate: true
  },
  {
    label: 'Transaction Amount',
    field: 'amount',
    searchKey: 'tableSearch.amount',
    searchNumberOnly: true,
    render: (row: CaseResultTableDetail) =>
      row.amount ? formatNumber(Number(row.amount)) : '',
    width: 200,
    align: 'right',
    noTruncate: true
  },
  {
    label: 'Masked Card Number',
    field: 'maskedCardNumber',
    searchKey: 'tableSearch.maskedCardNumber',
    width: 200,
    noTruncate: true
  },
  {
    label: 'Card Country ID',
    field: 'cardCountryId',
    searchKey: 'tableSearch.cardCountryId',
    width: 150,
    noTruncate: true
  },
  {
    label: 'Final Recommendation',
    field: 'finalRecommendation',
    searchKey: 'tableSearch.action',
    width: 200,
    noTruncate: true
  },
  {
    label: 'Marking Status',
    field: 'markingStatus',
    searchKey: 'tableSearch.markingStatus',
    width: 150,
    noTruncate: true
  }
];

// Fill refs for each searchKey, returning: { "tableSearch.id": createRef(), ... }
const fillRefs = () => {
  const r: Record<string, React.RefObject<HTMLDivElement>> = {};

  for (let i = 0; i < headers.length; i++) {
    const h = headers[i];

    if (!h.searchKey) continue;

    r[h.searchKey] = createRef<HTMLDivElement>();
  }

  return r;
};

const searchPlaceholders: { [key: string]: string } = {
  'tableSearch.id': TABLESEARCH_EXACT_MATCH_PLACEHOLDER,
  'tableSearch.api': TABLESEARCH_EXACT_MATCH_PLACEHOLDER,
  'tableSearch.referenceType': TABLESEARCH_START_WITH_PLACEHOLDER,
  'tableSearch.referenceId': TABLESEARCH_EXACT_MATCH_PLACEHOLDER,
  'tableSearch.email': TABLESEARCH_START_WITH_PLACEHOLDER,
  'tableSearch.deviceId': TABLESEARCH_EXACT_MATCH_PLACEHOLDER,
  'tableSearch.ipAddress': TABLESEARCH_START_WITH_PLACEHOLDER,
  'tableSearch.ipCountryId': TABLESEARCH_EXACT_MATCH_PLACEHOLDER,
  'tableSearch.cardCountryId': TABLESEARCH_EXACT_MATCH_PLACEHOLDER,
  'tableSearch.profileId': TABLESEARCH_EXACT_MATCH_PLACEHOLDER,
  'tableSearch.phone': TABLESEARCH_START_WITH_PLACEHOLDER,
  'tableSearch.bankAccountDetails': TABLESEARCH_CONTAINS_PLACEHOLDER,
  'tableSearch.currency': TABLESEARCH_EXACT_MATCH_PLACEHOLDER,
  'tableSearch.amount': TABLESEARCH_EXACT_MATCH_PLACEHOLDER,
  'tableSearch.maskedCardNumber': TABLESEARCH_START_WITH_PLACEHOLDER,
  'tableSearch.action': TABLESEARCH_EXACT_MATCH_PLACEHOLDER,
  'tableSearch.markingStatus': TABLESEARCH_EXACT_MATCH_PLACEHOLDER
};

const searchPlaceholder = (searchKey: string): string => {
  return searchPlaceholders[searchKey] || TABLESEARCH_DEFAULT_PLACEHOLDER;
};

let lastRowClickTime = 0;
let timeout: number | undefined = undefined;

/**
 * A component to display result table for Case Search.
 */
const ResultTable = ({
  data,
  filter,
  totalDataCount,
  page,
  pageSize,
  hideMarkStatusSuspect = false,
  hideMarkStatusTrusted = false,
  hideMarkStatusNeutral = false,
  hideAddToReview = false,
  hideAccountDeactivation = false,
  hideForceLogout = false,
  hideResetPassword = false,
  onPageChange,
  onMarkStatus,
  onRemediationAction,
  onAddToReview,
  onSearch
}: Props) => {
  const totalPages = Math.ceil(totalDataCount / pageSize);

  const [isCheckAllSelected, setIsCheckAllSelected] = useState(false);
  const [isRowIdxSelected, setIsRowIdxSelected] = useState<boolean[]>([]);
  const [selectedCount, setSelectedCount] = useState(0);

  // using refs instead of controlled input component for performance reason
  // because using controlled input caused lag when there are many rows on page.
  const searchInputs = useRef(fillRefs());

  const toggleSelectAll = () => {
    setSelectedCount(isCheckAllSelected ? 0 : data.length);

    setIsRowIdxSelected(isRowIdxSelected.map(() => !isCheckAllSelected));

    setIsCheckAllSelected(!isCheckAllSelected);
  };

  const toggleSelectRow = (rowIdx: number) => {
    const copy = [...isRowIdxSelected];

    copy[rowIdx] = !copy[rowIdx];

    setIsRowIdxSelected(copy);
    setIsCheckAllSelected(copy.find(checked => !checked) === undefined);

    setSelectedCount(copy[rowIdx] ? selectedCount + 1 : selectedCount - 1);
  };

  const goToPage = (pageIdx: number) => {
    if (totalPages === 0) return;

    const newPage = Math.min(Math.max(0, pageIdx), totalPages - 1);

    if (newPage === page) return;

    onPageChange?.(newPage);
  };

  const markStatus = (status: MarkingStatus) => {
    onMarkStatus?.(
      status,
      data.filter((d, idx) => isRowIdxSelected[idx])
    );
  };

  const doRemediationAction = (action: RemediationAction) => {
    onRemediationAction?.(
      action,
      data.filter((d, idx) => isRowIdxSelected[idx])
    );
  };

  const addToReview = () => {
    onAddToReview?.(data.filter((d, idx) => isRowIdxSelected[idx]));
  };

  const getInputElement = (key?: string): HTMLInputElement | undefined => {
    if (!key) return undefined;

    const div = searchInputs.current[key].current;
    if (!div) return undefined;

    const input = div?.getElementsByTagName('input')[0];
    if (!input) return undefined;

    return input;
  };

  const handleSearchNumberChange = (e: React.FormEvent) => {
    const input = e.currentTarget as HTMLInputElement;

    input.value = input.value.trim();
    if (input.value === '') return;

    const value = Number(input.value);

    if (!isNaN(value)) {
      input.value = Math.trunc(value).toString();
    } else {
      input.value = input.value.replace(/[^0-9]+/g, '');
    }
  };

  const handleSearchKeyUp = (e: React.KeyboardEvent) => {
    // Check if enter key is pressed (13 is the key code for enter key)
    if (e.key === 'Enter' || e.keyCode === 13) {
      const filter: Record<string, string> = {};

      // Build the filter variable, result: { "tableSearch.id": "123", ... }
      headers.forEach(col => {
        if (!col.searchKey) return;

        const input = getInputElement(col.searchKey);
        if (!input) return;

        if (input.value.trim() === '') return;

        filter[col.searchKey] = input.value;
      });

      // Call parent function from props
      onSearch?.(filter);
    }
  };

  const handleRowClick = (idx: number, row: CaseResultTableDetail) => {
    const currentTime = Date.now();

    if (currentTime - lastRowClickTime < 200) {
      window.clearTimeout(timeout);

      const win = window.open(`/case-details/${row.id}`, '_blank');
      win?.focus();
    } else {
      timeout = window.setTimeout(() => {
        toggleSelectRow(idx);
      }, 200);
    }

    lastRowClickTime = currentTime;
  };

  useEffect(() => {
    const setSearch = () => {
      if (!filter) return;

      Object.keys(searchInputs.current).forEach(k => {
        const div = searchInputs.current[k].current;
        if (!div) return;

        const input = div?.getElementsByTagName('input')[0];
        if (!input) return;

        input.value = filter[k] === undefined ? '' : filter[k];
      });
    };

    setSearch();
  }, [filter]);

  useEffect(() => {
    const selectedRows = [];

    for (let i = 0; i < data.length; i++) {
      selectedRows.push(false);
    }

    setSelectedCount(0);
    setIsCheckAllSelected(false);
    setIsRowIdxSelected(selectedRows);
  }, [data]);

  const isActionButtonsDisabled =
    isRowIdxSelected.find(isSelected => isSelected) === undefined;

  return (
    <div className={containerStyle}>
      <div className="actions">
        {!hideMarkStatusSuspect && (
          <Button
            primary
            size="tiny"
            content={MARK_SUSPECT_BTN}
            onClick={() => markStatus('SUSPECT')}
            disabled={isActionButtonsDisabled}
          />
        )}
        {!hideMarkStatusTrusted && (
          <Button
            primary
            size="tiny"
            content={MARK_TRUSTED_BTN}
            onClick={() => markStatus('TRUSTED')}
            disabled={isActionButtonsDisabled}
          />
        )}
        {!hideMarkStatusNeutral && (
          <Button
            primary
            size="tiny"
            content={MARK_NEUTRAL_BTN}
            onClick={() => markStatus('NEUTRAL')}
            disabled={isActionButtonsDisabled}
          />
        )}

        <div className="add-to-review">
          {!hideAddToReview && (
            <Button
              primary
              size="tiny"
              content={ADD_TO_REVIEW_BTN}
              onClick={addToReview}
              disabled={isActionButtonsDisabled}
            />
          )}
        </div>

        {!hideAccountDeactivation && (
          <Button
            primary
            size="tiny"
            content={REMEDIATION_DEACTIVATE_BTN}
            onClick={() => doRemediationAction(RemediationAction.DEACTIVATE)}
            disabled={hideAccountDeactivation || isActionButtonsDisabled}
          />
        )}
        {!hideResetPassword && (
          <Button
            primary
            size="tiny"
            content={REMEDIATION_RESET_PASSWORD_BTN}
            onClick={() =>
              doRemediationAction(RemediationAction.RESET_PASSWORD)
            }
            disabled={isActionButtonsDisabled}
          />
        )}
        {!hideForceLogout && (
          <Button
            primary
            size="tiny"
            content={REMEDIATION_FORCE_LOGOUT_BTN}
            onClick={() => doRemediationAction(RemediationAction.FORCE_LOGOUT)}
            disabled={isActionButtonsDisabled}
          />
        )}

        <div className="selected-count">Selected: {selectedCount}</div>
      </div>

      <FixedScrollDiv>
        <Table celled className={tableStyle}>
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell style={{ width: '50px' }}>
                <Checkbox
                  checked={isCheckAllSelected}
                  onClick={toggleSelectAll}
                />
              </Table.HeaderCell>
              {headers.map((header, idx) => {
                return (
                  <Table.HeaderCell
                    key={idx}
                    style={{ width: `${header.width}px` }}
                    className="header"
                  >
                    {header.label}
                  </Table.HeaderCell>
                );
              })}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell class="center aligned" style={{ width: '50px' }} />
              {headers.map((header, idx) => (
                <Table.Cell
                  key={idx}
                  className="search-cell center aligned"
                  style={{ width: `${header.width}px` }}
                >
                  {header.searchKey && (
                    <div
                      ref={searchInputs.current[header.searchKey]}
                      style={{ width: `${header.width - 2}px` }}
                    >
                      <Form.Input
                        fluid
                        icon="search"
                        placeholder={searchPlaceholder(header.searchKey)}
                        onKeyUp={handleSearchKeyUp}
                        onChange={
                          header.searchNumberOnly
                            ? handleSearchNumberChange
                            : undefined
                        }
                      />
                    </div>
                  )}
                </Table.Cell>
              ))}
            </Table.Row>
            {data.length ? (
              data.map((detail, idx) => {
                return (
                  <Table.Row
                    key={idx}
                    textAlign="center"
                    active={isRowIdxSelected[idx]}
                    onClick={() => handleRowClick(idx, detail)}
                    style={{ cursor: 'pointer' }}
                  >
                    <Table.Cell className="data-cell" style={{ width: '50px' }}>
                      <Checkbox
                        checked={isRowIdxSelected[idx]}
                        onClick={() => toggleSelectRow(idx)}
                      />
                    </Table.Cell>
                    {headers.map((header, idx) => {
                      const content = header.render
                        ? header.render(detail)
                        : detail[header.field as keyof CaseResultTableDetail];

                      const textAlign = header.align || 'center';

                      return header.noTruncate ? (
                        <Table.Cell
                          key={`col-${idx}`}
                          className="data-cell"
                          style={{ textAlign, width: `${header.width}px` }}
                        >
                          <span
                            onClick={e => e.stopPropagation()}
                            style={{ cursor: 'auto' }}
                          >
                            {content}
                          </span>
                        </Table.Cell>
                      ) : (
                        <TruncatedCell
                          key={`col-${idx}`}
                          className="data-cell"
                          style={{ textAlign, width: `${header.width}px` }}
                        >
                          <span
                            onClick={e => e.stopPropagation()}
                            style={{ cursor: 'auto' }}
                          >
                            {content}
                          </span>
                        </TruncatedCell>
                      );
                    })}
                  </Table.Row>
                );
              })
            ) : (
              <Table.Row textAlign="center">
                <Table.Cell colSpan={headers.length + 1}>No Data</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </FixedScrollDiv>

      {data.length > 0 && (
        <Grid colums={15}>
          <GridColumn
            floated={'left'}
            width={5}
            verticalAlign={'middle'}
            style={{ marginTop: '1em' }}
          >
            <label className={totalLabelStyle}>
              <h4>
                Showing {page * pageSize + 1}-
                {Math.min((page + 1) * pageSize, totalDataCount)} of{' '}
                {totalDataCount} rows
              </h4>
            </label>
          </GridColumn>
          <GridColumn
            floated={'right'}
            width={10}
            verticalAlign={'middle'}
            style={{ marginTop: '1em' }}
          >
            <Button
              content="Prev"
              icon="left arrow"
              labelPosition="left"
              disabled={page <= 0}
              onClick={() => goToPage(page - 1)}
            />
            <Button
              content="Next"
              icon="right arrow"
              labelPosition="right"
              disabled={page >= totalPages - 1}
              onClick={() => goToPage(page + 1)}
            />
          </GridColumn>
        </Grid>
      )}
    </div>
  );
};

export default ResultTable;
