import React, { useContext } from 'react';
import { Tab } from 'semantic-ui-react';
import Header from '../../components/header/Header';
import Layout from '../../components/Layout';
import DecisionTreeSummary from './dt-manager/DecisionTreeSummary';
import { useParams } from 'react-router-dom';
import DecisionPackageSummary from './dt-manager/DecisionPackageSummary';
import { AppContext } from '../../AppContext';

const RuleManagement = () => {
  //Get url parameter
  const { productId } = useParams();
  const [{ productApis }] = useContext(AppContext);

  let pageTitle = '';

  if (productApis.length) {
    const productDetail = productApis.find(
      api => api.id.toString() === productId
    );
    pageTitle = productDetail.displayName;
  }

  const panes = [
    {
      menuItem: 'Decision Graph',
      render: () => (
        <Tab.Pane className={'tab-list-add-upload'}>
          <DecisionTreeSummary productId={productId} />
        </Tab.Pane>
      )
    },
    {
      menuItem: 'Decision Package',
      render: () => (
        <Tab.Pane className={'tab-list-add-upload'}>
          <DecisionPackageSummary productId={productId} />
        </Tab.Pane>
      )
    }
  ];
  return (
    <Layout>
      <Header
        className="header-paylater-reg"
        title={pageTitle}
        groupTitle="Rules Management"
      />
      <Tab className="tab-paylater-reg" panes={panes} />
    </Layout>
  );
};

export default RuleManagement;
