import { Dispatch } from 'react';
import get from 'lodash/get';
import { PaginationType } from '../../constants/investigationToolsConstant';
import {
  getPagination,
  getMerchantData
} from '../../reducers/investigation-tools/selectors';
import { setTotalNumberOfPages, goToAPage } from './paginationAction';
import { searchMerchantData } from './serviceAction';
import { setIsLoading, setEntries, Entry } from './commonAction';
import { showErrorToast } from '../../utils/common';

type Spec = {
  clientInterface: string;
  data: {
    currentPageNo: number;
    searchAttributes: {
      searchType: string;
      searchValue: string;
    };
  };
};

type Result = {
  data: {
    merchantDataList: Entry[];
    pageCount: number;
    currentPageNo: number;
  };
};

export const fetchMerchantData = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
  getState: Function
) => {
  const state = getState();

  const pagination = getPagination(
    state.fraud,
    PaginationType.MERCHANT_DATA_TABLE
  );
  const table = getMerchantData(state);

  const spec: Spec = {
    clientInterface: '',
    data: {
      currentPageNo: get(pagination, 'currentPage', 1),
      searchAttributes: {
        searchType: get(table, 'searchType', null),
        searchValue: get(table, 'keyword', null)
      }
    }
  };

  dispatch(setIsLoading(true));

  return searchMerchantData(spec)
    .then((result: Partial<Result>) => {
      if (result && result.data) {
        dispatch(setEntries(result.data.merchantDataList));

        dispatch(
          setTotalNumberOfPages(
            PaginationType.MERCHANT_DATA_TABLE,
            result.data.pageCount
          )
        );

        dispatch(
          goToAPage(
            PaginationType.MERCHANT_DATA_TABLE,
            result.data.currentPageNo
          )
        );
      } else {
        dispatch(setTotalNumberOfPages(PaginationType.MERCHANT_DATA_TABLE, 1));
        dispatch(goToAPage(PaginationType.MERCHANT_DATA_TABLE, 1));
      }

      dispatch(setIsLoading(false));
    })
    .catch((err: Error) => {
      showErrorToast(err);
      dispatch(setTotalNumberOfPages(PaginationType.MERCHANT_DATA_TABLE, 1));
      dispatch(goToAPage(PaginationType.MERCHANT_DATA_TABLE, 1));
      dispatch(setIsLoading(false));
    });
};
