import get from 'lodash/get';
import { PaymentDataSearchType } from '../../constants/investigationToolsConstant';
import { actionHandler } from './commonReducerLogic';

type Entry = {
  [key: string]: any;
};

type PaymentDataState = {
  isLoading: boolean;
  keyword: string;
  searchType: string;
  entries: Entry[];
};

export const initialState: PaymentDataState = {
  isLoading: false,
  keyword: '',
  searchType: get(PaymentDataSearchType, 0, null),
  entries: []
};

export function PaymentDataReducer(
  state = initialState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any
) {
  return actionHandler[action.type]
    ? actionHandler[action.type](state, action)
    : state;
}
