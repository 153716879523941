import React from 'react';
import { Header as StyledHeader, Divider, Icon } from 'semantic-ui-react';
import style from './Header.style';

const Header = props => {
  return (
    <div className={style + ' header ' + props.groupTitle + props.title}>
      <StyledHeader as="h2">
        <StyledHeader.Content>{props.groupTitle}</StyledHeader.Content>
        <StyledHeader.Subheader>
          <h3>
            {props.title}{' '}
            {props.wikiLink ? (
              <a
                href={props.wikiLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name="question circle outline" />
              </a>
            ) : null}
          </h3>
        </StyledHeader.Subheader>
      </StyledHeader>
      <Divider />
    </div>
  );
};

/*
It's based on group menu and menu level, set default to empty strng if there is sub menu.
*/
Header.defaultProps = {
  groupTitle: '',
  wikiLink: ''
};

export default Header;
