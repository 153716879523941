import React from 'react';
import isFunction from 'lodash/isFunction';
import { Card, Form, Select, Button, Input } from 'semantic-ui-react';
import './SummaryCard.style.css';

type PropTypes = {
  labels?: any;
  useContext: () => any;
  useSelector: (state: any) => any;
  searchTypeConstants: string[];
  setKeyword: (value: string) => any;
  setSearchType: (value: string) => any;
  children?: React.ReactNode;
};

const SummaryCard = ({
  labels = {},
  useContext,
  useSelector,
  searchTypeConstants,
  setKeyword,
  setSearchType,
  children = null
}: PropTypes) => {
  const { getState, dispatch, handleSearch } = useContext();

  const state = getState();
  const { isLoading, keyword, searchType } = useSelector(state);

  const handleSelectChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: any
  ) => {
    dispatch(setSearchType(data.value));
  };

  const handleInputChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: any
  ) => {
    dispatch(setKeyword(data.value));
  };

  return (
    <Card className="common-summary-card">
      <Card.Content className="common-summary-card-content">
        <Form>
          <Form.Field>
            <label>Search Type</label>
            <Select
              className="common-summary-card-select-search-type"
              options={searchTypeConstants.map((type: string) => {
                return {
                  key: type,
                  value: type,
                  text: labels[type] || type
                };
              })}
              value={searchType}
              onChange={handleSelectChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Keyword</label>
            <Input
              className="common-summary-card-input-keyword"
              onChange={handleInputChange}
              value={keyword || ''}
            />
          </Form.Field>
          {children}
          <Form.Field>
            <Button
              className="common-summary-card-button-search"
              onClick={() => isFunction(handleSearch) && handleSearch(true)}
              disabled={
                isLoading ||
                !searchType ||
                typeof keyword !== 'string' ||
                !keyword.trim()
              }
            >
              Search
            </Button>
          </Form.Field>
        </Form>
      </Card.Content>
    </Card>
  );
};

export default SummaryCard;
