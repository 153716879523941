import React, { ReactNode } from 'react';
import { dtTooltipStyle } from '../../views/decision-tree/DecisionTree.style';

type Props = {
  x: number;
  y: number;
  show: boolean;
  children: ReactNode;
};

const Tooltip = ({ x, y, show, children }: Props) => {
  return (
    <div
      className={dtTooltipStyle}
      style={{
        display: show ? 'block' : 'none',
        transform: `translate(${x}px, ${y}px)`
      }}
    >
      {children}
    </div>
  );
};

export default Tooltip;
