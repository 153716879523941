import { Dispatch } from 'react';
import get from 'lodash/get';
import { PaginationType } from '../../constants/investigationToolsConstant';
import {
  getPagination,
  getDriverProfileContextSearch
} from '../../reducers/investigation-tools/selectors';
import { setTotalNumberOfPages, goToAPage } from './paginationAction';
import { searchDriverProfileContextSearch } from './serviceAction';
import { setIsLoading, setIsLoaded, setEntries } from './commonAction';
import { showErrorToast } from '../../utils/common';

type Entry = {
  [key: string]: string | number | null | Entry;
};

type Spec = {
  clientInterface: string;
  data: {
    currentPageNo: number;
    searchAttributes: {
      searchType: string;
      searchValue: string;
      filterAttributes: {
        [key: string]: string;
      };
    };
  };
};

type Result = {
  data: {
    driverProfileContextList: Entry[];
    pageCount: number;
    currentPageNo: number;
  };
};

export const fetchDriverProfileContextSearch = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
  getState: Function
) => {
  const state = getState();

  const pagination = getPagination(
    state.fraud,
    PaginationType.DRIVER_PROFILE_CONTEXT_SEARCH_TABLE
  );
  const table = getDriverProfileContextSearch(state);

  const spec: Spec = {
    clientInterface: '',
    data: {
      currentPageNo: get(pagination, 'currentPage', 1),
      searchAttributes: {
        searchType: get(table, 'searchType', null),
        searchValue: get(table, 'keyword', null),
        filterAttributes: get(table, 'filterAttributes', null)
      }
    }
  };

  dispatch(setIsLoaded(false));
  dispatch(setIsLoading(true));

  return searchDriverProfileContextSearch(spec)
    .then((result: Partial<Result>) => {
      if (result && result.data) {
        dispatch(setEntries(result.data.driverProfileContextList));

        dispatch(
          setTotalNumberOfPages(
            PaginationType.DRIVER_PROFILE_CONTEXT_SEARCH_TABLE,
            result.data.pageCount
          )
        );

        dispatch(
          goToAPage(
            PaginationType.DRIVER_PROFILE_CONTEXT_SEARCH_TABLE,
            result.data.currentPageNo
          )
        );
      } else {
        dispatch(
          setTotalNumberOfPages(
            PaginationType.DRIVER_PROFILE_CONTEXT_SEARCH_TABLE,
            1
          )
        );
        dispatch(
          goToAPage(PaginationType.DRIVER_PROFILE_CONTEXT_SEARCH_TABLE, 1)
        );
      }

      dispatch(setIsLoading(false));
      dispatch(setIsLoaded(true));
    })
    .catch((err: Error) => {
      showErrorToast(err);
      dispatch(
        setTotalNumberOfPages(
          PaginationType.DRIVER_PROFILE_CONTEXT_SEARCH_TABLE,
          1
        )
      );
      dispatch(
        goToAPage(PaginationType.DRIVER_PROFILE_CONTEXT_SEARCH_TABLE, 1)
      );
      dispatch(setIsLoading(false));
      dispatch(setIsLoaded(true));
    });
};
