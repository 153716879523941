// Case Search Result
export const PAGE_SIZE = 200;
export const RESULT_DATE_FORMAT = 'MMM dd yyyy hh:mm:ss a';

// Case Mark Status Multiple
export const BATCH_SIZE = 20;
export const UNEXPECTED_ERROR_TEXT = 'UNEXPECTED_ERROR - Please retry';
export const ABORTED_TEXT = 'ABORTED - Please retry';

// Tab labels
export const MULTIPLE_CRITERIA_TAB_LABEL = 'Multiple Criteria';
export const FIELD_AND_VALUE_TAB_LABEL = 'Field and Value';

// Action Button labels
export const MARK_SUSPECT_BTN = 'Mark as Suspect';
export const MARK_TRUSTED_BTN = 'Mark as Trusted';
export const MARK_NEUTRAL_BTN = 'Mark as Neutral';
export const REMEDIATION_DEACTIVATE_BTN = 'Account Deactivation';
export const REMEDIATION_RESET_PASSWORD_BTN = 'Reset Password';
export const REMEDIATION_FORCE_LOGOUT_BTN = 'Force Logout';
export const ADD_TO_REVIEW_BTN = 'Add to Review';

// Filter Form labels
export const SEARCH_PARAMS_ACCORDION_LABEL = 'Search Parameters';
export const CATEGORY_FILTER_LABEL = 'Category / API';
export const FINAL_RECOMMENDATION_FILTER_LABEL = 'Final Recommendation';
export const MARKING_STATUS_FILTER_LABEL = 'Marking Status';
export const DATE_TYPE_FILTER_LABEL = 'Date';
export const START_DATE_FILTER_LABEL = 'Start Date';
export const END_DATE_FILTER_LABEL = 'End Date';
export const FIELD_FILTER_LABEL = 'Field';
export const REFERENCE_TYPE_FILTER_LABEL = 'Reference Type';
export const VALUE_FILTER_LABEL = 'Value';
export const REFERENCE_ID_FILTER_LABEL = 'Reference ID';
export const REFERENCE_TYPE_FIELD = 'REFERENCE_TYPE';

// Date Input
export const DATEPICKER_DATE_FORMAT = 'MMMM d, yyyy HH:mm';
export const DATEPICKER_TIME_FORMAT = 'HH:mm';

// Case Summary
export const SUMMARY_CASE_ID_LABEL = 'Case ID';
export const SUMMARY_API_NAME_LABEL = 'API Name';
export const SUMMARY_REQUEST_DATE_LABEL = 'Request Date';
export const SUMMARY_REFERENCE_TYPE_LABEL = 'Reference Type';
export const SUMMARY_REFERENCE_ID_LABEL = 'Reference ID';
export const SUMMARY_PROFILE_ID_LABEL = 'Profile ID';
export const SUMMARY_EMAIL_ADDRESS_LABEL = 'Email Address';
export const SUMMARY_BOOKING_EMAIL_ADDRESS_LABEL = 'Booking Email Address';
export const SUMMARY_PHONE_NUMBER_LABEL = 'Phone Number';
export const SUMMARY_BOOKING_PHONE_NUMBER_LABEL = 'Booking Phone Number';
export const SUMMARY_DEVICE_ID_LABEL = 'Device ID';
export const SUMMARY_IP_ADDRESS_LABEL = 'IP Address';
export const SUMMARY_IP_COUNTRY_LABEL = 'IP Country';
export const SUMMARY_BANK_ACCOUNT_DETAILS_LABEL = 'Bank Account Details';
export const SUMMARY_RECOMMENDATION_LABEL = 'Recommendation';
export const SUMMARY_MARKING_STATUS_LABEL = 'Marking Status';
export const SUMMARY_REQUEST_DATE_FORMAT = 'yyyy-MM-dd HH:mm:ss xxx';
export const SUMMARY_MASKED_CARD_NUMBER_LABEL = 'Masked Card Number';
export const SUMMARY_CARD_COUNTRY_ID_LABEL = 'Card Country ID';
export const SUMMARY_CURRENCY_LABEL = 'Currency';
export const SUMMARY_TRANSACTION_AMOUNT_LABEL = 'Transaction Amount';

// Case Remediation
export const WL_PAYMENT_METHOD_LABEL = 'Payment Method';
export const WL_PAYMENT_DETAILS_LABEL = 'Payment Details';

// Case Marking Tools
export const MARKING_STATUS_SELECT_PLACEHOLDER = 'Select marking status';

// Case Notes
export const NOTES_DATE_FORMAT = 'MMM dd yyyy hh:mm:ss a';

// Action History
export const ACTION_HISTORY_DATE_FORMAT = 'MMM dd yyyy hh:mm:ss a';

// Other
export const NOTES_MAX_LENGTH = 512;
export const NOTES_DISPLAY_LINE = 5;

// Wikilink
export const CASE_DETAILS_WIKILINK =
  'https://29022131.atlassian.net/wiki/spaces/FP/pages/1452542312/Case+Detail';
export const MULTIPLE_CRITERIA_WIKILINK =
  'https://29022131.atlassian.net/wiki/spaces/FP/pages/1452542287/Search+Cases+by+Multiple+Criteria';
export const FIELD_AND_VALUE_WIKILINK =
  'https://29022131.atlassian.net/wiki/spaces/FP/pages/1452575309/Search+Cases+by+Field+Value';
export const CASE_QUEUE_WIKILINK =
  'https://29022131.atlassian.net/wiki/spaces/FP/pages/1728254124/Case+Queue';

// TableSearch Placeholder
export const TABLESEARCH_EXACT_MATCH_PLACEHOLDER = 'Exact match';
export const TABLESEARCH_START_WITH_PLACEHOLDER = 'Start with';
export const TABLESEARCH_CONTAINS_PLACEHOLDER = 'Contains';
export const TABLESEARCH_DEFAULT_PLACEHOLDER = 'Search';
