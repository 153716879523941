import { ActivitySearchType } from '../../constants/investigationToolsConstant';
import get from 'lodash/get';
import { multiQueryActionHandler } from './commonReducerLogic';

type Entry = {
  [key: string]: any;
};

type ActivityState = {
  isLoading: boolean;
  entries: Entry[];
  queryList: [
    {
      searchType: string;
      searchValue: string;
      conditional: string;
    }
  ];
};

export const initialState: ActivityState = {
  isLoading: false,
  entries: [],
  queryList: [
    {
      searchType: get(ActivitySearchType, 0, null),
      searchValue: '',
      conditional: null
    }
  ]
};

export default function ActivityReducer(
  state = initialState,
  action: Record<string, any> = {}
) {
  return multiQueryActionHandler[action.type]
    ? multiQueryActionHandler[action.type](state, action)
    : state;
}
