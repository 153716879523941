import React from 'react';
import { Card, Table } from 'semantic-ui-react';
import { ActionHistoryData } from '../../../types/caseManagement';
import { format, parseISO } from 'date-fns';
import { ACTION_HISTORY_DATE_FORMAT } from '../../../constants/caseManagement';
import { css } from 'emotion';

type Props = {
  data: ActionHistoryData[];
};

const detailText = (text: string) => {
  const splitted = text.split(':');

  if (splitted.length <= 1) return text;

  const label = splitted[0];
  const content = splitted.slice(1).join(':');

  return (
    <React.Fragment>
      <b>{label}:</b>
      {content}
    </React.Fragment>
  );
};

const actionHistoryStyle = css`
  & {
    max-height: 800px;
    overflow: auto;
  }

  & table {
    table-layout: fixed;
  }

  & table tr td {
    word-wrap: break-word;
  }
`;

const CaseActionHistory = ({ data }: Props) => {
  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>Action History</Card.Header>
      </Card.Content>
      <Card.Content>
        <div className={actionHistoryStyle}>
          <Table celled striped className="case-notes-table">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{ width: '200px' }}>
                  Action
                </Table.HeaderCell>
                <Table.HeaderCell style={{ width: '100px' }}>
                  Status
                </Table.HeaderCell>
                <Table.HeaderCell style={{ width: '400px' }}>
                  Details
                </Table.HeaderCell>
                <Table.HeaderCell style={{ width: '200px' }}>
                  Date
                </Table.HeaderCell>
                <Table.HeaderCell style={{ width: '180px' }}>
                  User
                </Table.HeaderCell>
                <Table.HeaderCell style={{ width: '250px' }}>
                  Notes
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {data.length > 0 ? (
                data.map((row, i) => (
                  <Table.Row key={`case-action-history-${i}`}>
                    <Table.Cell>{row.action}</Table.Cell>
                    <Table.Cell>{row.status}</Table.Cell>
                    <Table.Cell>
                      {row.details.split('\n').map((line, idx) => (
                        <span key={idx}>
                          {detailText(line)}
                          <br />
                        </span>
                      ))}
                    </Table.Cell>
                    <Table.Cell>
                      {format(
                        parseISO(row.createdAt),
                        ACTION_HISTORY_DATE_FORMAT
                      )}
                    </Table.Cell>
                    <Table.Cell>{row.createdBy}</Table.Cell>
                    <Table.Cell>
                      {row.notes.split('\n').map((line, idx) => (
                        <span key={idx}>
                          {line}
                          <br />
                        </span>
                      ))}
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row textAlign="center">
                  <Table.Cell colSpan={6}>No Data</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </div>
      </Card.Content>
    </Card>
  );
};

export default CaseActionHistory;
