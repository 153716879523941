import React, { useState } from 'react';
import { Card, Form, Select, Button } from 'semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize';
import { CaaseMarkingToolsSubmitParams } from '../../../types/caseManagement';
import { toast } from 'react-toastify';
import {
  MARKING_STATUS_SELECT_PLACEHOLDER,
  NOTES_MAX_LENGTH,
  NOTES_DISPLAY_LINE
} from '../../../constants/caseManagement';
import { css } from 'emotion';

type Props = {
  options: {
    name: string;
    displayName: string;
  }[];
  disabled?: boolean;
  onSubmit?: (params: CaaseMarkingToolsSubmitParams) => void | Promise<void>;
};

const actionButtonsStyle = css`
  & {
    float: right;
    margin-top: 2em;
  }
`;

const sectionTitleStyle = css`
  & {
    font-size: 1.1em;
    font-weight: bold;
  }
`;

const caseMarkingStyle = css`
  & {
    padding-bottom: 0.8em;
    border-bottom: 2px solid #e2e8f0;
  }

  & .dropdown {
    margin-top: 0.5em;
  }
`;

const markingDetailsStyle = css`
  & {
    margin-top: 1.2em;
  }
`;
const markingNotesStyle = css`
  & {
    margin: 1em 0 0 0;
  }
`;

const CaseMarkingTools = ({ options, disabled = false, onSubmit }: Props) => {
  const markingStatusOptions = options.map(option => ({
    key: option.name,
    value: option.name,
    text: `Mark as ${option.displayName}`
  }));

  const [markingStatus, setMarkingStatus] = useState('');
  const [note, setNote] = useState('');

  const handleReset = () => {
    setMarkingStatus('');
    setNote('');
  };

  const handleSubmit = async () => {
    const errs: string[] = [];

    if (!markingStatus) errs.push('Marking Status is required.');
    if (!note.trim()) errs.push('Notes is required.');

    if (errs.length > 0) {
      toast.error(
        <ul>
          {errs.map((err, i) => (
            <li key={`err-${i}`}>{err}</li>
          ))}
        </ul>
      );

      return;
    }

    await onSubmit?.({
      markingStatus,
      reason: note
    });

    handleReset();
  };

  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>Marking Tools</Card.Header>
      </Card.Content>
      <Card.Content>
        <div className={caseMarkingStyle}>
          <div className={sectionTitleStyle}>Case Marking</div>

          <Select
            disabled={disabled}
            fluid
            placeholder={MARKING_STATUS_SELECT_PLACEHOLDER}
            options={markingStatusOptions}
            value={markingStatus}
            onChange={(evt, data) => setMarkingStatus(data.value as string)}
          />
        </div>

        <div className={markingDetailsStyle}>
          <div className={sectionTitleStyle}>Marking Details</div>

          <div className={markingNotesStyle}>
            <label>
              Marking Notes*
              <Form>
                <TextareaAutosize
                  disabled={disabled}
                  className="no-resize"
                  rows={1}
                  value={note}
                  maxLength={NOTES_MAX_LENGTH}
                  maxRows={NOTES_DISPLAY_LINE}
                  onChange={evt => setNote(evt.currentTarget.value)}
                />
              </Form>
            </label>
          </div>
        </div>

        <div className={actionButtonsStyle}>
          <Button content="Reset" disabled={disabled} onClick={handleReset} />
          <Button
            primary
            content="Submit"
            disabled={disabled}
            onClick={handleSubmit}
          />
        </div>
      </Card.Content>
    </Card>
  );
};

export default CaseMarkingTools;
