import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';
import Header from 'semantic-ui-react/dist/commonjs/elements/Header';
import _ from 'lodash';
import config from '../../configs';
import { toast } from 'react-toastify';
import Table from 'semantic-ui-react/dist/commonjs/collections/Table';
import { Icon, TableBody, TableCell, TableRow } from 'semantic-ui-react';
import { convertToLocalTime } from '../../utils/dateFormatter';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { css } from 'emotion';

const activeProperiesStyle = css`
  &.table tbody tr td {
    border: none;
  }
`;

const ActiveDecisionTree = props => {
  const [treeSummary, setTreeSummary] = useState({});
  const [redirect, setRedirect] = useState(false);

  const viewDTreeHandler = () => {
    if (!_.isEmpty(treeSummary)) {
      setRedirect(true);
    }
  };

  useEffect(() => {
    // API for Decision Tree Summary
    axios
      .get(config.rulesDecisionTreeSummary + props.productApiId, {
        params: {
          status: 'ACTIVE'
        }
      })
      .then(res => {
        if (
          res &&
          res.data &&
          res.data.content &&
          res.data.content.length === 1
        ) {
          setTreeSummary(res.data.content[0]);
        } else {
          setTreeSummary({});
        }
      })
      .catch(err => {
        toast.error(
          'Error fetching Decision Graph summary. Error: ' +
            (err && err.message)
        );
      });
  }, [props.productApiId]);

  if (redirect && !_.isEmpty(treeSummary)) {
    const url =
      '/decision-tree/edit/' + props.productApiId + '/' + treeSummary.id;
    return <Redirect to={url} />;
  }

  return (
    <Segment>
      <Header as="h3">Active Graph Details</Header>
      {_.isEmpty(treeSummary) ? (
        <span>No active graph found</span>
      ) : (
        <Table basic="very" className={activeProperiesStyle} columns={9}>
          <TableBody>
            <TableRow>
              <TableCell width={1}>Version</TableCell>
              <TableCell width={8}>
                <span>{treeSummary.version}</span>&nbsp;&nbsp;&nbsp;
                <Icon link size="large" name="eye" onClick={viewDTreeHandler} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width={1}>Name</TableCell>
              <TableCell width={8}>{treeSummary.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell width={1}>Description</TableCell>
              <TableCell width={8}>{treeSummary.description}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell width={1}>Activated At</TableCell>
              <TableCell width={8}>
                {convertToLocalTime(treeSummary.lastUpdatedAt)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width={1}>Activated By</TableCell>
              <TableCell width={8}>{treeSummary.lastUpdatedBy}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </Segment>
  );
};

ActiveDecisionTree.propTypes = {
  productApiId: PropTypes.string.isRequired
};

export default ActiveDecisionTree;
