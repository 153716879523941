import React, { useState } from 'react';
import { Card, Button } from 'semantic-ui-react';
import CaseBlacklist from './CaseBlacklist';
import {
  SummaryAPIResponse,
  CaseBlacklistState,
  CaseBlacklistSubmitParams,
  CaseRemediationSubmitParams,
  CaseWhitelistState,
  WhitelistAttributeCombination,
  CaseWhitelistSubmitParams,
  CaseProfileRemediationState,
  CaseAccountDeactivationSubmitParams,
  CaseProfileRemediationType,
  CaseResetPasswordSubmitParams
} from '../../../types/caseManagement';
import CaseWhitelist from './CaseWhitelist';
import CaseProfileRemediation from './CaseProfileRemediation';
import { toast } from 'react-toastify';
import { css } from 'emotion';

type Props = {
  data?: SummaryAPIResponse;
  blacklistTags: string[];
  whitelistTags: string[];
  whitelistAttributeCombinations: WhitelistAttributeCombination[];
  blacklistDisabled?: boolean;
  whitelistDisabled?: boolean;
  accountDeactivationDisabled?: boolean;
  resetPasswordDisabled?: boolean;
  onSubmit?: (params: CaseRemediationSubmitParams) => void | Promise<void>;
};

const actionButtonStyle = css`
  & {
    float: right;
    margin-top: 2em;
  }
`;

const sectionStyle = css`
  & {
    margin-top: 2em;
  }
`;

const CaseRemediation = ({
  data,
  blacklistTags,
  whitelistTags,
  whitelistAttributeCombinations,
  blacklistDisabled = false,
  whitelistDisabled = false,
  accountDeactivationDisabled = false,
  resetPasswordDisabled = false,
  onSubmit
}: Props) => {
  const allDisabled =
    blacklistDisabled &&
    whitelistDisabled &&
    accountDeactivationDisabled &&
    resetPasswordDisabled;

  const [whitelistState, setWhitelistState] = useState<CaseWhitelistState>({
    isActive: false,
    attributeCombinationId: undefined,
    isTagSelected: {},
    note: ''
  });

  const [blacklistState, setBlacklistState] = useState<CaseBlacklistState>({
    isActive: false,
    isFieldSelected: {},
    isTagSelected: {},
    note: ''
  });

  const [profileRemediationState, setProfileRemediationState] =
    useState<CaseProfileRemediationState>({
      isActive: false,
      note: '',
      type: undefined
    });

  const onWhitelistChange = (value: CaseWhitelistState) => {
    setWhitelistState(value);
  };

  const onBlacklistChange = (value: CaseBlacklistState) => {
    setBlacklistState(value);
  };

  const handleProfileRemediationChange = (
    value: CaseProfileRemediationState
  ) => {
    setProfileRemediationState(value);
  };

  const handleReset = () => {
    setWhitelistState({
      isActive: false,
      attributeCombinationId: 0,
      isTagSelected: {},
      note: ''
    });

    setBlacklistState({
      isActive: false,
      isFieldSelected: {},
      isTagSelected: {},
      note: ''
    });

    setProfileRemediationState({
      isActive: false,
      note: '',
      type: undefined
    });
  };

  const validate = (params: CaseRemediationSubmitParams) => {
    const messageList: string[] = [];

    if (params.whitelist) {
      if (params.whitelist.attributeCombinations.length <= 0) {
        messageList.push('Whitelist attribute is required.');
      }

      if (params.whitelist.tags.length <= 0) {
        messageList.push('Whitelist tags is required.');
      }

      if (params.whitelist.notes.trim() === '') {
        messageList.push('Whitelist notes is required.');
      }
    }

    if (params.blacklist) {
      if (params.blacklist.attributeTypes.length <= 0) {
        messageList.push('Blacklist attribute is required.');
      }

      if (params.blacklist.tags.length <= 0) {
        messageList.push('Blacklist tags is required.');
      }

      if (params.blacklist.notes.trim() === '') {
        messageList.push('Blacklist notes is required.');
      }
    }

    if (
      profileRemediationState.isActive &&
      !params.accountDeactivation &&
      !params.resetPassword
    ) {
      messageList.push('Profile remediation type is required.');
    }

    if (params.accountDeactivation) {
      if (params.accountDeactivation.reason.trim() === '') {
        messageList.push('Account Deactivation notes is required.');
      }
    }

    if (params.resetPassword) {
      if (params.resetPassword.reason.trim() === '') {
        messageList.push('Reset Password notes is required.');
      }
    }

    return messageList;
  };

  const showErrorToast = (messageList: string[]) => {
    toast.error(
      <ul>
        {messageList.map((message, i) => (
          <li key={`error-message-${i}`}>{message}</li>
        ))}
      </ul>
    );
  };

  const handleSubmit = async () => {
    const whitelistParams: CaseWhitelistSubmitParams | undefined =
      whitelistState.isActive
        ? {
            attributeCombinations:
              whitelistAttributeCombinations.find(
                wlac => wlac.id === whitelistState.attributeCombinationId
              )?.list || [],
            notes: whitelistState.note,
            tags: Object.keys(whitelistState.isTagSelected).filter(
              k => whitelistState.isTagSelected[k]
            )
          }
        : undefined;

    const caseDetailToBLAttributeMap: Record<string, string> = {
      profileId: 'PROFILE_ID',
      primaryEmail: 'PRIMARY_EMAIL',
      secondaryEmail: 'SECONDARY_EMAIL',
      primaryPhone: 'PRIMARY_PHONE',
      secondaryPhone: 'SECONDARY_PHONE',
      deviceId: 'DEVICE_ID',
      ipAddress: 'IP_ADDRESS',
      bankAccountDetails: 'BANK_ACCOUNT_DETAILS'
    };

    const blacklistParams: CaseBlacklistSubmitParams | undefined =
      blacklistState.isActive
        ? {
            attributeTypes: Object.keys(blacklistState.isFieldSelected)
              .filter(k => blacklistState.isFieldSelected[k])
              .map(k => caseDetailToBLAttributeMap[k]),
            notes: blacklistState.note,
            tags: Object.keys(blacklistState.isTagSelected).filter(
              k => blacklistState.isTagSelected[k]
            )
          }
        : undefined;

    const accountDeactivationParams:
      | CaseAccountDeactivationSubmitParams
      | undefined =
      profileRemediationState.isActive &&
      profileRemediationState.type ===
        CaseProfileRemediationType.ACCOUNT_DEACTIVATION
        ? {
            reason: profileRemediationState.note
          }
        : undefined;

    const resetPasswordParams: CaseResetPasswordSubmitParams | undefined =
      profileRemediationState.isActive &&
      profileRemediationState.type === CaseProfileRemediationType.RESET_PASSWORD
        ? {
            reason: profileRemediationState.note
          }
        : undefined;

    const submitParams: CaseRemediationSubmitParams = {
      whitelist: whitelistParams,
      blacklist: blacklistParams,
      accountDeactivation: accountDeactivationParams,
      resetPassword: resetPasswordParams
    };

    const errMessages = validate(submitParams);

    if (errMessages.length > 0) {
      showErrorToast(errMessages);
      return;
    }

    await onSubmit?.(submitParams);

    handleReset();
  };

  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>Remediation Tools</Card.Header>
      </Card.Content>
      <Card.Content>
        <div>
          <CaseWhitelist
            data={data}
            whitelistTags={whitelistTags}
            isActive={whitelistState.isActive}
            attributeCombinationId={whitelistState.attributeCombinationId}
            attributeCombinations={whitelistAttributeCombinations}
            isTagSelected={whitelistState.isTagSelected}
            note={whitelistState.note}
            disabled={whitelistDisabled}
            onChange={onWhitelistChange}
          />
        </div>

        <div className={sectionStyle}>
          <CaseBlacklist
            data={data}
            blacklistTags={blacklistTags}
            isActive={blacklistState.isActive}
            isFieldSelected={blacklistState.isFieldSelected}
            isTagSelected={blacklistState.isTagSelected}
            note={blacklistState.note}
            disabled={blacklistDisabled}
            onChange={onBlacklistChange}
          />
        </div>

        <div className={sectionStyle}>
          <CaseProfileRemediation
            profileId={data?.profileId}
            isActive={profileRemediationState.isActive}
            note={profileRemediationState.note}
            type={profileRemediationState.type}
            accountDeactivationDisabled={accountDeactivationDisabled}
            resetPasswordDisabled={resetPasswordDisabled}
            onChange={handleProfileRemediationChange}
          />
        </div>

        <div className={actionButtonStyle}>
          <Button
            content="Reset"
            onClick={handleReset}
            disabled={allDisabled}
          />
          <Button
            primary
            content="Submit"
            onClick={handleSubmit}
            disabled={
              !whitelistState.isActive &&
              !blacklistState.isActive &&
              !profileRemediationState.isActive
            }
          />
        </div>
      </Card.Content>
    </Card>
  );
};

export default CaseRemediation;
