import { Dispatch } from 'react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import * as activityActionType from '../../types/investigation-tools/activityActionType';
import { PaginationType } from '../../constants/investigationToolsConstant';
import {
  getPagination,
  getActivity
} from '../../reducers/investigation-tools/selectors';
import { searchActivity } from './serviceAction';
import { showErrorToast } from '../../utils/common';
import { setIsLoading, setEntries, Entry } from './commonAction';

type SearchAttribute = {
  searchType: string;
  searchValue: string;
  conditional: string;
};

type Spec = {
  clientInterface: string;
  data: {
    currentPageNo: number;
    searchAttributes: SearchAttribute[];
  };
};

type Result = {
  data: {
    activityList: Entry[];
    pageCount: number;
    currentPageNo: number;
  };
};

export const setKeyword = (index: string, value: string) => ({
  type: activityActionType.SET_KEYWORD,
  index,
  value
});

export const setSearchType = (index: string, value: string) => ({
  type: activityActionType.SET_SEARCH_TYPE,
  index,
  value
});

export const setConditional = (index: string, value: string) => ({
  type: activityActionType.SET_CONDITIONAL,
  index,
  value
});

export const addQuery = () => ({
  type: activityActionType.ADD_QUERY
});

export const deleteQuery = (index: string) => ({
  type: activityActionType.REMOVE_QUERY,
  index
});

export const fetchActivity = async (
  dispatch: Dispatch<any>,
  getState: Function
) => {
  const state = getState();

  const pagination = getPagination(state, PaginationType.ACTIVITY_TABLE);
  const table = getActivity(state);
  const queryList = get(table, 'queryList', null);

  const spec: Spec = {
    clientInterface: '',
    data: {
      currentPageNo: get(pagination, 'currentPage', 1),
      searchAttributes: isArray(queryList)
        ? queryList.map(query => ({
            searchType: get(query, 'searchType', null),
            searchValue: get(query, 'searchValue', null),
            conditional: get(query, 'conditional', null)
          }))
        : []
    }
  };

  dispatch(setIsLoading(true));

  return searchActivity(spec)
    .then((result: Partial<Result>) => {
      if (result && result.data) {
        dispatch(setEntries(result.data.activityList));
      }

      dispatch(setIsLoading(false));
      return result;
    })
    .catch((err: Error) => {
      showErrorToast(err);
      dispatch(setIsLoading(false));
      return null;
    });
};
