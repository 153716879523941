import isArray from 'lodash/isArray';

export const optionMapper = options =>
  isArray(options)
    ? options.map(option => ({
        key: option,
        text: option,
        value: option
      }))
    : [];
