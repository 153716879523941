import { INode } from 'react-digraph';

export type SaveTreeNode = {
  id: number;
  nodeType: string;
  data?: {
    name?: string;
    deciderType?: string;
    deciderName?: string;
    decidingVariable?: string;
    edges?: SaveTreeEdge[];
  };
};

export type SaveTreeEdge = {
  id: number;
  priority: number;
  isDefaultBranch: boolean;
  operator?: string;
  conditionValue?: EdgeConditionValue;
  targetNode?: SaveTreeNode;
};

export type SaveEditorNode = {
  id: number;
  x: number;
  y: number;
  content: string;
};

export type SaveEditorEdge = {
  id: number;
  source: number;
  target: number;
};

export type SaveEditorData = {
  nodes: SaveEditorNode[];
  edges: SaveEditorEdge[];
};

export type SaveGraphNode = {
  id: number;
  nodeType: string;
  data?: {
    name?: string;
    deciderType?: string;
    deciderName?: string;
    decidingVariable?: string;
    edges?: number[];
  };
};

export type SaveGraphEdge = {
  id: number;
  priority: number;
  isDefaultBranch: boolean;
  operator?: string;
  conditionValue?: EdgeConditionValue;
  sourceNode?: number;
  targetNode?: number;
};

export type SaveGraphData = {
  nodes: SaveGraphNode[];
  edges: SaveGraphEdge[];
  rootNode: number;
};

export type SaveGraphEditorData = {
  [id: number]: {
    x: number;
    y: number;
  };
};

export type SaveData = {
  graph?: SaveGraphData;
  graphEditorData?: SaveGraphEditorData;
  decisionTree: SaveTreeNode;
  editorData: SaveEditorData;
};

export type SavePayload = {
  decisionTree: {
    name: string;
    description: string;
    api: string;
    detectorCallsAsync: boolean;
    root?: SaveTreeNode;
    graph?: SaveGraphData;
  };
  editorData?: SaveEditorData;
  graphEditorData?: SaveGraphEditorData;
};

export type ExportPayload = {
  name: string;
  description: string;
  api: string;
  detectorCallsAsync: boolean;
  root?: SaveTreeNode;
  graph?: SaveGraphData;
  graphEditorData?: SaveGraphEditorData;
};

export type NodeData = {
  x: number;
  y: number;
  type: NodeTypeKey;
  decider?: Decider;
  decidingVariable?: DecidingVariable;
  decisionPackage?: DecisionPackage;
};

export type GCNode = NodeData & {
  id: number;
  subtype?: string;
  title: string;
};

export type EdgeConditionValue = string | number | string[] | number[];

export type EdgeData = {
  source: number;
  target: number;
  priority: number;
  operator?: Operator;
  isDefaultBranch: boolean;
  conditionValue: EdgeConditionValue;
};

export type GCEdge = EdgeData & {
  id: number;
  type: string;
  handleText: string;
};

export type GCData = {
  nodes: GCNode[];
  edges: GCEdge[];
  lastId: number;
};

export enum NodeTypeKey {
  EMPTY = 'EMPTY',
  NORMAL = 'NORMAL_LINKED_NODE',
  DECISION_PACKAGE = 'DECISION_PACKAGE'
}

export enum OperatorType {
  STRING = 'STRING',
  NUMERIC = 'NUMERIC',
  BOOLEAN = 'BOOLEAN'
}

export type NodeType = {
  typeText: string;
  shapeId: string;
  shape: React.ReactNode;
};

export type NodeTypes = Record<string, NodeType>;

export type EdgeType = {
  shapeId: string;
  shape: React.ReactNode;
};

export type EdgeTypes = Record<string, EdgeType>;

export type Decider = {
  id: number;
  displayName: string;
  name: string;
  type: string;
};

export type DecisionPackage = {
  id: number;
  name: string;
};

export type DecidingVariable = {
  id: number;
  description: string;
  name: string;
  operatorType: string;
};

export type Operator = {
  name: string;
  sign: string;
};

export type Operators = {
  strings: Operator[];
  numerics: Operator[];
  booleans: Operator[];
};

export type NodeFns = {
  getNodeTypeXlinkHref: (data: INode, nodeTypes: NodeTypes) => string | null;
  getNodeSubtypeXlinkHref: (
    data: INode,
    nodeSubtypes?: NodeTypes
  ) => string | null;
};

export type DTLog = {
  level: DTLogLevel;
  message: string;
};

export enum DTLogLevel {
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  WARN = 'WARN',
  ERROR = 'ERROR'
}
