import React, { useState, useEffect } from 'react';
import { Form, Modal, Button } from 'semantic-ui-react';
import { ExportPayload } from '../../types/decisionTree';

type Props = {
  open: boolean;
  onClose?: () => void;
  onImport?: (importedTreeData: ExportPayload) => void;
};

type InputError =
  | false
  | {
      content: string;
    };

const ImportDTModal = ({ open, onClose, onImport }: Props) => {
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [fileError, setFileError] = useState<InputError>(false);

  const handleFileChange = (file?: File) => {
    setFileError(false);
    setSelectedFile(file);
  };

  const error = (message: string) => {
    return {
      content: message
    };
  };

  const handleImport = () => {
    if (!selectedFile) {
      setFileError(error(`File cannot be empty`));

      return;
    }

    try {
      const fileReader = new FileReader();

      fileReader.addEventListener('loadend', evt => {
        const data = evt.target?.result as string;

        onImport?.(JSON.parse(data));
      });

      fileReader.readAsText(selectedFile as Blob);
    } catch (err) {
      setFileError(
        error(
          `Could not open the file with name ${selectedFile.name}. Please select a file with decision graph in JSON format`
        )
      );
    }
  };

  useEffect(() => {
    setSelectedFile(undefined);
    setFileError(false);
  }, [open]);

  return (
    <div>
      <Modal open={open} size="small">
        <Modal.Header>Import JSON file decision graph</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Input
              type="file"
              accept=".json"
              error={fileError}
              onChange={evt => handleFileChange(evt.target.files?.[0])}
              placeholder={'file'}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button icon="delete" content="Close" onClick={onClose} />
          <Button
            primary
            icon="download"
            content="Import"
            onClick={handleImport}
          />
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default ImportDTModal;
