import React from 'react';

import DecisionTreeList from '../../../components/decision-tree/DecisionTreeList';
import ActiveDecisionTree from '../../../components/decision-tree/ActiveDecisionTree';

const DecisionTreeSummary = props => {
  const { productId } = props;

  return (
    <div>
      <ActiveDecisionTree productApiId={productId} />
      <DecisionTreeList productApiId={productId} />
    </div>
  );
};

export default DecisionTreeSummary;
