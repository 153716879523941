import React, { useEffect, useReducer } from 'react';
import { Grid, Loader } from 'semantic-ui-react';
import get from 'lodash/get';

import {
  initialState,
  DriverDataReducer
} from '../../../reducers/investigation-tools/driverDataReducer';
import {
  initPagination,
  goToAPage,
  setTotalNumberOfPages
} from '../../../actions/investigation-tools/paginationAction';
import paginationReducer from '../../../reducers/investigation-tools/paginationReducer';
import { fetchDriverData } from '../../../actions/investigation-tools/driverDataAction';
import { PaginationType } from '../../../constants/investigationToolsConstant';

import DriverDataContext from './DriverDataContext';
import DriverDataHistoryTable from '../../../components/investigation-tools/driver-data/DriverDataHistoryTable';
import Layout from '../../../components/Layout';
import { useParams } from 'react-router-dom';
import InfoBox from '../../../components/investigation-tools/common/InfoBox';
import { driverDataLabels } from '../../../constants/investigationToolsLabels';

const DriverData = () => {
  const { id } = useParams();

  const [state, dispatch] = useReducer(DriverDataReducer, initialState);
  const [paginationState, paginationDispatch] = useReducer(
    paginationReducer,
    {}
  );

  const { isLoading, driverData, contactData, otherData } = state;

  useEffect(() => {
    initComponent();
    paginationDispatch(
      // @ts-ignore
      initPagination([PaginationType.DRIVER_DATA_TABLE])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCombinedState = () => ({
    driver_data: { ...state },
    pagination: { ...paginationState }
  });

  const initComponent = () => {
    fetchDriverData(dispatch, getCombinedState, id).then(result => {
      const pageCount = get(result, 'data.pageCount', null);
      const currentPageNo = get(result, 'data.currentPageNo', null);

      paginationDispatch(
        // @ts-ignore
        setTotalNumberOfPages(
          PaginationType.DRIVER_DATA_TABLE,
          isFinite(pageCount) ? pageCount : 1
        )
      );

      paginationDispatch(
        // @ts-ignore
        goToAPage(
          PaginationType.DRIVER_DATA_TABLE,
          isFinite(currentPageNo) ? currentPageNo : 1
        )
      );
    });
  };

  return (
    <DriverDataContext.Provider
      value={{
        getState: getCombinedState,
        dispatch,
        paginationDispatch
      }}
    >
      <Layout>
        <h1>Driver Profile Data</h1>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column width={10}>
              <InfoBox data={driverData} labels={driverDataLabels} />
              <InfoBox
                title={'Contact Data'}
                data={contactData}
                labels={driverDataLabels}
              />
              <InfoBox
                title={'Other Data'}
                data={otherData}
                labels={driverDataLabels}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Loader active={isLoading} />
        <div style={{ marginBottom: '20px' }} />
        {!isLoading && <DriverDataHistoryTable />}
      </Layout>
    </DriverDataContext.Provider>
  );
};

export default DriverData;
