import { Dispatch } from 'react';
import get from 'lodash/get';
import { PaginationType } from '../../constants/investigationToolsConstant';
import {
  getPagination,
  getProfileSummary
} from '../../reducers/investigation-tools/selectors';
import { setTotalNumberOfPages, goToAPage } from './paginationAction';
import { searchProfile } from './serviceAction';
import { setIsLoading, setIsLoaded, setEntries, Entry } from './commonAction';
import { showErrorToast } from '../../utils/common';

type Spec = {
  clientInterface: string;
  data: {
    currentPageNo: number;
    searchAttributes: {
      searchType: string;
      searchValue: string;
      filterAttributes: {
        [key: string]: string;
      };
    };
  };
};

type Result = {
  data: {
    profileSummaryList: Entry[];
    pageCount: number;
    currentPageNo: number;
  };
};

export const fetchProfileSummary = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
  getState: Function
) => {
  const state = getState();

  const pagination = getPagination(
    state.fraud,
    PaginationType.PROFILE_SUMMARY_TABLE
  );
  const table = getProfileSummary(state);

  const spec: Spec = {
    clientInterface: '',
    data: {
      currentPageNo: get(pagination, 'currentPage', 1),
      searchAttributes: {
        searchType: get(table, 'searchType', null),
        searchValue: get(table, 'keyword', null),
        filterAttributes: get(table, 'filterAttributes', null)
      }
    }
  };

  dispatch(setIsLoaded(false));
  dispatch(setIsLoading(true));

  return searchProfile(spec)
    .then((result: Partial<Result>) => {
      if (result && result.data) {
        dispatch(setEntries(result.data.profileSummaryList));

        dispatch(
          setTotalNumberOfPages(
            PaginationType.PROFILE_SUMMARY_TABLE,
            result.data.pageCount
          )
        );

        dispatch(
          goToAPage(
            PaginationType.PROFILE_SUMMARY_TABLE,
            result.data.currentPageNo
          )
        );
      } else {
        dispatch(
          setTotalNumberOfPages(PaginationType.PROFILE_SUMMARY_TABLE, 1)
        );
        dispatch(goToAPage(PaginationType.PROFILE_SUMMARY_TABLE, 1));
      }

      dispatch(setIsLoading(false));
      dispatch(setIsLoaded(true));
    })
    .catch((err: Error) => {
      showErrorToast(err);
      dispatch(setTotalNumberOfPages(PaginationType.PROFILE_SUMMARY_TABLE, 1));
      dispatch(goToAPage(PaginationType.PROFILE_SUMMARY_TABLE, 1));
      dispatch(setIsLoading(false));
      dispatch(setIsLoaded(true));
    });
};
