import React, { useState, useEffect } from 'react';
import {
  Modal,
  Form,
  Button,
  InputProps,
  DropdownProps
} from 'semantic-ui-react';
import {
  Operators,
  OperatorType,
  EdgeConditionValue,
  Operator
} from '../../types/decisionTree';
import {
  isUnaryOperator,
  isMultiValueOperator
} from '../../utils/decision-tree/operatorUtils';

export type EdgeModalSaveParams = {
  priority: number;
  isDefaultBranch: boolean;
  operator?: Operator;
  conditionValue: EdgeConditionValue;
};

type Props = {
  open: boolean;
  operators?: Operators;
  operatorType?: OperatorType;
  priorityText: string;
  isDefaultBranch: boolean;
  operatorName: string;
  conditionValueText: string;
  onChange?: (data: {
    priorityText: string;
    isDefaultBranch: boolean;
    operatorName: string;
    conditionValueText: string;
  }) => void;
  onClose?: () => void;
  onSave?: (params: EdgeModalSaveParams) => void;
};

type Option = {
  key: string;
  text: string;
  value: string;
};

type InputError =
  | false
  | {
      content: string;
    };

const operatorsKeys: Record<OperatorType, keyof Operators> = {
  [OperatorType.STRING]: 'strings',
  [OperatorType.NUMERIC]: 'numerics',
  [OperatorType.BOOLEAN]: 'booleans'
};

const getFilteredOperators = (
  operators?: Operators,
  operatorType?: OperatorType
) => {
  if (!operators || !operatorType) return [];

  return operators[operatorsKeys[operatorType]];
};

const getOperator = (
  operatorName: string,
  operators?: Operators,
  operatorType?: OperatorType
) => {
  const filteredOperators = getFilteredOperators(operators, operatorType);

  return filteredOperators.find(op => op.name === operatorName);
};

const EdgeEditModal = ({
  open,
  operators,
  operatorType,
  priorityText,
  isDefaultBranch,
  operatorName,
  conditionValueText,
  onChange,
  onClose,
  onSave
}: Props) => {
  const [operatorOptions, setOperatorOptions] = useState<Option[]>([]);
  const [priorityError, setPriorityError] = useState<InputError>(false);
  const [operatorError, setOperatorError] = useState<InputError>(false);
  const [conditionValueError, setConditionValueError] =
    useState<InputError>(false);

  const inputToConditionValue = () => {
    // For multi value operators, convert input value to a list
    if (isMultiValueOperator(operatorName)) {
      if (operatorType === OperatorType.STRING) {
        return conditionValueText
          .split(',')
          .map(it => it.trim())
          .filter(it => it);
      } else {
        return conditionValueText
          .split(',')
          .filter(it => it)
          .map(parseFloat);
      }
    }

    return operatorType === OperatorType.NUMERIC
      ? parseFloat(conditionValueText)
      : conditionValueText;
  };

  const handlePriorityChange = (
    evt: React.FormEvent,
    { value }: InputProps
  ) => {
    setPriorityError(false);

    onChange?.({
      priorityText: value,
      isDefaultBranch,
      operatorName,
      conditionValueText
    });
  };

  const handleIsDefaultBranchChange = () => {
    if (!isDefaultBranch) {
      setOperatorError(false);
      setConditionValueError(false);
    }

    onChange?.({
      priorityText: priorityText,
      isDefaultBranch: !isDefaultBranch,
      operatorName,
      conditionValueText
    });
  };

  const handleOperatorNameChange = (
    evt: React.FormEvent,
    { value }: DropdownProps
  ) => {
    setOperatorError(false);

    onChange?.({
      priorityText: priorityText,
      isDefaultBranch,
      operatorName: value as string,
      conditionValueText
    });
  };

  const handleConditionValueTextChange = (
    evt: React.FormEvent,
    { value }: InputProps
  ) => {
    setConditionValueError(false);

    onChange?.({
      priorityText: priorityText,
      isDefaultBranch,
      operatorName,
      conditionValueText: value
    });
  };

  const error = (message: string) => {
    return {
      content: message
    };
  };

  const handleSave = () => {
    const priority = Number(priorityText);
    const conditionValue = inputToConditionValue();

    if (!Number.isInteger(priority) || priority <= 0) {
      setPriorityError(error('Priority must be a positive integer'));
      return;
    }

    if (!isDefaultBranch) {
      if (!operatorName) {
        setOperatorError(error('Operator cannot be empty'));
        return;
      }

      if (!isUnaryOperator(operatorName) && !conditionValueText) {
        setConditionValueError(error('Condition Value cannot be empty'));
        return;
      }

      if (isMultiValueOperator(operatorName)) {
        if (!Array.isArray(conditionValue)) {
          setConditionValueError(
            error(`Operator ${operatorName} requires a list of values`)
          );
          return;
        }

        if (
          operatorType === OperatorType.NUMERIC &&
          conditionValue.findIndex(
            (it: string | number) => !isFinite(Number(it))
          ) !== -1
        ) {
          setConditionValueError(
            error(
              `Invalid values for numeric multivalue operator ${operatorName}`
            )
          );
          return;
        }
      } else {
        if (
          operatorType === OperatorType.NUMERIC &&
          !isFinite(Number(conditionValue))
        ) {
          setConditionValueError(
            error(`Invalid values for numeric operator ${operatorName}`)
          );
          return;
        }
      }
    }

    onSave?.({
      priority,
      isDefaultBranch,
      operator: isDefaultBranch
        ? undefined
        : getOperator(operatorName, operators, operatorType),
      conditionValue:
        isDefaultBranch || isUnaryOperator(operatorName) ? '' : conditionValue
    });
  };

  useEffect(() => {
    const filteredOperators = getFilteredOperators(operators, operatorType);

    setOperatorOptions(
      filteredOperators.map(op => ({
        key: op.name,
        value: op.name,
        text: op.sign
      }))
    );
  }, [operators, operatorType]);

  useEffect(() => {
    setPriorityError(false);
    setOperatorError(false);
    setConditionValueError(false);
  }, [open]);

  return (
    <Modal open={open} size="small">
      <Modal.Header>Create / Edit Edge</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group widths={3}>
            <Form.Field label={'Priority'} required />
            <Form.Input
              type={'number'}
              value={priorityText}
              onChange={handlePriorityChange}
              error={priorityError}
              placeholder={'Priority'}
              width={8}
            />
          </Form.Group>
          <br />
          <Form.Group widths={3}>
            <Form.Field label={'Default Branch'} />
            <Form.Checkbox
              checked={isDefaultBranch}
              onChange={handleIsDefaultBranchChange}
              width={8}
            />
          </Form.Group>
          {!isDefaultBranch && (
            <Form.Group widths={3}>
              <br />
              <Form.Field label={'Operator'} required />
              <Form.Select
                search
                placeholder={'Select Operator'}
                value={operatorName}
                error={operatorError}
                onChange={handleOperatorNameChange}
                options={operatorOptions}
                width={8}
              />
            </Form.Group>
          )}
          {!isDefaultBranch && !isUnaryOperator(operatorName) && (
            <Form.Group widths={3}>
              <Form.Field label={'Condition Value'} required />
              <Form.Input
                value={conditionValueText}
                error={conditionValueError}
                onChange={handleConditionValueTextChange}
                placeholder={'ConditionValue'}
                width={8}
              />
            </Form.Group>
          )}
          {!isDefaultBranch && isMultiValueOperator(operatorName) && (
            <Form.Group widths={3}>
              <Form.Field label={'Condition Value as list'} />
              <Form.Field width={8} className={'text-wrap'}>
                {JSON.stringify(inputToConditionValue())}
              </Form.Field>
            </Form.Group>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button icon="delete" content="Close" onClick={onClose} />
        <Button primary icon="add" content="Save" onClick={handleSave} />
      </Modal.Actions>
    </Modal>
  );
};

export default EdgeEditModal;
