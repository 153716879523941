import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { Table, Input } from 'semantic-ui-react';
import PaginationContainer from '../common/PaginationContainer';
import { setFilterAttribute } from '../../../actions/investigation-tools/commonAction';
import { DriverProfileContextFilterAttributes } from '../../../constants/investigationToolsConstant';
import { getDriverProfileContextSearch } from '../../../reducers/investigation-tools/selectors';
import { useDriverProfileContextSearchContext } from '../../../views/investigation-tools/driver-profile-context-search/DriverProfileContextSearchContext';
import { Link } from 'react-router-dom';

type Entry = {
  driverId: string;
  driverName: string;
  mainPhoneNumber: string;
  emergencyPhoneNumber: string;
  emailAddress: string;
  domicileCity: string;
  accountStatus: string;
  isSuspended: boolean;
};

const DriverProfileContextSearchTableContainer = () => {
  const { getState, dispatch, handleSearch } =
    useDriverProfileContextSearchContext();
  const [filters, setFilters] = useState({});

  const state = getState();
  const { entries, filterAttributes } = getDriverProfileContextSearch(state);

  useEffect(() => {
    setFilters({ ...filterAttributes });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterChange = attribute => (event, data) => {
    setFilters({ ...filterAttributes, [attribute]: data.value });
    dispatch(setFilterAttribute(attribute, data.value));
  };

  return (
    <div>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Driver ID</Table.HeaderCell>
            <Table.HeaderCell>Driver Name</Table.HeaderCell>
            <Table.HeaderCell>Main Phone Number</Table.HeaderCell>
            <Table.HeaderCell>Emergency Phone Number</Table.HeaderCell>
            <Table.HeaderCell>Email Address</Table.HeaderCell>
            <Table.HeaderCell>Domicile City</Table.HeaderCell>
            <Table.HeaderCell>Account Status</Table.HeaderCell>
            <Table.HeaderCell>Suspension Status</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>
              <Input
                className="common-filter-input-keyword"
                value={get(
                  filters,
                  DriverProfileContextFilterAttributes.DRIVER_NAME,
                  ''
                )}
                onChange={handleFilterChange(
                  DriverProfileContextFilterAttributes.DRIVER_NAME
                )}
                onKeyPress={(event: React.KeyboardEvent) => {
                  if (event.key === 'Enter') {
                    handleSearch();
                  }
                }}
                placeholder={'Press Enter to Search'}
              />
            </Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell />
            <Table.HeaderCell />
            <Table.HeaderCell>
              <Input
                className="common-filter-input-keyword"
                value={get(
                  filters,
                  DriverProfileContextFilterAttributes.DOMICILE_CITY,
                  ''
                )}
                onChange={handleFilterChange(
                  DriverProfileContextFilterAttributes.DOMICILE_CITY
                )}
                onKeyPress={(event: React.KeyboardEvent) => {
                  if (event.key === 'Enter') {
                    handleSearch();
                  }
                }}
                placeholder={'Press Enter to Search'}
              />
            </Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {entries && entries.length > 0 ? (
            entries.map((entry: Entry) => {
              return (
                <Table.Row key={entry.driverId}>
                  <Table.Cell>
                    <Link
                      to={`/investigation-tools/driver-data/${entry.driverId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {entry.driverId}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>{entry.driverName}</Table.Cell>
                  <Table.Cell>{entry.mainPhoneNumber}</Table.Cell>
                  <Table.Cell>{entry.emergencyPhoneNumber}</Table.Cell>
                  <Table.Cell>{entry.emailAddress}</Table.Cell>
                  <Table.Cell>{entry.domicileCity}</Table.Cell>
                  <Table.Cell>{entry.accountStatus}</Table.Cell>
                  <Table.Cell>{entry.isSuspended.toString()}</Table.Cell>
                </Table.Row>
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell colSpan={'8'}>
                <p style={{ textAlign: 'center' }}>- No Data -</p>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <PaginationContainer tableName={'DRIVER_PROFILE_CONTEXT_SEARCH_TABLE'} />
    </div>
  );
};

export default DriverProfileContextSearchTableContainer;
