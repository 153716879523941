import React from 'react';
import { Form } from 'semantic-ui-react';
import ReactDatePicker from 'react-datepicker';
import { addDays } from 'date-fns';

const DATEPICKER_DATE_FORMAT = 'MMMM d, yyyy HH:mm';

const DateRangeFilter = ({ startDate, endDate, setStartDate, setEndDate }) => {
  const onChange = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <Form.Field>
      <label>Start Date (Max Range 30 Days)</label>
      <ReactDatePicker
        startDate={startDate}
        endDate={endDate}
        selected={startDate}
        onChange={onChange}
        selectsRange
        inline
        dateFormat={DATEPICKER_DATE_FORMAT}
        maxDate={addDays(startDate, 30)}
      />
    </Form.Field>
  );
};

export default DateRangeFilter;
