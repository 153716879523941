import React, { createContext, useReducer } from 'react';
import {
  appReducer,
  initialAppState,
  AppState,
  AppAction
} from './reducers/app';

export type AppContextType = [AppState, (action: AppAction) => void];

export const AppContext = createContext<AppContextType>([
  initialAppState,
  () => {
    // noop
  }
]);

export const AppStateProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialAppState);

  return (
    <AppContext.Provider value={[state, dispatch]}>
      {children}
    </AppContext.Provider>
  );
};
