import React, { useEffect, useReducer } from 'react';
import axios from 'axios';
import CaseResult from './CaseResult';
import { caseReducer, initialCaseState } from '../../reducers/caseManagement';
import Layout from '../../components/Layout';
import Header from '../../components/header/Header';
import { CASE_QUEUE_WIKILINK } from '../../constants/caseManagement';
import {
  FilterData,
  CaseSearchAPIResponse,
  FilterDataType
} from '../../types/caseManagement';
import { showErrorToast } from '../../utils/common';
import configs from '../../configs';

const CaseQueue = () => {
  const [state, dispatch] = useReducer(caseReducer, initialCaseState);

  const fetchAndSetData = async (
    filterData?: FilterData,
    tableSearchData?: Record<string, string>,
    page = 0
  ) => {
    if (!filterData || filterData.type !== FilterDataType.QUEUE) return;

    try {
      const response = (await axios.get(configs.caseQueueEntries, {
        params: {
          ...tableSearchData,
          page: page
        }
      })) as { data: CaseSearchAPIResponse };

      dispatch({
        type: 'CHANGE_RESULT_DATA',
        payload: {
          data: response.data,
          page
        }
      });
    } catch (err) {
      showErrorToast(err, 'error', 'Error fetching search result');
    }
  };

  useEffect(() => {
    const filterData: FilterData = {
      type: FilterDataType.QUEUE
    };

    dispatch({ type: 'SUBMIT_FILTER', payload: { filterData } });
    fetchAndSetData(filterData);
  }, []);

  return (
    <Layout>
      <Header
        className="header-list-add"
        groupTitle="Case Management"
        title="Case Queue"
        wikiLink={CASE_QUEUE_WIKILINK}
      />

      <CaseResult
        state={state}
        dispatch={dispatch}
        fetchAndSetData={fetchAndSetData}
        addToReviewDisabled
      />
    </Layout>
  );
};

export default CaseQueue;
