import axios from 'axios';
import config from '../configs';

export const getReferenceTypes = token => {
  return axios.get(config.blacklistReferenceTypes, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const getBlackListTags = token => {
  return axios.get(config.blacklistTags, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const getBlacklistAttributes = token => {
  return axios.get(config.blacklistAttributes, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const submitBlackList = (token, payload) => {
  return axios.post(config.blacklistEntries, payload, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};
