import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { Table, Select } from 'semantic-ui-react';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import util from 'util';
import ProfileSummaryPaginationContainer from './ProfileSummaryPaginationContainer';
import { setFilterAttribute } from '../../../actions/investigation-tools/commonAction';
import {
  ProfileProxyType,
  ProfileSearchFilterAttributes
} from '../../../constants/investigationToolsConstant';
import { getProfileSummary } from '../../../reducers/investigation-tools/selectors';
import { useProfileSummaryContext } from '../../../views/investigation-tools/profile-summary/ProfileSummaryContext';
import './ProfileSummaryComponents.style.css';

const profileListTypes = {
  UNIQUE: 'UNIQUE',
  UNIQUE_NAUGHTY: 'UNIQUE_NAUGHTY'
};

const ProfileSummaryTableContainer = () => {
  const { getState, dispatch, handleSearch } = useProfileSummaryContext();
  const [filters, setFilters] = useState({});

  const state = getState();
  const { entries, filterAttributes } = getProfileSummary(state);

  useEffect(() => {
    if (isEqual(filters, filterAttributes) && isFunction(handleSearch)) {
      handleSearch();
    }
  }, [filters, filterAttributes, handleSearch]);

  const handleFilterChange = attribute => (event, data) => {
    setFilters({ ...filterAttributes, [attribute]: data.value });
    dispatch(setFilterAttribute(attribute, data.value));
  };

  return (
    <div>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Profile ID</Table.HeaderCell>
            <Table.HeaderCell>Customer ID</Table.HeaderCell>
            <Table.HeaderCell>Proxy ID</Table.HeaderCell>
            <Table.HeaderCell>Type of Proxy</Table.HeaderCell>
            <Table.HeaderCell>Count of Unique Profile ID</Table.HeaderCell>
            <Table.HeaderCell>
              Count of Unique Naughty Profile ID
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell />
            <Table.HeaderCell />
            <Table.HeaderCell>
              <Select
                fluid
                onChange={handleFilterChange(
                  ProfileSearchFilterAttributes.PROXY_TYPE
                )}
                options={[
                  { key: 'NULL', value: null, text: 'NULL' },
                  ...ProfileProxyType.map(proxyType => {
                    return {
                      key: proxyType,
                      value: proxyType,
                      text: proxyType
                    };
                  })
                ]}
                value={get(
                  filterAttributes,
                  ProfileSearchFilterAttributes.PROXY_TYPE,
                  null
                )}
              />
            </Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {entries && entries.length > 0 ? (
            entries.map(entry => {
              const profileId = entry.profileId;
              const proxyId = entry.proxyId;
              const encodeProxyId = encodeURIComponent(proxyId);
              const proxyType = entry.proxyType;
              const profileListPathname = `/investigation-tools/profile-summary/profile-list/${profileId}`;
              const profileListSearch = `?proxyId=${encodeProxyId}&proxyType=${proxyType}&listType=%s`;
              const profileListFullPath = `${profileListPathname}${profileListSearch}`;

              return (
                <Table.Row key={`${profileId}-${proxyType}-${proxyId}`}>
                  <Table.Cell width={2}>{profileId}</Table.Cell>
                  <Table.Cell width={2}>{entry.customerId}</Table.Cell>
                  <Table.Cell width={4}>{proxyId}</Table.Cell>
                  <Table.Cell width={4}>{proxyType}</Table.Cell>
                  <Table.Cell width={2} selectable>
                    <a
                      href={util.format(
                        profileListFullPath,
                        profileListTypes.UNIQUE
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div>{entry.uniqueProfileIdCount}</div>
                    </a>
                  </Table.Cell>
                  <Table.Cell width={2} selectable>
                    <a
                      href={util.format(
                        profileListFullPath,
                        profileListTypes.UNIQUE_NAUGHTY
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div>{entry.uniqueNaughtyProfileIdCount}</div>
                    </a>
                  </Table.Cell>
                </Table.Row>
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell colSpan={'6'}>
                <p style={{ textAlign: 'center' }}>- No Data -</p>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>

      <ProfileSummaryPaginationContainer />
    </div>
  );
};

export default ProfileSummaryTableContainer;
