import React from 'react';
import isFunction from 'lodash/isFunction';
import { Card, Form, Select, Button, Input } from 'semantic-ui-react';

import { CommonSearchConditional } from '../../../constants/investigationToolsConstant';
import './SummaryCardWithAddCondition.style.css';

type PropTypes = {
  useContext: () => any;
  useSelector: (state: any) => any;
  searchTypeConstants: string[];
  setKeyword: (index: string, value: string) => any;
  setSearchType: (index: string, value: string) => any;
  addQuery: () => any;
  deleteQuery: (value: string) => any;
  setConditional: (index: string, condition: string) => any;
};

const SummaryCardWithAddCondition = ({
  useContext,
  useSelector,
  searchTypeConstants,
  setKeyword,
  setSearchType,
  addQuery,
  deleteQuery,
  setConditional
}: PropTypes) => {
  const { getState, dispatch, handleSearch } = useContext();

  const state = getState();
  const { queryList, isLoading } = useSelector(state);

  const handleConditionalChange = index => (event, data) => {
    dispatch(setConditional(index, data.value));
  };

  const handleSelectChange = index => (event, data) => {
    dispatch(setSearchType(index, data.value));
  };

  const handleInputChange = index => (event, data) => {
    dispatch(setKeyword(index, data.value));
  };

  const handleAddQuery = () => {
    dispatch(addQuery());
  };

  const handleDeleteQuery = index => (event, data) => {
    dispatch(deleteQuery(index));
  };

  const disableSearch = () => {
    if (!queryList) return true;

    for (const [index, query] of queryList.entries()) {
      if (
        (index === 0 && (!query.searchValue || !query.searchType)) ||
        (index > 0 &&
          (!queryList ||
            !query.searchValue ||
            !query.searchType ||
            !query.conditional))
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <Card className="common-summary-card">
      <Card.Content className={'cardContainer'}>
        <Form className={'form'}>
          {queryList &&
            queryList.length > 0 &&
            queryList.map((query, index) => {
              return (
                <div key={index} style={{ marginBottom: '20px' }}>
                  {index > 0 && (
                    <Form.Field>
                      <label>Conditional</label>
                      <Select
                        options={CommonSearchConditional.map(conditional => {
                          return {
                            key: conditional,
                            value: conditional,
                            text: conditional
                          };
                        })}
                        onChange={handleConditionalChange(index)}
                        value={query.conditional}
                      />
                    </Form.Field>
                  )}
                  <Form.Field>
                    <label>Search Type</label>
                    <Select
                      className="common-summary-card-select-search-type"
                      options={searchTypeConstants.map(searchType => {
                        return {
                          key: searchType,
                          value: searchType,
                          text: searchType
                        };
                      })}
                      value={query.searchType}
                      onChange={handleSelectChange(index)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Keyword</label>
                    <Input
                      className="common-summary-card-input-keyword"
                      value={query.searchValue}
                      onChange={handleInputChange(index)}
                    />
                  </Form.Field>
                  {index > 0 && (
                    <Form.Field>
                      <Button color={'red'} onClick={handleDeleteQuery(index)}>
                        Remove Condition
                      </Button>
                    </Form.Field>
                  )}
                </div>
              );
            })}
          <Form.Field>
            <Button color={'blue'} onClick={handleAddQuery}>
              Add Condition
            </Button>
          </Form.Field>
          <Form.Field>
            <Button
              className="common-summary-card-button-search"
              onClick={() => isFunction(handleSearch) && handleSearch(true)}
              disabled={isLoading || disableSearch()}
            >
              Search
            </Button>
          </Form.Field>
        </Form>
      </Card.Content>
    </Card>
  );
};

export default SummaryCardWithAddCondition;
