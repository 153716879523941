import React, { useEffect, useReducer } from 'react';
import get from 'lodash/get';
import isFinite from 'lodash/isFinite';
import { Loader } from 'semantic-ui-react';

import connectionSearchReducer from '../../../reducers/investigation-tools/connectionSearchReducer';
import paginationReducer from '../../../reducers/investigation-tools/paginationReducer';
import {
  fetchApplicationRelationships,
  fetchBookingRelationships
} from '../../../actions/investigation-tools/connectionSearchAction';
import {
  goToAPage,
  initPagination,
  setTotalNumberOfPages
} from '../../../actions/investigation-tools/paginationAction';
import { PaginationType } from '../../../constants/investigationToolsConstant';
import Layout from '../../../components/Layout';
import ConnectionSearchCard from '../../../components/investigation-tools/connection-search/ConnectionSearchCard';
import ApplicationRelationshipTableContainer from '../../../components/investigation-tools/connection-search/table/ApplicationRelationshipTableContainer';
import BookingRelationshipTableContainer from '../../../components/investigation-tools/connection-search/table/BookingRelationshipTableContainer';
import ConnectionSearchContext from './ConnectionSearchContext';
import './ConnectionSearch.style.css';

const getNextPagination = data => {
  const pageCount = get(data, 'pageCount', null);
  const currentPageNo = get(data, 'currentPageNo', null);

  return {
    pageCount: isFinite(pageCount) ? pageCount : 1,
    currentPageNo: isFinite(currentPageNo) ? currentPageNo : 1
  };
};

const ConnectionSearch = () => {
  const [state, dispatch] = useReducer(connectionSearchReducer);
  const [paginationState, paginationDispatch] = useReducer(paginationReducer);

  const {
    isLoadingApplication,
    isLoadedApplication,
    isLoadingBooking,
    isLoadedBooking
  } = { ...state };

  useEffect(() => {
    dispatch();
    paginationDispatch(
      initPagination([
        PaginationType.APPLICATION_RELATIONSHIP_TABLE,
        PaginationType.BOOKING_RELATIONSHIP_TABLE
      ])
    );
  }, []);

  const getCombinedState = () => ({
    connection_search: { ...state },
    pagination: { ...paginationState }
  });

  const handleApplicationSearch = () => {
    fetchApplicationRelationships(dispatch, getCombinedState).then(result => {
      const nextPagination = getNextPagination(get(result, 'data', null));

      paginationDispatch(
        setTotalNumberOfPages(
          PaginationType.APPLICATION_RELATIONSHIP_TABLE,
          nextPagination.pageCount
        )
      );

      paginationDispatch(
        goToAPage(
          PaginationType.APPLICATION_RELATIONSHIP_TABLE,
          nextPagination.currentPageNo
        )
      );
    });
  };

  const handleBookingSearch = () => {
    fetchBookingRelationships(dispatch, getCombinedState).then(result => {
      const nextPagination = getNextPagination(get(result, 'data', null));

      paginationDispatch(
        setTotalNumberOfPages(
          PaginationType.BOOKING_RELATIONSHIP_TABLE,
          nextPagination.pageCount
        )
      );

      paginationDispatch(
        goToAPage(
          PaginationType.BOOKING_RELATIONSHIP_TABLE,
          nextPagination.currentPageNo
        )
      );
    });
  };

  const handleSearch = () => {
    handleApplicationSearch();
    handleBookingSearch();
  };

  return (
    <ConnectionSearchContext.Provider
      value={{
        getState: getCombinedState,
        dispatch,
        paginationDispatch,
        handleApplicationSearch,
        handleBookingSearch,
        handleSearch
      }}
    >
      <Layout>
        <div className="connection-search-card-div">
          <h1>Connection Search</h1>
          <ConnectionSearchCard />
        </div>
        <div className="connection-search-table-div connection-search-application-table-div">
          <Loader active={isLoadingApplication} inline="centered" />
          {isLoadedApplication && <ApplicationRelationshipTableContainer />}
        </div>
        <div className="connection-search-table-div connection-search-booking-table-div">
          <Loader active={isLoadingBooking} inline={'centered'} />
          {isLoadedBooking && <BookingRelationshipTableContainer />}
        </div>
      </Layout>
    </ConnectionSearchContext.Provider>
  );
};

export default ConnectionSearch;
