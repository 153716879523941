import * as CommonActionType from '../../types/investigation-tools/commonActionType';

export const actionHandler = {
  [CommonActionType.SET_IS_LOADING_STATE]: (state, action) => ({
    ...state,
    isLoading: action.value
  }),
  [CommonActionType.SET_IS_LOADED_STATE]: (state, action) => ({
    ...state,
    isLoaded: action.value
  }),
  [CommonActionType.SET_KEYWORD]: (state, action) => ({
    ...state,
    keyword: action.value
  }),
  [CommonActionType.SET_SEARCH_TYPE]: (state, action) => ({
    ...state,
    searchType: action.value
  }),
  [CommonActionType.SET_FILTER_ATTRIBUTE]: (state, action) => ({
    ...state,
    filterAttributes: {
      ...state.filterAttributes,
      [action.attribute]: action.value
    }
  }),
  [CommonActionType.SET_ENTRIES]: (state, action) => ({
    ...state,
    entries: action.entries
  })
};

export const multiQueryActionHandler = {
  [CommonActionType.SET_IS_LOADING_STATE]: (state, action) => ({
    ...state,
    isLoading: action.value
  }),
  [CommonActionType.SET_IS_LOADED_STATE]: (state, action) => ({
    ...state,
    isLoaded: action.value
  }),
  [CommonActionType.SET_KEYWORD]: (state, action) => ({
    ...state,
    queryList: [
      ...state.queryList.slice(0, action.index),
      { ...state.queryList[action.index], searchValue: action.value },
      ...state.queryList.slice(action.index + 1)
    ]
  }),
  [CommonActionType.SET_SEARCH_TYPE]: (state, action) => ({
    ...state,
    queryList: [
      ...state.queryList.slice(0, action.index),
      { ...state.queryList[action.index], searchType: action.value },
      ...state.queryList.slice(action.index + 1)
    ]
  }),
  [CommonActionType.SET_CONDITIONAL]: (state, action) => ({
    ...state,
    queryList: [
      ...state.queryList.slice(0, action.index),
      { ...state.queryList[action.index], conditional: action.value },
      ...state.queryList.slice(action.index + 1)
    ]
  }),
  [CommonActionType.SET_ENTRIES]: (state, action) => ({
    ...state,
    entries: action.entries
  }),
  [CommonActionType.ADD_QUERY]: (state, action) => ({
    ...state,
    queryList: [
      ...state.queryList,
      {
        searchType: null,
        searchValue: '',
        conditional: null
      }
    ]
  }),
  [CommonActionType.REMOVE_QUERY]: (state, action) => ({
    ...state,
    queryList: [
      ...state.queryList.slice(0, action.index),
      ...state.queryList.slice(action.index + 1)
    ]
  })
};
