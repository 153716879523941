import React, { useState } from 'react';
import {
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableRow
} from 'semantic-ui-react';
import { getCommActivity } from '../../../reducers/investigation-tools/selectors';
import { useCommActivityContext } from '../../../views/investigation-tools/comm-activity/CommActivityContext';
import { css } from 'emotion';
import PaginationController from '../common/PaginationController';
import { formatEpochToDateTime } from '../../../utils/dateFormatter';

type Entry = {
  messageType: string;
  profileId: string;
  address: string;
  topic: string;
  action: string;
  requestTime: string;
};

const activePropertiesStyle = css`
  &.table tbody tr td {
    border: none;
  }
`;

const CommActivityTableContainer = () => {
  const { getState, paginationDispatch, handleSearch } =
    useCommActivityContext();

  const state = getState();
  const { entries } = getCommActivity(state);
  const [entryDetail, setEntryDetail] = useState(null);

  const renderModalContent = () => {
    const {
      requestId,
      context,
      sendTime,
      deliveryTime,
      trackingId,
      messageId,
      reason
    } = entryDetail;
    return (
      <Table basic="very" columns={10} className={activePropertiesStyle}>
        <TableBody>
          <TableRow>
            <TableCell width={3}>
              <b>Request ID</b>
            </TableCell>
            <TableCell width={7}>{requestId}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell width={3}>
              <b>Context</b>
            </TableCell>
            <TableCell width={7}>{context}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell width={3}>
              <b>Send Time</b>
            </TableCell>
            <TableCell width={7}>{formatEpochToDateTime(sendTime)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell width={3}>
              <b>Delivery Time</b>
            </TableCell>
            <TableCell width={7}>
              {formatEpochToDateTime(deliveryTime)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell width={3}>
              <b>Tracking ID</b>
            </TableCell>
            <TableCell width={7}>{trackingId}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell width={3}>
              <b>Message ID</b>
            </TableCell>
            <TableCell width={7}>{messageId}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell width={3}>
              <b>Reason</b>
            </TableCell>
            <TableCell width={7}>{reason}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  return (
    <div>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Message Type</Table.HeaderCell>
            <Table.HeaderCell>Profile ID</Table.HeaderCell>
            <Table.HeaderCell>Address</Table.HeaderCell>
            <Table.HeaderCell>Topic</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
            <Table.HeaderCell>Request Time</Table.HeaderCell>
            <Table.HeaderCell>Details</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {entries && entries.length > 0 ? (
            entries.map((entry: Entry) => {
              return (
                <Table.Row key={entry.profileId}>
                  <Table.Cell>{entry.messageType}</Table.Cell>
                  <Table.Cell>{entry.profileId}</Table.Cell>
                  <Table.Cell>{entry.address}</Table.Cell>
                  <Table.Cell>{entry.topic}</Table.Cell>
                  <Table.Cell>{entry.action}</Table.Cell>
                  <Table.Cell>
                    {formatEpochToDateTime(entry.requestTime)}
                  </Table.Cell>
                  <Table.Cell>
                    <Button primary onClick={() => setEntryDetail(entry)}>
                      Details
                    </Button>
                  </Table.Cell>
                </Table.Row>
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell colSpan={'10'}>
                <p style={{ textAlign: 'center' }}>- No Data -</p>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <Modal open={!!entryDetail} size="tiny">
        <Modal.Header>Details</Modal.Header>
        <Modal.Content>{entryDetail && renderModalContent()}</Modal.Content>
        <Modal.Actions>
          <Button
            primary
            content="Close"
            onClick={() => setEntryDetail(null)}
          />
        </Modal.Actions>
      </Modal>
      <PaginationController
        tableName={'COMM_ACTIVITY_TABLE'}
        state={state}
        dispatch={paginationDispatch}
        onPageNumberClick={handleSearch}
      />
    </div>
  );
};

export default CommActivityTableContainer;
