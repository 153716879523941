import { Dispatch } from 'react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isFinite from 'lodash/isFinite';
import * as commonActionType from '../../types/investigation-tools/commonActionType';
import { searchUserProfileList } from './serviceAction';
import { showErrorToast } from '../../utils/common';

type Entry = {
  [key: string]: string | number | null | Entry;
};

type Params = {
  proxyId: string;
  proxyType: string;
  listType: string;
  pageNo: string;
};

type Result = {
  data: {
    pageCount: number;
    currentPageNo: number;
    userProfileList: Entry[];
  };
};

export const setIsLoading = (value: boolean) => ({
  type: commonActionType.SET_IS_LOADING_STATE,
  value
});

export const setEntries = (entries: Entry[]) => ({
  type: commonActionType.SET_ENTRIES,
  entries
});

export const fetchUserProfileList = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
  profileId: string,
  proxyId: string,
  proxyType: string,
  listType: string,
  pageNo?: number
) => {
  const params: Params = {
    proxyId,
    proxyType,
    listType,
    pageNo: isFinite(pageNo) && pageNo > 0 ? pageNo.toString() : '1'
  };

  dispatch(setIsLoading(true));

  return searchUserProfileList({ profileId, params })
    .then((result: Partial<Result>) => {
      const profileList: Entry[] = get(result, 'data.profileList', null);

      if (isArray(profileList)) dispatch(setEntries(profileList));

      return result;
    })
    .catch((err: Error) => {
      showErrorToast(err);

      return {};
    })
    .finally(() => {
      dispatch(setIsLoading(false));
    });
};
