import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { Table, Input } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { setFilterAttribute } from '../../../actions/investigation-tools/commonAction';
import { UserProfileContextFilterAttributes } from '../../../constants/investigationToolsConstant';
import { getUserProfileContextSearch } from '../../../reducers/investigation-tools/selectors';
import { useUserProfileContextSearchContext } from '../../../views/investigation-tools/user-profile-context-search/UserProfileContextSearchContext';
import PaginationController from '../common/PaginationController';
import { isFunction } from 'lodash';

type Entry = {
  profileId: string;
  kycId: string;
  profileFullName: string;
  kycName: string;
  emailAddresses: string[];
  kycEmailAddress: string;
  phoneNumbers: string[];
  kycPhoneNumber: string;
};

const UserProfileContextSearchTableContainer = () => {
  const { getState, dispatch, paginationDispatch, handleSearch } =
    useUserProfileContextSearchContext();
  const [filters, setFilters] = useState({});

  const state = getState();
  const { entries, filterAttributes } = getUserProfileContextSearch(state);

  useEffect(() => {
    setFilters({ ...filterAttributes });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterChange = attribute => (event, data) => {
    setFilters({ ...filterAttributes, [attribute]: data.value });
    dispatch(setFilterAttribute(attribute, data.value));
  };

  return (
    <div>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Profile ID</Table.HeaderCell>
            <Table.HeaderCell>KYC ID</Table.HeaderCell>
            <Table.HeaderCell>Profile Full Name</Table.HeaderCell>
            <Table.HeaderCell>KYC Name</Table.HeaderCell>
            <Table.HeaderCell>Email Addresses</Table.HeaderCell>
            <Table.HeaderCell>KYC Email Address</Table.HeaderCell>
            <Table.HeaderCell>Phone Numbers</Table.HeaderCell>
            <Table.HeaderCell>KYC Phone Number</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell />
            <Table.HeaderCell>
              <Input
                className="common-filter-input-keyword"
                value={get(
                  filters,
                  UserProfileContextFilterAttributes.PROFILE_FULL_NAME,
                  ''
                )}
                onChange={handleFilterChange(
                  UserProfileContextFilterAttributes.PROFILE_FULL_NAME
                )}
                onKeyPress={(event: React.KeyboardEvent) => {
                  if (event.key === 'Enter') {
                    isFunction(handleSearch) && handleSearch(true);
                  }
                }}
                placeholder={'Press Enter to Search'}
              />
            </Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell>
              <Input
                className="common-filter-input-keyword"
                value={get(
                  filters,
                  UserProfileContextFilterAttributes.EMAIL_ADDRESS,
                  ''
                )}
                onChange={handleFilterChange(
                  UserProfileContextFilterAttributes.EMAIL_ADDRESS
                )}
                onKeyPress={(event: React.KeyboardEvent) => {
                  if (event.key === 'Enter') {
                    isFunction(handleSearch) && handleSearch(true);
                  }
                }}
                placeholder={'Press Enter to Search'}
              />
            </Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell />
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {entries && entries.length > 0 ? (
            entries.map((entry: Entry) => {
              return (
                <Table.Row key={entry.profileId}>
                  <Table.Cell>
                    <Link
                      to={`/investigation-tools/user-profile-data/${entry.profileId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {entry.profileId}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    <Link
                      to={`/investigation-tools/kyc-data/${entry.kycId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {entry.kycId}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>{entry.profileFullName}</Table.Cell>
                  <Table.Cell>{entry.kycName}</Table.Cell>
                  <Table.Cell>
                    {entry.emailAddresses.map(email => (
                      <p key={email}>{email}</p>
                    ))}
                  </Table.Cell>
                  <Table.Cell>{entry.kycEmailAddress}</Table.Cell>
                  <Table.Cell>
                    {entry.phoneNumbers.map(phone => (
                      <p key={phone}>{phone}</p>
                    ))}
                  </Table.Cell>
                  <Table.Cell>{entry.kycPhoneNumber}</Table.Cell>
                </Table.Row>
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell colSpan={'8'}>
                <p style={{ textAlign: 'center' }}>- No Data -</p>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <PaginationController
        dispatch={paginationDispatch}
        state={state}
        tableName={`USER_PROFILE_CONTEXT_SEARCH_TABLE`}
        onPageNumberClick={handleSearch}
      />
    </div>
  );
};

export default UserProfileContextSearchTableContainer;
