import { Dispatch } from 'react';
import get from 'lodash/get';
import * as commonActionType from '../../types/investigation-tools/commonActionType';
import { PaginationType } from '../../constants/investigationToolsConstant';
import {
  getPagination,
  getFraudCheckHistory
} from '../../reducers/investigation-tools/selectors';
import { setTotalNumberOfPages, goToAPage } from './paginationAction';
import { searchFraudCheckHistory } from './serviceAction';
import { setIsLoading, setEntries } from './commonAction';
import { showErrorToast } from '../../utils/common';
import { format } from 'date-fns';

type Entry = {
  [key: string]: string | number | null | Entry;
};

type Spec = {
  clientInterface: string;
  data: {
    currentPageNo: number;
    searchAttributes: {
      searchType: string;
      searchValue: string;
      startDate: any;
      endDate: any;
    };
  };
};

type Result = {
  data: {
    fraudHistoryList: Entry[];
    pageCount: number;
    currentPageNo: number;
  };
};

export const setKeyword = (value: string) => ({
  type: commonActionType.SET_KEYWORD,
  value
});

export const setSearchType = (value: string) => ({
  type: commonActionType.SET_SEARCH_TYPE,
  value
});

export const setStartDate = (value: Date) => ({
  type: commonActionType.SET_START_DATE,
  value
});

export const setEndDate = (value: Date) => ({
  type: commonActionType.SET_END_DATE,
  value
});

export const fetchFraudCheckHistory = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
  getState: Function
) => {
  const state = getState();

  const pagination = getPagination(
    state.fraud_check_history,
    PaginationType.FRAUD_CHECK_HISTORY_TABLE
  );
  const table = getFraudCheckHistory(state);

  const endDate = get(state.fraud_check_history, 'endDate', null);
  const spec: Spec = {
    clientInterface: '',
    data: {
      currentPageNo: get(pagination, 'currentPage', 1),
      searchAttributes: {
        searchType: get(table, 'searchType', null),
        searchValue: get(table, 'keyword', null),
        startDate: format(
          get(state.fraud_check_history, 'startDate', null),
          'T'
        ),
        endDate: endDate ? format(endDate, 'T') : null
      }
    }
  };

  dispatch(setIsLoading(true));

  return searchFraudCheckHistory(spec)
    .then((result: Partial<Result>) => {
      if (result && result.data) {
        dispatch(setEntries(result.data.fraudHistoryList));

        dispatch(
          setTotalNumberOfPages(
            PaginationType.FRAUD_CHECK_HISTORY_TABLE,
            result.data.pageCount
          )
        );

        dispatch(
          goToAPage(
            PaginationType.FRAUD_CHECK_HISTORY_TABLE,
            result.data.currentPageNo
          )
        );
      } else {
        dispatch(
          setTotalNumberOfPages(PaginationType.FRAUD_CHECK_HISTORY_TABLE, 1)
        );
        dispatch(goToAPage(PaginationType.FRAUD_CHECK_HISTORY_TABLE, 1));
      }

      dispatch(setIsLoading(false));
    })
    .catch((err: Error) => {
      showErrorToast(err);
      dispatch(
        setTotalNumberOfPages(PaginationType.FRAUD_CHECK_HISTORY_TABLE, 1)
      );
      dispatch(goToAPage(PaginationType.FRAUD_CHECK_HISTORY_TABLE, 1));
      dispatch(setIsLoading(false));
    });
};
