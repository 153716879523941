import {
  CaseResultTableDetail,
  FilterData,
  MarkingStatusResultData,
  MarkingStatus,
  RemediationAction,
  CaseSearchAPIResponse,
  WhitelistAttributeCombination
} from '../types/caseManagement';

export enum ActionType {
  MARK_STATUS,
  REMEDIATION,
  NONE
}

export enum ModalState {
  NONE,
  MARK_SUSPECT,
  MARK_NEUTRAL,
  MARK_TRUSTED,
  REMEDIATION,
  PROGRESS,
  ADD_TO_QUEUE
}

export type CaseTabState = {
  resultData: CaseResultTableDetail[];
  page: number;
  pageSize: number;
  totalDataCount: number;
  filterData?: FilterData;
  tableSearchData: Record<string, string>;
  markStatusResultData: MarkingStatusResultData[];
  markingCaseCount: number;
  markingStatusAction: MarkingStatus;
  remediationAction: RemediationAction;
  currentAction: ActionType;
  selectedData: CaseResultTableDetail[];
};

export type CaseResultState = {
  tabs: Record<number, CaseTabState>;
  currentTabIndex: number;
  currentModal: ModalState;
  blacklistTags: string[];
  whitelistAttributeCombinations: WhitelistAttributeCombination[];
  progress: number;
};

export type CaseAction =
  | { type: 'CHANGE_TAB'; payload: { tabIndex: number } }
  | { type: 'SET_BLACKLIST_TAGS'; payload: string[] }
  | {
      type: 'SET_WHITELIST_ATTRIBUTES';
      payload: WhitelistAttributeCombination[];
    }
  | {
      type: 'CHANGE_RESULT_DATA';
      payload: {
        data: CaseSearchAPIResponse;
        page?: number;
      };
    }
  | { type: 'RESET_FILTER' }
  | { type: 'SUBMIT_FILTER'; payload: { filterData: FilterData } }
  | { type: 'CHANGE_RESULT_PAGE'; payload: { page: number } }
  | {
      type: 'BEGIN_REMEDIATION';
      payload: {
        action: RemediationAction;
        selectedData: CaseResultTableDetail[];
      };
    }
  | {
      type: 'SUBMIT_REMEDIATION';
      payload: {
        action: RemediationAction;
      };
    }
  | {
      type: 'BEGIN_MARK_STATUS';
      payload: {
        markingStatus: MarkingStatus;
        selectedData: CaseResultTableDetail[];
      };
    }
  | {
      type: 'SUBMIT_MARK_STATUS';
      payload: {
        markingStatus: MarkingStatus;
      };
    }
  | { type: 'SET_PROGRESS'; payload: { progress: number } }
  | { type: 'SET_MARK_STATUS_RESULT_DATA'; payload: MarkingStatusResultData[] }
  | {
      type: 'BEGIN_ADD_QUEUE';
      payload: { selectedData: CaseResultTableDetail[] };
    }
  | { type: 'SUBMIT_ADD_QUEUE' }
  | { type: 'SUBMIT_TABLE_SEARCH'; payload: { filter: Record<string, string> } }
  | { type: 'HIDE_MODAL' };

const initialTabState: CaseTabState = {
  resultData: [],
  page: 0,
  pageSize: 0,
  totalDataCount: 0,
  filterData: undefined,
  tableSearchData: {},
  markStatusResultData: [],
  markingCaseCount: 0,
  markingStatusAction: 'SUSPECT',
  remediationAction: RemediationAction.DEACTIVATE,
  currentAction: ActionType.NONE,
  selectedData: []
};

export const initialCaseState: CaseResultState = {
  tabs: { 0: initialTabState },
  currentTabIndex: 0,
  currentModal: ModalState.NONE,
  blacklistTags: [],
  whitelistAttributeCombinations: [],
  progress: 0
};

export const initialCaseStateWithTabs = (tabCount: number): CaseResultState => {
  const tabs: Record<number, CaseTabState> = [];

  for (let i = 0; i < tabCount; i++) {
    tabs[i] = initialTabState;
  }

  return {
    tabs,
    currentTabIndex: 0,
    currentModal: ModalState.NONE,
    blacklistTags: [],
    whitelistAttributeCombinations: [],
    progress: 0
  };
};

export function caseReducer(
  state: CaseResultState,
  action: CaseAction
): CaseResultState {
  switch (action.type) {
    case 'CHANGE_TAB':
      return {
        ...state,
        currentTabIndex: action.payload.tabIndex
      };
    case 'SET_BLACKLIST_TAGS':
      return { ...state, blacklistTags: action.payload };
    case 'SET_WHITELIST_ATTRIBUTES':
      return { ...state, whitelistAttributeCombinations: action.payload };
    case 'CHANGE_RESULT_DATA':
      const newData = action.payload.data.content.map(d => ({
        id: d.id,
        api: d.api,
        referenceType: d.request.referenceType,
        referenceId: d.request.referenceId,
        requestDate: d.requestDate,
        emails: d.request.emails,
        deviceId: d.request.deviceId,
        ipAddress: d.request.ipAddress,
        ipCountryId: d.request.ipCountryId,
        profileId: d.request.profileId,
        phones: d.request.phones,
        bankAccountDetails: d.request.bankAccountDetails,
        finalRecommendation: d.response.action,
        cardCountryId: d.request.cardCountryId,
        markingStatus: d.markingStatus,
        maskedCardNumber: d.request.maskedCardNumber,
        currency: d.request.currency,
        amount: d.request.amount
      }));

      return {
        ...state,
        tabs: {
          ...state.tabs,
          [state.currentTabIndex]: {
            ...state.tabs[state.currentTabIndex],
            resultData: newData,
            page: action.payload.page || 0,
            pageSize: action.payload.data.pageable.size,
            totalDataCount: action.payload.data.total
          }
        }
      };
    case 'RESET_FILTER':
      return {
        ...state,
        tabs: {
          ...state.tabs,
          [state.currentTabIndex]: {
            ...state.tabs[state.currentTabIndex],
            filterData: undefined,
            resultData: [],
            currentAction: ActionType.NONE,
            tableSearchData: {},
            page: 0,
            totalDataCount: 0,
            pageSize: 0
          }
        }
      };
    case 'SUBMIT_FILTER':
      return {
        ...state,
        tabs: {
          ...state.tabs,
          [state.currentTabIndex]: {
            ...state.tabs[state.currentTabIndex],
            filterData: action.payload.filterData,
            currentAction: ActionType.NONE,
            tableSearchData: {}
          }
        }
      };
    case 'CHANGE_RESULT_PAGE':
      return {
        ...state,
        tabs: {
          ...state.tabs,
          [state.currentTabIndex]: {
            ...state.tabs[state.currentTabIndex],
            currentAction: ActionType.NONE,
            page: action.payload.page
          }
        }
      };
    case 'BEGIN_REMEDIATION':
      return {
        ...state,
        currentModal: ModalState.REMEDIATION,
        tabs: {
          ...state.tabs,
          [state.currentTabIndex]: {
            ...state.tabs[state.currentTabIndex],
            selectedData: action.payload.selectedData,
            remediationAction: action.payload.action
          }
        }
      };
    case 'SUBMIT_REMEDIATION':
      return {
        ...state,
        currentModal: ModalState.PROGRESS,
        progress: 0,
        tabs: {
          ...state.tabs,
          [state.currentTabIndex]: {
            ...state.tabs[state.currentTabIndex],
            currentAction: ActionType.REMEDIATION,
            remediationAction: action.payload.action,
            markingCaseCount:
              state.tabs[state.currentTabIndex].selectedData.length,
            markStatusResultData: []
          }
        }
      };
    case 'BEGIN_MARK_STATUS':
      const modal =
        action.payload.markingStatus === 'SUSPECT'
          ? ModalState.MARK_SUSPECT
          : action.payload.markingStatus === 'NEUTRAL'
          ? ModalState.MARK_NEUTRAL
          : ModalState.MARK_TRUSTED;

      return {
        ...state,
        currentModal: modal,
        tabs: {
          ...state.tabs,
          [state.currentTabIndex]: {
            ...state.tabs[state.currentTabIndex],
            selectedData: action.payload.selectedData
          }
        }
      };
    case 'SUBMIT_MARK_STATUS':
      return {
        ...state,
        currentModal: ModalState.PROGRESS,
        progress: 0,
        tabs: {
          ...state.tabs,
          [state.currentTabIndex]: {
            ...state.tabs[state.currentTabIndex],
            currentAction: ActionType.MARK_STATUS,
            markingStatusAction: action.payload.markingStatus,
            markingCaseCount:
              state.tabs[state.currentTabIndex].selectedData.length,
            markStatusResultData: []
          }
        }
      };
    case 'SET_PROGRESS':
      return {
        ...state,
        progress: action.payload.progress
      };
    case 'SET_MARK_STATUS_RESULT_DATA':
      return {
        ...state,
        tabs: {
          ...state.tabs,
          [state.currentTabIndex]: {
            ...state.tabs[state.currentTabIndex],
            markStatusResultData: action.payload
          }
        }
      };
    case 'BEGIN_ADD_QUEUE':
      return {
        ...state,
        currentModal: ModalState.ADD_TO_QUEUE,
        tabs: {
          ...state.tabs,
          [state.currentTabIndex]: {
            ...state.tabs[state.currentTabIndex],
            selectedData: action.payload.selectedData
          }
        }
      };
    case 'SUBMIT_ADD_QUEUE':
      return {
        ...state,
        currentModal: ModalState.NONE,
        tabs: {
          ...state.tabs,
          [state.currentTabIndex]: {
            ...state.tabs[state.currentTabIndex],
            currentAction: ActionType.NONE
          }
        }
      };
    case 'SUBMIT_TABLE_SEARCH':
      return {
        ...state,
        tabs: {
          ...state.tabs,
          [state.currentTabIndex]: {
            ...state.tabs[state.currentTabIndex],
            page: 0,
            tableSearchData: action.payload.filter,
            currentAction: ActionType.NONE
          }
        }
      };
    case 'HIDE_MODAL':
      return {
        ...state,
        currentModal: ModalState.NONE
      };
    default:
      return state;
  }
}
