import * as CommonActionType from '../../types/investigation-tools/commonActionType';

type Entry = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

type UserProfileListState = {
  isLoading: boolean;
  profileList: Entry[];
};

export const initialState: UserProfileListState = {
  isLoading: false,
  profileList: []
};

export function userProfileListReducer(
  state = initialState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: Record<string, any> = {}
): UserProfileListState {
  switch (action.type) {
    case CommonActionType.SET_IS_LOADING_STATE:
      return {
        ...state,
        isLoading: action.value
      };
    case CommonActionType.SET_ENTRIES:
      return {
        ...state,
        profileList: action.entries
      };
    default:
      return state;
  }
}
