import get from 'lodash/get';

import * as ConnectionSearchActionType from '../../types/investigation-tools/connectionSearchActionType';
import {
  ConnectionSearchType,
  MatchType,
  ConnectionSearchFilterAttributes
} from '../../constants/investigationToolsConstant';

type Entry = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

type ConnectionSearchState = {
  isLoadingApplication: boolean;
  isLoadingBooking: boolean;
  isLoadedApplication: boolean;
  isLoadedBooking: boolean;
  keyword: string;
  applicationSearchType: string;
  bookingSearchType: string;
  applicationEntries: Entry[];
  bookingEntries: Entry[];
  matchType: string;
  applicationFilterAttributes: {
    [key: string]: string;
  };
  bookingFilterAttributes: {
    [key: string]: string;
  };
};

const initialSearchType = get(ConnectionSearchType, 0, null);

export const initialConnectionSearchState: ConnectionSearchState = {
  isLoadingApplication: false,
  isLoadingBooking: false,
  isLoadedApplication: false,
  isLoadedBooking: false,
  keyword: '',
  applicationSearchType: initialSearchType,
  bookingSearchType: initialSearchType,
  applicationEntries: [],
  bookingEntries: [],
  matchType: get(Object.values(MatchType), 0, null),
  applicationFilterAttributes: {
    [ConnectionSearchFilterAttributes.RELATIONSHIP_TYPE]: null
  },
  bookingFilterAttributes: {
    [ConnectionSearchFilterAttributes.BOOKING_CONTACT_EMAIL]: null
  }
};

export default function connectionSearchReducer(
  state = initialConnectionSearchState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: Record<string, any> = {}
) {
  const action = { ...payload };

  switch (action.type) {
    case ConnectionSearchActionType.SET_IS_LOADING_APPLICATION_STATE:
      return {
        ...state,
        isLoadingApplication: action.value
      };
    case ConnectionSearchActionType.SET_IS_LOADING_BOOKING_STATE:
      return {
        ...state,
        isLoadingBooking: action.value
      };
    case ConnectionSearchActionType.SET_IS_LOADED_APPLICATION_STATE:
      return {
        ...state,
        isLoadedApplication: action.value
      };
    case ConnectionSearchActionType.SET_IS_LOADED_BOOKING_STATE:
      return {
        ...state,
        isLoadedBooking: action.value
      };
    case ConnectionSearchActionType.SET_KEYWORD:
      return {
        ...state,
        keyword: action.value
      };
    case ConnectionSearchActionType.SET_APPLICATION_SEARCH_TYPE:
      return {
        ...state,
        applicationSearchType: action.value
      };
    case ConnectionSearchActionType.SET_BOOKING_SEARCH_TYPE:
      return {
        ...state,
        bookingSearchType: action.value
      };
    case ConnectionSearchActionType.SET_MATCH_TYPE:
      return {
        ...state,
        matchType: action.value
      };
    case ConnectionSearchActionType.SET_APPLICATION_ENTRIES:
      return {
        ...state,
        applicationEntries: action.entries
      };
    case ConnectionSearchActionType.SET_BOOKING_ENTRIES:
      return {
        ...state,
        bookingEntries: action.entries
      };
    case ConnectionSearchActionType.SET_APPLICATION_FILTER_ATTRIBUTE:
      return {
        ...state,
        applicationFilterAttributes: {
          ...state.applicationFilterAttributes,
          [action.attribute]: action.value
        }
      };
    case ConnectionSearchActionType.SET_BOOKING_FILTER_ATTRIBUTE:
      return {
        ...state,
        bookingFilterAttributes: {
          ...state.bookingFilterAttributes,
          [action.attribute]: action.value
        }
      };
    default:
      return state;
  }
}
