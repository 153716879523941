import get from 'lodash/get';
import * as CommonActionType from '../../types/investigation-tools/commonActionType';
import { MerchantDataSearchType } from '../../constants/investigationToolsConstant';

type Entry = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

type PaymentDataState = {
  isLoading: boolean;
  keyword: string;
  searchType: string;
  entries: Entry[];
};

export const initialState: PaymentDataState = {
  isLoading: false,
  keyword: '',
  searchType: get(MerchantDataSearchType, 0, null),
  entries: []
};

export function MerchantDataReducer(
  state = initialState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any
) {
  switch (action.type) {
    case CommonActionType.SET_IS_LOADING_STATE:
      return {
        ...state,
        isLoading: action.value
      };
    case CommonActionType.SET_KEYWORD:
      return {
        ...state,
        keyword: action.value
      };
    case CommonActionType.SET_SEARCH_TYPE:
      return {
        ...state,
        searchType: action.value
      };
    case CommonActionType.SET_ENTRIES:
      return {
        ...state,
        entries: action.entries
      };
    default:
      return state;
  }
}
