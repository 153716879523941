import * as React from 'react';

export type MenuLink = {
  path: string;
  childPaths: string;
  component: React.ReactElement;
  href: string;
  title: string;
};

export type MenuData = {
  showAtBottom: boolean;
  title: string;
  links: MenuLink[];
}[];

export const trimPath = path => {
  if (path[path.length - 1] === '/') {
    return path.substring(0, path.length - 1);
  }

  return path;
};

export const isSameRootPath = (path, currentPath) => {
  if (path === '' || path === '/') return false;

  const p1 = path.split('/');
  const p2 = currentPath.split('/');

  if (p1.length > p2.length) return false;

  for (let i = 1; i < p2.length; i++) {
    if (!p1[i]) return true;
    if (p1[i] !== p2[i]) return false;
  }

  return true;
};

export const isActiveLink = (
  { path, childPaths }: { path: string; childPaths: string },
  currentPath
) => {
  const trimmedPath = trimPath(path);

  if (trimmedPath === currentPath) return true;

  if (isSameRootPath(trimmedPath, currentPath)) return true;

  if (childPaths && childPaths.length > 0) {
    for (let i = 0; i < childPaths.length; i++) {
      const trimmedChildPath = trimPath(childPaths[i]);

      if (isSameRootPath(trimmedChildPath, currentPath)) return true;
    }
  }

  return false;
};

export const selectedActiveIndex = (
  menuData: MenuData,
  currentPath: string
) => {
  let activeSelectedIndex = null;
  const trimmedPath = trimPath(currentPath);
  for (let id = 0; id < menuData.length; id++) {
    const menuLinks = menuData[id]?.links;
    for (let idx = 0; idx < menuLinks.length; idx++) {
      const link = menuLinks[idx];
      if (
        link?.component &&
        React.isValidElement(link.component) &&
        isActiveLink(link, trimmedPath)
      ) {
        activeSelectedIndex = id;
        break;
      }
    }
    if (activeSelectedIndex !== null) {
      break;
    }
  }
  return activeSelectedIndex;
};
