import { Dispatch } from 'react';
import get from 'lodash/get';
import { PaginationType } from '../../constants/investigationToolsConstant';
import {
  getPagination,
  getPaymentData
} from '../../reducers/investigation-tools/selectors';
import { searchPaymentData } from './serviceAction';
import { setIsLoading, setEntries, Entry } from './commonAction';
import { showErrorToast } from '../../utils/common';

type Spec = {
  clientInterface: string;
  data: {
    currentPageNo: number;
    searchAttributes: {
      searchType: string;
      searchValue: string;
    };
  };
};

type Result = {
  data: {
    paymentHistoryList: Entry[];
    pageCount: number;
    currentPageNo: number;
  };
};

export const fetchPaymentData = async (
  dispatch: Dispatch<any>,
  getState: Function
): Promise<Partial<Result>> => {
  const state = getState();

  const pagination = getPagination(state, PaginationType.PAYMENT_DATA_TABLE);
  const table = getPaymentData(state);

  const spec: Spec = {
    clientInterface: '',
    data: {
      currentPageNo: get(pagination, 'currentPage', 1),
      searchAttributes: {
        searchType: get(table, 'searchType', null),
        searchValue: get(table, 'keyword', null)
      }
    }
  };

  dispatch(setIsLoading(true));

  return searchPaymentData(spec)
    .then((result: Partial<Result>) => {
      if (result && result.data) {
        dispatch(setEntries(result.data.paymentHistoryList));
      }

      dispatch(setIsLoading(false));
      return result;
    })
    .catch((err: Error) => {
      showErrorToast(err);
      dispatch(setIsLoading(false));
      return null;
    });
};
