import React, { useState, useEffect } from 'react';
import { convertToLocalTime } from '../../../utils/dateFormatter';
import config from '../../../configs';
import { Table } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import axios from 'axios';

const DecisionPackageSummary = props => {
  const headers = [
    'Name',
    'Description',
    'Status',
    'Created by',
    'Created at (Local time)',
    'Final recommendation',
    'Remediations'
  ];
  const { productId } = props;
  const [data, setData] = useState([]);

  useEffect(() => {
    // API for Decision Package Summary
    axios
      .get(config.rulesDecisionPackageSummary, {
        params: {
          productId
        }
      })
      .then(res => {
        if (res && res.data && res.data) {
          setData(res.data);
        }
      })
      .catch(err => {
        toast.error(
          'Error fetching Decision Package summary. Error: ' +
            (err && err.message)
        );
      });
  }, [productId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Table celled>
        <Table.Header>
          {headers && (
            <Table.Row textAlign="center">
              {headers.map((header, idx) => {
                return <Table.HeaderCell key={idx}>{header}</Table.HeaderCell>;
              })}
            </Table.Row>
          )}
        </Table.Header>
        <Table.Body>
          {data.length ? (
            data.map((detail, idx) => {
              return (
                <Table.Row key={idx} textAlign="center">
                  <Table.Cell width={1}>{detail.name}</Table.Cell>
                  <Table.Cell width={3}>{detail.description}</Table.Cell>
                  <Table.Cell width={1}>{detail.status}</Table.Cell>
                  <Table.Cell width={2}>{detail.createdBy}</Table.Cell>
                  <Table.Cell width={2}>
                    {convertToLocalTime(detail.createdAt)}
                  </Table.Cell>
                  <Table.Cell width={2}>
                    {detail.finalRecommendation}
                  </Table.Cell>
                  <Table.Cell width={2}>
                    {detail.remediations.map((remediation, i) => (
                      <div key={`remediation-${idx}-${i}`}>{remediation}</div>
                    ))}
                  </Table.Cell>
                </Table.Row>
              );
            })
          ) : (
            <Table.Row textAlign="center">
              <Table.Cell colSpan={headers.length}>No Data</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </div>
  );
};

export default DecisionPackageSummary;
