import * as CommonActionType from '../../types/investigation-tools/commonActionType';
import omit from 'lodash/omit';

type Entry = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

type DriverDataState = {
  isLoading: boolean;
  entries: Entry[];
  driverData: Entry;
  contactData: Entry;
  otherData: Entry;
};

export const initialState: DriverDataState = {
  isLoading: false,
  entries: [],
  driverData: {},
  contactData: {},
  otherData: {}
};

export function DriverDataReducer(
  state = initialState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any
) {
  switch (action.type) {
    case CommonActionType.SET_IS_LOADING_STATE:
      return {
        ...state,
        isLoading: action.value
      };
    case CommonActionType.SET_RESULT:
      return {
        ...state,
        driverData: omit(action.result.driverData, [
          'contactData',
          'otherData',
          'deliveryHistories'
        ]),
        contactData: action.result.driverData.contactData,
        otherData: action.result.driverData.otherData
      };
    case CommonActionType.SET_ENTRIES:
      return {
        ...state,
        entries: action.entries
      };
    default:
      return state;
  }
}
