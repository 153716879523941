import { css } from 'emotion';

export const listAdditionStyle = css`
  & .ui.form .ui.disabled > input {
    opacity: 1;
    background-color: lightgray;
  }

  & .button-container {
    float: right;
  }

  & .ui.form .inline.field > :first-child,
  & .ui.form .inline.fields .field > :first-child {
    width: 20%;
  }

  & .ui.form input[type='checkbox'],
  & .ui.form textarea,
  & .ui.form .inline.field > .selection.dropdown,
  & .ui.form .inline.fields .field > .selection.dropdown,
  & .ui.form .inline.field:not(.wide) .ui.input,
  & .ui.form .inline.fields .field:not(.wide) .ui.input {
    width: 50%;
  }
`;
