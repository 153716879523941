import { DTLogLevel, DTLog } from '../../types/decisionTree';
import { CONSOLE_LOG_LEVEL_LENGTH } from '../../constants/decisionTree';

export function formatLog(message: string, level = DTLogLevel.INFO): DTLog {
  const lines = message.split('\n');
  const levelSpaces = ' '.repeat(CONSOLE_LOG_LEVEL_LENGTH - level.length);
  const emptySpaces = ' '.repeat(CONSOLE_LOG_LEVEL_LENGTH + 2); // 2 brackets

  let r = `[${level.toUpperCase()}${levelSpaces}] ${lines[0]}`;

  for (let idx = 1; idx < lines.length; idx++) {
    r += `\n${emptySpaces} ${lines[idx]}`;
  }

  return {
    level,
    message: r
  };
}
