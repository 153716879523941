export const NODE_RADIUS = 80;
export const NODE_KEY = 'id';
export const NODE_SIZE = 154;
export const NODE_TEXT_SIZE = 11;
export const NODE_TEXT_LINE_GAP = 16;
export const NODE_TEXT_LINE_MAX_LENGTH = 20;
export const LINKED_GRAPH_NODE = 'LINKED_GRAPH_NODE';

export const EDGE_WIDTH = 87;
export const EDGE_HEIGHT = 64;
export const EDGE_TEXT_SIZE = 10;
export const EDGE_TEXT_CHAR_WIDTH = 6;
export const EDGE_TEXT_MIN_CHAR = 5;
export const EDGE_TEXT_LINE_GAP = 14;
export const EDGE_TEXT_LINE_MAX_LENGTH = 20;
export const EDGE_PADDING = 24;

export const DECISION_NODE_WIDTH = 156;
export const DECISION_NODE_HEIGHT = 75;

export const CONSOLE_LOG_LEVEL_LENGTH = 7;
