import React, { useEffect, useReducer } from 'react';
import axios from 'axios';
import CaseResult from './CaseResult';
import { caseReducer, initialCaseState } from '../../reducers/caseManagement';
import Layout from '../../components/Layout';
import Header from '../../components/header/Header';
import { Button } from 'semantic-ui-react';
import {
  FilterData,
  CaseSearchAPIResponse,
  FilterDataType
} from '../../types/caseManagement';
import { showErrorToast } from '../../utils/common';
import { Link, useParams } from 'react-router-dom';
import { startOfDay, subYears } from 'date-fns';
import configs from '../../configs';

const getSearchFieldText = (field: string): string => {
  switch (field) {
    case 'ALL':
      return 'Email Address, Phone Number, Device ID, IP Address, Profile ID, Bank Account Details';
    case 'EMAIL_ADDRESS':
      return 'Email Address';
    case 'PHONE_NUMBER':
      return 'Phone Number';
    case 'DEVICE_ID':
      return 'Device ID';
    case 'IP_ADDRESS':
      return 'IP Address';
    case 'PROFILE_ID':
      return 'Profile ID';
    case 'BANK_ACC_DETAILS':
      return 'Bank Account Details';
    default:
      return '';
  }
};

const CaseSimilarSearch = () => {
  const [state, dispatch] = useReducer(caseReducer, initialCaseState);

  const { caseId, searchField } = useParams();

  const fetchAndSetData = async (
    filterData?: FilterData,
    tableSearchData?: Record<string, string>,
    page = 0
  ) => {
    if (!filterData || filterData.type !== FilterDataType.SIMILAR_SEARCH)
      return;

    try {
      const response = (await axios.get(configs.caseFieldAndValueSearch, {
        params: {
          ...tableSearchData,
          field: filterData.field,
          value: filterData.value,
          startDate: filterData.startDate,
          endDate: filterData.endDate,
          similarSearch: true,
          page: page
        }
      })) as { data: CaseSearchAPIResponse };

      dispatch({
        type: 'CHANGE_RESULT_DATA',
        payload: {
          data: response.data,
          page
        }
      });
    } catch (err) {
      showErrorToast(err, 'error', 'Error fetching search result');
    }
  };

  useEffect(() => {
    const filterData: FilterData = {
      type: FilterDataType.SIMILAR_SEARCH,
      field: searchField,
      value: caseId,
      startDate: startOfDay(subYears(new Date(), 1)),
      endDate: new Date()
    };

    dispatch({ type: 'SUBMIT_FILTER', payload: { filterData } });
    fetchAndSetData(filterData);
  }, [caseId, searchField]);

  return (
    <Layout>
      <Header
        className="header-list-add"
        groupTitle="Case Management"
        title="Case Similar Search"
      />

      <div>
        <Link to={`/case-details/${caseId}`}>
          <Button>Back</Button>
        </Link>
        <span style={{ marginLeft: '1em' }}>
          Search by [{getSearchFieldText(searchField)}] from Case ID {caseId}
        </span>
      </div>

      <CaseResult
        state={state}
        dispatch={dispatch}
        fetchAndSetData={fetchAndSetData}
      />
    </Layout>
  );
};

export default CaseSimilarSearch;
