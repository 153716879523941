import * as CommonActionType from '../../types/investigation-tools/commonActionType';

type Entry = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

type UserProfileDataState = {
  userProfileData: Entry;
  customerHistory: Entry;
  storedCreditCards: Entry[];
  userAssets: Entry[];
  emailAddresses: Entry[];
  phoneNumbers: Entry[];
};

export const initialState: UserProfileDataState = {
  userProfileData: {},
  customerHistory: {},
  storedCreditCards: [],
  userAssets: [],
  emailAddresses: [],
  phoneNumbers: []
};

export function UserProfileDataReducer(
  state = initialState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any
) {
  switch (action.type) {
    case CommonActionType.SET_IS_LOADING_STATE:
      return {
        ...state,
        isLoading: action.value
      };
    case CommonActionType.SET_IS_LOADED_STATE:
      return {
        ...state,
        isLoaded: action.value
      };
    case CommonActionType.SET_RESULT:
      return {
        ...state,
        userProfileData: {
          profileId: action.result.profileId,
          kycId: action.result.kycId,
          fullName: action.result.fullName,
          userProfileStatus: action.result.userProfileStatus,
          gender: action.result.gender,
          birthDate: action.result.birthdate,
          cityOfResidence: action.result.cityOfResidence,
          registeredDate: action.result.registeredDate
        },
        customerHistory: action.result.customerHistory,
        storedCreditCards: action.result.storedCreditCards,
        userAssets: action.result.userAssets,
        emailAddresses: action.result.emailAddresses,
        phoneNumbers: action.result.phoneNumbers
      };
    default:
      return state;
  }
}
