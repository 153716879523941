import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import 'react-datepicker/dist/react-datepicker.css';
import App from './App';
import { Auth0Provider } from './react-auth0-spa';
import config from './configs';
import { AppStateProvider } from './AppContext';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onRedirectCallback = (res?: any) => {
  window.history.replaceState(
    {},
    document.title,
    res?.appState?.targetUrl ? res.appState.targetUrl : window.location.pathname
  );
};

ReactDOM.render(
  <Auth0Provider
    domain={config.auth0DomainName}
    client_id={config.auth0ClientId}
    audience={config.auth0Audience}
    redirect_uri={window.location.origin + config.appCallback}
    onRedirectCallback={onRedirectCallback}
  >
    <AppStateProvider>
      <App />
    </AppStateProvider>
  </Auth0Provider>,
  document.getElementById('root')
);
