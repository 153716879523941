import React, { useEffect, useReducer } from 'react';
import { Grid, TableBody, TableCell, TableRow } from 'semantic-ui-react';

import {
  initialState,
  UserProfileDataReducer
} from '../../../reducers/investigation-tools/userProfileDataReducer';
import { fetchUserProfileData } from '../../../actions/investigation-tools/userProfileDataAction';
import InfoBox from '../../../components/investigation-tools/common/InfoBox';
import ScrollableCard from '../../../components/investigation-tools/common/ScrollableCard';
import Layout from '../../../components/Layout';
import { Link, useParams, BrowserRouter } from 'react-router-dom';
import Table from 'semantic-ui-react/dist/commonjs/collections/Table';
import { userProfileDataLabel } from '../../../constants/investigationToolsLabels';
import { css } from 'emotion';

const activePropertiesStyle = css`
  &.table tbody tr td {
    border: none;
  }
`;

const UserProfileData = () => {
  const { id } = useParams<{ id?: string }>();

  const [state, dispatch] = useReducer(UserProfileDataReducer, initialState);
  const {
    userProfileData,
    customerHistory,
    emailAddresses,
    storedCreditCards,
    userAssets,
    phoneNumbers
  } = state;

  useEffect(() => {
    fetchUserProfileData(dispatch, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderUserProfileData = () => {
    const cxpToolLink =
      process.env.REACT_APP_ENV === 'production'
        ? 'https://tool.cxp.traveloka.com'
        : 'https://tool.cxp.staging-traveloka.com';

    return (
      <Table basic="very" columns={10} className={activePropertiesStyle}>
        <TableBody>
          <TableRow>
            <TableCell width={3}>
              <b>Profile ID</b>
            </TableCell>
            <TableCell width={7}>
              <BrowserRouter>
                <Link
                  to={{
                    pathname: `${cxpToolLink}/customer/profile?id=${userProfileData.profileId}`
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {userProfileData.profileId}
                </Link>
              </BrowserRouter>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell width={3}>
              <b>KYC ID</b>
            </TableCell>
            <TableCell width={7}>
              <BrowserRouter>
                <Link
                  to={`/investigation-tools/kyc-data/${userProfileData.kycId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {userProfileData.kycId}
                </Link>
              </BrowserRouter>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell width={3}>Full Name</TableCell>
            <TableCell width={7}>{userProfileData.fullName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell width={3}>User Profile Status</TableCell>
            <TableCell width={7}>{userProfileData.userProfileStatus}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell width={3}>Gender</TableCell>
            <TableCell width={7}>{userProfileData.gender}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell width={3}>Birthdate</TableCell>
            <TableCell width={7}>{userProfileData.birthDate}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell width={3}>City of Residence</TableCell>
            <TableCell width={7}>{userProfileData.cityOfResidence}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell width={3}>Registered Date</TableCell>
            <TableCell width={7}>{userProfileData.registeredDate}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  return (
    <Layout>
      <h1>User Profile Data</h1>
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column width={8}>
            {renderUserProfileData()}
            <InfoBox
              title="Customer History"
              data={customerHistory}
              labels={userProfileDataLabel}
            />
            <ScrollableCard
              title={'Stored Credit Card'}
              data={storedCreditCards}
              headers={['maskedCardNumber', 'expiryDate']}
              className={'stored-credit-card-list'}
              labels={userProfileDataLabel}
            />
            <ScrollableCard
              title={'User Assets'}
              data={userAssets}
              headers={['assetName', 'value']}
              className={'user-assets-card-list'}
              labels={userProfileDataLabel}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <ScrollableCard
              title={'Email Address'}
              data={emailAddresses}
              headers={['value', 'addedDate', 'removedDate']}
              className={'email-address-card-list'}
              labels={{
                ...userProfileDataLabel,
                value: userProfileDataLabel.emailAddress
              }}
            />
            <ScrollableCard
              title={'Phone Number'}
              data={phoneNumbers}
              headers={['value', 'addedDate', 'removedDate']}
              className={'phone-numbers-card-list'}
              labels={{
                ...userProfileDataLabel,
                value: userProfileDataLabel.phoneNumber
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Layout>
  );
};

export default UserProfileData;
