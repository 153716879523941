import {
  GCData,
  GCEdge,
  GCNode,
  NodeTypeKey,
  NodeData,
  EdgeData
} from '../../types/decisionTree';
import { isUnaryOperator } from './operatorUtils';

export function createEmptyData(): GCData {
  return {
    nodes: [],
    edges: [],
    lastId: 0
  };
}

export function createRandomData(nodeCount = 100, cols = 20) {
  let r = createEmptyData();

  for (let i = 0; i < nodeCount; i++) {
    const col = i % cols;
    const row = Math.floor(i / cols);

    r = addNode(r, { x: col * 320, y: row * 320, type: NodeTypeKey.EMPTY });

    if (i > 0) {
      r = addEdge(r, {
        source: r.nodes[i - 1].id,
        target: r.nodes[i].id,
        priority: 0,
        isDefaultBranch: false,
        conditionValue: ''
      });
    }
  }

  return r;
}

export function createNodeContent(nodeData: NodeData): string {
  let content = '';

  if (nodeData.type === NodeTypeKey.NORMAL) {
    content += `${nodeData.decider?.name}\n[${nodeData.decidingVariable?.name}]`;
  } else if (nodeData.type === NodeTypeKey.DECISION_PACKAGE) {
    content += `${nodeData.decisionPackage?.name}`;
  } else {
    content += `Double click to edit`;
  }

  return content;
}

export function createEdgeContent(edgeData: EdgeData): string {
  let content = edgeData.priority.toString();

  if (!edgeData.isDefaultBranch) {
    const { operator, conditionValue } = edgeData;

    if (operator) {
      content += `\n${operator.sign}`;

      if (!isUnaryOperator(operator.sign)) {
        content += `\n${conditionValue}`;
      }
    }
  } else {
    content += `\nDEFAULT`;
  }

  return content;
}

export function addNode(
  data: GCData,
  {
    x = 0,
    y = 0,
    type = NodeTypeKey.EMPTY,
    decider,
    decidingVariable,
    decisionPackage
  }: { [T in keyof NodeData]?: NodeData[T] },
  id?: number
): GCData {
  const nodeData = {
    x,
    y,
    type,
    decider,
    decidingVariable,
    decisionPackage
  };

  const newId = id || data.lastId + 1;

  const newNode: GCNode = {
    id: newId,
    title: createNodeContent(nodeData),
    ...nodeData
  };

  return {
    ...data,
    nodes: [...data.nodes, newNode],
    lastId: id ? Math.max(id, data.lastId) : data.lastId + 1
  };
}

export function updateNode(
  data: GCData,
  nodeId: number,
  nodeData: NodeData
): GCData {
  const node = data.nodes.find(n => n.id === nodeId);

  if (!node) return data;

  const updatedNode = {
    ...node,
    title: createNodeContent(nodeData),
    x: nodeData.x,
    y: nodeData.y,
    type: nodeData.type,
    decider: nodeData.decider,
    decidingVariable: nodeData.decidingVariable,
    decisionPackage: nodeData.decisionPackage
  };

  let updatedData = {
    ...data,
    nodes: [...data.nodes.filter(n => n.id !== nodeId), updatedNode]
  };

  // if operator type is not changed, return data immediately
  if (
    node.decidingVariable === nodeData.decidingVariable ||
    node.decidingVariable?.operatorType ===
      nodeData.decidingVariable?.operatorType
  ) {
    return updatedData;
  }

  // clear operator on outgoing edges
  const outgoingEdges = data.edges.filter(edge => edge.source === nodeId);

  outgoingEdges.forEach(edge => {
    updatedData = updateEdge(updatedData, edge.id, {
      ...edge,
      operator: undefined
    });
  });

  return updatedData;
}

export function deleteNode(data: GCData, nodeId: number): GCData {
  const node = data.nodes.find(n => n.id === nodeId);

  if (!node) return data;

  let updatedData = {
    ...data,
    nodes: data.nodes.filter(node => node.id !== nodeId),
    edges: data.edges.filter(edge => edge.target !== nodeId)
  };

  const outgoingEdges = data.edges.filter(edge => edge.source === nodeId);

  outgoingEdges.forEach(edge => {
    updatedData = deleteEdge(updatedData, edge.id);
  });

  return updatedData;
}

export function addEdge(
  data: GCData,
  {
    source,
    target,
    priority = 0,
    isDefaultBranch = false,
    operator,
    conditionValue = ''
  }: {
    [T in keyof EdgeData]?: EdgeData[T];
  } & {
    source: number;
    target: number;
  },
  id?: number
): GCData {
  const edgeData = {
    source,
    target,
    priority,
    isDefaultBranch,
    operator,
    conditionValue
  };

  const newEdge: GCEdge = {
    ...edgeData,
    id: id || data.lastId + 1,
    type: 'emptyEdge',
    handleText: createEdgeContent(edgeData)
  };

  return {
    ...data,
    edges: [...data.edges, newEdge],
    lastId: id ? Math.max(id, data.lastId) : data.lastId + 1
  };
}

export function updateEdge(
  data: GCData,
  edgeId: number,
  edgeData: EdgeData
): GCData {
  const edge = data.edges.find(e => e.id === edgeId);

  if (!edge) return data;

  const updatedEdge = {
    ...edge,
    source: edgeData.source,
    target: edgeData.target,
    priority: edgeData.priority,
    isDefaultBranch: edgeData.isDefaultBranch,
    operator: edgeData.operator,
    conditionValue: edgeData.conditionValue,
    handleText: createEdgeContent(edgeData)
  };

  return {
    ...data,
    edges: [...data.edges.filter(e => e.id !== edgeId), updatedEdge]
  };
}

export function deleteEdge(data: GCData, edgeId: number): GCData {
  const edge = data.edges.find(e => e.id === edgeId);

  if (!edge) return data;

  return {
    ...data,
    edges: data.edges.filter(edge => edge.id !== edgeId)
  };
}
