import get from 'lodash/get';
import { axiosNoLoading } from '../../utils/axiosUtils';
import { FraudServiceUrl } from '../../constants/serviceConstants';

type ServiceActionSpec = {
  [key: string]: string | number | ServiceActionSpec | ServiceActionSpec[];
};

type ServiceActionResult = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

const getBlobResponse = async (
  url: string,
  spec: ServiceActionSpec,
  action = 'POST'
): Promise<ServiceActionResult> => {
  let response;
  if (action === 'GET') {
    response = await axiosNoLoading.get(url, spec);
  } else {
    response = await axiosNoLoading.post(url, spec);
  }
  const data = get(response, 'data', null);
  return { data };
};

const getResponse = async (
  url: string,
  spec: ServiceActionSpec,
  action = 'POST'
): Promise<ServiceActionResult> => {
  let response;
  if (action === 'GET') {
    response = await axiosNoLoading.get(url, spec);
  } else {
    response = await axiosNoLoading.post(url, spec);
  }
  const data = get(response, 'data', null);

  return { ...data };
};

export const searchProfile = (spec: ServiceActionSpec) =>
  getResponse(FraudServiceUrl.SEARCH_PROFILE, spec);

export const searchBooking = (spec: ServiceActionSpec) =>
  getResponse(FraudServiceUrl.SEARCH_BOOKING, spec);

export const downloadBooking = (spec: ServiceActionSpec) =>
  getBlobResponse(FraudServiceUrl.DOWNLOAD_BOOKING, spec);

export const searchBookingConnection = (spec: ServiceActionSpec) =>
  getResponse(FraudServiceUrl.SEARCH_BOOKING_CONNECTION, spec);

export const searchApplicationConnection = (spec: ServiceActionSpec) =>
  getResponse(FraudServiceUrl.SEARCH_APPLICATION_CONNECTION, spec);

export const searchActivity = (spec: ServiceActionSpec) =>
  getResponse(FraudServiceUrl.SEARCH_ACTIVITY, spec);

export const searchUserProfileContextSearch = (spec: ServiceActionSpec) =>
  getResponse(FraudServiceUrl.SEARCH_USER_PROFILE_CONTEXT, spec);

export const searchPaymentData = (spec: ServiceActionSpec) =>
  getResponse(FraudServiceUrl.SEARCH_PAYMENT_DATA, spec);

export const searchDriverProfileContextSearch = (spec: ServiceActionSpec) =>
  getResponse(FraudServiceUrl.SEARCH_DRIVER_PROFILE_CONTEXT_SEARCH, spec);

export const searchUserProfileData = (spec: ServiceActionSpec) =>
  getResponse(
    FraudServiceUrl.SEARCH_USER_PROFILE_DATA + `/${spec.profileId}`,
    spec,
    'GET'
  );

export const searchDriverData = (spec: ServiceActionSpec) =>
  getResponse(
    FraudServiceUrl.SEARCH_DRIVER_DATA +
      `/${spec.driverId}/${spec.currentPageNo}`,
    spec,
    'GET'
  );

export const searchMerchantData = (spec: ServiceActionSpec) =>
  getResponse(FraudServiceUrl.SEARCH_MERCHANT_DATA, spec);

export const searchFraudCheckHistory = (spec: ServiceActionSpec) =>
  getResponse(FraudServiceUrl.SEARCH_FRAUD_CHECK_HISTORY, spec);

export const searchCommActivity = (spec: ServiceActionSpec) =>
  getResponse(FraudServiceUrl.SEARCH_COMM_ACTIVITY, spec);

export const searchUserProfileList = (spec: ServiceActionSpec) => {
  const { profileId = '', ...config } = spec;

  return getResponse(
    `${FraudServiceUrl.SEARCH_USER_PROFILE_LIST}/${profileId}`,
    config,
    'GET'
  );
};
