import get from 'lodash/get';

export const getProfileSummary = state => get(state, 'profile_summary', {});
export const getConnectionSearch = state => get(state, 'connection_search', {});
export const getBookingDetail = state => get(state, 'booking_detail', {});
export const getActivity = state => get(state, 'activity', {});
export const getPagination = (state, id) => get(state, ['pagination', id], {});
export const getUserProfileContextSearch = state =>
  get(state, 'user_profile_context', {});
export const getPaymentData = state => get(state, 'payment_data', {});
export const getDriverProfileContextSearch = state =>
  get(state, 'driver_profile_context', {});
export const getDriverData = state => get(state, 'driver_data', {});
export const getMerchantData = state => get(state, 'merchant_data', {});
export const getFraudCheckHistory = state =>
  get(state, 'fraud_check_history', {});
export const getCommActivity = state => get(state, 'comm_activity', {});
