import get from 'lodash/get';
import * as ProfileSummaryActionType from '../../types/investigation-tools/profileSummaryActionType';
import {
  ProfileSearchFilterAttributes,
  ProfileSearchType
} from '../../constants/investigationToolsConstant';

type Entry = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

type ProfileSummaryState = {
  isLoading: boolean;
  isLoaded: boolean;
  keyword: string;
  searchType: string;
  filterAttributes: {
    [key: string]: string;
  };
  entries: Entry[];
};

const initialState: ProfileSummaryState = {
  isLoading: false,
  isLoaded: false,
  keyword: '',
  searchType: get(ProfileSearchType, 0, null),
  filterAttributes: {
    [ProfileSearchFilterAttributes.PROXY_TYPE]: null
  },
  entries: []
};

export default function ProfileSummaryReducer(
  state = initialState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any
) {
  switch (action.type) {
    case ProfileSummaryActionType.SET_IS_LOADING_STATE:
      return {
        ...state,
        isLoading: action.value
      };
    case ProfileSummaryActionType.SET_IS_LOADED_STATE:
      return {
        ...state,
        isLoaded: action.value
      };
    case ProfileSummaryActionType.SET_KEYWORD:
      return {
        ...state,
        keyword: action.value
      };
    case ProfileSummaryActionType.SET_SEARCH_TYPE:
      return {
        ...state,
        searchType: action.value
      };
    case ProfileSummaryActionType.SET_FILTER_ATTRIBUTE:
      return {
        ...state,
        filterAttributes: {
          ...state.filterAttributes,
          [action.attribute]: action.value
        }
      };
    case ProfileSummaryActionType.SET_ENTRIES:
      return {
        ...state,
        entries: action.entries
      };
    default:
      return state;
  }
}
