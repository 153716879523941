import React from 'react';
import configs from '../../configs';
import PrivateRoute from '../PrivateRoute';

type Props = {
  component: () => JSX.Element;
  path: string;
};

const RestrictedRoute = ({ component: Component, path, ...rest }: Props) => {
  const cfg = configs as { disabledPaths?: string[] };

  if (cfg.disabledPaths && cfg.disabledPaths.includes(path)) return null;

  return <PrivateRoute path={path} {...rest} component={Component} />;
};

export default RestrictedRoute;
