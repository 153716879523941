import accounting from 'accounting';
import get from 'lodash/get';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import configs from '../configs';

const currencies = get(configs, 'currencies', null);

const defaultCurrency = {
  code: 'IDR',
  currencySymbol: 'Rp ',
  currencyPosition: 'head',
  locale: 'id-ID',
  numberOfDecimalPoint: 0,
  separator: {
    decimal: ',',
    group: '.'
  }
};

const getCurrency = currencyValueDisplay => {
  if (!isArray(currencies)) return defaultCurrency;

  const currency = find(currencies, [
    'code',
    get(currencyValueDisplay, 'currencyValue.currency', null)
  ]);

  return currency || defaultCurrency;
};

export function printValue(currencyValueDisplay) {
  if (!currencyValueDisplay) return '';

  const currency = getCurrency(currencyValueDisplay);

  return accounting.formatNumber(
    currencyValueDisplay.currencyValue.amount /
      Math.pow(10, currency.numberOfDecimalPoint),
    currency.numberOfDecimalPoint,
    currency.separator.group,
    currency.separator.decimal
  );
}

export function printCurrencyValue(currencyValueDisplay) {
  if (!currencyValueDisplay) return '';

  const currency = getCurrency(currencyValueDisplay);

  let symbolFormat;

  if (currency.currencyPosition === 'head') {
    symbolFormat = currency.currencySymbol;
  } else {
    symbolFormat = {
      symbol: currency.currencySymbol,
      format: '%v %s',
      precision: currency.numberOfDecimalPoint
    };
  }

  if (typeof symbolFormat === 'string') {
    return accounting.formatMoney(
      currencyValueDisplay.currencyValue.amount /
        Math.pow(10, currency.numberOfDecimalPoint),
      symbolFormat,
      currency.numberOfDecimalPoint,
      currency.separator.group,
      currency.separator.decimal
    );
  }

  const additionalSymbolFormatData = {
    decimal: currency.separator.decimal,
    precision: currency.numberOfDecimalPoint,
    thousand: currency.separator.group
  };

  return accounting.formatMoney(
    currencyValueDisplay.currencyValue.amount /
      Math.pow(10, currency.numberOfDecimalPoint),
    { ...symbolFormat, ...additionalSymbolFormatData }
  );
}

export function printRawCurrencyValue(currencyValueDisplay) {
  const amount = printValue(currencyValueDisplay);
  const currency = currencyValueDisplay.currencyValue.currency;

  return `${currency} ${amount}`;
}
