import React, { useRef, useEffect, UIEvent, useState } from 'react';
import { css } from 'react-emotion';

const rootStyle = css`
  & {
    width: 100%;
    position: relative;
  }
`;

const scrollerStyle = css`
  & {
    width: 100%;
    overflow-x: auto;
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
  }
  & div {
    height: 1px;
  }
`;

const containerStyle = css`
  & {
    width: 100%;
    overflow-x: auto;
  }
`;

type Props = {
  scrollParent?: HTMLElement;
  children: React.ReactNode;
};

// source: https://stackoverflow.com/questions/13382516/getting-scroll-bar-width-using-javascript
const getScrollbarWidth = () => {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode?.removeChild(outer);

  return scrollbarWidth;
};

const scrollbarWidth = getScrollbarWidth();

const SCROLL_TOP_OFFSET = 12;

const getScrollerTop = (parentHeight: number): number => {
  return parentHeight - scrollbarWidth - SCROLL_TOP_OFFSET;
};

const FixedScrollDiv = ({ scrollParent = document.body, children }: Props) => {
  const [scrollIndex, setScrollIndex] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      !containerRef.current ||
      !scrollRef.current ||
      !scrollContentRef.current
    )
      return;

    scrollRef.current.style.top = `${getScrollerTop(
      scrollParent.clientHeight
    )}px`;
  }, [scrollParent]);

  useEffect(() => {
    if (!containerRef.current || !scrollContentRef.current) return;

    scrollContentRef.current.style.width = `${containerRef.current.scrollWidth}px`;
  });

  useEffect(() => {
    const handleResize = () => {
      if (!scrollRef.current) return;

      scrollRef.current.style.top = `${getScrollerTop(
        scrollParent.clientHeight
      )}px`;
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [scrollParent.clientHeight]);

  const handleScrollerScroll = (evt: React.UIEvent) => {
    if (scrollIndex !== 0 || !containerRef.current || !scrollRef.current)
      return;

    containerRef.current.scrollLeft = scrollRef.current.scrollLeft;
  };

  const handleContainerScroll = (evt: React.UIEvent) => {
    if (scrollIndex !== 1 || !containerRef.current || !scrollRef.current)
      return;

    scrollRef.current.scrollLeft = containerRef.current.scrollLeft;
  };

  return (
    <div className={rootStyle}>
      <div
        ref={scrollRef}
        className={scrollerStyle}
        onScroll={handleScrollerScroll}
        onMouseEnter={() => setScrollIndex(0)}
      >
        <div ref={scrollContentRef}></div>
      </div>
      <div
        ref={containerRef}
        className={containerStyle}
        onScroll={handleContainerScroll}
        onPointerEnter={() => setScrollIndex(1)}
      >
        {children}
      </div>
    </div>
  );
};

export default FixedScrollDiv;
