import { css } from 'emotion';

export const caseNotesFormStyle = css`
  & {
    display: flex;
    width: 100%;
  }
`;

export const caseNotesTextareaStyle = css`
  & {
    flex: 1 1 auto;
  }
`;

export const addNotesButtonStyle = css`
  & {
    margin-left: 1em;
  }

  & button {
    padding: 1em !important;
  }
`;

export const caseNotesTableWrapperStyle = css`
  & {
    max-height: 800px;
    overflow-y: auto;
    margin-top: 1em;
  }
`;

export const caseNotesTableStyle = css`
  & {
    table-layout: fixed;
  }

  & tr td {
    word-wrap: break-word;
  }
`;
