import React, { useEffect, useReducer } from 'react';
import { Loader } from 'semantic-ui-react';
import get from 'lodash/get';

import profileSummaryReducer from '../../../reducers/investigation-tools/profileSummaryReducer';
import {
  initPagination,
  goToAPage,
  setTotalNumberOfPages
} from '../../../actions/investigation-tools/paginationAction';
import paginationReducer from '../../../reducers/investigation-tools/paginationReducer';
import {
  PaginationType,
  ProfileSearchType
} from '../../../constants/investigationToolsConstant';
import ProfileSummaryContext, {
  useProfileSummaryContext
} from './ProfileSummaryContext';
import { getProfileSummary } from '../../../reducers/investigation-tools/selectors';
import { fetchProfileSummary } from '../../../actions/investigation-tools/profileSummaryAction';
import {
  setKeyword,
  setSearchType
} from '../../../actions/investigation-tools/commonAction';

import Layout from '../../../components/Layout';
import ProfileSummaryTableContainer from '../../../components/investigation-tools/profile-summary/ProfileSummaryTableContainer';
import SummaryCard from '../../../components/investigation-tools/common/SummaryCard';

const ProfileSummary = () => {
  const [state, dispatch] = useReducer(profileSummaryReducer);
  const [paginationState, paginationDispatch] = useReducer(paginationReducer);

  const { isLoading, isLoaded } = { ...state };

  useEffect(() => {
    paginationDispatch(initPagination([PaginationType.PROFILE_SUMMARY_TABLE]));
  }, [paginationDispatch]);

  const getCombinedState = () => ({
    profile_summary: { ...state },
    pagination: { ...paginationState }
  });

  const handleSearch = () => {
    fetchProfileSummary(dispatch, getCombinedState).then(result => {
      const pageCount = get(result, 'data.pageCount', null);
      const currentPageNo = get(result, 'data.currentPageNo', null);

      paginationDispatch(
        setTotalNumberOfPages(
          PaginationType.PROFILE_SUMMARY_TABLE,
          isFinite(pageCount) ? pageCount : 1
        )
      );

      paginationDispatch(
        goToAPage(
          PaginationType.PROFILE_SUMMARY_TABLE,
          isFinite(currentPageNo) ? currentPageNo : 1
        )
      );
    });
  };

  return (
    <ProfileSummaryContext.Provider
      value={{
        getState: getCombinedState,
        dispatch,
        paginationDispatch,
        handleSearch
      }}
    >
      <Layout>
        <h1>Profile Summary</h1>
        <SummaryCard
          useContext={useProfileSummaryContext}
          useSelector={getProfileSummary}
          searchTypeConstants={ProfileSearchType}
          setKeyword={setKeyword}
          setSearchType={setSearchType}
        />
        <Loader active={isLoading} />
        <div style={{ marginBottom: '20px' }}></div>
        {isLoaded && <ProfileSummaryTableContainer />}
      </Layout>
    </ProfileSummaryContext.Provider>
  );
};

export default ProfileSummary;
