import React from 'react';
import isFunction from 'lodash/isFunction';
import startCase from 'lodash/startCase';
import { Card, Form, Select, Button, Input, Radio } from 'semantic-ui-react';

import {
  setKeyword,
  setSearchType,
  setMatchType,
  setStartDate,
  setEndDate
} from '../../../actions/investigation-tools/bookingDetailAction';
import {
  MatchType,
  BookingSearchType
} from '../../../constants/investigationToolsConstant';
import { getBookingDetail } from '../../../reducers/investigation-tools/selectors';
import { useBookingDetailContext } from '../../../views/investigation-tools/booking-detail/BookingDetailContext';
import './BookingDetailCard.style.css';
import DateRangePicker from '../common/DateRangePicker';

const BookingDetailCard = () => {
  const { getState, dispatch, handleSearch } = useBookingDetailContext();

  const state = getState();
  const { isLoading, keyword, matchType, searchType, startDate, endDate } =
    getBookingDetail(state);

  const handleSelectChange = (event, data) => {
    dispatch(setSearchType(data.value));
  };

  const handleInputChange = (event, data) => {
    dispatch(setKeyword(data.value));
  };

  const handleRadioChange = (event, data) => {
    dispatch(setMatchType(data.value));
  };

  return (
    <Card className="booking-detail-card">
      <Card.Content className="booking-detail-card-content">
        <Form>
          <Form.Field>
            <label>Search Type</label>
            <Select
              className="booking-detail-card-select-search-type"
              options={BookingSearchType.map(type => ({
                key: type,
                value: type,
                text: type
              }))}
              value={searchType}
              onChange={handleSelectChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Keyword</label>
            <Input
              className="booking-detail-card-input-keyword"
              onChange={handleInputChange}
              value={keyword || ''}
            />
          </Form.Field>
          {Object.keys(MatchType).map(key => {
            const value = MatchType[key];

            return (
              <Form.Field key={value}>
                <Radio
                  className="booking-detail-card-radio"
                  label={startCase(key.toLowerCase())}
                  name="matchTypeGroup"
                  value={value}
                  checked={matchType === value}
                  onChange={handleRadioChange}
                />
              </Form.Field>
            );
          })}
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            setStartDate={startDate => {
              dispatch(setStartDate(startDate));
            }}
            setEndDate={endDate => {
              dispatch(setEndDate(endDate));
            }}
          />
          <Form.Field>
            <Button
              className="booking-detail-card-button-search"
              onClick={() => isFunction(handleSearch) && handleSearch(true)}
              disabled={
                isLoading ||
                !searchType ||
                !matchType ||
                typeof keyword !== 'string' ||
                !keyword.trim()
              }
            >
              Search
            </Button>
          </Form.Field>
        </Form>
      </Card.Content>
    </Card>
  );
};

export default BookingDetailCard;
