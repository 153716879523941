import {
  GCNode,
  GCEdge,
  SaveGraphData,
  NodeTypeKey,
  Operators,
  GCData,
  NodeData,
  SaveGraphEditorData
} from '../../types/decisionTree';
import { isUnaryOperator, getOperator } from './operatorUtils';
import { addNode, addEdge } from './treeEditUtils';
import { LINKED_GRAPH_NODE } from '../../constants/decisionTree';

type CyclicCheckNode = {
  id: number;
  edges: number[];
};

type CyclicCheckEdge = {
  id: number;
  source: number;
  target: number;
  isDefaultBranch: boolean;
};

type CyclicCheckGraph = {
  nodes: { [id: number]: CyclicCheckNode };
  edges: { [id: number]: CyclicCheckEdge };
};

// reference: https://www.geeksforgeeks.org/detect-cycle-in-a-graph
const isCyclic = (
  graph: CyclicCheckGraph,
  currentId: number,
  visited: Record<number, boolean>,
  recStack: Record<number, boolean>
): boolean => {
  if (recStack[currentId]) return true;
  if (visited[currentId]) return false;

  visited[currentId] = true;
  recStack[currentId] = true;

  const nodeEdgeIds = graph.nodes[currentId].edges;

  for (let i = 0; i < graph.nodes[currentId].edges.length; i++) {
    const currentEdgeId = nodeEdgeIds[i];
    const currentEdge = graph.edges[currentEdgeId];
    const childNodeId = currentEdge.target;

    if (isCyclic(graph, childNodeId, visited, recStack)) {
      return true;
    }
  }

  recStack[currentId] = false;

  return false;
};

const checkCyclic = (graph: CyclicCheckGraph): boolean => {
  const visited: Record<number, boolean> = {};
  const recStack: Record<number, boolean> = {};

  const nodes = Object.values(graph.nodes);

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];

    if (isCyclic(graph, node.id, visited, recStack)) {
      return true;
    }
  }

  return false;
};

const buildCyclicCheckGraph = (
  nodes: GCNode[],
  edges: GCEdge[]
): CyclicCheckGraph => {
  const graph: CyclicCheckGraph = {
    nodes: {},
    edges: {}
  };

  nodes.forEach(node => {
    graph.nodes[node.id] = {
      id: node.id,
      edges: edges.filter(e => e.source === node.id).map(e => e.id)
    };
  });

  edges.forEach(edge => {
    graph.edges[edge.id] = {
      id: edge.id,
      source: edge.source,
      target: edge.target,
      isDefaultBranch: edge.isDefaultBranch
    };
  });

  return graph;
};

export const validateGraph = (nodes: GCNode[], edges: GCEdge[]): string => {
  const rootNodes = nodes.filter(
    node => edges.findIndex(edge => edge.target === node.id) === -1
  );

  if (rootNodes.length > 1)
    return 'More than one root node (nodes without incoming edges) found.';

  if (rootNodes.length === 0)
    return 'No root node (nodes without incoming edges) found.';

  const graph = buildCyclicCheckGraph(nodes, edges);
  if (checkCyclic(graph)) return 'Cyclic reference found.';

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];

    if (node.type === NodeTypeKey.DECISION_PACKAGE) {
      if (!node.decisionPackage?.name) {
        return `Decision package name is empty for node with id ${node.id}`;
      }
    } else {
      if (!node.decider?.type) {
        return `Decider type is empty for node with id ${node.id}`;
      }

      if (!node.decider?.name) {
        return `Decider name is empty for node with id ${node.id}`;
      }

      if (!node.decidingVariable?.name) {
        return `Deciding variable is empty for node with id ${node.id}`;
      }

      const defaultBranchCount = graph.nodes[node.id].edges.reduce(
        (prev, curr) => prev + (graph.edges[curr].isDefaultBranch ? 1 : 0),
        0
      );

      if (defaultBranchCount !== 1) {
        return `Node with id ${node.id} and decider name ${node.decider.name} should have exactly one default branch. Found ${defaultBranchCount}`;
      }
    }
  }

  for (let i = 0; i < edges.length; i++) {
    const edge = edges[i];

    if (!Number.isInteger(edge.priority) || edge.priority <= 0) {
      return `Priority is empty for edge with id ${edge.id}`;
    }

    if (!edge.isDefaultBranch) {
      if (!edge.operator) {
        return `Operator is empty for edge with id ${edge.id}`;
      }

      if (!isUnaryOperator(edge.operator.name)) {
        if (
          edge.conditionValue === '' ||
          edge.conditionValue === null ||
          edge.conditionValue === undefined
        ) {
          return `Condition value is empty for edge with id ${edge.id}`;
        }
      }
    }

    if (!edge.source) {
      return `No source node for edge with id ${edge.id}`;
    }

    if (!edge.target) {
      return `No target node for edge with id ${edge.id}`;
    }
  }

  return '';
};

export const generateGraphData = (
  nodes: GCNode[],
  edges: GCEdge[]
): SaveGraphData => {
  const rootNodes = nodes.filter(node => {
    return edges.findIndex(edge => edge.target === node.id) === -1;
  });

  return {
    nodes: nodes.map(node => ({
      id: node.id,
      nodeType:
        node.type === NodeTypeKey.NORMAL ? LINKED_GRAPH_NODE : node.type,
      data:
        node.type === NodeTypeKey.DECISION_PACKAGE
          ? {
              name: node.decisionPackage?.name
            }
          : {
              deciderType: node.decider?.type,
              deciderName: node.decider?.name,
              decidingVariable: node.decidingVariable?.name,
              edges: edges
                .filter(edge => edge.source === node.id)
                .map(edge => edge.id)
            }
    })),
    edges: edges.map(edge => ({
      id: edge.id,
      priority: edge.priority,
      isDefaultBranch: edge.isDefaultBranch,
      operator: edge.isDefaultBranch ? undefined : edge.operator?.name,
      conditionValue:
        !edge.isDefaultBranch && !isUnaryOperator(edge.operator?.name as string)
          ? edge.conditionValue
          : undefined,
      sourceNode: edge.source,
      targetNode: edge.target
    })),
    rootNode: rootNodes[0].id
  };
};

export const generateClientFormatFromGraph = (
  graphData: SaveGraphData,
  editorData: SaveGraphEditorData,
  operators: Operators
): GCData => {
  let res: GCData = {
    nodes: [],
    edges: [],
    lastId: 0
  };

  graphData.nodes.forEach(node => {
    const params: { [T in keyof NodeData]?: NodeData[T] } =
      node.nodeType === NodeTypeKey.DECISION_PACKAGE
        ? {
            decisionPackage: {
              id: 0,
              name: node.data?.name || ''
            }
          }
        : {
            decider: {
              id: 0,
              displayName: '',
              type: node.data?.deciderType || '',
              name: node.data?.deciderName || ''
            },
            decidingVariable: {
              id: 0,
              description: '',
              operatorType: '',
              name: node.data?.decidingVariable || ''
            }
          };

    res = addNode(
      res,
      {
        x: editorData[node.id].x,
        y: editorData[node.id].y,
        type: (node.nodeType === LINKED_GRAPH_NODE
          ? NodeTypeKey.NORMAL
          : node.nodeType) as NodeTypeKey,
        ...params
      },
      node.id
    );
  });

  graphData.edges.forEach(edge => {
    const id = edge.id;
    const operator = getOperator(operators, edge.operator || '');

    res = addEdge(
      res,
      {
        source: edge.sourceNode || 0,
        target: edge.targetNode || 0,
        priority: Number(edge.priority),
        isDefaultBranch: edge.isDefaultBranch,
        operator: operator,
        conditionValue: edge.conditionValue
      },
      id
    );
  });

  return res;
};

export const generateGraphEditorData = (
  nodes: GCNode[]
): SaveGraphEditorData => {
  const r: SaveGraphEditorData = {};

  nodes.forEach(node => {
    r[node.id] = {
      x: node.x,
      y: node.y
    };
  });

  return r;
};
