import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import CaseWhitelist from '../case-details/CaseWhitelist';
import {
  CaseWhitelistState,
  CaseWhitelistSubmitParams,
  WhitelistAttributeCombination
} from '../../../types/caseManagement';
import { toast } from 'react-toastify';

type Props = {
  open: boolean;
  whitelistTags?: string[];
  whitelistAttributeCombinations?: WhitelistAttributeCombination[];
  onSubmit?: (params: CaseWhitelistSubmitParams) => void;
  onCancel?: () => void;
};

const MarkTrustedModal = ({
  open,
  whitelistTags = [],
  whitelistAttributeCombinations = [],
  onSubmit,
  onCancel
}: Props) => {
  const [whitelistState, setWhitelistState] = useState<CaseWhitelistState>({
    isActive: false,
    attributeCombinationId: 0,
    isTagSelected: {},
    note: ''
  });

  const handleChange = (value: CaseWhitelistState) => {
    setWhitelistState(value);
  };

  const validate = (params: CaseWhitelistSubmitParams) => {
    const messageList: string[] = [];
    if (params.attributeCombinations.length <= 0 && params.tags.length > 0) {
      messageList.push(
        'Whitelist tags must be empty if no attribute is selected.'
      );
    }

    if (params.attributeCombinations.length > 0 && params.tags.length <= 0) {
      messageList.push('Whitelist tags is required.');
    }

    if (params.notes.trim() === '') {
      messageList.push('Whitelist notes is required.');
    }

    return messageList;
  };

  const handleSubmit = () => {
    const whitelistParams: CaseWhitelistSubmitParams = {
      attributeCombinations:
        whitelistAttributeCombinations.find(
          wlac => wlac.id === whitelistState.attributeCombinationId
        )?.list || [],
      notes: whitelistState.note,
      tags: Object.keys(whitelistState.isTagSelected).filter(
        k => whitelistState.isTagSelected[k]
      )
    };

    const errMessages = validate(whitelistParams);

    if (errMessages.length > 0) {
      toast.error(
        <ul>
          {errMessages.map((message, i) => (
            <li key={`error-message-${i}`}>{message}</li>
          ))}
        </ul>
      );

      return;
    }

    onSubmit?.(whitelistParams);
  };

  useEffect(() => {
    if (open === true) {
      setWhitelistState({
        isActive: false,
        attributeCombinationId: 0,
        isTagSelected: {},
        note: ''
      });
    }
  }, [open]);

  return (
    <Modal open={open} size="tiny">
      <Modal.Header>Confirmation</Modal.Header>
      <Modal.Content>
        <CaseWhitelist
          attributeCombinations={whitelistAttributeCombinations}
          attributeCombinationId={whitelistState.attributeCombinationId}
          hideFields
          showAllAttrCombinations
          isActive={whitelistState.isActive}
          whitelistTags={whitelistTags}
          isTagSelected={whitelistState.isTagSelected}
          note={whitelistState.note}
          onChange={handleChange}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button content="Cancel" onClick={onCancel} />
        <Button primary content="Submit" onClick={handleSubmit} />
      </Modal.Actions>
    </Modal>
  );
};

export default MarkTrustedModal;
