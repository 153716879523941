import React, { useState, useEffect, useContext } from 'react';
import Header from '../../components/header/Header';
import Layout from '../../components/Layout';
import { Grid, Confirm } from 'semantic-ui-react';
import CaseSummary from '../../components/case-management/case-details/CaseSummary';
import { useParams, useHistory } from 'react-router-dom';
import CaseRemediation from '../../components/case-management/case-details/CaseRemediation';
import {
  SummaryAPIResponse,
  DetectorAPIResponse,
  CaseRemediationSubmitParams,
  WhitelistAttributeCombination,
  CaseBlacklistSubmitParams,
  CaseAccountDeactivationSubmitParams,
  CaseWhitelistSubmitParams,
  CaaseMarkingToolsSubmitParams,
  CaseNotesSubmitParams,
  CaseNotesData,
  CommonAPIResponse,
  ActionHistoryData,
  CaseResetPasswordSubmitParams
} from '../../types/caseManagement';
import configs from '../../configs';
import { toast } from 'react-toastify';
import CaseMarkingTools from '../../components/case-management/case-details/CaseMarkingTools';
import CaseNotes from '../../components/case-management/case-details/CaseNotes';
import CaseDetectorResponse from '../../components/case-management/case-details/CaseDetectorResponse';
import CaseProductRequestDetails from '../../components/case-management/case-details/CaseProductRequestDetails';
import CaseActionHistory from '../../components/case-management/case-details/CaseActionHistory';
import { CASE_DETAILS_WIKILINK } from '../../constants/caseManagement';
import {
  CASE_MARK_STATUS,
  CASE_BLACKLIST,
  CASE_WHITELIST,
  CASE_USER_BLOCK,
  CASE_RESET_PASSWORD,
  CASE_QUEUE_WRITE
} from '../../constants/userPermissions';
import axios from 'axios';
import { AppContext } from '../../AppContext';
import { showErrorToast } from '../../utils/common';
import { css } from 'emotion';

enum RemediationSaveStatus {
  SUCCESS,
  PARTIAL,
  FAIL
}

const remediationStatusToMessage = {
  [RemediationSaveStatus.SUCCESS]: 'success',
  [RemediationSaveStatus.PARTIAL]: 'partially success',
  [RemediationSaveStatus.FAIL]: 'failed'
};

const noPadLeftStyle = css`
  & {
    padding-left: 0 !important;
  }
`;

const cardWrapperStyle = css`
  & .ui.card .content:nth-child(1) {
    background: #ccc;
  }
`;

const CaseDetails = () => {
  const { id } = useParams();

  const history = useHistory();

  const [{ userPermissions }] = useContext(AppContext);

  const [summaryData, setSummaryData] = useState<SummaryAPIResponse>();
  const [productRequestDetailsData, setProductRequestDetailsData] =
    useState<CommonAPIResponse>();
  const [detectorResponseData, setDetectorResponseData] =
    useState<DetectorAPIResponse>();
  const [whitelistTags, setWhitelistTags] = useState<string[]>([]);
  const [whitelistAttributeCombinations, setWhitelistAttributeCombinations] =
    useState<WhitelistAttributeCombination[]>([]);
  const [blacklistTags, setBlacklistTags] = useState<string[]>([]);
  const [markingStatuses, setMarkingStatuses] = useState<
    { name: string; displayName: string }[]
  >([]);
  const [notesData, setNotesData] = useState<CaseNotesData[]>([]);
  const [actionHistoryData, setActionHistoryData] = useState<
    ActionHistoryData[]
  >([]);

  const [isAddQueueConfirmationOpen, setIsAddQueueConfirmationOpen] =
    useState(false);

  const fetchCaseSummary = async () => {
    try {
      const response = await axios.get(
        `${configs.caseDetailsSummary}?caseId=${id}`
      );

      setSummaryData(response.data);
    } catch (err) {
      showErrorToast(err);
    }
  };

  const fetchCaseDetectorResponse = async () => {
    try {
      const response = await axios.get(
        configs.caseDetailsDetectorResponse.replace(':caseId', id)
      );

      setDetectorResponseData(response.data);
    } catch (err) {
      showErrorToast(err);
    }
  };

  const fetchProductRequestDetails = async () => {
    try {
      const response = await axios.get(
        configs.caseDetailsProductRequest.replace(':caseId', id)
      );

      setProductRequestDetailsData(response.data);
    } catch (err) {
      showErrorToast(err);
    }
  };

  const fetchMarkingStatuses = async () => {
    try {
      const response = await axios.get(configs.caseMarkingStatuses);

      setMarkingStatuses(response.data);
    } catch (err) {
      showErrorToast(err);
    }
  };

  const fetchWLTags = async () => {
    try {
      const response = await axios.get(configs.whitelistTags);

      setWhitelistTags(
        response.data.map((tag: { displayName: string }) => tag.displayName)
      );
    } catch (err) {
      showErrorToast(err);
    }
  };

  const fetchWLAttributeCombinations = async () => {
    try {
      const response = await axios.get(configs.whitelistAttributeCombinations);

      setWhitelistAttributeCombinations(response.data);
    } catch (err) {
      showErrorToast(err);
    }
  };

  const fetchBLTags = async () => {
    try {
      const response = await axios.get(configs.blacklistTags);

      setBlacklistTags(response.data.map((tag: { name: string }) => tag.name));
    } catch (err) {
      showErrorToast(err);
    }
  };

  const fetchNotes = async () => {
    try {
      const response = await axios.get(
        configs.caseDetailsNotes.replace(':caseId', id)
      );

      setNotesData(response.data.content);
    } catch (err) {
      showErrorToast(err);
    }
  };

  const fetchActionHistory = async () => {
    try {
      const response = await axios.get(
        configs.caseDetailsActionHistory.replace(':caseId', id)
      );

      setActionHistoryData(response.data.content);
    } catch (err) {
      showErrorToast(err);
    }
  };

  const saveRemediation = async (
    url: string,
    params:
      | CaseWhitelistSubmitParams
      | CaseBlacklistSubmitParams
      | CaseAccountDeactivationSubmitParams
      | CaseResetPasswordSubmitParams
  ): Promise<RemediationSaveStatus> => {
    return new Promise(async resolve => {
      try {
        const response = await axios.post(url.replace(':caseId', id), params);

        if (response.status === 200) {
          resolve(RemediationSaveStatus.SUCCESS);
        } else if (response.status === 206) {
          resolve(RemediationSaveStatus.PARTIAL);
        }
      } catch (err) {
        resolve(RemediationSaveStatus.FAIL);
      }
    });
  };

  const handleRemediationSubmit = async (
    params: CaseRemediationSubmitParams
  ) => {
    const promises: Promise<RemediationSaveStatus>[] = [];
    const labels: string[] = [];

    if (params.whitelist) {
      labels.push('Whitelist');

      promises.push(
        saveRemediation(
          configs.caseDetailsRemediationWhitelist,
          params.whitelist
        )
      );
    }

    if (params.blacklist) {
      labels.push('Blacklist');

      promises.push(
        saveRemediation(
          configs.caseDetailsRemediationBlacklist,
          params.blacklist
        )
      );
    }

    if (params.accountDeactivation) {
      labels.push('Account Deactivation');

      promises.push(
        saveRemediation(
          configs.caseDetailsRemediationAccountDeactivation,
          params.accountDeactivation
        )
      );
    }

    if (params.resetPassword) {
      labels.push('Reset Password');

      promises.push(
        saveRemediation(
          configs.caseDetailsRemediationResetPassword,
          params.resetPassword
        )
      );
    }

    const res = await Promise.all(promises);

    const message = (
      <div>
        <ul>
          {res.map((status, i) => (
            <li key={labels[i]}>
              {labels[i]} Remediation {remediationStatusToMessage[status]}.
            </li>
          ))}
        </ul>

        <p>Please check Action History for the details.</p>
      </div>
    );

    const hasSuccess =
      res.find(status => status === RemediationSaveStatus.SUCCESS) !==
      undefined;

    const hasFail =
      res.find(
        status =>
          status === RemediationSaveStatus.FAIL ||
          status === RemediationSaveStatus.PARTIAL
      ) !== undefined;

    if (!hasFail) {
      toast.success(message);
    } else if (!hasSuccess) {
      toast.error(message);
    } else {
      toast.warn(message);
    }

    fetchActionHistory();
  };

  const handleMarkingToolsSubmit = async (
    params: CaaseMarkingToolsSubmitParams
  ) => {
    try {
      await axios.post(
        configs.caseDetailsMarkStatus.replace(':caseId', id),
        params
      );

      toast.success(
        'Marking status success. Please check Action History for the details.'
      );

      fetchCaseSummary();
      fetchActionHistory();
    } catch (err) {
      if (err.response?.status === 400 || err.response?.status === 404) {
        showErrorToast(err, 'warn');
      } else {
        toast.error(
          'Marking status failed. Please check Action History for the details.'
        );
      }
    } finally {
      fetchActionHistory();
    }
  };

  const handleNotesSubmit = async (params: CaseNotesSubmitParams) => {
    try {
      await axios.post(configs.caseDetailsNotes.replace(':caseId', id), params);

      toast.success('Successfully added notes.');

      fetchNotes();
    } catch (err) {
      showErrorToast(err);
    }
  };

  const handleAddToReview = async () => {
    setIsAddQueueConfirmationOpen(false);

    if (!summaryData) return;

    try {
      await axios.put(configs.caseQueueAdd, {
        caseIds: [summaryData.caseId]
      });

      setSummaryData({ ...summaryData, reviewStatus: 'IN_REVIEW' });

      toast.success('Add to review success');
    } catch (err) {
      showErrorToast(err);
    }
  };

  useEffect(() => {
    fetchWLTags();
    fetchWLAttributeCombinations();
    fetchBLTags();
    fetchMarkingStatuses();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchCaseSummary();
    fetchProductRequestDetails();
    fetchCaseDetectorResponse();
    fetchNotes();
    fetchActionHistory();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <Header
        className="header-list-add"
        groupTitle="Case Management"
        title="Case Details"
        wikiLink={CASE_DETAILS_WIKILINK}
      />

      <Grid columns={2} className={cardWrapperStyle}>
        <Grid.Row>
          <Grid.Column width={9}>
            <CaseSummary
              data={summaryData}
              pushHistory={history.push}
              onAddToReview={() => setIsAddQueueConfirmationOpen(true)}
              hideAddToReview={!userPermissions[CASE_QUEUE_WRITE]}
            />
            <CaseNotes data={notesData} onSubmit={handleNotesSubmit} />
          </Grid.Column>
          <Grid.Column width={7} className={noPadLeftStyle}>
            <CaseMarkingTools
              disabled={!userPermissions[CASE_MARK_STATUS]}
              options={markingStatuses}
              onSubmit={handleMarkingToolsSubmit}
            />
            <CaseRemediation
              blacklistDisabled={!userPermissions[CASE_BLACKLIST]}
              whitelistDisabled={!userPermissions[CASE_WHITELIST]}
              accountDeactivationDisabled={!userPermissions[CASE_USER_BLOCK]}
              resetPasswordDisabled={!userPermissions[CASE_RESET_PASSWORD]}
              data={summaryData}
              whitelistTags={whitelistTags}
              whitelistAttributeCombinations={whitelistAttributeCombinations}
              blacklistTags={blacklistTags}
              onSubmit={handleRemediationSubmit}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <div className={cardWrapperStyle} style={{ margin: '1em 0' }}>
        <CaseActionHistory data={actionHistoryData} />
      </div>

      <Grid columns={2} className={cardWrapperStyle}>
        <Grid.Row>
          <Grid.Column width={8}>
            <CaseProductRequestDetails data={productRequestDetailsData} />
          </Grid.Column>
          <Grid.Column width={8} className={noPadLeftStyle}>
            <CaseDetectorResponse data={detectorResponseData} />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Confirm
        open={isAddQueueConfirmationOpen}
        size="mini"
        header="Confirmation"
        content={'Are you sure to add this case to queue?'}
        onCancel={() => setIsAddQueueConfirmationOpen(false)}
        onConfirm={handleAddToReview}
      />
    </Layout>
  );
};

export default CaseDetails;
