import { css } from 'react-emotion';

export const normalLayoutStyle = css`
  & {
    padding-top: 10px;
    padding-right: 20px;
    transition: padding-left 0.2s ease-in-out;
    height: 100vh;
    overflow: auto;
  }

  &.open {
    padding-left: 240px;
  }

  &.collapsed {
    padding-left: 80px;
  }
`;

export const fullLayoutStyle = css`
  & {
    padding-top: 0;
    padding-right: 0;
    height: 100vh;
    transition: padding-left 0.2s ease-in-out;
    overflow: auto;
  }

  &.open {
    padding-left: 0;
  }

  &.collapsed {
    padding-left: 0;
  }
`;
