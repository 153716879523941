import React from 'react';
import UploadFile from '../../views/list-management/list-addition/UploadFile';
import BlackListForm from './BlackListAdditionForm';
import { listAdditionStyle } from '../../views/list-management/list-addition/WhiteListAddition.style';

const BlackListAddittion = () => {
  return (
    <div className={listAdditionStyle}>
      <BlackListForm />
      <UploadFile />
    </div>
  );
};
export default BlackListAddittion;
