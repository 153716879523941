import { Dispatch } from 'react';
import get from 'lodash/get';
import * as commActivityActionType from '../../types/investigation-tools/commActivityActionType';
import { PaginationType } from '../../constants/investigationToolsConstant';
import { getPagination } from '../../reducers/investigation-tools/selectors';
import { setTotalNumberOfPages, goToAPage } from './paginationAction';
import { searchCommActivity } from './serviceAction';
import { showErrorToast } from '../../utils/common';
import { format } from 'date-fns';

type Entry = {
  [key: string]: string | number | null | Entry;
};

type Spec = {
  clientInterface: string;
  data: {
    currentPageNo: number;
    searchAttributes: {
      profileId: string;
      topic: string;
      messageType: string;
      startDate: any;
      endDate: any;
      searchLimit: string;
    };
  };
};

type Result = {
  data: {
    communicationHistoryList: Entry[];
    pageCount: number;
    currentPageNo: number;
  };
};

export const setIsLoading = (value: boolean) => ({
  type: commActivityActionType.SET_IS_LOADING_STATE,
  value
});

export const setMessageType = (value: string) => ({
  type: commActivityActionType.SET_MESSAGE_TYPE,
  value
});

export const setProfileId = (value: string) => ({
  type: commActivityActionType.SET_PROFILE_ID,
  value
});

export const setTopic = (value: string) => ({
  type: commActivityActionType.SET_TOPIC,
  value
});

export const setSearchLimit = (value: string) => ({
  type: commActivityActionType.SET_SEARCH_LIMIT,
  value
});

export const setEntries = (entries: Entry[]) => ({
  type: commActivityActionType.SET_ENTRIES,
  entries
});

export const setStartDate = (value: Date) => ({
  type: commActivityActionType.SET_START_DATE,
  value
});

export const setEndDate = (value: Date) => ({
  type: commActivityActionType.SET_END_DATE,
  value
});

export const fetchCommActivity = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
  getState: Function
) => {
  const state = getState();
  const pagination = await getPagination(
    state,
    PaginationType.COMM_ACTIVITY_TABLE
  );

  const endDate = get(state.comm_activity, 'endDate', null);
  const spec: Spec = {
    clientInterface: '',
    data: {
      currentPageNo: get(pagination, 'currentPage', 1),
      searchAttributes: {
        profileId: get(state.comm_activity, 'profileId', null),
        topic: get(state.comm_activity, 'topic', null),
        messageType: get(state.comm_activity, 'messageType', null),
        startDate: format(get(state.comm_activity, 'startDate', null), 'T'),
        endDate: endDate ? format(endDate, 'T') : null,
        searchLimit: get(state.comm_activity, 'searchLimit', null)
      }
    }
  };

  return searchCommActivity(spec)
    .then((result: Partial<Result>) => {
      dispatch(setEntries(result.data.communicationHistoryList));
      return result;
    })
    .catch((err: Error) => {
      showErrorToast(err);
      dispatch(setTotalNumberOfPages(PaginationType.COMM_ACTIVITY_TABLE, 1));
      dispatch(goToAPage(PaginationType.COMM_ACTIVITY_TABLE, 1));
      return null;
    });
};
