import { css } from 'emotion';

export const dtWrapperStyle = css`
  & {
    position: relative;
    width: 100%;
  }

  &.maximized {
    height: 100%;
  }
`;

export const dtActionsStyle = css`
  & {
    position: absolute;
    top: 0;
    left: 0;
    margin: 1em;
  }

  &.vertical {
    display: flex;
    flex-direction: column;
  }

  &.vertical button {
    margin-bottom: 0.5em !important;
  }
`;

export const dtTooltipStyle = css`
  & {
    position: absolute;
    top: 0;
    left: 0;
    background: #2d3748;
    opacity: 0.8;
    color: white;
    padding: 1em;
    border-radius: 0.5em;
    text-align: center;
    pointer-events: none;
    user-select: none;
    max-width: 20em;
    word-break: break-word;
  }
`;

export const dtTooltipContentStyle = css`
  & {
    margin-top: 1em;
  }
`;

export const dtConsoleWrapperStyle = css`
  & {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
`;
