import React from 'react';
import Layout from './Layout';

const Default = () => (
  <Layout>
    <div />
  </Layout>
);

export default Default;
