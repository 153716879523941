import { Dispatch } from 'react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import * as BookingDetailActionType from '../../types/investigation-tools/bookingDetailActionType';
import { PaginationType } from '../../constants/investigationToolsConstant';
import {
  getPagination,
  getBookingDetail
} from '../../reducers/investigation-tools/selectors';
import { downloadBooking, searchBooking } from './serviceAction';
import { showErrorToast } from '../../utils/common';
import fileDownload from 'js-file-download';
import { format } from 'date-fns';

const MORE_RECORDS_GOTTEN_ERR = 1111;

type BookingDetail = {
  [key: string]: string | number | null | BookingDetail;
};

type Spec = {
  clientInterface: string;
  data: {
    currentPageNo: number;
    searchAttributes: {
      searchType: string;
      matchType: string;
      searchValue: string;
      filterAttributes: {
        [key: string]: string;
      };
      startDatetime: string;
      endDatetime: string;
    };
  };
};

type Result = {
  data: {
    bookingDetailList: BookingDetail[];
    pageCount: number;
    currentPageNo: number;
  };
};

type DownloadResult = {
  data: string;
};

export const setIsLoading = (value: boolean) => ({
  type: BookingDetailActionType.SET_IS_LOADING_STATE,
  value
});

export const setIsLoaded = (value: boolean) => ({
  type: BookingDetailActionType.SET_IS_LOADED_STATE,
  value
});

export const setKeyword = (value: string) => ({
  type: BookingDetailActionType.SET_KEYWORD,
  value
});

export const setSearchType = (value: string) => ({
  type: BookingDetailActionType.SET_SEARCH_TYPE,
  value
});

export const setMatchType = (value: string) => ({
  type: BookingDetailActionType.SET_MATCH_TYPE,
  value
});

export const setEntries = (entries: BookingDetail[]) => ({
  type: BookingDetailActionType.SET_ENTRIES,
  entries
});

export const setFilterAttribute = (attribute: string, value: string) => ({
  type: BookingDetailActionType.SET_FILTER_ATTRIBUTE,
  attribute,
  value
});

export const setStartDate = (value: Date) => ({
  type: BookingDetailActionType.SET_START_DATE,
  value
});

export const setEndDate = (value: Date) => ({
  type: BookingDetailActionType.SET_END_DATE,
  value
});

export const setIsDownloading = (value: boolean) => ({
  type: BookingDetailActionType.SET_IS_DOWNLOADING,
  value
});

export const fetchBookingDetail = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
  getState: Function
): Promise<Partial<Result>> => {
  const state = getState();
  const pagination = getPagination(state, PaginationType.BOOKING_DETAIL_TABLE);

  const searchAttributes = extractSearchAttributes(state);
  const spec: Spec = {
    clientInterface: '',
    data: {
      currentPageNo: get(pagination, 'currentPage', 1),
      searchAttributes
    }
  };

  dispatch(setIsLoading(true));
  dispatch(setIsLoaded(false));

  return searchBooking(spec)
    .then((result: Partial<Result>) => {
      const bookingDetailList: BookingDetail[] = get(
        result,
        'data.bookingDetailList',
        null
      );

      if (isArray(bookingDetailList)) dispatch(setEntries(bookingDetailList));

      return result;
    })
    .catch((err: Error) => {
      showErrorToast(err);

      return {};
    })
    .finally(() => {
      dispatch(setIsLoading(false));
      dispatch(setIsLoaded(true));
    });
};

export const downloadBookingDetail = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
  getState: Function
) => {
  const state = getState();
  const searchAttributes = extractSearchAttributes(state);

  const spec: Spec = {
    clientInterface: '',
    data: {
      currentPageNo: 1,
      searchAttributes
    }
  };

  dispatch(setIsDownloading(true));

  downloadBooking(spec)
    .then((result: Partial<DownloadResult>) => {
      fileDownload(result.data, 'booking_detail.csv');
    })
    .catch((err: any) => {
      if (err?.response?.data?.code === MORE_RECORDS_GOTTEN_ERR) {
        showErrorToast({
          message: 'Please limit the number of records to 1000'
        });
      } else {
        showErrorToast(err);
      }
    })
    .finally(() => {
      dispatch(setIsDownloading(false));
    });
};

function extractSearchAttributes(state) {
  const table = getBookingDetail(state);
  const startDate = get(table, 'startDate', null);
  const endDate = get(table, 'endDate', null);

  return {
    searchType: get(table, 'searchType', null),
    matchType: get(table, 'matchType', null),
    searchValue: get(table, 'keyword', null),
    filterAttributes: get(table, 'filterAttributes', null),
    startDatetime: startDate ? format(startDate, 'yyyy-MM-dd 00:00:00') : null,
    endDatetime: endDate ? format(endDate, 'yyyy-MM-dd 23:59:59') : null
  };
}
