import get from 'lodash/get';
import {
  UserProfileContextFilterAttributes,
  UserProfileSearchType
} from '../../constants/investigationToolsConstant';
import { actionHandler } from './commonReducerLogic';
import * as CommonActionType from '../../types/investigation-tools/commonActionType';

type Entry = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

type UserProfileContextSearchState = {
  isLoading: boolean;
  isLoaded: boolean;
  keyword: string;
  searchType: string;
  filterAttributes: {
    [key: string]: string;
  };
  entries: Entry[];
};

export const initialState: UserProfileContextSearchState = {
  isLoading: false,
  isLoaded: false,
  keyword: '',
  searchType: get(UserProfileSearchType, 0, null),
  filterAttributes: {
    [UserProfileContextFilterAttributes.PROFILE_FULL_NAME]: '',
    [UserProfileContextFilterAttributes.EMAIL_ADDRESS]: ''
  },
  entries: []
};

export function UserProfileContextSearchReducer(
  state = initialState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any
) {
  switch (action.type) {
    case CommonActionType.SET_FILTER_ATTRIBUTE: {
      state.filterAttributes[action.attribute] = action.value;
      return state;
    }
    default:
      return actionHandler[action.type]
        ? actionHandler[action.type](state, action)
        : state;
  }
}
