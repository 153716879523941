import React, { useEffect, useState } from 'react';
import isFunction from 'lodash/isFunction';
import isFinite from 'lodash/isFinite';

import PaginationControl from '../../../components/common/PaginationControl/PaginationControl';
import { PaginationType } from '../../../constants/investigationToolsConstant';
import { getPagination } from '../../../reducers/investigation-tools/selectors';
import { goToAPage } from '../../../actions/investigation-tools/paginationAction';
import { useBookingDetailContext } from '../../../views/investigation-tools/booking-detail/BookingDetailContext';
import './BookingDetailPaginationContainer.style.css';

const BookingDetailPaginationContainer = () => {
  const { getState, paginationDispatch, handleSearch } =
    useBookingDetailContext();
  const [currentPageNumber, setCurrentPageNumber] = useState(0);

  const state = getState();
  const { currentPage, totalNumberOfPages } = getPagination(
    state,
    PaginationType.BOOKING_DETAIL_TABLE
  );

  useEffect(() => {
    if (currentPageNumber === currentPage && isFunction(handleSearch)) {
      handleSearch();
    }
  }, [currentPageNumber, currentPage, handleSearch]);

  const paginationControlProps = {
    currentPageNumber: isFinite(currentPage) ? currentPage : 1,
    totalNumberOfPages: isFinite(totalNumberOfPages) ? totalNumberOfPages : 1,
    onPageNumberClick: pageNumber => {
      setCurrentPageNumber(pageNumber);
      paginationDispatch(
        goToAPage(PaginationType.BOOKING_DETAIL_TABLE, pageNumber)
      );
    }
  };

  return (
    <div className="booking-detail-pagination-container">
      <PaginationControl {...paginationControlProps} />
    </div>
  );
};

export default BookingDetailPaginationContainer;
