import React, { useRef, useContext } from 'react';
import { Confirm } from 'semantic-ui-react';
import ResultTable from '../../components/case-management/case-search/ResultTable';
import {
  CaseResultTableDetail,
  RemediationAction,
  FilterData,
  MarkingStatus,
  CaseBlacklistSubmitParams,
  MarkingStatusResultData,
  BulkRemediationAPIResponse,
  BulkMarkSuspectAPIResponseCases,
  BulkMarkNeutralAPIResponseCases,
  CaseWhitelistSubmitParams,
  BulkMarkTrustedAPIResponseCases
} from '../../types/caseManagement';
import { toast } from 'react-toastify';
import configs from '../../configs';
import { AppContext } from '../../AppContext';
import {
  CASE_MARK_STATUS,
  CASE_USER_BLOCK,
  CASE_RESET_PASSWORD,
  CASE_FORCE_LOGOUT,
  CASE_BLACKLIST,
  CASE_WHITELIST,
  CASE_QUEUE_WRITE
} from '../../constants/userPermissions';
import MarkSuspectModal from '../../components/case-management/case-search/MarkSuspectModal';
import MarkTrustedModal from '../../components/case-management/case-search/MarkTrustedModal';
import ProgressModal from '../../components/case-management/case-search/ProgressModal';
import { showErrorToast } from '../../utils/common';
import MarkStatusResultTable from '../../components/case-management/case-search/MarkStatusResultTable';
import RemediationModal from '../../components/case-management/case-search/RemediationModal';
import MarkNeutralModal from '../../components/case-management/case-search/MarkNeutralModal';
import axios from 'axios';
import {
  ModalState,
  ActionType,
  CaseResultState,
  CaseAction
} from '../../reducers/caseManagement';
import { axiosNoLoading } from '../../utils/axiosUtils';
import {
  UNEXPECTED_ERROR_TEXT,
  ABORTED_TEXT,
  BATCH_SIZE
} from '../../constants/caseManagement';

type Props = {
  state: CaseResultState;
  dispatch: React.Dispatch<CaseAction>;
  addToReviewDisabled?: boolean;
  fetchAndSetData: (
    filterData?: FilterData,
    tableSearchData?: Record<string, string>,
    page?: number
  ) => Promise<void>;
};

const getRemediationActionName = (action: RemediationAction) => {
  if (action === RemediationAction.DEACTIVATE) return 'Account Deactivation';
  if (action === RemediationAction.RESET_PASSWORD) return 'Reset Password';
  if (action === RemediationAction.FORCE_LOGOUT) return 'Force Logout';

  return '';
};

const CaseResult = ({
  state,
  dispatch,
  addToReviewDisabled = false,
  fetchAndSetData
}: Props) => {
  const [{ userPermissions }] = useContext(AppContext);

  // ref needed to jump to result when clicking on link after bulk process
  const actionResultRef = useRef<HTMLDivElement>(null);

  const currentTabState = state.tabs[state.currentTabIndex];

  const fetchBLTags = async () => {
    if (state.blacklistTags.length > 0) return;

    try {
      const response = await axios.get(configs.blacklistTags);

      dispatch({
        type: 'SET_BLACKLIST_TAGS',
        payload: response.data.map((tag: { name: string }) => tag.name)
      });
    } catch (err) {
      showErrorToast(err, 'error', 'Error loading BL Tags');
    }
  };

  const fetchWLAttributeCombinations = async () => {
    if (state.whitelistAttributeCombinations.length > 0) return;

    try {
      const response = await axios.get(configs.whitelistAttributeCombinations);

      dispatch({
        type: 'SET_WHITELIST_ATTRIBUTES',
        payload: response.data
      });
    } catch (err) {
      showErrorToast(err, 'error', 'Error loading WL Attributes');
    }
  };

  const onResultPageChange = async (newPage: number) => {
    await fetchAndSetData(
      currentTabState.filterData,
      currentTabState.tableSearchData,
      newPage
    );

    dispatch({ type: 'CHANGE_RESULT_PAGE', payload: { page: newPage } });
  };

  const beginRemediation = async (
    action: RemediationAction,
    selectedData: CaseResultTableDetail[]
  ) => {
    if (action === RemediationAction.FORCE_LOGOUT) {
      toast.warn('This feature will be available soon.');
      return;
    }

    dispatch({ type: 'BEGIN_REMEDIATION', payload: { action, selectedData } });
  };

  const handleRemediationSubmit = async (
    action: RemediationAction,
    note: string
  ) => {
    dispatch({ type: 'SUBMIT_REMEDIATION', payload: { action } });

    processMarkStatusorRemediation(
      async (caseIds: string[]) => {
        const resultData: MarkingStatusResultData[] = [];

        const response = await axiosNoLoading.post(
          configs.caseBulkRemediation,
          {
            caseIds,
            remediationType: action,
            reason: note
          }
        );

        if (response.data.length > 0) {
          (response.data as BulkRemediationAPIResponse).forEach(cs => {
            resultData.push({
              caseId: cs.caseId,
              remediationStatus: 'FAILED',
              relevantAttributes: [],
              tags: [],
              failedAttributes: [
                {
                  attributeType: '',
                  tag: '',
                  failureReason: cs.status
                }
              ],
              action: 'Remediation'
            });
          });
        }

        return resultData;
      },
      ['Remediation']
    );
  };

  const beginMarkStatus = (
    markingStatus: MarkingStatus,
    selectedData: CaseResultTableDetail[]
  ) => {
    dispatch({
      type: 'BEGIN_MARK_STATUS',
      payload: { markingStatus, selectedData }
    });

    fetchBLTags();
    fetchWLAttributeCombinations();
  };

  const markStatusAsTrusted = (params: CaseWhitelistSubmitParams) => {
    dispatch({
      type: 'SUBMIT_MARK_STATUS',
      payload: { markingStatus: 'TRUSTED' }
    });

    processMarkStatusorRemediation(
      async (caseIds: string[]) => {
        const resultData: MarkingStatusResultData[] = [];

        const response = await axiosNoLoading.post(
          configs.caseMultipleMarkTrusted,
          {
            caseIds,
            attributeCombinations: params.attributeCombinations,
            tags: params.tags,
            notes: params.notes
          }
        );

        if (response.data.failedCases && response.data.failedCases.length > 0) {
          response.data.failedCases.forEach(
            (cs: BulkMarkTrustedAPIResponseCases) => {
              resultData.push({
                ...cs,
                relevantAttributes: params.attributeCombinations,
                failedAttributes: cs.failedTags.map(tg => ({
                  attributeType: params.attributeCombinations.join(','),
                  tag: tg.tag,
                  failureReason: tg.failureReason
                })),
                tags: params.tags,
                action: 'Whitelist'
              });

              if (cs.markingFailure) {
                resultData.push({
                  caseId: cs.caseId,
                  remediationStatus: 'FAILED',
                  relevantAttributes: [],
                  tags: [],
                  failedAttributes: [
                    {
                      attributeType: '',
                      tag: '',
                      failureReason: cs.markingFailureReason
                    }
                  ],
                  action: 'Mark as Trusted'
                });
              }
            }
          );
        }

        return resultData;
      },
      ['Whitelist', 'Mark as Trusted']
    );
  };

  const markStatusAsSuspect = (params: CaseBlacklistSubmitParams) => {
    dispatch({
      type: 'SUBMIT_MARK_STATUS',
      payload: { markingStatus: 'SUSPECT' }
    });

    processMarkStatusorRemediation(
      async (caseIds: string[]) => {
        const resultData: MarkingStatusResultData[] = [];

        const response = await axiosNoLoading.post(
          configs.caseMultipleMarkSuspect,
          {
            caseIds,
            attributeTypes: params.attributeTypes,
            tags: params.tags,
            notes: params.notes
          }
        );

        if (response.data.failedCases && response.data.failedCases.length > 0) {
          response.data.failedCases.forEach(
            (cs: BulkMarkSuspectAPIResponseCases) => {
              resultData.push({
                ...cs,
                tags: params.tags,
                action: 'Blacklist'
              });

              if (cs.markingFailure) {
                resultData.push({
                  caseId: cs.caseId,
                  remediationStatus: 'FAILED',
                  relevantAttributes: [],
                  tags: [],
                  failedAttributes: [
                    {
                      attributeType: '',
                      tag: '',
                      failureReason: cs.markingFailureReason
                    }
                  ],
                  action: 'Mark as Suspect'
                });
              }
            }
          );
        }

        return resultData;
      },
      ['Blacklist', 'Mark as Suspect']
    );
  };

  const markStatusAsNeutral = async (selectedTags: string[], note: string) => {
    dispatch({
      type: 'SUBMIT_MARK_STATUS',
      payload: { markingStatus: 'NEUTRAL' }
    });

    processMarkStatusorRemediation(
      async (caseIds: string[]) => {
        const resultData: MarkingStatusResultData[] = [];

        const response = await axiosNoLoading.put(
          configs.caseMultipleMarkNeutral,
          {
            caseIds,
            tags: selectedTags,
            notes: note
          }
        );

        if (response.data.failedCases && response.data.failedCases.length > 0) {
          response.data.failedCases.forEach(
            (cs: BulkMarkNeutralAPIResponseCases) => {
              if (cs.blFailedAttributes && cs.blFailedAttributes.length > 0) {
                resultData.push({
                  ...cs,
                  tags: selectedTags,
                  remediationStatus: cs.blRemediationStatus,
                  action: 'Blacklist Deletion',
                  failedAttributes: cs.blFailedAttributes
                });
              }
              if (cs.wlFailedAttributes && cs.wlFailedAttributes.length > 0) {
                resultData.push({
                  ...cs,
                  tags: selectedTags,
                  remediationStatus: cs.wlRemediationStatus,
                  action: 'Whitelist Deletion',
                  failedAttributes: cs.wlFailedAttributes
                });
              }
              if (cs.markingFailure) {
                resultData.push({
                  caseId: cs.caseId,
                  remediationStatus: 'FAILED',
                  relevantAttributes: [],
                  tags: [],
                  failedAttributes: [
                    {
                      attributeType: '',
                      tag: '',
                      failureReason: cs.markingFailureReason
                    }
                  ],
                  action: 'Mark as Neutral'
                });
              }
            }
          );
        }

        return resultData;
      },
      ['Blacklist Deletion', 'Whitelist Deletion', 'Mark as Neutral']
    );
  };

  const processMarkStatusUnexpectedError = (
    selectedData: CaseResultTableDetail[],
    startIndex: number,
    totalData: number,
    actions: string[]
  ) => {
    const res: MarkingStatusResultData[] = [];

    const batchLastIndex = Math.min(startIndex + 20, totalData);

    for (let i = startIndex; i < totalData; i++) {
      const id = selectedData[i].id;

      actions.forEach(action => {
        res.push({
          caseId: id,
          remediationStatus: 'FAILED',
          relevantAttributes: [],
          tags: [],
          failedAttributes: [
            {
              attributeType: '',
              tag: '',
              failureReason:
                i < batchLastIndex ? UNEXPECTED_ERROR_TEXT : ABORTED_TEXT
            }
          ],
          action
        });
      });
    }

    return res;
  };

  const processMarkStatusorRemediation = async (
    process: (caseIds: string[]) => Promise<MarkingStatusResultData[]>,
    unexpectedErrorActions: string[]
  ) => {
    const totalData = currentTabState.selectedData.length;
    const resultData: MarkingStatusResultData[] = [];

    for (let i = 0; i < totalData; i += BATCH_SIZE) {
      const lastIndex = Math.min(i + 20, totalData);
      const caseIds = currentTabState.selectedData
        .slice(i, lastIndex)
        .map(x => x.id);

      try {
        const result = await process(caseIds);
        Array.prototype.push.apply(resultData, result);

        dispatch({ type: 'SET_PROGRESS', payload: { progress: lastIndex } });
      } catch (err) {
        const res = processMarkStatusUnexpectedError(
          currentTabState.selectedData,
          i,
          totalData,
          unexpectedErrorActions
        );

        Array.prototype.push.apply(resultData, res);

        dispatch({ type: 'SET_PROGRESS', payload: { progress: totalData } });

        break;
      }
    }

    dispatch({ type: 'SET_MARK_STATUS_RESULT_DATA', payload: resultData });

    fetchAndSetData(
      currentTabState.filterData,
      currentTabState.tableSearchData,
      currentTabState.page
    );
  };

  const handleAddToReview = (selectedData: CaseResultTableDetail[]) => {
    dispatch({ type: 'BEGIN_ADD_QUEUE', payload: { selectedData } });
  };

  const processAddToReview = async () => {
    dispatch({ type: 'SUBMIT_ADD_QUEUE' });

    try {
      await axios.put(configs.caseQueueAdd, {
        caseIds: currentTabState.selectedData.map(selected => selected.id)
      });

      toast.success('Add to review success');
    } catch (err) {
      showErrorToast(err);
    }
  };

  const onSearch = async (filter: Record<string, string>) => {
    dispatch({ type: 'SUBMIT_TABLE_SEARCH', payload: { filter } });
    await fetchAndSetData(currentTabState.filterData, filter, 0);
  };

  const handleProgressLinkClick = () => {
    if (!actionResultRef.current) return;

    actionResultRef.current.scrollIntoView();

    dispatch({ type: 'HIDE_MODAL' });
  };

  return (
    <React.Fragment>
      {Object.values(state.tabs).map((tabState, i) => (
        <div
          key={`result-table-${i}`}
          style={{
            display: state.currentTabIndex === i ? 'block' : 'none'
          }}
        >
          <ResultTable
            data={tabState.resultData}
            filter={tabState.tableSearchData}
            page={tabState.page}
            pageSize={tabState.pageSize}
            totalDataCount={tabState.totalDataCount}
            hideMarkStatusSuspect={
              !userPermissions[CASE_MARK_STATUS] ||
              !userPermissions[CASE_BLACKLIST]
            }
            hideMarkStatusTrusted={
              !userPermissions[CASE_MARK_STATUS] ||
              !userPermissions[CASE_WHITELIST]
            }
            hideMarkStatusNeutral={
              !userPermissions[CASE_MARK_STATUS] ||
              !userPermissions[CASE_BLACKLIST] ||
              !userPermissions[CASE_WHITELIST]
            }
            hideAddToReview={
              addToReviewDisabled || !userPermissions[CASE_QUEUE_WRITE]
            }
            hideAccountDeactivation={!userPermissions[CASE_USER_BLOCK]}
            hideResetPassword={!userPermissions[CASE_RESET_PASSWORD]}
            hideForceLogout={!userPermissions[CASE_FORCE_LOGOUT]}
            onPageChange={onResultPageChange}
            onMarkStatus={beginMarkStatus}
            onRemediationAction={beginRemediation}
            onAddToReview={handleAddToReview}
            onSearch={onSearch}
          />
        </div>
      ))}

      <div ref={actionResultRef} style={{ margin: '1em 0' }}>
        {Object.values(state.tabs).map((tabState, i) => (
          <div
            key={`mark-status-result-${i}`}
            style={{
              display:
                state.currentTabIndex === i &&
                tabState.currentAction !== ActionType.NONE
                  ? 'block'
                  : 'none'
            }}
          >
            <div>
              {tabState.currentAction === ActionType.REMEDIATION
                ? `Remediation ${getRemediationActionName(
                    tabState.remediationAction
                  )} result`
                : `Marking status ${tabState.markingStatusAction} result`}
            </div>
            <MarkStatusResultTable
              data={tabState.markStatusResultData}
              hideActionCol={tabState.currentAction === ActionType.REMEDIATION}
              headerLabels={[
                'Case ID',
                'Action',
                'Status',
                tabState.currentAction === ActionType.REMEDIATION
                  ? 'Notes'
                  : 'Details'
              ]}
              successText={`All ${
                tabState.currentAction === ActionType.REMEDIATION
                  ? 'remediation'
                  : 'marking status'
              } for ${tabState.markingCaseCount} case(s) are success.`}
            />
          </div>
        ))}
      </div>

      <MarkSuspectModal
        open={state.currentModal === ModalState.MARK_SUSPECT}
        blacklistTags={state.blacklistTags}
        onCancel={() => dispatch({ type: 'HIDE_MODAL' })}
        onSubmit={markStatusAsSuspect}
      />
      <MarkTrustedModal
        open={state.currentModal === ModalState.MARK_TRUSTED}
        whitelistTags={state.blacklistTags}
        whitelistAttributeCombinations={state.whitelistAttributeCombinations}
        onCancel={() => dispatch({ type: 'HIDE_MODAL' })}
        onSubmit={markStatusAsTrusted}
      />
      <MarkNeutralModal
        open={state.currentModal === ModalState.MARK_NEUTRAL}
        tags={state.blacklistTags}
        onCancel={() => dispatch({ type: 'HIDE_MODAL' })}
        onSubmit={markStatusAsNeutral}
      />
      <RemediationModal
        open={state.currentModal === ModalState.REMEDIATION}
        onCancel={() => dispatch({ type: 'HIDE_MODAL' })}
        action={currentTabState.remediationAction}
        onSubmit={handleRemediationSubmit}
      />
      <ProgressModal
        open={state.currentModal === ModalState.PROGRESS}
        label={
          currentTabState.currentAction === ActionType.REMEDIATION
            ? 'Remediation'
            : 'Mark Status'
        }
        current={state.progress}
        target={currentTabState.selectedData.length}
        onClose={() => dispatch({ type: 'HIDE_MODAL' })}
        onLinkClick={handleProgressLinkClick}
      />
      <Confirm
        open={state.currentModal === ModalState.ADD_TO_QUEUE}
        size="mini"
        header="Confirmation"
        content={'Are you sure to add the selected case(s) to queue?'}
        onCancel={() => dispatch({ type: 'HIDE_MODAL' })}
        onConfirm={processAddToReview}
      />
    </React.Fragment>
  );
};

export default CaseResult;
