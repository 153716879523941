import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import { Table, Input, Button, Icon, Select } from 'semantic-ui-react';

import {
  ConnectionSearchFilterAttributes,
  PaginationType,
  SearchIdType
} from '../../../../constants/investigationToolsConstant';
import { getConnectionSearch } from '../../../../reducers/investigation-tools/selectors';
import {
  setBookingSearchType,
  setBookingFilterAttribute
} from '../../../../actions/investigation-tools/connectionSearchAction';
import { useConnectionSearchContext } from '../../../../views/investigation-tools/connection-search/ConnectionSearchContext';
import ConnectionSearchPaginationContainer from '../ConnectionSearchPaginationContainer';
import './ConnectionSearchTableContainer.style.css';
import { optionMapper } from './common';

const BookingRelationshipTableContainer = () => {
  const { getState, dispatch, handleBookingSearch } =
    useConnectionSearchContext();
  const [searchType, setSearchType] = useState('');

  const state = getState();
  const { bookingEntries, bookingSearchType, bookingFilterAttributes } =
    getConnectionSearch(state);

  useEffect(() => {
    if (
      isEqual(searchType, bookingSearchType) &&
      isFunction(handleBookingSearch)
    ) {
      handleBookingSearch();
    }
  }, [searchType, bookingSearchType, handleBookingSearch]);

  const handleSearchTypeChange = (event, data) => {
    setSearchType(data.value);
    dispatch(setBookingSearchType(data.value));
  };

  const handleBookingContactEmailChange = (event, data) => {
    const value = get(data, 'value', null);

    dispatch(
      setBookingFilterAttribute(
        ConnectionSearchFilterAttributes.BOOKING_CONTACT_EMAIL,
        typeof value === 'string' && !isEmpty(value) ? value : null
      )
    );
  };

  const handleBookingContactEmailSearchButtonClick = () =>
    isFunction(handleBookingSearch) && handleBookingSearch();

  const handleBookingContactEmailEnterKeyPress = event => {
    const key = get(event, 'key', null);

    if (typeof key === 'string' && key.toLowerCase() === 'enter') {
      handleBookingContactEmailSearchButtonClick();
    }
  };

  return (
    <div>
      <div className="connection-search-table-container">
        <Table className="connection-search-table">
          <Table.Header>
            <Table.Row className="connection-search-table-column-name-row">
              <Table.HeaderCell>Search ID</Table.HeaderCell>
              <Table.HeaderCell>Type of Search ID</Table.HeaderCell>
              <Table.HeaderCell>Relationship Profile ID</Table.HeaderCell>
              <Table.HeaderCell>Booking Contact Email</Table.HeaderCell>
              <Table.HeaderCell>Booking Contact Phone</Table.HeaderCell>
              <Table.HeaderCell>Device ID</Table.HeaderCell>
              <Table.HeaderCell>Cookie ID</Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell>
                <Select
                  className="connection-search-table-parameter-select"
                  options={optionMapper(SearchIdType)}
                  onChange={handleSearchTypeChange}
                  value={bookingSearchType}
                />
              </Table.HeaderCell>
              <Table.HeaderCell />
              <Table.HeaderCell>
                <div className="connection-search-booking-table-input-div">
                  <Input
                    className="connection-search-booking-table-input-contact-email"
                    onChange={handleBookingContactEmailChange}
                    onKeyPress={handleBookingContactEmailEnterKeyPress}
                    value={
                      get(
                        bookingFilterAttributes,
                        ConnectionSearchFilterAttributes.BOOKING_CONTACT_EMAIL,
                        null
                      ) || ''
                    }
                  />
                  <Button
                    icon
                    onClick={handleBookingContactEmailSearchButtonClick}
                  >
                    <Icon name={'search'} />
                  </Button>
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell />
              <Table.HeaderCell />
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {isArray(bookingEntries) && !isEmpty(bookingEntries) ? (
              bookingEntries.map((entry, idx) => (
                <Table.Row
                  className="connection-search-entry-table-row"
                  key={idx}
                >
                  <Table.Cell>{get(entry, 'searchId', '')}</Table.Cell>
                  <Table.Cell>{get(entry, 'searchIdType', '')}</Table.Cell>
                  <Table.Cell>
                    {get(entry, 'relationshipProfileId', '')}
                  </Table.Cell>
                  <Table.Cell>
                    {get(entry, 'bookingContactEmail', '')}
                  </Table.Cell>
                  <Table.Cell>
                    {get(entry, 'bookingContactPhone', '')}
                  </Table.Cell>
                  <Table.Cell>{get(entry, 'deviceId', '')}</Table.Cell>
                  <Table.Cell>{get(entry, 'cookieId', '')}</Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row className="connection-search-no-data-table-row">
                <Table.Cell colSpan={'7'} style={{ textAlign: 'center' }}>
                  - No Data -
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
      <ConnectionSearchPaginationContainer
        paginationType={PaginationType.BOOKING_RELATIONSHIP_TABLE}
        handleSearch={handleBookingSearch}
      />
    </div>
  );
};

export default BookingRelationshipTableContainer;
