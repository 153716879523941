import React from 'react';
import Layout from '../../../components/Layout';
import Header from '../../../components/header/Header';
import { Tab } from 'semantic-ui-react';
import WhiteListSearch from './WhiteListSearch';
import BlackListSearch from './BlackListSearch';

const ListSearch = () => {
  return (
    <Layout>
      <Header groupTitle="List Management" title="List Search"></Header>
      <Tab panes={panes} />
    </Layout>
  );
};

const panes = [
  {
    menuItem: 'Blacklist',
    render: () => (
      <Tab.Pane>
        <BlackListSearch />
      </Tab.Pane>
    )
  },
  {
    menuItem: 'Whitelist',
    render: () => (
      <Tab.Pane>
        <WhiteListSearch />
      </Tab.Pane>
    )
  }
];

export default ListSearch;
