import React, { useState, useReducer } from 'react';
import axios from 'axios';
import CaseResult from './CaseResult';
import {
  caseReducer,
  initialCaseStateWithTabs
} from '../../reducers/caseManagement';
import Layout from '../../components/Layout';
import Header from '../../components/header/Header';
import { Tab, Segment, Accordion, Icon } from 'semantic-ui-react';
import {
  MULTIPLE_CRITERIA_TAB_LABEL,
  FIELD_AND_VALUE_TAB_LABEL,
  SEARCH_PARAMS_ACCORDION_LABEL,
  MULTIPLE_CRITERIA_WIKILINK,
  FIELD_AND_VALUE_WIKILINK
} from '../../constants/caseManagement';
import MultipleFieldSearchForm from '../../components/case-management/case-search/MultipleFieldSearchForm';
import FieldAndValueSearchForm from '../../components/case-management/case-search/FieldAndValueSearchForm';
import {
  FilterData,
  CaseSearchAPIResponse,
  FilterDataType
} from '../../types/caseManagement';
import { showErrorToast } from '../../utils/common';
import configs from '../../configs';

export enum TabIndex {
  MULTIPLE_CRITERIA,
  FIELD_AND_VALUE
}

const getFilterRequestParam = (filterData: FilterData) => {
  if (filterData.type === FilterDataType.MULTIPLE_CRITERIA) {
    return {
      category: filterData.category === 'ALL' ? undefined : filterData.category,
      finalRecommendation:
        filterData.finalRecommendation === 'ALL'
          ? undefined
          : filterData.finalRecommendation,
      markingStatus:
        filterData.markingStatus === 'ALL'
          ? undefined
          : filterData.markingStatus
    };
  }

  if (filterData.type === FilterDataType.FIELD_AND_VALUE) {
    return {
      field: filterData.field,
      value: filterData.value,
      referenceType:
        filterData.referenceType === 'ALL'
          ? undefined
          : filterData.referenceType
    };
  }

  return undefined;
};

const CaseSearch = () => {
  const [state, dispatch] = useReducer(
    caseReducer,
    initialCaseStateWithTabs(2)
  );

  const [isFilterFormExpanded, setIsFilterFormExpanded] = useState(true);

  const fetchAndSetData = async (
    filterData?: FilterData,
    tableSearchData?: Record<string, string>,
    page = 0
  ) => {
    if (
      !filterData ||
      (filterData.type !== FilterDataType.MULTIPLE_CRITERIA &&
        filterData.type !== FilterDataType.FIELD_AND_VALUE)
    )
      return;

    try {
      const endpoint =
        filterData.type === FilterDataType.MULTIPLE_CRITERIA
          ? configs.caseMultiCriteriaSearch
          : configs.caseFieldAndValueSearch;

      const params = getFilterRequestParam(filterData);

      const response = (await axios.get(endpoint, {
        params: {
          ...params,
          ...tableSearchData,
          startDate: filterData.startDate,
          endDate: filterData.endDate,
          page: page
        }
      })) as { data: CaseSearchAPIResponse };

      dispatch({
        type: 'CHANGE_RESULT_DATA',
        payload: {
          data: response.data,
          page
        }
      });
    } catch (err) {
      showErrorToast(err, 'error', 'Error fetching search result');
    }
  };

  const onFilterReset = () => {
    dispatch({ type: 'RESET_FILTER' });
  };

  const onFilterSubmit = (filterData: FilterData) => {
    dispatch({ type: 'SUBMIT_FILTER', payload: { filterData } });

    fetchAndSetData(filterData, {}, 0);
  };

  const renderTabPane = (key: number, child: React.ReactNode) => (
    <Tab.Pane key={key}>
      <Segment>
        <Accordion>
          <Accordion.Title
            active={isFilterFormExpanded}
            index={0}
            onClick={() => setIsFilterFormExpanded(!isFilterFormExpanded)}
          >
            <Icon name="dropdown" />
            {SEARCH_PARAMS_ACCORDION_LABEL}
          </Accordion.Title>
          <Accordion.Content active={isFilterFormExpanded}>
            {child}
          </Accordion.Content>
        </Accordion>
      </Segment>
    </Tab.Pane>
  );

  const panes = [
    {
      menuItem: MULTIPLE_CRITERIA_TAB_LABEL,
      pane: renderTabPane(
        TabIndex.MULTIPLE_CRITERIA,
        <MultipleFieldSearchForm
          onSubmit={onFilterSubmit}
          onReset={onFilterReset}
        />
      )
    },
    {
      menuItem: FIELD_AND_VALUE_TAB_LABEL,
      pane: renderTabPane(
        TabIndex.FIELD_AND_VALUE,
        <FieldAndValueSearchForm
          onSubmit={onFilterSubmit}
          onReset={onFilterReset}
        />
      )
    }
  ];

  return (
    <Layout>
      <Header
        className="header-list-add"
        groupTitle="Case Management"
        title="Case Search"
        wikiLink={
          state.currentTabIndex === TabIndex.MULTIPLE_CRITERIA
            ? MULTIPLE_CRITERIA_WIKILINK
            : FIELD_AND_VALUE_WIKILINK
        }
      />

      <Tab
        panes={panes}
        renderActiveOnly={false}
        activeIndex={state.currentTabIndex}
        onTabChange={(a, b) =>
          dispatch({
            type: 'CHANGE_TAB',
            payload: { tabIndex: b.activeIndex as TabIndex }
          })
        }
      />

      <CaseResult
        state={state}
        dispatch={dispatch}
        fetchAndSetData={fetchAndSetData}
      />
    </Layout>
  );
};

export default CaseSearch;
