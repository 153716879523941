import { Dispatch } from 'react';
import get from 'lodash/get';
import * as commonActionType from '../../types/investigation-tools/commonActionType';
import { PaginationType } from '../../constants/investigationToolsConstant';
import { getPagination } from '../../reducers/investigation-tools/selectors';
import { setTotalNumberOfPages, goToAPage } from './paginationAction';
import { searchDriverData } from './serviceAction';
import { showErrorToast } from '../../utils/common';

type Entry = {
  [key: string]: string | number | null | Entry;
};

type Spec = {
  clientInterface: string;
  driverId: string;
  currentPageNo: number;
};

type Result = {
  data: {
    driverData: Entry;
  };
};

export const setIsLoading = (value: boolean) => ({
  type: commonActionType.SET_IS_LOADING_STATE,
  value
});

export const setEntries = (entries: Entry[]) => ({
  type: commonActionType.SET_ENTRIES,
  entries
});

export const setResult = (result: Result) => ({
  type: commonActionType.SET_RESULT,
  result: result.data
});

export const fetchDriverData = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
  getState: Function,
  driverId: string
) => {
  const state = getState();

  const pagination = getPagination(
    state.fraud,
    PaginationType.DRIVER_DATA_TABLE
  );

  const spec: Spec = {
    clientInterface: '',
    currentPageNo: get(pagination, 'currentPage', 1),
    driverId
  };

  dispatch(setIsLoading(true));

  return searchDriverData(spec)
    .then((result: any) => {
      if (result && result.data) {
        dispatch(setResult(result));
        dispatch(setEntries(result.data.driverData.deliveryHistories));

        dispatch(
          setTotalNumberOfPages(
            PaginationType.DRIVER_DATA_TABLE,
            result.data.pageCount
          )
        );

        dispatch(
          goToAPage(PaginationType.DRIVER_DATA_TABLE, result.data.currentPageNo)
        );
      } else {
        dispatch(setTotalNumberOfPages(PaginationType.DRIVER_DATA_TABLE, 1));
        dispatch(goToAPage(PaginationType.DRIVER_DATA_TABLE, 1));
      }

      dispatch(setIsLoading(false));
    })
    .catch((err: Error) => {
      showErrorToast(err);
      dispatch(setTotalNumberOfPages(PaginationType.DRIVER_DATA_TABLE, 1));
      dispatch(goToAPage(PaginationType.DRIVER_DATA_TABLE, 1));
      dispatch(setIsLoading(false));
    });
};
