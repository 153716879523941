import React from 'react';
import { Table } from 'semantic-ui-react';
import PaginationContainer from '../common/PaginationContainer';
import { getDriverData } from '../../../reducers/investigation-tools/selectors';
import { useDriverDataContext } from '../../../views/investigation-tools/driver-data/DriverDataContext';

type Entry = {
  timestamp: string;
  product: string;
  bookingId: string;
  deliveryId: string;
  originAddress: string;
  deliveryAddress: string;
  distance: string;
  transactionAmount: string;
  deliveryAmount: string;
};

const ProfileSummaryTableContainer = () => {
  const { getState } = useDriverDataContext();

  const state = getState();
  const { entries } = getDriverData(state);

  return (
    <div>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Timestamp</Table.HeaderCell>
            <Table.HeaderCell>Product</Table.HeaderCell>
            <Table.HeaderCell>Booking ID</Table.HeaderCell>
            <Table.HeaderCell>Delivery ID</Table.HeaderCell>
            <Table.HeaderCell>Origin Address</Table.HeaderCell>
            <Table.HeaderCell>Destination Address</Table.HeaderCell>
            <Table.HeaderCell>Distance</Table.HeaderCell>
            <Table.HeaderCell>Transaction</Table.HeaderCell>
            <Table.HeaderCell>Delivery</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {entries && entries.length > 0 ? (
            entries.map((entry: Entry) => {
              return (
                <Table.Row key={entry.bookingId}>
                  <Table.Cell>{entry.timestamp}</Table.Cell>
                  <Table.Cell>{entry.product}</Table.Cell>
                  <Table.Cell>{entry.bookingId}</Table.Cell>
                  <Table.Cell>{entry.deliveryId}</Table.Cell>
                  <Table.Cell>{entry.originAddress}</Table.Cell>
                  <Table.Cell>{entry.deliveryAddress}</Table.Cell>
                  <Table.Cell>{entry.distance}</Table.Cell>
                  <Table.Cell>{entry.transactionAmount}</Table.Cell>
                  <Table.Cell>{entry.deliveryAmount}</Table.Cell>
                </Table.Row>
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell colSpan={'8'}>
                <p style={{ textAlign: 'center' }}>- No Data -</p>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <PaginationContainer tableName={'DRIVER_DATA_TABLE'} />
    </div>
  );
};

export default ProfileSummaryTableContainer;
