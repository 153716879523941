import React from 'react';
import {
  NodeTypes,
  EdgeTypes,
  NodeType,
  NodeTypeKey
} from '../../types/decisionTree';

import {
  EDGE_WIDTH,
  EDGE_HEIGHT,
  DECISION_NODE_WIDTH,
  DECISION_NODE_HEIGHT
} from '../../constants/decisionTree';

export const nodeTypes: Record<NodeTypeKey, NodeType> = {
  [NodeTypeKey.EMPTY]: {
    // required to show empty nodes
    typeText: 'Empty',
    shapeId: '#empty', // relates to the type property of a node
    shape: (
      <symbol viewBox="0 0 156 156" id="empty" key="0">
        <circle cx="78" cy="78" r="75"></circle>
      </symbol>
    )
  },
  [NodeTypeKey.NORMAL]: {
    typeText: 'Node',
    shapeId: '#normal', // relates to the type property of a node
    shape: (
      <symbol viewBox="0 0 156 156" id="normal" key="0">
        <circle cx="78" cy="78" r="75"></circle>
      </symbol>
    )
  },
  [NodeTypeKey.DECISION_PACKAGE]: {
    typeText: 'Decision Package',
    shapeId: '#decisionPackage', // relates to the type property of a node
    shape: (
      <symbol
        viewBox={`0 0 ${DECISION_NODE_WIDTH} ${DECISION_NODE_HEIGHT}`}
        id="decisionPackage"
        key="0"
      >
        <rect
          x="0"
          y="0"
          width={DECISION_NODE_WIDTH}
          height={DECISION_NODE_HEIGHT}
        ></rect>
      </symbol>
    )
  }
};

export const nodeSubtypes: NodeTypes = {};

export const edgeTypes: EdgeTypes = {
  emptyEdge: {
    // required to show empty edges
    shapeId: '#emptyEdge',
    shape: (
      <symbol
        viewBox={`0 0 ${EDGE_WIDTH} ${EDGE_HEIGHT}`}
        id="emptyEdge"
        key="0"
      >
        <rect
          x="0"
          y="0"
          width={EDGE_WIDTH}
          height={EDGE_HEIGHT}
          rx="8"
          ry="8"
          fill="currentColor"
        ></rect>
      </symbol>
    )
  }
};
