import React from 'react';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { Button, Icon } from 'semantic-ui-react';
import { CSVLink } from 'react-csv';

import { printCurrencyValue } from '../../../utils/currencyView';
import './BookingDetailTableDownloadButton.style.css';

const BookingDetailTableDownloadButton = ({ entries }) => {
  if (!isArray(entries) || isEmpty(entries)) return null;

  return (
    <Button className="booking-detail-table-download-button">
      <CSVLink
        data={entries.map(entry => ({
          ...entry,
          expectedAmount: printCurrencyValue(get(entry, 'expectedAmount', '')),
          totalCouponAmount: printCurrencyValue(
            get(entry, 'totalCouponAmount', '')
          )
        }))}
        filename={'booking_detail.csv'}
      >
        <Icon name="download" /> Download current page CSV
      </CSVLink>
    </Button>
  );
};

export default BookingDetailTableDownloadButton;
