import React, { useEffect, useReducer } from 'react';
import { Loader } from 'semantic-ui-react';
import get from 'lodash/get';

import {
  initialState,
  DriverProfileContextSearchReducer
} from '../../../reducers/investigation-tools/driverProfileContextSearchReducer';
import {
  initPagination,
  goToAPage,
  setTotalNumberOfPages
} from '../../../actions/investigation-tools/paginationAction';
import { fetchDriverProfileContextSearch } from '../../../actions/investigation-tools/driverProfileContextSearchAction';
import {
  setKeyword,
  setSearchType
} from '../../../actions/investigation-tools/commonAction';
import {
  PaginationType,
  DriverProfileSearchType
} from '../../../constants/investigationToolsConstant';
import paginationReducer from '../../../reducers/investigation-tools/paginationReducer';
import { getDriverProfileContextSearch } from '../../../reducers/investigation-tools/selectors';

import DriverProfileContextSearchContext, {
  useDriverProfileContextSearchContext
} from './DriverProfileContextSearchContext';
import DriverProfileContextSearchTable from '../../../components/investigation-tools/driver-profile-context-search/DriverProfileContextSearchTable';
import Layout from '../../../components/Layout';
import SummaryCard from '../../../components/investigation-tools/common/SummaryCard';

const DriverProfileContextSearch = () => {
  const [state, dispatch] = useReducer(
    DriverProfileContextSearchReducer,
    initialState
  );
  const [paginationState, paginationDispatch] = useReducer(
    paginationReducer,
    {}
  );

  const { isLoading, isLoaded } = state;

  useEffect(() => {
    paginationDispatch(
      // @ts-ignore
      initPagination([PaginationType.DRIVER_PROFILE_CONTEXT_SEARCH_TABLE])
    );
  }, []);

  const getCombinedState = () => ({
    driver_profile_context: { ...state },
    pagination: { ...paginationState }
  });

  const handleSearch = () => {
    fetchDriverProfileContextSearch(dispatch, getCombinedState).then(result => {
      const pageCount = get(result, 'data.pageCount', null);
      const currentPageNo = get(result, 'data.currentPageNo', null);

      paginationDispatch(
        // @ts-ignore
        setTotalNumberOfPages(
          PaginationType.DRIVER_PROFILE_CONTEXT_SEARCH_TABLE,
          isFinite(pageCount) ? pageCount : 1
        )
      );

      paginationDispatch(
        // @ts-ignore
        goToAPage(
          PaginationType.DRIVER_PROFILE_CONTEXT_SEARCH_TABLE,
          isFinite(currentPageNo) ? currentPageNo : 1
        )
      );
    });
  };

  return (
    <DriverProfileContextSearchContext.Provider
      value={{
        getState: getCombinedState,
        dispatch,
        paginationDispatch,
        handleSearch
      }}
    >
      <Layout>
        <h1>Driver Profile Context Search</h1>
        <SummaryCard
          useContext={useDriverProfileContextSearchContext}
          useSelector={getDriverProfileContextSearch}
          searchTypeConstants={DriverProfileSearchType}
          setKeyword={setKeyword}
          setSearchType={setSearchType}
        />
        <Loader active={isLoading} />
        <div style={{ marginBottom: '20px' }} />
        {isLoaded && <DriverProfileContextSearchTable />}
      </Layout>
    </DriverProfileContextSearchContext.Provider>
  );
};

export default DriverProfileContextSearch;
