export type FilterOption = {
  key: string | number;
  value: string | number;
  text: string;
};

export enum DateFilterType {
  LAST_HOUR,
  TODAY,
  YESTERDAY,
  WEEK_TO_DATE,
  LAST_WEEK,
  MONTH_TO_DATE,
  LAST_MONTH,
  LAST_12_MONTHS,
  CUSTOM
}

export enum RemediationAction {
  DEACTIVATE = 'ACCOUNT_DEACTIVATION',
  RESET_PASSWORD = 'RESET_PASSWORD',
  FORCE_LOGOUT = 'FORCE_LOGOUT'
}

export type MarkingStatus = 'SUSPECT' | 'TRUSTED' | 'NEUTRAL';

export type CaseSearchAPIResponse = {
  content: {
    id: string;
    api: string;
    markingStatus: string;
    requestDate: string;
    request: {
      referenceType: string;
      referenceId: string;
      emails: string[];
      deviceId: string;
      bankAccountDetails: string;
      ipAddress: string;
      ipCountryId: string;
      cardCountryId: string;
      profileId: string;
      phones: string[];
      maskedCardNumber?: string | null;
      currency?: string | null;
      amount?: string | null;
    };
    response: {
      action: string;
    };
  }[];
  pageable: {
    page: number;
    size: number;
  };
  total: number;
};

export type CaseResultTableDetail = {
  id: string;
  api: string;
  referenceType: string;
  referenceId: string;
  requestDate: string;
  emails: string[];
  deviceId: string | null;
  ipAddress: string | null;
  ipCountryId: string | null;
  profileId: string | null;
  phones: string[];
  bankAccountDetails: string | null;
  finalRecommendation: string;
  cardCountryId: string | null;
  markingStatus: string;
  maskedCardNumber?: string | null;
  currency?: string | null;
  amount?: string | null;
};

export enum FilterDataType {
  MULTIPLE_CRITERIA,
  FIELD_AND_VALUE,
  SIMILAR_SEARCH,
  QUEUE
}

export type MultipleCriteriaFilterData = {
  type: FilterDataType.MULTIPLE_CRITERIA;
  category: string;
  finalRecommendation: string;
  markingStatus: string;
  dateType: DateFilterType;
  startDate: Date;
  endDate: Date;
};

export type FieldAndValueFilterData = {
  type: FilterDataType.FIELD_AND_VALUE;
  field: string;
  referenceType?: string;
  value: string;
  dateType: DateFilterType;
  startDate: Date;
  endDate: Date;
};

export type SimilarSearchFilterData = {
  type: FilterDataType.SIMILAR_SEARCH;
  field: string;
  value: string;
  startDate: Date;
  endDate: Date;
};

export type QueueFilterData = {
  type: FilterDataType.QUEUE;
};

export type FilterData = (
  | MultipleCriteriaFilterData
  | FieldAndValueFilterData
  | SimilarSearchFilterData
  | QueueFilterData
) & {
  type: FilterDataType;
};

export type SummaryAPIResponse = {
  caseId: string;
  api: string;
  requestDate: string;
  referenceType: string;
  referenceId: string;
  emailAddresses: string[];
  primaryEmail: string | null;
  secondaryEmail: string | null;
  phoneNumbers: string[];
  primaryPhone: string | null;
  secondaryPhone: string | null;
  profileId: string | null;
  ipAddress: string | null;
  ipCountryId: string | null;
  deviceId: string | null;
  bankAccountDetails: string | null;
  finalRecommendation: string;
  cardCountryId: string | null;
  markingStatus: string;
  maskedCardNumber?: string | null;
  currency?: string | null;
  amount?: string | null;
  reviewStatus?: string | null;
};

export type CaseWhitelistState = {
  isActive: boolean;
  attributeCombinationId?: number;
  isTagSelected: Record<string, boolean>;
  note: string;
};

export type WhitelistAttributeCombination = {
  id: number;
  list: string[];
  displayName: string;
};

export type CaseWhitelistSubmitParams = {
  attributeCombinations: string[];
  notes: string;
  tags: string[];
};

export type CaseBlacklistState = {
  isActive: boolean;
  isFieldSelected: Record<string, boolean>;
  isTagSelected: Record<string, boolean>;
  note: string;
};

export type CaseBlacklistSubmitParams = {
  attributeTypes: string[];
  notes: string;
  tags: string[];
};

export enum CaseProfileRemediationType {
  ACCOUNT_DEACTIVATION,
  RESET_PASSWORD
}

export type CaseProfileRemediationState = {
  isActive: boolean;
  type?: CaseProfileRemediationType;
  note: string;
};

export type CaseAccountDeactivationSubmitParams = {
  reason: string;
};

export type CaseResetPasswordSubmitParams = {
  reason: string;
};

export type CaseRemediationSubmitParams = {
  whitelist?: CaseWhitelistSubmitParams;
  blacklist?: CaseBlacklistSubmitParams;
  accountDeactivation?: CaseAccountDeactivationSubmitParams;
  resetPassword?: CaseResetPasswordSubmitParams;
};

export type CaaseMarkingToolsSubmitParams = {
  markingStatus: string;
  reason: string;
};

export type CommonAPIResponse =
  | string
  | number
  | boolean
  | null
  | CommonAPIResponse[]
  | { [key: string]: CommonAPIResponse };

export type DetectorAPIResponse = {
  name: string;
  res: CommonAPIResponse;
}[];

export type CaseNotesData = {
  createdAt: string;
  createdBy: string;
  notes: string;
};

export type CaseNotesSubmitParams = {
  notes: string;
};

export type ActionHistoryData = {
  createdAt: string;
  createdBy: string;
  details: string;
  notes: string;
  action: string;
  status: string;
};

export type MarkingStatusFailedAttribute = {
  attributeType: string;
  tag: string;
  failureReason: string;
};

export type MarkingStatusResultData = {
  caseId: string;
  remediationStatus: string;
  relevantAttributes: string[];
  tags: string[];
  failedAttributes: MarkingStatusFailedAttribute[];
  action: string;
};

export type BulkRemediationAPIResponse = {
  caseId: string;
  status: string;
}[];

export type BulkMarkSuspectAPIResponseCases = {
  caseId: string;
  remediationStatus: string;
  relevantAttributes: string[];
  failedAttributes: MarkingStatusFailedAttribute[];
  markingFailure: boolean;
  markingFailureReason: string;
};

export type BulkMarkSuspectAPIResponse = {
  failedCases: BulkMarkSuspectAPIResponseCases[];
};

export type BulkMarkNeutralAPIResponseCases = {
  caseId: string;
  wlRemediationStatus: string;
  blRemediationStatus: string;
  relevantAttributes: string[];
  wlFailedAttributes: MarkingStatusFailedAttribute[];
  blFailedAttributes: MarkingStatusFailedAttribute[];
  markingFailure: boolean;
  markingFailureReason: string;
};

export type BulkMarkNeutralAPIResponse = {
  failedCases: BulkMarkNeutralAPIResponseCases[];
};

export type BulkMarkTrustedAPIResponseCases = {
  caseId: string;
  remediationStatus: string;
  failedTags: MarkingStatusFailedTag[];
  markingFailure: boolean;
  markingFailureReason: string;
};

export type MarkingStatusFailedTag = {
  tag: string;
  failureReason: string;
};

export type BulkMarkTrustedAPIResponse = {
  failedCases: BulkMarkTrustedAPIResponseCases[];
};
