import React from 'react';
import { GCEdge } from '../../types/decisionTree';
import { dtTooltipContentStyle } from '../../views/decision-tree/DecisionTree.style';

type Props = {
  edge: GCEdge;
};

const EdgeTooltipContent = ({ edge }: Props) => {
  const lines = edge.handleText.split('\n');
  lines.shift();

  return (
    <div>
      <div>
        <b>Edge ID: {edge.id}</b>
      </div>

      <div className={dtTooltipContentStyle}>Priority: {edge.priority}</div>

      <div className={dtTooltipContentStyle}>
        {lines.map((line, i) => (
          <div key={`tooltip-content-info-${i}`}>{line}</div>
        ))}
      </div>
    </div>
  );
};

export default EdgeTooltipContent;
