import React, { useContext } from 'react';
import PrivateRoute from '../PrivateRoute';
import { Redirect } from 'react-router-dom';
import { AppContext } from '../../AppContext';

const ProductApiRequiredRoute = ({
  component: Component,
  productApiParam,
  ...rest
}) => {
  const [{ productApis }] = useContext(AppContext);

  return (
    <PrivateRoute
      {...rest}
      component={Component}
      render={({ match }) => {
        const urlProductId = Number(match.params[productApiParam]);

        if (!productApis.find(api => api.id === urlProductId))
          return <Redirect to="/" />;

        return <Component />;
      }}
    />
  );
};

export default ProductApiRequiredRoute;
