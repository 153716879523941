import React from 'react';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';
import Table from 'semantic-ui-react/dist/commonjs/collections/Table';
import { TableBody, TableCell, TableRow } from 'semantic-ui-react';
import _ from 'lodash';
import { css } from 'emotion';
import Header from 'semantic-ui-react/dist/commonjs/elements/Header';

const activePropertiesStyle = css`
  &.table tbody tr td {
    border: none;
  }
`;

const InfoBox = ({ title = null, data, className = '', labels = {} }) => {
  if (!data) return null;

  const objKeys = Object.keys(data);
  return (
    <Segment>
      {title && <Header as="h3">{title}</Header>}
      {_.isEmpty(data) ? (
        <span>No active graph found</span>
      ) : (
        <Table basic="very" className={activePropertiesStyle} columns={10}>
          <TableBody className={className}>
            {objKeys.map(item => (
              <TableRow key={item}>
                <TableCell width={3}>{labels[item] || item}</TableCell>
                {data[item] && typeof data[item] === 'object' ? (
                  <TableCell width={7}>
                    {Object.values(data[item]).join(' ')}
                  </TableCell>
                ) : (
                  <TableCell width={7}>{data[item]}</TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Segment>
  );
};

export default InfoBox;
