import get from 'lodash/get';
import {
  DriverProfileContextFilterAttributes,
  DriverProfileSearchType
} from '../../constants/investigationToolsConstant';
import { actionHandler } from './commonReducerLogic';

type Entry = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

type DriverProfileContextSearchState = {
  isLoading: boolean;
  isLoaded: boolean;
  keyword: string;
  searchType: string;
  filterAttributes: {
    [key: string]: string;
  };
  entries: Entry[];
};

export const initialState: DriverProfileContextSearchState = {
  isLoading: false,
  isLoaded: false,
  keyword: '',
  searchType: get(DriverProfileSearchType, 0, null),
  filterAttributes: {
    [DriverProfileContextFilterAttributes.DRIVER_NAME]: null,
    [DriverProfileContextFilterAttributes.DOMICILE_CITY]: null
  },
  entries: []
};

export function DriverProfileContextSearchReducer(
  state = initialState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any
) {
  return actionHandler[action.type]
    ? actionHandler[action.type](state, action)
    : state;
}
