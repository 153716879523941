/**
 * Adapted from Traveloka Soya Component
 * https://github.com/traveloka/soya-component
 * This class to handle some event for sid-nav (e.g: onscroll, onkeypress, etc)
 */
class ActionEventListener {
  constructor(event, action, el) {
    this._el = el;
    this._event = event;
    this._action = action;
  }

  listen() {
    if (typeof window !== 'undefined') {
      (this._el || document).addEventListener(this._event, this._action, true);
    }
  }

  destroy() {
    if (typeof window !== 'undefined') {
      (this._el || document).removeEventListener(
        this._event,
        this._action,
        true
      );
    }
  }

  /**
   *
   * @param eventType: get from ActionEventListener.event
   * @param param: can be { el, callback } | callback depends on the eventType
   * @param el: can be null if it's document
   * @returns {ActionEventListener}
   */
  static createPredefinedEvent(eventType, param, el) {
    switch (eventType) {
      case ActionEventListener.event.SAVE_KEY:
        return new ActionEventListener(
          'keydown',
          ActionEventListener.eventSaveKey(param),
          el
        );
      case ActionEventListener.event.CLICK_OUTSIDE:
        return new ActionEventListener(
          'click',
          ActionEventListener.eventClickedOutside(param),
          el
        );
      case ActionEventListener.event.CTRL_K:
        return new ActionEventListener(
          'keydown',
          ActionEventListener.eventSearchMenu(param),
          el
        );
      case ActionEventListener.event.SCROLL:
        return new ActionEventListener(
          'scroll',
          ActionEventListener.eventScroll(param),
          el
        );
      // no default
    }
  }

  static eventSaveKey(callback) {
    return event => {
      if (event.which === 83 && event.ctrlKey) {
        event.preventDefault();
        callback(event);
        return;
      }
    };
  }

  static eventClickedOutside(param) {
    const { el, callback } = param;
    return event => {
      if (!el || !el.contains(event.target)) {
        callback(event);
        return;
      }
    };
  }

  static eventSearchMenu(callback) {
    return event => {
      if (event.which === 75 && event.ctrlKey) {
        event.preventDefault();
        callback(event);
        return;
      }
    };
  }

  static eventScroll(callback) {
    return event => {
      callback(event);
    };
  }

  /**
   * To get actions/event from the user
   * @returns event type
   */
  static get event() {
    return {
      SAVE_KEY: 'SAVE_KEY',
      CLICK_OUTSIDE: 'CLICK_OUTSIDE',
      CTRL_K: 'CTRL_K',
      SCROLL: 'SCROLL'
    };
  }
}

export default ActionEventListener;
