export const SET_IS_LOADING_APPLICATION_STATE =
  'SET_IS_LOADING_APPLICATION_STATE';
export const SET_IS_LOADING_BOOKING_STATE = 'SET_IS_LOADING_BOOKING_STATE';
export const SET_IS_LOADED_APPLICATION_STATE =
  'SET_IS_LOADED_APPLICATION_STATE';
export const SET_IS_LOADED_BOOKING_STATE = 'SET_IS_LOADED_BOOKING_STATE';
export const SET_KEYWORD = 'SET_KEYWORD';
export const SET_APPLICATION_SEARCH_TYPE = 'SET_APPLICATION_SEARCH_TYPE';
export const SET_BOOKING_SEARCH_TYPE = 'SET_BOOKING_SEARCH_TYPE';
export const SET_MATCH_TYPE = 'SET_MATCH_TYPE';
export const SET_APPLICATION_ENTRIES = 'SET_APPLICATION_ENTRIES';
export const SET_BOOKING_ENTRIES = 'SET_BOOKING_ENTRIES';
export const SET_APPLICATION_FILTER_ATTRIBUTE =
  'SET_APPLICATION_FILTER_ATTRIBUTE';
export const SET_BOOKING_FILTER_ATTRIBUTE = 'SET_BOOKING_FILTER_ATTRIBUTE';
