import React, { useEffect, useReducer } from 'react';
import get from 'lodash/get';
import { useLocation, useParams } from 'react-router-dom';
import { GridRow, GridColumn, Grid, Loader } from 'semantic-ui-react';

import {
  initialState,
  userProfileListReducer
} from '../../../reducers/investigation-tools/userProfileListReducer';
import Layout from '../../../components/Layout';
import { fetchUserProfileList } from '../../../actions/investigation-tools/userProfileListAction';
import {
  initPagination,
  goToAPage,
  setTotalNumberOfPages
} from '../../../actions/investigation-tools/paginationAction';
import paginationReducer from '../../../reducers/investigation-tools/paginationReducer';
import { PaginationType } from '../../../constants/investigationToolsConstant';
import UserProfileListTableContainer from '../../../components/investigation-tools/profile-summary/UserProfileListTableContainer';
import UserProfileListPaginationContainer from '../../../components/investigation-tools/profile-summary/UserProfileListPaginationContainer';

const listNames = {
  UNIQUE: 'Unique Profile ID',
  UNIQUE_NAUGHTY: 'Unique Naughty Profile ID'
};

const UserProfileList = () => {
  const { profileId } = useParams();
  const searchParams = new URLSearchParams(useLocation().search);

  const [state, dispatch] = useReducer(userProfileListReducer, initialState);
  const [paginationState, paginationDispatch] = useReducer(paginationReducer);

  const proxyId = searchParams.get('proxyId');
  const proxyType = searchParams.get('proxyType');
  const listType = searchParams.get('listType');

  const pagination = get(
    paginationState,
    PaginationType.PROFILE_SUMMARY_PROFILE_LIST
  );

  const currentPage = get(pagination, 'currentPage', 1);
  const totalNumberOfPages = get(pagination, 'totalNumberOfPages', 1);

  useEffect(() => {
    paginationDispatch(
      initPagination([PaginationType.PROFILE_SUMMARY_PROFILE_LIST])
    );
  }, []);

  useEffect(() => {
    fetchUserProfileList(
      dispatch,
      profileId,
      proxyId,
      proxyType,
      listType,
      currentPage
    ).then(result => {
      const pageCount = get(result, 'data.pageCount', null);
      const currentPageNo = get(result, 'data.currentPageNo', null);

      paginationDispatch(
        setTotalNumberOfPages(
          PaginationType.PROFILE_SUMMARY_PROFILE_LIST,
          isFinite(pageCount) ? pageCount : 1
        )
      );

      paginationDispatch(
        goToAPage(
          PaginationType.PROFILE_SUMMARY_PROFILE_LIST,
          isFinite(currentPageNo) ? currentPageNo : 1
        )
      );
    });
  }, [profileId, proxyId, proxyType, listType, currentPage]);

  return (
    <Layout>
      <h1>
        Profile Summary
        {listType ? ` - ${get(listNames, listType.toUpperCase(), '')}` : ''}
      </h1>
      <div style={{ margin: '50px 0' }}>
        <Grid>
          <GridRow>
            <GridColumn width={4}>
              <strong>Proxy ID</strong>
            </GridColumn>
            <GridColumn width={12}>
              <strong>{proxyId || '-'}</strong>
            </GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn width={4}>
              <strong>Type of Proxy</strong>
            </GridColumn>
            <GridColumn width={12}>
              <strong>{proxyType || '-'}</strong>
            </GridColumn>
          </GridRow>
        </Grid>
      </div>
      {get(state, 'isLoading', false) ? (
        <Loader active />
      ) : (
        <>
          <UserProfileListTableContainer
            profileList={get(state, 'profileList', null)}
          />
          <UserProfileListPaginationContainer
            dispatch={paginationDispatch}
            currentPage={currentPage}
            totalNumberOfPages={totalNumberOfPages}
          />
        </>
      )}
    </Layout>
  );
};

export default UserProfileList;
