import React, { ReactNode } from 'react';
import { Table } from 'semantic-ui-react';
import { MarkingStatusResultData } from '../../../types/caseManagement';
import { Link } from 'react-router-dom';

type Props = {
  data: MarkingStatusResultData[];
  hideActionCol?: boolean;
  headerLabels?: [string, string, string, string];
  successText?: string;
};

const MarkStatusResultTable = ({
  data,
  hideActionCol = false,
  headerLabels = ['Case ID', 'Action', 'Status', 'Detail'],
  successText = 'All marking status and remediation are success.'
}: Props) => {
  const renderRowDetail = (row: MarkingStatusResultData, i: number) => {
    const r: ReactNode[] = [];

    if (row.failedAttributes && row.failedAttributes.length > 0) {
      r.push(
        <Table.Row key={`case-mark-status-blacklist-result-${i}`}>
          <Table.Cell>
            <Link to={`/case-details/${row.caseId}`} target="_blank">
              {row.caseId}
            </Link>
          </Table.Cell>
          {!hideActionCol && <Table.Cell>{row.action}</Table.Cell>}
          <Table.Cell>{row.remediationStatus}</Table.Cell>
          <Table.Cell>
            {row.relevantAttributes.length > 0 && (
              <div>
                <b>Attributes</b>: {row.relevantAttributes.join(', ')}
              </div>
            )}
            {row.tags.length > 0 && (
              <div>
                <b>Tag(s)</b>: {row.tags.join(', ')}
              </div>
            )}
            <div>
              {(row.relevantAttributes.length > 0 || row.tags.length > 0) && (
                <div>
                  <b>Failed</b>:
                </div>
              )}
              {row.failedAttributes.map((entry, i) => (
                <div key={`case-mark-status-fail-${i}`}>
                  {entry.attributeType}
                  {entry.attributeType ? '-' : ''}
                  {entry.tag}
                  {entry.tag ? '-' : ''}
                  {entry.failureReason}
                </div>
              ))}
            </div>
          </Table.Cell>
        </Table.Row>
      );
    }

    return r;
  };

  return (
    <Table celled striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{headerLabels[0]}</Table.HeaderCell>
          {!hideActionCol && (
            <Table.HeaderCell>{headerLabels[1]}</Table.HeaderCell>
          )}
          <Table.HeaderCell>{headerLabels[2]}</Table.HeaderCell>
          <Table.HeaderCell>{headerLabels[3]}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {data.length > 0 ? (
          data.map((row, i) => renderRowDetail(row, i)).flat()
        ) : (
          <Table.Row textAlign="left">
            <Table.Cell colSpan={4}>{successText}</Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};

export default MarkStatusResultTable;
