import React, { useContext } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { normalLayoutStyle, fullLayoutStyle } from './Layout.style';
import 'semantic-ui-css/semantic.min.css';
import { AppContext } from '../AppContext';

const Layout = ({ children }) => {
  const [appState] = useContext(AppContext);

  const contentClass = appState.isSideNavOpen ? 'open' : 'collapsed';
  const layoutStyle = appState.isDTMaximized
    ? fullLayoutStyle
    : normalLayoutStyle;

  return (
    <div>
      <Dimmer
        active={appState.isLoading > 0}
        style={{ zIndex: 9999, height: '100%', backgroundColor: '#fff0' }}
        inverted
      >
        <Loader size="massive">Loading</Loader>
      </Dimmer>
      <div className={`${layoutStyle} ${contentClass}`}>{children}</div>
    </div>
  );
};

export default Layout;
