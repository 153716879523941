import { css } from 'emotion';

export const spaceTopStyle = css`
  & {
    margin-top: 1.5em;
  }
`;

export const containerStyle = css`
  & {
    display: flex;
  }

  & .details {
    flex: 1 1 auto;
  }

  & .similar-search {
    flex: 0 0 12em;
  }
`;

export const cardStyle = css`
  & .header-with-button {
    padding: 0.45em 1em !important;
  }

  & .header-flex {
    display: flex;
    align-items: center;
  }

  & .header-title {
    flex: 1 1 auto;
  }

  & .header-button {
    margin-top: 0.2em;
  }

  & .header-button:hover {
    background-color: #aaa;
  }
`;
