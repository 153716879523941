import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Form, Segment, Header } from 'semantic-ui-react';
import config from '../../../configs';
import {
  isEmpty,
  isEmailValid,
  isPhoneNumberValid,
  isIpAddressValid,
  isMaskedCreditCardValid,
  isEmailDomainValid,
  isValidWithoutSpace
} from '../../../utils/commonFormValidations';
import axios from 'axios';
import { listAdditionStyle } from './WhiteListAddition.style';

const WhiteListAddition = () => {
  const [visibleListFields, setVisibleListFields] = useState([]);
  const isListFieldVisible = fieldKey =>
    visibleListFields.indexOf(fieldKey) !== -1;

  const isEmailAddressFieldVisible = isListFieldVisible('EMAIL_ADDRESS');
  const isEmailDomainFieldVisible = isListFieldVisible('EMAIL_DOMAIN');
  const isPhoneNumberFieldVisible = isListFieldVisible('PHONE_NUMBER');
  const isDeviceIdFieldVisible = isListFieldVisible('DEVICE_ID');
  const isIpAddressFieldVisible = isListFieldVisible('IP_ADDRESS');
  const isBankAccDetailsFieldVisible = isListFieldVisible(
    'BANK_ACCOUNT_DETAILS'
  );
  const isProfileIdFieldVisible = isListFieldVisible('PROFILE_ID');
  const isPaymentMethodFieldVisible = isListFieldVisible('PAYMENT_METHOD');

  //Form state
  const [combinationId, setCombinationId] = useState('');
  const [tagId, setTagId] = useState('');
  const [notes, setNotes] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [emailDomain, setEmailDomain] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [deviceId, setDeviceId] = useState('');
  const [profileId, setProfileId] = useState('');
  const [ipAddress, setIpAddress] = useState('');
  const [bankAccDetails, setBankAccDetails] = useState('');
  const [paymentMethodId, setPaymentMethodId] = useState('');
  const [paymentDetails, setPaymentDetails] = useState('');
  const [referenceTypeId, setReferenceTypeId] = useState('');
  const [referenceId, setReferenceId] = useState('');
  const [paymentDetailsError, setPaymentDetailsError] = useState(false);

  const isReferenceIdRequired = referenceTypeId !== '';

  const isFormInvalid = () => {
    const combinationIdInvalid = isEmpty(combinationId);
    const tagIdInvalid = isEmpty(tagId);
    const emailAddressInvalid =
      isEmailAddressFieldVisible &&
      (isEmpty(emailAddress) || !isEmailValid(emailAddress));
    const phoneNumberInvalid =
      isPhoneNumberFieldVisible &&
      (isEmpty(phoneNumber) || !isPhoneNumberValid(phoneNumber));
    const deviceIdInvalid = isDeviceIdFieldVisible && isEmpty(deviceId);
    const ipAddressInvalid =
      isIpAddressFieldVisible &&
      (isEmpty(ipAddress) || !isIpAddressValid(ipAddress));
    const bankAccDetailsInvalid =
      isBankAccDetailsFieldVisible && isEmpty(bankAccDetails);
    const profileIdInvalid = isProfileIdFieldVisible && isEmpty(profileId);
    const referenceIdInvalid = isReferenceIdRequired && isEmpty(referenceId);

    let paymentMethodInvalid = false;

    if (isPaymentMethodFieldVisible) {
      if (isEmpty(paymentMethodId) || isEmpty(paymentDetails)) {
        paymentMethodInvalid = true;
      } else {
        const selectedPaymentMethod = paymentMethods.filter(
          method => method.key === paymentMethodId
        );
        if (
          selectedPaymentMethod.length > 0 &&
          selectedPaymentMethod[0].text === 'Credit Card' &&
          !isMaskedCreditCardValid(paymentDetails)
        ) {
          setPaymentDetailsError({
            content:
              "Credit card number must be masked, starting with 6 digits followed by 2-9 'X's and ending with 3-4 digits"
          });
          paymentMethodInvalid = true;
        }
      }
    }

    return (
      combinationIdInvalid ||
      tagIdInvalid ||
      emailAddressInvalid ||
      phoneNumberInvalid ||
      deviceIdInvalid ||
      ipAddressInvalid ||
      bankAccDetailsInvalid ||
      profileIdInvalid ||
      paymentMethodInvalid ||
      referenceIdInvalid
    );
  };

  const clearForm = () => {
    setVisibleListFields([]);
    setCombinationId('');
    setTagId('');
    setNotes('');
    setEmailAddress('');
    setEmailDomain('');
    setPhoneNumber('');
    setDeviceId('');
    setProfileId('');
    setIpAddress('');
    setBankAccDetails('');
    setPaymentMethodId('');
    setPaymentDetails('');
    setReferenceTypeId('');
    setReferenceId('');
    setPaymentDetailsError(false);
  };

  //Options for dropdown fields
  const [attributeCombinations, setAttributeCombinations] = useState([]);
  const [tags, setTags] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [referenceTypes, setReferenceTypes] = useState([]);

  const toDropDownList = responseList => {
    return responseList.map(item => {
      return {
        key: item.id,
        text: item.displayName,
        value: item.id
      };
    });
  };

  const toCombinationList = responseList => {
    return responseList.map(item => {
      return {
        key: item.id,
        text: item.displayName,
        value: item.id,
        fields: item.list
      };
    });
  };

  const isInputDisplayError = (value, validator) => {
    return !isEmpty(value) && !isValidWithoutSpace(value, validator);
  };

  useEffect(() => {
    // API for attribute combinations
    axios
      .get(config.whitelistAttributeCombinations)
      .then(res => {
        if (res && res.data) {
          setAttributeCombinations(toCombinationList(res.data));
        }
      })
      .catch(err => {
        toast.error(
          'Error loading attribute combinations. Error: ' + (err && err.message)
        );
      });

    // API for tags
    axios
      .get(config.whitelistTags)
      .then(res => {
        if (res && res.data) {
          setTags(toDropDownList(res.data));
        }
      })
      .catch(err => {
        toast.error('Error loading tags. Error: ' + (err && err.message));
      });

    // API for whitelist payment methods
    axios
      .get(config.whitelistPaymentMethods)
      .then(res => {
        if (res && res.data) {
          setPaymentMethods(toDropDownList(res.data));
        }
      })
      .catch(err => {
        toast.error(
          'Error loading Payment Methods. Error: ' + (err && err.message)
        );
      });

    // API for whitelist reference types
    axios
      .get(config.whitelistReferenceTypes)
      .then(res => {
        if (res && res.data) {
          setReferenceTypes(toDropDownList(res.data));
        }
      })
      .catch(err => {
        toast.error(
          'Error loading Payment Methods. Error: ' + (err && err.message)
        );
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onCombinationIdChange = (evt, data, setter) => {
    const selectedOption = data.options.find(
      option => option.value === data.value
    );

    if (selectedOption) {
      setVisibleListFields(selectedOption.fields);
    } else {
      setVisibleListFields([]);
    }
    setPaymentDetailsError(false);

    onFormFieldValueChange(evt, data, setter);
  };

  const onPaymentDetailsChange = data => {
    setPaymentDetailsError(false);
    setPaymentDetails(data.value);
  };

  const onFormFieldValueChange = (evt, data, setter) => {
    setter(data.value);
  };

  const onBlurRemoveSpaces = (evt, setter) => {
    setter(evt.target.value.split(' ').join(''));
  };

  const onBlurTrim = (evt, setter) => {
    setter(evt.target.value.trim());
  };

  const onSubmit = () => {
    if (isFormInvalid()) {
      toast.error('Please fix errors / required fields in the form');
      return;
    }

    const formPayload = toFormPayload();

    axios
      .post(config.whitelistEntry, formPayload)
      .then(() => {
        toast.success('Whitelist entry has been added successfully!');
        clearForm();
      })
      .catch(err => {
        if (err.response) {
          toast.error('Error: ' + err.response.data);
        } else {
          toast.error('Error saving whitelist data: ' + (err && err.message));
        }
      });
  };

  const toFormPayload = () => {
    let payment = null;
    let referenceInfo = null;

    if (isPaymentMethodFieldVisible) {
      payment = {
        details: paymentDetails,
        paymentMethodId: paymentMethodId
      };
    }

    //it doesn't append referenceInfo to payload if required elements is empty
    if (isReferenceIdRequired) {
      referenceInfo = {
        referenceIdNotes: referenceId,
        referenceTypeId: referenceTypeId
      };
    }

    const formPayload = {
      attributeCombinationId: combinationId,
      fields: {
        deviceId: isDeviceIdFieldVisible ? deviceId : null,
        emailAddress: isEmailAddressFieldVisible ? emailAddress : null,
        emailDomain: isEmailDomainFieldVisible ? emailDomain : null,
        ipAddress: isIpAddressFieldVisible ? ipAddress : null,
        bankAccountDetails: isBankAccDetailsFieldVisible
          ? bankAccDetails
          : null,
        phoneNumber: isPhoneNumberFieldVisible ? phoneNumber : null,
        profileId: isProfileIdFieldVisible ? profileId : null,
        payment: payment,
        referenceInfo: referenceInfo
      },
      metadata: {
        notes: notes,
        tagId: tagId
      }
    };

    return formPayload;
  };

  return (
    <div className={listAdditionStyle}>
      <br />
      <Form>
        <Form.Select
          inline
          required
          search
          clearable
          label={'Field Option'}
          value={combinationId}
          onChange={(evt, data) =>
            onCombinationIdChange(evt, data, setCombinationId)
          }
          options={attributeCombinations}
          placeholder={'Select field option'}
        />

        <Segment>
          <Header size={'medium'}>Marking Details</Header>
          <Form.Select
            inline
            required
            search
            clearable
            label={'Tag'}
            value={tagId}
            onChange={(evt, data) =>
              onFormFieldValueChange(evt, data, setTagId)
            }
            options={tags}
            placeholder={'Select Tag option'}
          />

          <Form.TextArea
            inline
            label={'Marking Notes'}
            value={notes}
            onChange={(evt, data) =>
              onFormFieldValueChange(evt, data, setNotes)
            }
            placeholder={'Marking Notes'}
          />
        </Segment>

        <Segment>
          <Header size={'medium'}>List Fields</Header>
          <Form.Input
            inline
            error={isInputDisplayError(emailAddress, isEmailValid)}
            label={'Email Address'}
            value={isEmailAddressFieldVisible ? emailAddress : ''}
            onChange={(evt, data) =>
              onFormFieldValueChange(evt, data, setEmailAddress)
            }
            onBlur={evt => onBlurRemoveSpaces(evt, setEmailAddress)}
            placeholder={'Email Address'}
            disabled={!isEmailAddressFieldVisible}
            required={isEmailAddressFieldVisible}
          />

          <Form.Input
            inline
            error={isInputDisplayError(emailDomain, isEmailDomainValid)}
            label={'Email Domain'}
            value={isEmailDomainFieldVisible ? emailDomain : ''}
            onChange={(evt, data) =>
              onFormFieldValueChange(evt, data, setEmailDomain)
            }
            onBlur={evt => onBlurRemoveSpaces(evt, setEmailDomain)}
            placeholder={'Email Domain'}
            disabled={!isEmailDomainFieldVisible}
            required={isEmailDomainFieldVisible}
          />

          <Form.Input
            inline
            error={isInputDisplayError(phoneNumber, isPhoneNumberValid)}
            label={'Phone Number'}
            value={isPhoneNumberFieldVisible ? phoneNumber : ''}
            onChange={(evt, data) =>
              onFormFieldValueChange(evt, data, setPhoneNumber)
            }
            onBlur={evt => onBlurRemoveSpaces(evt, setPhoneNumber)}
            placeholder={'Phone Number'}
            disabled={!isPhoneNumberFieldVisible}
            required={isPhoneNumberFieldVisible}
          />

          <Form.Input
            inline
            label={'Device ID'}
            value={isDeviceIdFieldVisible ? deviceId : ''}
            onChange={(evt, data) =>
              onFormFieldValueChange(evt, data, setDeviceId)
            }
            onBlur={evt => onBlurRemoveSpaces(evt, setDeviceId)}
            placeholder={'Device ID'}
            disabled={!isDeviceIdFieldVisible}
            required={isDeviceIdFieldVisible}
          />

          <Form.Input
            inline
            label={'IP Address'}
            error={isInputDisplayError(ipAddress, isIpAddressValid)}
            value={isIpAddressFieldVisible ? ipAddress : ''}
            onChange={(evt, data) =>
              onFormFieldValueChange(evt, data, setIpAddress)
            }
            onBlur={evt => onBlurRemoveSpaces(evt, setIpAddress)}
            placeholder={'IP Address'}
            disabled={!isIpAddressFieldVisible}
            required={isIpAddressFieldVisible}
          />

          <Form.Input
            inline
            label={'Bank Account Details'}
            value={isBankAccDetailsFieldVisible ? bankAccDetails : ''}
            onChange={(evt, data) =>
              onFormFieldValueChange(evt, data, setBankAccDetails)
            }
            onBlur={evt => onBlurTrim(evt, setBankAccDetails)}
            placeholder={'Bank Account Details'}
            disabled={!isBankAccDetailsFieldVisible}
            required={isBankAccDetailsFieldVisible}
          />

          <Form.Input
            inline
            label={'Profile ID'}
            value={isProfileIdFieldVisible ? profileId : ''}
            onChange={(evt, data) =>
              onFormFieldValueChange(evt, data, setProfileId)
            }
            onBlur={evt => onBlurRemoveSpaces(evt, setProfileId)}
            placeholder={'Profile ID'}
            disabled={!isProfileIdFieldVisible}
            required={isProfileIdFieldVisible}
          />

          <Form.Select
            inline
            search
            clearable
            label={'Payment Method'}
            value={isPaymentMethodFieldVisible ? paymentMethodId : ''}
            onChange={(evt, data) =>
              onFormFieldValueChange(evt, data, setPaymentMethodId)
            }
            options={paymentMethods}
            placeholder={'Select Payment Method'}
            disabled={!isPaymentMethodFieldVisible}
            required={isPaymentMethodFieldVisible}
          />

          <Form.Input
            inline
            label={'Payment Details'}
            value={isPaymentMethodFieldVisible ? paymentDetails : ''}
            onChange={(evt, data) => onPaymentDetailsChange(data)}
            placeholder={'Payment Details'}
            disabled={!isPaymentMethodFieldVisible}
            required={isPaymentMethodFieldVisible}
            error={paymentDetailsError}
          />

          <Form.Select
            inline
            search
            clearable
            label={'Reference Type'}
            value={referenceTypeId}
            onChange={(evt, data) =>
              onFormFieldValueChange(evt, data, setReferenceTypeId)
            }
            options={referenceTypes}
            placeholder={'Select Reference Type'}
          />

          <Form.Input
            inline
            label={'Reference ID'}
            disabled={!isReferenceIdRequired}
            required={isReferenceIdRequired}
            value={isReferenceIdRequired ? referenceId : ''}
            onChange={(evt, data) =>
              onFormFieldValueChange(evt, data, setReferenceId)
            }
            placeholder={'Reference ID'}
          />
        </Segment>

        <Segment basic className="button-container">
          <Button
            primary
            icon="add"
            content="Add Whitelist"
            onClick={onSubmit}
          />
          <Button basic icon="delete" content="Clear" onClick={clearForm} />
        </Segment>
      </Form>
      <br />
    </div>
  );
};

export default WhiteListAddition;
