import {
  CASE_READ,
  CASE_QUEUE_READ,
  INVESTIGATION_TOOLS_READ
} from '../constants/userPermissions';

type ProductApi = {
  id: number;
  displayName: string;
  name: string;
};

type MenuLink = {
  path: string;
  title: string;
  childPaths?: string[];
  neededPermission?: string[];
};

type MenuSection = {
  title: string;
  icon: string;
  links?: MenuLink[];
  linksFromProductApi?: boolean;
  getLinkPaths?: (api: ProductApi) => string;
  getLinkTitles?: (api: ProductApi) => string;
  getLinkChildPaths?: (api: ProductApi) => string[];
  neededPermission?: string[];
};

const menu: MenuSection[] = [
  {
    title: 'Case Management',
    icon: 'suitcase',
    neededPermission: [CASE_READ],
    links: [
      {
        path: '/case-search/',
        title: 'Case Search',
        childPaths: ['/case-details/', '/case-similar-search']
      },
      {
        path: '/case-queue/',
        title: 'Case Queue',
        neededPermission: [CASE_QUEUE_READ]
      }
    ]
  },
  {
    title: 'List Management',
    icon: 'edit outline',
    links: [
      {
        path: '/list-search/',
        title: 'List Search'
      },
      {
        path: '/',
        title: 'List Addition'
      }
    ]
  },
  {
    title: 'Rules Management',
    icon: 'file alternate outline',
    linksFromProductApi: true,
    getLinkPaths: (api: { id: number; displayName: string }) =>
      `/rule-management/${api.id}/`,
    getLinkTitles: (api: { id: number; displayName: string }) =>
      api.displayName,
    getLinkChildPaths: (api: { id: number; displayName: string }) => [
      `/decision-tree/edit/${api.id}/`
    ]
  },
  {
    title: 'Investigation Tools',
    icon: 'spy',
    links: [
      {
        path: '/investigation-tools/profile-summary',
        title: 'Profile Summary',
        neededPermission: [INVESTIGATION_TOOLS_READ.PROFILE_SUMMARY]
      },
      {
        path: '/investigation-tools/booking-detail',
        title: 'Booking Detail',
        neededPermission: [INVESTIGATION_TOOLS_READ.BOOKING_DETAIL]
      },
      {
        path: '/investigation-tools/connection-search',
        title: 'Connection Search',
        neededPermission: [
          INVESTIGATION_TOOLS_READ.CONNECTION_SEARCH_APPLICATION,
          INVESTIGATION_TOOLS_READ.CONNECTION_SEARCH_BOOKING
        ]
      },
      {
        path: '/investigation-tools/activity-page',
        title: 'Activity',
        neededPermission: [INVESTIGATION_TOOLS_READ.ACTIVITY]
      },

      {
        path: '/investigation-tools/user-profile-context-search',
        title: 'User Profile Context Search',
        neededPermission: [INVESTIGATION_TOOLS_READ.USER_PROFILE_CONTEXT_SEARCH]
      },
      {
        path: '/investigation-tools/driver-profile-context-search',
        title: 'Driver Profile Context Search',
        neededPermission: [
          INVESTIGATION_TOOLS_READ.DRIVER_PROFILE_CONTEXT_SEARCH
        ]
      },
      {
        path: '/investigation-tools/payment-data',
        title: 'Payment Data',
        neededPermission: [INVESTIGATION_TOOLS_READ.PAYMENT_DATA]
      },
      {
        path: '/investigation-tools/fraud-check-history',
        title: 'Fraud Check History',
        neededPermission: [INVESTIGATION_TOOLS_READ.FRAUD_CHECK_HISTORY]
      },
      {
        path: '/investigation-tools/comm-activity',
        title: 'Comm Activity',
        neededPermission: [INVESTIGATION_TOOLS_READ.COMM_ACTIVITY]
      },
      {
        path: '/investigation-tools/merchant-data',
        title: 'Merchant Data',
        neededPermission: [INVESTIGATION_TOOLS_READ.MERCHANT_DATA]
      }
    ]
  },
  {
    title: 'Configuration',
    icon: 'settings',
    links: [
      {
        path: '/settings/',
        title: 'Settings'
      }
    ]
  }
];

export default menu;
