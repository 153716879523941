import React, { useState, useEffect } from 'react';
import { Grid, GridColumn, Icon, Table } from 'semantic-ui-react';
import { convertToLocalTime } from '../../utils/dateFormatter';
import { NavLink, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import config from '../../configs';
import { toast } from 'react-toastify';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';

import axios from 'axios';
import { css } from 'emotion';

const linkCreateStyle = css`
  & {
    margin-top: 10px !important;
    margin-bottom: 30px !important;
  }
`;

const totalLabelStyle = css`
  & {
    padding: 0;
  }
`;

const DecisionTreeList = props => {
  const headers = [
    'Version',
    'Description',
    'Status',
    'Created by',
    'Created at (Local time)',
    'Actions'
  ];
  const [redirect, setRedirect] = useState(false);
  const [treeId, setTreeId] = useState(false);
  const [data, setData] = useState([]);

  const PAGE_SIZE = 10;
  const [page, setPage] = useState(0);
  const [totalNum, setTotalNum] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    setPage(0);
    setTotalNum(0);
    setTotalPages(0);

    getDecisionTreeList(0);
  }, [props.productApiId]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDecisionTreeList = selectedPage => {
    // API for Decision Tree Summary
    axios
      .get(config.rulesDecisionTreeSummary + props.productApiId, {
        params: {
          page: selectedPage,
          size: PAGE_SIZE,
          sort: 'version,desc'
        }
      })
      .then(res => {
        if (res && res.data && res.data.content) {
          setData(res.data.content);
          setPage(selectedPage);
          setTotalNum(res.data.total);
          setTotalPages(Math.ceil(res.data.total / PAGE_SIZE));
        }
      })
      .catch(err => {
        toast.error(
          'Error fetching Decision Graph summary. Error: ' +
            (err && err.message)
        );
      });
  };

  const hasNext = () => totalPages > 0 && page + 1 < totalPages;
  const nextPage = () => {
    if (!hasNext()) {
      return;
    }
    getDecisionTreeList(page + 1);
  };

  const hasPrev = () => totalPages > 0 && page - 1 >= 0;
  const prevPage = () => {
    if (!hasPrev()) {
      return;
    }
    getDecisionTreeList(page - 1);
  };

  const viewDTreeHandler = id => {
    setTreeId(id);
    setRedirect(true);
  };

  if (redirect) {
    const url = '/decision-tree/edit/' + props.productApiId + '/' + treeId;
    return <Redirect to={url} />;
  }

  return (
    <Segment>
      <NavLink exact to={'/decision-tree/edit/' + props.productApiId}>
        <Button
          primary
          floated="right"
          icon="add"
          content="Create Graph"
          className={linkCreateStyle}
        />
      </NavLink>
      <Table celled>
        <Table.Header>
          {headers && (
            <Table.Row textAlign="center">
              {headers.map((header, idx) => {
                return <Table.HeaderCell key={idx}>{header}</Table.HeaderCell>;
              })}
            </Table.Row>
          )}
        </Table.Header>
        <Table.Body>
          {data.length ? (
            data.map((detail, idx) => {
              return (
                <Table.Row key={idx} textAlign="center">
                  <Table.Cell width={1}>{detail.version}</Table.Cell>
                  <Table.Cell width={3}>{detail.description}</Table.Cell>
                  <Table.Cell width={1}>{detail.status}</Table.Cell>
                  <Table.Cell width={2}>{detail.createdBy}</Table.Cell>
                  <Table.Cell width={2}>
                    {convertToLocalTime(detail.createdAt)}
                  </Table.Cell>
                  <Table.Cell width={1}>
                    <Icon
                      link
                      size="large"
                      name="eye"
                      onClick={() => viewDTreeHandler(detail.id)}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })
          ) : (
            <Table.Row textAlign="center">
              <Table.Cell colSpan={headers.length}>No Data</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <Grid colums={15} className={data.length ? 'margin-top-0' : 'hidden'}>
        <GridColumn floated={'left'} width={5} verticalAlign={'middle'}>
          <label className={totalLabelStyle}>
            <h4>
              Showing {page * PAGE_SIZE + 1}-{page * PAGE_SIZE + data.length} of{' '}
              {totalNum} rows
            </h4>
          </label>
        </GridColumn>
        <GridColumn floated={'right'} width={10} verticalAlign={'middle'}>
          <Button
            content="Prev"
            icon="left arrow"
            labelPosition="left"
            disabled={!hasPrev()}
            onClick={prevPage}
          />
          <Button
            content="Next"
            icon="right arrow"
            labelPosition="right"
            disabled={!hasNext()}
            onClick={nextPage}
          />
        </GridColumn>
      </Grid>
    </Segment>
  );
};

DecisionTreeList.propTypes = {
  productApiId: PropTypes.string.isRequired
};

export default DecisionTreeList;
