import React, { useEffect, useReducer } from 'react';
import { Loader } from 'semantic-ui-react';

import {
  initialState,
  PaymentDataReducer
} from '../../../reducers/investigation-tools/paymentDataReducer';
import {
  initPagination,
  setTotalNumberOfPages,
  goToFirstPage
} from '../../../actions/investigation-tools/paginationAction';
import paginationReducer from '../../../reducers/investigation-tools/paginationReducer';
import { fetchPaymentData } from '../../../actions/investigation-tools/paymentDataAction';
import {
  setKeyword,
  setSearchType
} from '../../../actions/investigation-tools/commonAction';
import {
  PaginationType,
  PaymentDataSearchType
} from '../../../constants/investigationToolsConstant';
import { getPaymentData } from '../../../reducers/investigation-tools/selectors';

import PaymentDataContext, {
  usePaymentDataContext
} from './PaymentDataContext';
import PaymentDataTable from '../../../components/investigation-tools/payment-data/PaymentDataTable';
import Layout from '../../../components/Layout';
import SummaryCard from '../../../components/investigation-tools/common/SummaryCard';
import { paymentDataSearchLabels } from '../../../constants/investigationToolsLabels';

const PaymentData = () => {
  const [state, dispatch] = useReducer(PaymentDataReducer, initialState);
  const [paginationState, paginationDispatch] = useReducer(
    paginationReducer,
    {}
  );

  const { isLoading } = state;

  useEffect(() => {
    paginationDispatch(initPagination([PaginationType.PAYMENT_DATA_TABLE]));
  }, []);

  const getCombinedState = () => ({
    payment_data: state,
    pagination: paginationState
  });

  const handleSearch = async (toFirstPage = false) => {
    if (toFirstPage) {
      paginationDispatch(goToFirstPage(PaginationType.PAYMENT_DATA_TABLE));
    }

    setTimeout(() => {
      fetchPaymentData(dispatch, getCombinedState).then(result => {
        let totalPages = 1;
        const { totalNumberOfPages } =
          paginationState[PaginationType.PAYMENT_DATA_TABLE];

        if (result && result.data) {
          const { pageCount } = result.data;
          totalPages = pageCount;
        }
        if (totalNumberOfPages !== totalPages) {
          paginationDispatch(
            setTotalNumberOfPages(PaginationType.PAYMENT_DATA_TABLE, totalPages)
          );
        }
      });
    }, 500);
  };

  return (
    <PaymentDataContext.Provider
      value={{
        getState: getCombinedState,
        dispatch,
        paginationDispatch,
        handleSearch
      }}
    >
      <Layout>
        <h1>Payment Data</h1>
        <SummaryCard
          useContext={usePaymentDataContext}
          useSelector={getPaymentData}
          searchTypeConstants={PaymentDataSearchType}
          setKeyword={setKeyword}
          setSearchType={setSearchType}
          labels={paymentDataSearchLabels}
        />
        <Loader active={isLoading} />
        <div style={{ marginBottom: '20px' }} />
        {!isLoading && <PaymentDataTable />}
      </Layout>
    </PaymentDataContext.Provider>
  );
};

export default PaymentData;
