export const formatNumber = (
  amount: number,
  decimalCount = 0,
  decimalSeparator = '.',
  thousandSeparator = ','
): string => {
  const negativeSign = amount < 0 ? '-' : '';
  const amountStr = amount.toFixed(decimalCount).replace('-', '');
  const [intPartStr, decimalPartStr] = amountStr.split('.');
  const thousandCount = intPartStr.length > 3 ? intPartStr.length % 3 : 0;

  return (
    negativeSign +
    (thousandCount
      ? intPartStr.substr(0, thousandCount) + thousandSeparator
      : '') +
    intPartStr
      .substr(thousandCount)
      .replace(/(\d{3})(?=\d)/g, '$1' + thousandSeparator) +
    (decimalPartStr ? `${decimalSeparator}${decimalPartStr}` : '')
  );
};
