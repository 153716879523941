import React, { useEffect, useContext } from 'react';
import { Icon, Image } from 'semantic-ui-react';
import { NavLink, useLocation } from 'react-router-dom';
import get from 'lodash/get';
import { useAuth0 } from '../react-auth0-spa';
import config from '../configs';
import menu from '../configs/menu';
import { AppContext } from '../AppContext';
import permissionChecker from '../utils/permissionChecker';
import { default as SideNav } from './side-nav/SideNav';
import 'semantic-ui-css/semantic.min.css';

const getNavBarMenus = (user, logout, productApi, permissions) => {
  if (!permissions) return [];

  const activeStyle = { backgroundColor: 'rgba(255,255,255,0.08)' };
  const menuPermissionChecker = permissionChecker(permissions);

  const sideNavMenu = [];

  menu.forEach(m => {
    if (!menuPermissionChecker(m.neededPermission)) return;

    sideNavMenu.push({
      title: m.title,
      iconText: <Icon name={m.icon} />,
      links: m.linksFromProductApi
        ? productApi.map(api => ({
            path: m.getLinkPaths?.(api),
            childPaths: m.getLinkChildPaths?.(api),
            apiId: api.id,
            component: (
              <NavLink
                exact
                to={m.getLinkPaths?.(api)}
                activeStyle={activeStyle}
              >
                {m.getLinkTitles?.(api)}
              </NavLink>
            )
          }))
        : m.links
            ?.map(l => {
              if (
                !menuPermissionChecker(l.neededPermission) ||
                (config.disabledPaths && config.disabledPaths.includes(l.path))
              ) {
                return null;
              }

              return {
                path: l.path,
                childPaths: l.childPaths,
                component: (
                  <NavLink exact to={l.path} activeStyle={activeStyle}>
                    {l.title}
                  </NavLink>
                )
              };
            })
            .filter(l => l)
    });
  });

  sideNavMenu.push({
    title: user.name,
    iconText: <Image src={user.picture} size="mini" circular />,
    showAtBottom: true,
    links: [
      {
        path: '/logout',
        component: (
          <NavLink to="/logout" onClick={logout}>
            <Icon name="log out" /> Log Out
          </NavLink>
        )
      }
    ]
  });

  return sideNavMenu;
};

const PopulatedSideNav = () => {
  const [appState, dispatch] = useContext(AppContext);
  const { user, logout } = useAuth0();
  const route = useLocation();

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin + config.appLogout
    });

  useEffect(() => {
    dispatch({ type: 'MINIMIZE_DT' });
  }, [dispatch, route]);

  return !appState.isDTMaximized ? (
    <SideNav
      title="Central Fraud Platform"
      menuData={getNavBarMenus(
        user,
        logoutWithRedirect,
        appState.productApis,
        appState.userPermissions
      )}
      currentPath={get(route, 'pathname', null) || ''}
    />
  ) : null;
};

export default PopulatedSideNav;
