import React, { useEffect, useRef } from 'react';
import { DTLog } from '../../types/decisionTree';
import { formatLog } from '../../utils/decision-tree/treeConsoleUtils';
import { css } from 'react-emotion';

type Props = {
  expanded?: boolean;
  logs?: DTLog[];
  contentHeight?: number;
  onHeadClick?: () => void;
};

const welcomeMsg = `Welcome to Decision Graph Editor
Quick Notes:
To create a node     : shift + click
To create an edge    : shift + click on node and drag to other node
To select node/edge  : click node/edge
To delete selected   : press Delete
To zoom in/out       : scroll up/down
In screen, only one root node is allowed
Execution order is done from edge with smallest number to largest
`;

const welcomeLog = formatLog(welcomeMsg);

const style = css`
  & {
    color: #fff;
    font-family: Menlo, Courier New;
  }

  & .head {
    padding: 0.25em;
    border-color: #868686;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #4c4a44;
    text-align: center;
    cursor: ns-resize;
    font-weight: bold;
  }

  & .body {
    overflow: auto;
    background-color: #300a24;
    text-rendering: optimizeLegibility;
    transition: max-height 500ms;
  }

  & .body .content {
    padding: 1em;
  }

  & pre {
    padding: 0;
    margin: 0;
    font-family: Menlo, Courier New;
  }

  & pre.log-info {
    color: #fff;
  }

  & pre.log-success {
    color: #6ee7b7;
  }

  & pre.log-warn {
    color: #fde68a;
  }

  & pre.log-error {
    color: #f87171;
  }
`;

const DecisionTreeConsole = ({
  expanded = false,
  logs = [],
  contentHeight = 380,
  onHeadClick
}: Props) => {
  const logsWithWelcome = [welcomeLog, ...logs];

  const bodyDiv = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!bodyDiv.current) return;

    bodyDiv.current.scrollTop = bodyDiv.current.scrollHeight;
  }, [logs]);

  return (
    <div className={style}>
      <div className="head" onClick={onHeadClick}>
        Decision Graph Console
      </div>
      <div
        ref={bodyDiv}
        className="body"
        style={{
          height: contentHeight,
          maxHeight: expanded ? contentHeight : 0
        }}
      >
        <div className="content">
          {logsWithWelcome.map((log, i) => (
            <pre key={`log-${i}`} className={`log-${log.level.toLowerCase()}`}>
              {log.message}
            </pre>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DecisionTreeConsole;
